import React from "react";
import TermsConditionsPrivacyPolicyController, {
  Props,
} from "./TermsConditionsPrivacyPolicyController.web";
import CustomTermsAndConditionsDialog from "../../../components/src/CustomTermsAndConditionsDialog.web";

export default class TermsConditionsPrivacyPolicy extends TermsConditionsPrivacyPolicyController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const {
      showPrivacyPolicy,
      showTermsAndConditions,
      isTermsAndPrivacyPolicyChecked,
      privacyPolicyDataObject,
      termsAndConditionsDataObject,
    } = this.state;
    return (
      <CustomTermsAndConditionsDialog
        isChecked={isTermsAndPrivacyPolicyChecked}
        showTermsAndConditions={showTermsAndConditions}
        showPrivacyPolicy={showPrivacyPolicy}
        isOpen={!!this.props.isOpenTermsAndPolicyDialog}
        handleChecked={this.handleChangeTermsAndPrivacyCheck}
        toggleAccordion={this.toggleAccordion}
        onAgree={() => this.props.handleClose(true)}
        onCancel={() => this.props.handleClose(false)}
        privacyPolicyDataObject={privacyPolicyDataObject}
        termsAndConditionsDataObject={termsAndConditionsDataObject}
      />
    );
  }
}
