import React from "react";
import VerifyEmailController, {
  Props,
  configJSON,
} from "./VerifyEmailController";
import { Box } from "@material-ui/core";
import { verifyEmailBackground, logo } from "./assets";
import CustomButton from "../../../components/src/CustomButton.web";
import CustomOTPInput from "../../../components/src/CustomOTPInput.web";

export default class VerifyEmail extends VerifyEmailController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { otpCode, verificationFailedMsg } = this.state;
    return (
      <Box height={"auto"}>
        <Box style={webStyle.container}>
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            height={"inherit"}
            width={"100%"}
            margin={"auto"}
          >
            <Box width={"551px"}>
              <Box sx={webStyle.verifyEmailContainer}>
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  paddingBottom={"32px"}
                >
                  <img src={logo} alt="logo" width={279} height={179} />
                </Box>
                <Box textAlign={"center"} style={webStyle.title}>
                  {configJSON.verifyEmailLabel}
                </Box>
                <Box
                  textAlign={"center"}
                  style={webStyle.verifyEmailDescription}
                >
                  {configJSON.verifyEmailDescriptionLabel}
                </Box>
                {verificationFailedMsg && (
                  <Box
                    style={webStyle.verificationError}
                    data-test-id="error-message"
                  >
                    {verificationFailedMsg}
                  </Box>
                )}
                <Box
                  padding={"0px 0px 92px"}
                  paddingTop={verificationFailedMsg ? "32px" : "92px"}
                >
                  <CustomOTPInput
                    otp={otpCode}
                    onChange={this.handleOTPChange}
                    digitCount={6}
                  />
                </Box>
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  marginBottom={"62px"}
                >
                  <Box>
                    <CustomButton
                      label={configJSON.goBackLabel}
                      onClick={this.handleGoBack}
                      variant="outlined"
                      width={173}
                    />
                  </Box>
                  <Box paddingLeft={"8px"}>
                    <CustomButton
                      label={configJSON.verifyLabel}
                      onClick={this.handleVerifyEmail}
                      variant="contained"
                      width={173}
                      disabled={otpCode.length < 6}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  }
}

const webStyle = {
  container: {
    backgroundImage: `url(${verifyEmailBackground})`,
    backgroundSize: "cover",
    backgroundColor: "#001327",
    backgroundPosition: "center",
    height: "100%",
    display: "flex",
    minHeight: "100vh",
  },
  verifyEmailContainer: {
    padding: "32px 40px",
    borderRadius: "16px",
    background: "rgba(0, 19, 39, 0.60)",
    backdropFilter: "blur(8px)",
    "@media (max-width: 600px)": {
      padding: "32px 10px",
    },
  },
  title: {
    color: "#FFF",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontSize: "36px",
    fontWeight: 700,
    lineHeight: 1.22,
    letterSpacing: -0.36,
  },
  verifyEmailDescription: {
    color: "#FFF",
    fontFamily: "Open Sans",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: 1.5,
    paddingTop: "4px",
    paddingBottom: "16px",
  },
  verificationError: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: 700,
    borderLeft: "2px solid #DC2626",
    fontSize: "12px",
    lineHeight: 1.5,
    color: "#DC2626",
    backgroundColor: "#FEE2E2",
    borderRadius: "4px",
    padding: "21px 16px",
  },
};
