import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "../../../framework/src/Utilities";
import { clearStorage } from "../../../components/src/Utilities";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  handleRedirection: (path: string) => void;
}

interface S {
  isOpenDeleteAccountDialog: boolean;
}

interface SS {}

export default class SettingsDeleteAccountController extends BlockComponent<
  Props,
  S,
  SS
> {
  deleteAccountAPIId: string = "";

  constructor(props: Props) {
    super(props);

    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      isOpenDeleteAccountDialog: false,
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.deleteAccountAPIId) {
      if (responseJson?.message) {
        clearStorage();
        this.props.handleRedirection("Home");
      }
    }
  }

  toggleDeleteAccountDialog = () => {
    this.setState({
      isOpenDeleteAccountDialog: !this.state.isOpenDeleteAccountDialog,
    });
  };

  deleteAccount = async () => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: await getStorageData("token")
    };

    const deleteAccountRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteAccountAPIId = deleteAccountRequestMsg.messageId;

    deleteAccountRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteAccountApiEndPoint
    );

    deleteAccountRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    deleteAccountRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteApiMethodType
    );

    runEngine.sendMessage(deleteAccountRequestMsg.id, deleteAccountRequestMsg);
  };
}
