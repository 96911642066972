import React from "react";
import { Box, Button, Dialog, DialogContent } from "@material-ui/core";

import SettingsDeleteAccountController, {
  Props,
  configJSON,
} from "./SettingsDeleteAccountController.web";
import { dialogBackground } from "./assets";
import CustomButton from "../../../components/src/CustomButton.web";

export default class SettingsDeleteAccount extends SettingsDeleteAccountController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { isOpenDeleteAccountDialog } = this.state;
    return (
      <Box>
        <Box style={webStyle.settingsTabTitle}>
          {configJSON.settingsTabList[4]}
        </Box>
        <Box padding={"32px 0px"}>
          <Box style={webStyle.descriptionText}>
            {configJSON.deleteAccountDescription}
          </Box>
        </Box>
        <Box display={"flex"} justifyContent={"center"}>
          <Button
            onClick={this.toggleDeleteAccountDialog}
            style={webStyle.deleteBtn}
            variant="outlined"
          >
            {configJSON.settingsTabList[4]}
          </Button>
        </Box>
        {isOpenDeleteAccountDialog && (
          <Dialog
            open={isOpenDeleteAccountDialog}
            PaperProps={{
              style: webStyle.deleteDialogPaper,
            }}
            fullWidth
          >
            <DialogContent style={webStyle.deleteDialogContent}>
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                style={webStyle.dialogTitle}
              >
                {configJSON.settingsTabList[4]}
              </Box>
              <Box
                style={webStyle.dialogDescription}
                padding={"28px 0px"}
                textAlign={"center"}
              >
                {configJSON.deleteAccountConfirmation}
              </Box>
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Box paddingRight={"8px"}>
                  <CustomButton
                    variant="outlined"
                    label={configJSON.settingsTabList[4]}
                    onClick={this.deleteAccount}
                    width={173}
                  />
                </Box>
                <Box>
                  <CustomButton
                    variant="contained"
                    label={configJSON.keepAccount}
                    onClick={this.toggleDeleteAccountDialog}
                    width={173}
                  />
                </Box>
              </Box>
            </DialogContent>
          </Dialog>
        )}
      </Box>
    );
  }
}

const webStyle = {
  settingsTabTitle: {
    fontFamily: "Brown",
    fontStyle: "normal",
    fontWeight: 700,
    color: "#FFF",
    fontSize: "32px",
  },
  descriptionText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 400,
    color: "#FFF",
    fontSize: "16px",
    lineHeight: 1.5,
  },
  deleteBtn: {
    width: "250px",
    borderRadius: "8px",
    color: "#DC2626",
    textTransform: "none",
    borderColor: "#DC2626",
    height: "44px",
    fontSize: "16px",
    fontWeight: 700,
    fontStyle: "normal",
    fontFamily: "Open Sans",
  } as React.CSSProperties,
  deleteDialogPaper: {
    borderRadius: "16px",
    backgroundColor: "#001327",
    width: "418px",
  },
  deleteDialogContent: {
    backgroundImage: `url(${dialogBackground})`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    padding: "40px 0px 32px",
  },
  dialogTitle: {
    fontFamily: "Open Sans",
    fontSize: "30px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: 1.33,
    letterSpacing: -0.15,
    color: "#FFF",
  },
  dialogDescription: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: 1.5,
    color: "#FFF",
  },
};
