Object.defineProperty(exports, "__esModule", {
    value: true,
  });
  
  // Customizable Area Start
  exports.apiContentType = "application/json";
  exports.confirmPaymentMethod = "POST";
  exports.stripePaymentIntent = "stripe_integration/payment_intents"
  
  exports.stripePublishableKey = ""
  exports.stripeMerchantDisplayName = "Example.com"
  exports.stripeMerchantIdentifier = "stripeMerchantIdentifier" //used for apple pay
  exports.urlScheme = "rnbuildingblockmasterapp" //app deep linking url
exports.createSubscriptionApiEndPoint =
  "bx_block_stripe_integration/create_subscription?plan=Amortize Prepaid Expense Module&token_stripe=";
exports.subscriptionMessage = "Subscription created successfully";
exports.submit = "Submit";
exports.cancel = "Cancel";
exports.stripeKey =
  "pk_test_51OdGPDC2oZPoMLCljLoTsaICr2WFl85T9pWgoShpmrou7Rh84zn54owbtnQidVGz5JwLrMVmSvFiW0rpibHQcPkT00fPXNpL6e";
exports.optionMode = "subscription";
exports.optionAmount = 100;
exports.optionCurrency = "usd";
exports.optionPaymentMethod = "manual";
exports.apiGetMethod = "GET";
exports.apiDeleteMethod = "DELETE";
exports.noSubscriptionMsg = "No Subscription found for this account";
exports.subscriptionFound = "Active subscription found";
exports.checkSubscriptionStatusApiEndPoint =
  "bx_block_stripe_integration/check_subscription_status";
exports.showCardListApiEndPoint =
  "bx_block_stripe_integration/show_card_details";
exports.deleteCardApiEndPoint =
  "bx_block_stripe_integration/remove_card?card_token=";
exports.addNewCardApiEndPoint = "bx_block_stripe_integration/add_card_to_user";
exports.setDefaultCardApiEndPoint =
  "bx_block_stripe_integration/set_default_payment";
exports.addNewCard = "+ Add New Card";
exports.savedCards = "Saved Cards";
exports.setAsActive = "Set as Active";
exports.updateCardSuccessMsg = "Card updated successfully";
exports.deleteCardSuccessMsg = "Card removed successfully";
exports.addCardSuccessMsg = "Card added successfully";
exports.addCardBtn = "Add New Card";
exports.cardNumber = "Card number";
exports.expiryDate = "Expiration date";
exports.securityCode = "Security code";
exports.editOrCancelSubscription = "Edit / Cancel Subscription";
exports.editSubscription = "Edit Subscription";
exports.cancelSubscription = "Cancel Subscription";
exports.close = "Close";
exports.terminateSubscription = "Terminate Subscription & Log Out";
exports.cancelFutureSubscription = "Cancel Future Subscription";
exports.cancelSubscriptionMidMonthApiEndPoint =
  "bx_block_stripe_integration/cancel_subscription_mid_month";
exports.cancelAndLogoutApiEndPoint =
  "bx_block_stripe_integration/cancel_subscription";
exports.logoutApiEndPoint = "account_block/logout_from_all_devices";
exports.putMethod = "PUT";
exports.cancelAtMidMonthSuccessMsg =
  "Subscription cancellation scheduled at the end of the current billing period";
exports.cancelImmediatelySuccessMsg = "Subscription canceled immediately";
exports.immediateCancelDescription =
  "Your subscription will be canceled immediately, and you will be logged out. No further charges will be applied.";
exports.cancelAtMidDescription =
  "Your subscription will be canceled at the end of the current billing period. No further charges will be applied.";
exports.addCardFailedMsg = "Authentication failed! Unable to add card.";
exports.authenticationRequiredMsg = "3D Secure authentication required";
exports.active = "Active";
exports.reactivateSubscriptionApiEndPoint =
  "bx_block_stripe_integration/reactivate_subscription";
exports.reactivateSubscriptionTitle = "Reactivate Subscription";
exports.reactivate = "Reactivate";
exports.reactivateSuccessMsg = "Subscription reactivated successfully";
exports.reactivateConfirmMsg =
  "Are you sure you want to reactivate your subscription? This will resume your billing cycle, and your next payment will be processed on the scheduled date.";
  // Customizable Area End
  