import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import OtpInput from "react-otp-input";

const useStyles = makeStyles((theme) => ({
  otpInputContainer: {
    justifyContent: "center",
    "& input": {
      border: "1px solid #CBD5E1 !important",
      width: "56px  !important",
      height: "56px  !important",
      borderRadius: "8px  !important",
      fontSize: "24px  !important",
      margin: "0px 12px  !important",
      fontFamily: "Open Sans  !important",
      [theme.breakpoints.down("xs")]: {
        margin: "0px 4px  !important",
        width: "40px  !important",
        height: "40px  !important",
      },
    },
  },
}));

interface ICustomOTPInputProps {
  otp: string;
  onChange: (otp: string) => void;
  digitCount: number;
}

const CustomOTPInput = (props: ICustomOTPInputProps) => {
  const classes = useStyles(props);

  return (
    <OtpInput
      value={props.otp}
      onChange={props.onChange}
      numInputs={props.digitCount}
      renderInput={(propsOb) => <input {...propsOb} />}
      containerStyle={classes.otpInputContainer}
      inputType="tel"
    />
  );
};

export default CustomOTPInput;
