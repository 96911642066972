import React from "react";
import { Box, Dialog, DialogContent } from "@material-ui/core";

import SettingsEditProfileController, {
  Props,
  configJSON,
} from "./SettingsEditProfileController.web";
import CustomInput from "../../../components/src/CustomInput.web";
import CustomButton from "../../../components/src/CustomButton.web";
import { dialogBackground } from "./assets";
import CustomPasswordInput from "../../../components/src/CustomPasswordInput.web";
import CustomOTPInput from "../../../components/src/CustomOTPInput.web";

export default class SettingsEditProfile extends SettingsEditProfileController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const {
      fullName,
      businessName,
      email,
      isOpenChangeEmailDialog,
      isOpenVerificationDialog,
      isShoPassword,
      otpCode,
      newEmail,
      currentPassword,
      fullNameError,
      businessNameError,
      editProfileSuccessMsg,
      emailError,
      currentPasswordError,
      changeEmailError,
      isResendClicked,
      emailChangeSuccessMsg,
    } = this.state;
    return (
      <Box>
        <Box style={webStyle.editProfileLabel}>
          {configJSON.settingsTabList[0]}
        </Box>
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          paddingTop={"24px"}
          borderBottom={"1px solid #57534E"}
        >
          <Box
            sx={{
              minWidth: {
                xs: "300px",
                sm: "320px",
                md: "480px",
              },
            }}
          >
            {editProfileSuccessMsg && (
              <Box
                style={webStyle.editProfileSuccessMsg}
                data-test-id="success-message"
              >
                {editProfileSuccessMsg}
              </Box>
            )}
            <Box paddingTop={"8px"}>
              <Box style={webStyle.fieldInputLabel}>{configJSON.fullName}</Box>
              <CustomInput
                value={fullName}
                handleChange={(event) =>
                  this.handleChangeProfileField(event, "fullName")
                }
                placeholder={configJSON.fullName}
                isError={!!fullNameError}
                errorMessage={fullNameError}
              />
            </Box>
            <Box padding={"32px 0px"}>
              <Box style={webStyle.fieldInputLabel}>
                {configJSON.businessName}
              </Box>
              <CustomInput
                placeholder={configJSON.businessName}
                value={businessName}
                handleChange={(event) =>
                  this.handleChangeProfileField(event, "businessName")
                }
                isError={!!businessNameError}
                errorMessage={businessNameError}
              />
            </Box>
            <Box
              paddingBottom={"32px"}
              justifyContent={"center"}
              display={"flex"}
              alignItems={"center"}
            >
              <Box>
                <CustomButton
                  variant="outlined"
                  onClick={this.saveProfile}
                  width={148}
                  label={configJSON.saveChanges}
                  disabled={this.checkForSaveButtonDisabled()}
                />
              </Box>
            </Box>
          </Box>
        </Box>
        <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
          <Box
            sx={{
              minWidth: {
                xs: "300px",
                sm: "320px",
                md: "480px",
              },
            }}
          >
            {changeEmailError && (
              <Box style={webStyle.errorMsg} data-test-id="error-message">
                {changeEmailError}
              </Box>
            )}
            {emailChangeSuccessMsg && (
              <Box
                style={webStyle.editProfileSuccessMsg}
                data-test-id="success-message"
              >
                {emailChangeSuccessMsg}
              </Box>
            )}
            <Box padding={"32px 0px"}>
              <Box style={webStyle.fieldInputLabel}>{configJSON.email}</Box>
              <CustomInput
                value={email}
                handleChange={(event) => {}}
                placeholder={configJSON.email}
                disabled
              />
            </Box>
            <Box
              paddingBottom={"32px"}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Box>
                <CustomButton
                  variant="outlined"
                  onClick={this.handleOpenChangeEmailDialog}
                  width={148}
                  label={configJSON.changeEmail}
                />
              </Box>
            </Box>
          </Box>
        </Box>
        {isOpenChangeEmailDialog && (
          <Dialog
            open={isOpenChangeEmailDialog}
            PaperProps={{
              style: webStyle.changeEmailDialogPaper,
            }}
            onClose={this.handleCloseEmailDialog}
            fullWidth
          >
            <DialogContent style={webStyle.changeEmailDialogContent}>
              <Box
                textAlign={"center"}
                style={webStyle.changeEmailDialogTitle}
                padding={"0px 8px 8px"}
              >
                {configJSON.changeEmail}
              </Box>
              <Box
                style={webStyle.changeEmailDialogSubtitle}
                padding={"8px 8px 16px"}
                textAlign={"center"}
              >
                {configJSON.changeEmailDescription}
              </Box>
              <Box padding={"16px 0px"}>
                <Box style={webStyle.fieldInputLabel}>
                  {configJSON.newEmail}
                </Box>
                <CustomInput
                  value={newEmail}
                  handleChange={(event) =>
                    this.handleChangeProfileField(event, "newEmail")
                  }
                  placeholder={configJSON.newEmailPlaceholder}
                  isError={!!emailError}
                  errorMessage={emailError}
                />
              </Box>
              <Box padding={"16px 0px"}>
                <Box style={webStyle.fieldInputLabel}>
                  {configJSON.currentPasswordLabel}
                </Box>
                <CustomPasswordInput
                  value={currentPassword}
                  handleChange={(event) =>
                    this.handleChangeProfileField(event, "currentPassword")
                  }
                  placeholder={configJSON.currentPasswordPlaceholder}
                  isShow={isShoPassword}
                  handleToggle={this.togglePassword}
                  isError={!!currentPasswordError}
                  errorMessage={currentPasswordError}
                />
              </Box>
              <Box
                padding={"32px 0px 0px"}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Box>
                  <CustomButton
                    variant="contained"
                    onClick={() => this.changeEmail(false)}
                    width={240}
                    label={configJSON.sendChangeEmailCode}
                    disabled={
                      !newEmail ||
                      !currentPassword ||
                      !!emailError ||
                      !!currentPasswordError
                    }
                  />
                </Box>
              </Box>
            </DialogContent>
          </Dialog>
        )}
        {isOpenVerificationDialog && (
          <Dialog
            open={isOpenVerificationDialog}
            PaperProps={{
              style: webStyle.verificationDialogWrapper,
            }}
            fullWidth
            onClose={this.handleOpenChangeEmailDialog}
          >
            <DialogContent style={webStyle.changeEmailDialogContent}>
              <Box
                textAlign={"center"}
                style={webStyle.changeEmailDialogTitle}
                padding={"0px 8px 8px"}
              >
                {configJSON.verifyYourEmailAddress}
              </Box>
              <Box
                style={webStyle.changeEmailDialogSubtitle}
                padding={"8px 8px 16px"}
                textAlign={"center"}
              >
                {configJSON.enterCode}
              </Box>
              <Box padding={"32px 0px 40px"}>
                <CustomOTPInput
                  digitCount={6}
                  otp={otpCode}
                  onChange={this.onChangeOTP}
                />
              </Box>
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                paddingBottom={"16px"}
              >
                {!isResendClicked && (
                  <Box
                    style={webStyle.resendCode}
                    onClick={() => this.changeEmail(true)}
                  >
                    {configJSON.resendCode}
                  </Box>
                )}
              </Box>
              <Box
                padding={"32px 0px 0px"}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Box>
                  <CustomButton
                    variant="outlined"
                    onClick={this.handleOpenChangeEmailDialog}
                    width={173}
                    label={configJSON.goBack}
                  />
                </Box>
                <Box paddingLeft={"8px"}>
                  <CustomButton
                    variant="contained"
                    onClick={this.verifyEmailAccount}
                    width={173}
                    label={configJSON.verify}
                    disabled={otpCode.length < 6}
                  />
                </Box>
              </Box>
            </DialogContent>
          </Dialog>
        )}
      </Box>
    );
  }
}

const webStyle = {
  editProfileLabel: {
    fontWeight: 700,
    fontFamily: "Brown",
    fontStyle: "normal",
    color: "#FFF",
    fontSize: "32px",
  },
  fieldInputLabel: {
    color: "#FFF",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: 700,
    paddingBottom: "4px",
    fontSize: "16px",
    lineHeight: 1.5,
  },
  changeEmailDialogPaper: {
    borderRadius: "16px",
    width: "544px",
    backgroundColor: "#001327",
  },
  verificationDialogWrapper: {
    borderRadius: "16px",
    backgroundColor: "#001327",
    width: "537px",
  },
  changeEmailDialogContent: {
    padding: "32px",
    backgroundImage: `url(${dialogBackground})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  },
  changeEmailDialogTitle: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontSize: "30px",
    fontWeight: 700,
    letterSpacing: -0.15,
    lineHeight: 1.33,
    color: "#FFF",
  },
  changeEmailDialogSubtitle: {
    fontStyle: "normal",
    fontFamily: "Open Sans",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: 1.5,
    color: "#FFF",
  },
  resendCode: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontSize: "14px",
    lineHeight: 1.57,
    fontWeight: 700,
    color: "#FFF",
    borderBottom: "1px solid #FFF",
    cursor: "pointer",
  },
  editProfileSuccessMsg: {
    fontFamily: "Open Sans",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: 1.5,
    color: "#01AA7F",
    backgroundColor: "#FFF",
    borderLeft: "2px solid ##01AA7F",
    borderRadius: "4px",
    padding: "21px 16px",
    margin: "16px 0px",
  },
  errorMsg: {
    fontFamily: "Open Sans",
    color: "#DC2626",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: 1.5,
    margin: "26px 0px",
    backgroundColor: "#FEE2E2",
    borderLeft: "2px solid #DC2626",
    padding: "21px 16px",
    borderRadius: "4px",
  },
};
