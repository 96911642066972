Object.defineProperty(exports, "__esModule", {
    value: true
  });

//APi Methods
exports.httpGetMethod = "GET";
exports.httpPostMethod =  "POST";

exports.profileValidationSettingsAPiEndPoint = "profile/validations";
exports.passwordRecoveryStartOtpAPiEndPoint = "forgot_password/otp"
exports.passwordRecoveryConfirmOtpAPiEndPoint = "otp_confirmation"
exports.passwordRecoveryChangePasswordAPiEndPoint = "forgot_password/password"
exports.forgotPasswordAPiContentType = "application/json";
exports.pleaseEnterAValidEmail = "Please enter a valid email";
exports.emailIsRequired = "Email is required";
exports.phoneNumberIsNotValid = "Phone number is not valid";
exports.phoneNumberIsRequired = "Phone number is required";
exports.otpCodeIsRequired = "Phone number is required";
exports.pleaseEnterAPassword = "Please enter a password";
exports.passwordMustBeAtLeast2Characters = "Password must be at least 2 characters";
exports.pleaseConfirmYourPassword = "Please confirm your password";
exports.passwordsMustMatch = "Passwords must match";
exports.invalidEmailAddress = "Invalid email address";
exports.invalidPassword = "Invalid password";
exports.goToOtpAfterPhoneValidationErrorTitle = "Error";
exports.goToOtpAfterPhoneValidationErrorBody = "Please select country code";

exports.labelTextIsAccountRecovery = "Account Recovery";
exports.secondLabelText = "Please choose what type of account you signed up with."
exports.thirdLabelText = "To Reset your password, please enter the email associated with your account.";
exports.forthLabelText = "We sent a confirmation code to the following email:";
exports.fifthLabelText = "To Reset your password, please enter the phone number associated with your account."
exports.sixthLabelText = "We sent a confirmation code to the following phone:"

exports.firstInputAutoCompleteType = "email";
exports.firstInputPlaceholder = "Email";
exports.firstInputKeyboardStyle = "email-address";
exports.firstInputErrorColor = "red";

exports.buttonTextIsNext = "Next";
exports.buttonColorForNextButton = "#6200EE";

exports.secondInputAutoCompleteType = "tel";
exports.secondInputKeyboardType= "phone-pad"
exports.secondInputPlaceholder = "Mobile"
exports.secondInputErrorColor = "red";

exports.thirdInputPlaceholder = "Enter OTP";
exports.thirdInputErrorColor = "red";

exports.buttonTitleIsSMSPhoneAccount = "SMS (Phone) Account";
exports.buttonTitleIsEmailAccount = "Email Account";

exports.labelTextIsPleaseEnterYourNewPassword = "Please enter your new password.";
exports.labelTextIsYourPasswordHasBeenSuccessfullyChanged = "Your password has been successfully changed";

exports.handled = "handled";

exports.placeholderIsReTypePassword = "Re-Type Password";

exports.buttonTitleIsOk = "Ok"
exports.buttonColorForOkButton = "#6200EE"; 

exports.placeholderIsPassword = "password";
exports.countryCodeSelectorPlaceholder = "Select Country";
// Customizable Area Start
exports.phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
exports.passwordRecoveryLabel = "Password Recovery";
exports.forgotPasswordLabel = "Forgot your password?";
exports.forgotPasswordDescription = "No worries, we'll send a recovery link to your email";
exports.emailPlaceholder = "Your email";
exports.recoveryLinkBtn = "Send a recovery link";
exports.backToLoginBtn = "Back to login";
exports.checkYourEmailText = "Check your email";
exports.emailSentMessage = "We have sent  a password  reset link to";
exports.alreadyHaveAccountText = "Have already an account?";
exports.loginLabel = "Log in";
exports.resendEmailBtn = "Resend e-mail";
exports.capitalLetterLabel = "At least one capital letter";
exports.lowercaseLetterLabel = "At least one lowercase letter";
exports.oneNumberLabel = "At least one number";
exports.eightCharLabel = "Minimum character length is 8 characters";
exports.newPassword = "New Password";
exports.confirmNewPassword = "Confirm New Password";
exports.resetPasswordBtn = "Reset Password";
exports.resendPasswordDescription = "Enter a new password for ";
exports.passwordTypeDetails = "Your password must contain:";
exports.emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
exports.emailRequired = "Email is required";
exports.emailInvalid = "Invalid Email";
exports.forgotPasswordApiEndPoint = "account_block/forgot_password";
exports.tokenVerificationApiEndPoint = "account_block/token_expired";
exports.resetPasswordApiEndPoint = "account_block/reset_password";
exports.httpPutMethod = "PUT";
exports.verificationFailedMsg = "Token Expired!";
exports.somethingWentWrongMsg = "Something went wrong!";
exports.invalidPassword = "Invalid Password";
exports.passwordRequiredMsg = "Password is required";
exports.passwordMissMatch = "Confirm new password is not matched with the new password";
// Customizable Area End