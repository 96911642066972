import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
}

interface S {
  isOpenTermsAndConditions: boolean;
  isOpenPrivacyPolicy: boolean;
  termsAndConditionsData: string;
  privacyPolicyData: string;
}

interface SS {}

export default class SettingsTermsConditionsController extends BlockComponent<
  Props,
  S,
  SS
> {
  termsAndConditionsApiId: string = "";
  privacyPolicyApiId: string = "";

  constructor(props: Props) {
    super(props);

    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    this.state = {
      isOpenTermsAndConditions: false,
      isOpenPrivacyPolicy: false,
      termsAndConditionsData: "",
      privacyPolicyData: "",
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.termsAndConditionsApiId) {
      if (responseJson?.data && responseJson.data?.length > 0) {
        this.setState({
          termsAndConditionsData: responseJson.data[0]?.description,
        });
      }
    }

    if (apiRequestCallId === this.privacyPolicyApiId) {
      if (responseJson?.data && responseJson.data?.length > 0) {
        this.setState({ privacyPolicyData: responseJson.data[0]?.description });
      }
    }
  }

  async componentDidMount() {
    this.getPrivacyPolicy();
    this.getTermsAndConditions();
  }

  toggleTermsAndConditions = () => {
    this.setState({
      isOpenTermsAndConditions: !this.state.isOpenTermsAndConditions,
      isOpenPrivacyPolicy: false,
    });
  };

  togglePrivacyPolicy = () => {
    this.setState({
      isOpenPrivacyPolicy: !this.state.isOpenPrivacyPolicy,
      isOpenTermsAndConditions: false,
    });
  };

  getTermsAndConditions = () => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const termsAndConditionsRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.termsAndConditionsApiId = termsAndConditionsRequestMsg.messageId;

    termsAndConditionsRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.termsAndConditionsApiEndPoint
    );

    termsAndConditionsRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    termsAndConditionsRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(
      termsAndConditionsRequestMsg.id,
      termsAndConditionsRequestMsg
    );
  };

  getPrivacyPolicy = () => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const privacyPolicyRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.privacyPolicyApiId = privacyPolicyRequestMsg.messageId;

    privacyPolicyRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.privacyPolicyApiEndPoint
    );

    privacyPolicyRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    privacyPolicyRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(privacyPolicyRequestMsg.id, privacyPolicyRequestMsg);
  };
}
