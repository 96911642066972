import React from "react";
import LandingPageController, {
  Props,
  configJSON,
} from "./LandingPageController";
import { Box } from "@material-ui/core";

import LandingPageContainer from "../../../components/src/LandingPageContainer.web";
import { backgroundImg, logo } from "./assets";
import CustomButton from "../../../components/src/CustomButton.web";
import CustomInput from "../../../components/src/CustomInput.web";
import { passwordLabel } from "../../../components/src/Utilities";

export default class LandingPage extends LandingPageController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const {
      videoLink,
      isUserVerified,
      passwordString,
      passwordError,
    } = this.state;
    return (
      <Box height={"auto"}>
        {isUserVerified ? (
          <LandingPageContainer
            onLogin={this.redirectToLogin}
            onSubscribe={this.redirectToSignUp}
            videoUrl={videoLink}
          />
        ) : (
          <Box style={webStyle.mainLandingContainer}>
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              height={"inherit"}
              width={"100%"}
              margin={"auto"}
            >
              <Box width={"551px"}>
                <Box sx={webStyle.mainLandingInsideContainer}>
                  <Box
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    <img src={logo} alt="logo" width={250} height={160} />
                  </Box>
                  <Box
                    marginBottom={"32px"}
                    textAlign={"center"}
                    style={webStyle.comingSoonTitle}
                  >
                    {configJSON.comingSoon}
                  </Box>
                  <>
                    <Box style={webStyle.inputContainer}>
                      <Box style={webStyle.fieldLabel}>
                        {passwordLabel}
                      </Box>
                      <CustomInput
                        value={passwordString}
                        handleChange={this.handleChangePassword}
                        placeholder={passwordLabel}
                        isError={!!passwordError}
                        errorMessage={passwordError}
                      />
                    </Box>
                    <Box marginTop={"32px"}>
                      <CustomButton
                        fullWidth
                        variant="contained"
                        onClick={this.handleVerify}
                        label={configJSON.submitBtn}
                        disabled={!passwordString}
                      />
                    </Box>
                  </>
                </Box>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    );
  }
}

const webStyle = {
  mainLandingContainer: {
    backgroundImage: `url(${backgroundImg})`,
    backgroundSize: "cover",
    backgroundColor: "#001327",
    backgroundPosition: "center",
    minHeight: "100vh",
    height: "100%",
    display: "flex",
  },
  mainLandingInsideContainer: {
    padding: "32px 95px",
    background: "rgba(0, 19, 39, 0.60)",
    backdropFilter: "blur(8px)",
    borderRadius: "16px",
    "@media (max-width: 600px)": {
      padding: "32px 40px",
    },
  },
  comingSoonTitle: {
    fontFamily: "Open Sans",
    color: "#FFF",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: 1.22,
    fontSize: "36px",
    letterSpacing: -0.36,
  },
  inputContainer: {
    padding: "8px 0px",
  },
  fieldLabel: {
    color: "#FFF",
    fontFamily: "Open Sans",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: 1.5,
    paddingBottom: "4px",
  },
};
