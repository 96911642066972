Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.httpGetMethod = "GET";
exports.httpPostMethod = "POST";
exports.httpDeleteMethod = "DELETE";

exports.contactUsApiContentType = "application/json";
exports.getContactUsAPiEndPoint = "contact_us/contacts";

exports.errorTitle = "Error";
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";
exports.errorEmailNotValid = "Email not valid.";
exports.phoneNumberError = "Phone number not valid";

exports.nameTitle = "Name";
exports.emailTitle = "Email";
exports.numberTitle = "Phone number";
exports.commentsTitle = "Comments";

exports.namePlaceHolder = "Enter Your Name";
exports.emailPlaceHolder = "Enter Your Email";
exports.numberPlaceHolder = "Enter Phone number";
exports.commentsPlaceHolder = "Enter Comments";

exports.labelTitleText = "contactus";
exports.labelBodyText = "contactus Body";

exports.btnExampleTitle = "CLICK ME";
exports.contactUsTitle = "Contact us";
exports.contactUsDescription =
  "Fill in the form below, and we will get back to you as soon as possible!";
exports.reachOutText = "Reach out via email";
exports.haveQuestionText =
  "Have questions? We'd love to hear from you. Reach us via email.";
exports.sendEmail = "Send Email";
exports.scheduleMeetingText = "Schedule a meeting";
exports.wantToChatText =
  "Want to chat in more detail? Schedule a convenient time to connect with us on Calendly.";
exports.scheduleMeeting = "Schedule Meeting";
exports.suggestFeatureText = "Suggest a feature";
exports.suggestionDescription =
  "Do you have a suggestion for a new feature? We'd love to hear from you.";
exports.suggestFeature = "Suggest Feature";
exports.calendlyUrl = "https://calendly.com/customersupport-1t8c/30min";
exports.mailToUrl = "info@getamortize.com";
exports.featureSuggestionSubject = "Amortize Feature Suggestion";
exports.questionSubject = "Questions about Amortize";
// Customizable Area End