import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "../../../framework/src/Utilities";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import moment from "moment";
import {
  downloadFile,
  extractFileNameFromUrl,
} from "../../../components/src/Utilities";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
}

export interface IJournalEntriesData {
  id?: number;
  images?: { name?: string; url?: string | File | null; id?: number }[];
  vendor_name_id: number;
  vendor_name: string;
  prepaid_account_identifier_id: number;
  prepaid_account_identifier: string;
  invoice_date: string;
  invoice_number: string;
  invoice_description: string;
  check: string;
  amortization_period: string;
  amortization_start: string;
  amortization_end: string;
  expense_account: string;
  exp_account_id: number;
  total_amount: string;
  expense_amount: number;
  remaining_balance: number;
  notes: string;
  account_id: number;
  time_of_creation: string;
}

export interface IJournalEntriesResponseData {
  attributes: IJournalEntriesData;
}

export interface IIdentifiers {
  id: string;
  attributes: {
    prepaid_account_identifier: string;
  };
}

export interface IVendors {
  id: string;
  attributes: {
    vendor_name: string;
  };
}

export interface IExpenseAccounts {
  id: string;
  attributes: {
    exp_account: string;
  };
}

interface IJournalEntriesColumn {
  name: string;
  isSelected: boolean;
  key: keyof IJournalEntriesData;
  attribute: string;
}

interface S {
  navigationBarWidth: number;
  monthList: { name: string; value: string; isLocked: boolean }[];
  selectedMonth: { name: string; value: string; isLocked: boolean };
  journalEntriesColumns: IJournalEntriesColumn[];
  journalEntriesData: IJournalEntriesData[];
  vendorList: { name: string; value: string }[];
  prepaidAccountIdentifierList: { name: string; value: string }[];
  expenseAccountList: { name: string; value: string }[];
  menuAnchor: null | HTMLElement;
  hoveredItem: string | null;
  isLoading: boolean;
}

interface SS {}

export default class JournalEntriesController extends BlockComponent<
  Props,
  S,
  SS
> {
  getMonthListDataApiId: string = "";
  getMonthlySheetDataApiId: string = "";
  getVendorListApiId: string = "";
  getIdentifiersListApiId: string = "";
  getExpenseAccountApiId: string = "";
  downloadJournalEntriesApiId: string = "";

  constructor(props: Props) {
    super(props);

    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    this.state = {
      navigationBarWidth: 100,
      selectedMonth: { name: "", value: "", isLocked: false },
      monthList: [],
      journalEntriesColumns: configJSON.journalEntriesColumns,
      journalEntriesData: [],
      vendorList: [],
      prepaidAccountIdentifierList: [],
      expenseAccountList: [],
      menuAnchor: null,
      hoveredItem: null,
      isLoading: true,
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (
      responseJson?.errors &&
      responseJson.errors?.length > 0 &&
      responseJson.errors[0]?.token
    ) {
      this.journalEntriesNavigation("Home");
    }

    if (apiRequestCallId === this.getMonthListDataApiId) {
      this.setMonthListResponse(responseJson);
    }

    if (apiRequestCallId === this.getMonthlySheetDataApiId) {
      this.setMonthlyJournalEntries(responseJson);
    }

    if (apiRequestCallId === this.getExpenseAccountApiId) {
      this.setExpenseAccountListResponse(responseJson);
    }

    if (apiRequestCallId === this.getIdentifiersListApiId) {
      this.setIdentifierListResponse(responseJson);
    }

    if (apiRequestCallId === this.getVendorListApiId) {
      this.setVendorListResponse(responseJson);
    }

    if (apiRequestCallId === this.downloadJournalEntriesApiId) {
      this.setDownloadJournalEntries(responseJson);
    }
  }

  async componentDidMount() {
    const isFirstLoad = await getStorageData("isFirstLoad");
    if (isFirstLoad === "true") {
      this.journalEntriesNavigation("Details");
    }

    this.getPrepaidIdentifierList();
    this.getVendorList();
    this.getExpenseAccountList();
    this.getMonthListData();
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  getActiveMonthsJournalEntries = () => {
    const newMonth = this.state.monthList.find(
      (item) => item.name === this.state.selectedMonth?.name
    );
    if (newMonth) {
      this.getMonthWiseJournalEntries(newMonth.value);
    }
  };

  onClickAddAnotherField = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({ menuAnchor: event.currentTarget });
  };

  handleCloseMenu = () => {
    this.setState({
      menuAnchor: null,
      journalEntriesColumns: configJSON.journalEntriesColumns,
    });
  };

  handleToggleFilterItem = (value: string) => {
    const columnList = this.state.journalEntriesColumns;
    const columnIndex = columnList.findIndex((object) => object.key === value);
    if (columnIndex > -1) {
      const columnItem = columnList[columnIndex];
      columnItem.isSelected = !columnItem.isSelected;
      columnList.splice(columnIndex, 1, columnItem);
      this.setState({ journalEntriesColumns: columnList });
    }
  };

  toggleHoverState = (value: string | null) => {
    this.setState({ hoveredItem: value });
  };

  setMenuItemStyle = (option: IJournalEntriesColumn) => {
    return {
      padding: "8px 16px",
      backgroundColor:
        option.isSelected || this.state.hoveredItem === option.key
          ? "#44403C"
          : "#171717",
    };
  };

  setMonthlyJournalEntries = (responseJson: {
    data: IJournalEntriesResponseData[];
  }) => {
    let detailedJournalEntriesData: IJournalEntriesData[] = [];
    if (responseJson?.data && responseJson.data?.length > 0) {
      detailedJournalEntriesData = responseJson.data
        .filter(
          (fItem: IJournalEntriesResponseData) =>
            fItem.attributes.expense_amount !== 0
        )
        .map((item: IJournalEntriesResponseData) => ({
          ...item.attributes,
        }));
    }
    this.setState({
      journalEntriesData: detailedJournalEntriesData,
      isLoading: false,
    });
  };

  setMonthListResponse = async (responseJson: {
    months?: string[];
    message?: string;
  }) => {
    const currentDate = moment();
    const updatedMonthList = responseJson?.months?.filter((month) =>
      moment(month, "MMMM YYYY").isSameOrBefore(currentDate)
    );
    if (updatedMonthList && updatedMonthList?.length > 0) {
      const pastMonthsData: {
        name: string;
        value: string;
        isLocked: boolean;
      }[] = [];
      updatedMonthList.forEach((item) => {
        const monthName = moment(item, "MMMM YYYY").format("MMM ´YY");
        pastMonthsData.push({
          name: monthName,
          value: item,
          isLocked: false,
        });
      });
      const activeMonthOb = pastMonthsData[pastMonthsData.length - 1];
      this.setState(
        {
          monthList: pastMonthsData,
          selectedMonth: activeMonthOb,
        },
        () => {
          this.getActiveMonthsJournalEntries();
        }
      );
    } else if (
      responseJson?.message === configJSON.noMonthsDataMessage ||
      !updatedMonthList ||
      !updatedMonthList.length
    ) {
      this.setState({
        monthList: [],
        selectedMonth: { name: "", value: "", isLocked: false },
      });
    }
  };

  onChangeMonth = (event: React.ChangeEvent<{}>, step: string) => {
    let updatedMonths = this.state.monthList;

    const updatedMonth = { name: "", value: "", isLocked: false };
    this.setState(
      {
        selectedMonth:
          this.state.monthList.find((object) => object.name === step) ||
          updatedMonth,
        monthList: updatedMonths,
        isLoading: true,
      },
      () => {
        this.getActiveMonthsJournalEntries();
      }
    );
  };

  setVendorListResponse = (responseJson: { data: IVendors[] }) => {
    if (responseJson?.data && responseJson.data.length > 0) {
      this.setState({
        vendorList: responseJson.data.map((item: IVendors) => ({
          name: item.attributes.vendor_name,
          value: item.id,
        })),
      });
    } else {
      this.setState({ vendorList: [] });
    }
  };

  setIdentifierListResponse = (responseJson: { data: IIdentifiers[] }) => {
    if (responseJson?.data && responseJson.data.length > 0) {
      this.setState({
        prepaidAccountIdentifierList: responseJson.data.map(
          (object: IIdentifiers) => ({
            name: object.attributes.prepaid_account_identifier,
            value: object.id,
          })
        ),
      });
    } else {
      this.setState({ prepaidAccountIdentifierList: [] });
    }
  };

  setExpenseAccountListResponse = (responseJson: {
    data: IExpenseAccounts[];
  }) => {
    if (responseJson?.data && responseJson.data.length > 0) {
      this.setState({
        expenseAccountList: responseJson.data.map((item: IExpenseAccounts) => ({
          name: item.attributes.exp_account,
          value: item.id,
        })),
      });
    } else {
      this.setState({ expenseAccountList: [] });
    }
  };

  setDownloadJournalEntries = (responseJson: {
    data: { attributes: { document: string } };
  }) => {
    const filePath = responseJson?.data?.attributes?.document;
    const fileName = extractFileNameFromUrl(
      responseJson?.data?.attributes?.document
    );
    if (fileName) {
      downloadFile(filePath, fileName);
    }
  };

  onSidebarResize = (isSidebarOpen: boolean) => {
    this.setState({ navigationBarWidth: isSidebarOpen ? 241 : 100 });
  };

  journalEntriesNavigation = (path: string) => {
    const routeMsg = new Message(getName(MessageEnum.NavigationMessage));
    routeMsg.addData(getName(MessageEnum.NavigationTargetMessage), path);
    routeMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(routeMsg);
  };

  getMonthListData = async () => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: await getStorageData("token"),
    };

    const getMonthListRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getMonthListDataApiId = getMonthListRequestMsg.messageId;

    getMonthListRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getMonthListApiEndPoint
    );

    getMonthListRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getMonthListRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(getMonthListRequestMsg.id, getMonthListRequestMsg);
  };

  getMonthWiseJournalEntries = async (month: string) => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: await getStorageData("token"),
    };

    const getMonthlyJournalEntriesRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getMonthlySheetDataApiId =
      getMonthlyJournalEntriesRequestMsg.messageId;

    getMonthlyJournalEntriesRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getMonthlyJournalEntriesApiEndPoint + month
    );

    getMonthlyJournalEntriesRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getMonthlyJournalEntriesRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(
      getMonthlyJournalEntriesRequestMsg.id,
      getMonthlyJournalEntriesRequestMsg
    );
  };

  getVendorList = async () => {
    const headers = {
      token: await getStorageData("token"),
      "Content-Type": configJSON.validationApiContentType,
    };

    const getVendorListRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getVendorListApiId = getVendorListRequestMsg.messageId;

    getVendorListRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getVendorListApiEndPoint
    );

    getVendorListRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getVendorListRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(getVendorListRequestMsg.id, getVendorListRequestMsg);
  };

  getPrepaidIdentifierList = async () => {
    const headers = {
      token: await getStorageData("token"),
      "Content-Type": configJSON.validationApiContentType,
    };

    const getPrepaidIdentifierListRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getIdentifiersListApiId = getPrepaidIdentifierListRequestMsg.messageId;

    getPrepaidIdentifierListRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getIdentifierListApiEndPoint
    );

    getPrepaidIdentifierListRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getPrepaidIdentifierListRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(
      getPrepaidIdentifierListRequestMsg.id,
      getPrepaidIdentifierListRequestMsg
    );
  };

  getExpenseAccountList = async () => {
    const headers = {
      token: await getStorageData("token"),
      "Content-Type": configJSON.validationApiContentType,
    };

    const expenseAccountListRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getExpenseAccountApiId = expenseAccountListRequestMsg.messageId;

    expenseAccountListRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getExpenseAccountsApiEndPoint
    );

    expenseAccountListRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    expenseAccountListRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(
      expenseAccountListRequestMsg.id,
      expenseAccountListRequestMsg
    );
  };

  downloadJournalEntries = async (isXls: boolean) => {
    const headers = {
      token: await getStorageData("token"),
      "Content-Type": configJSON.validationApiContentType,
    };

    let apiEndPoint = isXls
      ? configJSON.downloadXLSApiEndPoint
      : configJSON.downloadCSVApiEndPoint;

    const appendedEndPoint = `time=${encodeURIComponent(
      this.state.selectedMonth.value
    )}&attributes=${encodeURIComponent(
      JSON.stringify(
        this.state.journalEntriesColumns
          .filter((item) => item.isSelected)
          .map((object) => object.attribute)
      )
    )}`;

    const downloadJournalEntriesRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.downloadJournalEntriesApiId =
      downloadJournalEntriesRequestMsg.messageId;

    downloadJournalEntriesRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint + appendedEndPoint
    );

    downloadJournalEntriesRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    downloadJournalEntriesRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(
      downloadJournalEntriesRequestMsg.id,
      downloadJournalEntriesRequestMsg
    );
  };
}
