import React from "react";
import { Radio } from "@material-ui/core";
const radioIcon = require("../src/radio.svg");
const radioCheckedIcon = require("../src/radio-checked.svg");
const checkedTableCheckboxIcon = require("../src/table-check-radio.svg");
const tableCheckboxIcon = require("../src/table-radio.svg");

interface ICommonRadioButtonProps {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  checked?: boolean;
  value: string;
  isTableRadio?: boolean;
}

const CommonRadioButton = (props: ICommonRadioButtonProps) => {
  return (
    <Radio
      checked={props.checked}
      onChange={props.onChange}
      name="radio"
      icon={
        <img
          src={props.isTableRadio ? tableCheckboxIcon : radioIcon}
          alt="radio"
          width={20}
          height={20}
        />
      }
      checkedIcon={
        <img
          src={props.isTableRadio ? checkedTableCheckboxIcon : radioCheckedIcon}
          alt="radio"
          width={20}
          height={20}
        />
      }
      value={props.value}
      style={webStyle.checkBox}
      disableRipple
    />
  );
};

const webStyle = {
  checkBox: {
    paddingLeft: "0px",
    paddingTop: "2px",
  },
};

export default CommonRadioButton;
