import React from "react";
import { Box } from "@material-ui/core";
import IdentifyPrepaidAccountsController, {
  Props,
  configJSON,
} from "./IdentifyPrepaidAccountsController.web";
import CustomTable from "../../../components/src/CustomTable.web";
import CustomButton from "../../../components/src/CustomButton.web";
import CustomSearchInput from "../../../components/src/CustomSearchInput.web";

export default class IdentifyPrepaidAccounts extends IdentifyPrepaidAccountsController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const {
      accountDataWithIdentifiers,
      accountColumnsWithIdentifiers,
      selectedAccountsData,
      searchText,
      filteredAccountDataWithIdentifiers,
      createIdentifiersError,
      sortedColumn
    } = this.state;
    return (
      <>
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          height={"inherit"}
          width={"100%"}
          margin={"auto"}
        >
          <Box margin={"48px 0px"}>
            <Box style={webStyle.identifyPrepaidHeader} textAlign={"center"}>
              {selectedAccountsData.length > 0
                ? configJSON.reviewIdentifiers
                : configJSON.identifyPrepaidAccounts}
            </Box>
            {!selectedAccountsData.length && (
              <Box
                marginTop={this.props.showSearchBar ? "8px" : "36px"}
                style={webStyle.identifyPrepaidSubHeader}
                textAlign={"center"}
              >
                {configJSON.selectRows}
              </Box>
            )}
            {!selectedAccountsData.length && this.props.showSearchBar && (
              <Box
                display={"flex"}
                justifyContent={"center"}
                margin={"48px auto"}
                sx={{
                  width: {
                    lg: "414px",
                    md: "414px",
                  },
                }}
              >
                <CustomSearchInput
                  value={searchText}
                  handleChange={this.handleSearchInTable}
                  onClear={this.onClearSearch}
                  placeholder={configJSON.search}
                />
              </Box>
            )}
            {createIdentifiersError && (
              <Box data-test-id="error-message" style={webStyle.identifierErrors}>
                {createIdentifiersError}
              </Box>
            )}
            <Box margin={"48px 0px"}>
              <CustomTable
                rows={
                  selectedAccountsData.length > 0
                    ? selectedAccountsData
                    : filteredAccountDataWithIdentifiers
                }
                columns={accountColumnsWithIdentifiers}
                width={this.props.tableWidth}
                showCheckbox={!selectedAccountsData.length}
                showSortIcons={!selectedAccountsData.length}
                selectAll={
                  filteredAccountDataWithIdentifiers.length > 0
                    ? filteredAccountDataWithIdentifiers.every(
                        (object) => object.isSelected === true
                      )
                    : false
                }
                onCheckboxChange={this.onSelectRow}
                onSort={this.handleSortTable}
                sortedColumn={sortedColumn}
              />
            </Box>
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              margin={"48px 0px 0px"}
            >
              <Box
                sx={{
                  paddingRight: {
                    xs: "8px",
                    sm: "12px",
                    md: "24px",
                    lg: "48px",
                    xl: "48px",
                  },
                }}
              >
                <CustomButton
                  label={configJSON.backBtn}
                  variant="outlined"
                  onClick={this.onBackClick}
                  width={197}
                />
              </Box>
              <Box>
                <CustomButton
                  label={configJSON.continueBtn}
                  variant="contained"
                  onClick={this.onContinue}
                  width={197}
                  disabled={
                    !accountDataWithIdentifiers.some(
                      (object) => object?.isSelected === true
                    )
                  }
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </>
    );
  }
}

const webStyle = {
  identifyPrepaidHeader: {
    color: "#FFF",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "30px",
  },
  identifyPrepaidSubHeader: {
    color: "#FFF",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "16px",
  },
  identifierErrors: {
    fontFamily: "Open Sans",
    fontSize: "12px",
    color: "#DC2626",
    fontStyle: "normal",
    fontWeight: 700,
    backgroundColor: "#FEE2E2",
    padding: "21px 16px",
    lineHeight: 1.5,
    borderLeft: "2px solid #DC2626",
    margin: "10px 0px",
    borderRadius: "4px",
  },
};
