import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
}

interface S {
  showCalendarView: boolean;
  contactUsSidebarWidth: number;
}

interface SS {}

export default class ContactUsPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [];

    this.state = {
      showCalendarView: false,
      contactUsSidebarWidth: 100,
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    window.scrollTo({
      behavior: "smooth",
      top: 0,
    });
  }

  onPageWidthResize = (isSidebarOpen: boolean) => {
    this.setState({ contactUsSidebarWidth: isSidebarOpen ? 241 : 100 });
  };

  toggleCalendarView = () => {
    this.setState({ showCalendarView: !this.state.showCalendarView });
  };

  redirectToMailPage = (isFeatureSuggestion: boolean) => {
    const receiverEmailAddress = configJSON.mailToUrl;
    const mailSubject = isFeatureSuggestion
      ? configJSON.featureSuggestionSubject
      : configJSON.questionSubject;

    const mailToUrl = `mailto:${receiverEmailAddress}?subject=${encodeURIComponent(
      mailSubject
    )}`;

    window.location.href = mailToUrl;
  };
}
