import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { clearStorage } from "../../../components/src/Utilities";
import { setStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  videoLink: string;
  isUserVerified: boolean;
  passwordString: string;
  passwordError: string;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class LandingPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      videoLink: "",
      isUserVerified: false,
      passwordString: "",
      passwordError: "",
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.getVideoApiId) {
      if (responseJson?.data && responseJson.data?.id) {
        const video = responseJson.data?.attributes?.video;
        const videoUrl = video.split("?")[0];
        this.setState({
          videoLink: videoUrl,
        });
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  getVideoApiId: string = "";

  async componentDidMount() {
    this.getLandingPageVideoLink();
    clearStorage();
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  goToHome() {
    const message: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }

  handlePageNavigation = (path: string) => {
    const routeMsg = new Message(getName(MessageEnum.NavigationMessage));
    routeMsg.addData(getName(MessageEnum.NavigationTargetMessage), path);
    routeMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(routeMsg);
  };

  redirectToLogin = () => {
    this.handlePageNavigation("Login");
  };

  redirectToSignUp = () => {
    this.handlePageNavigation("SignUp");
  };

  handleChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ passwordString: event.target.value });
  };

  handleVerify = async () => {
    if (this.state.passwordString === "Amortize123") {
      this.setState({ isUserVerified: true });
      await setStorageData("isUserVerified", "true");
    } else {
      this.setState({ passwordError: configJSON.wrongPassword });
    }
  };

  getLandingPageVideoLink = () => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const getLandingPageVideoRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getVideoApiId = getLandingPageVideoRequestMsg.messageId;

    getLandingPageVideoRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getVideoAPIEndPoint
    );

    getLandingPageVideoRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getLandingPageVideoRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(
      getLandingPageVideoRequestMsg.id,
      getLandingPageVideoRequestMsg
    );
  };
  // Customizable Area End
}
