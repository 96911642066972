import React from "react";
import PaymentDetailsController, {
  Props,
  configJSON,
} from "./PaymentDetailsController";
import { Box, Snackbar } from "@material-ui/core";
import { verifyEmailBackground, logo, stripeIcon } from "./assets";
import CustomButton from "../../../components/src/CustomButton.web";
import StripeSubscription from "../../../blocks/stripepayments/src/StripeSubscription.web";
import { Alert } from "@material-ui/lab";

export default class PaymentDetails extends PaymentDetailsController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <Box height={"auto"}>
        <Snackbar
          open={
            this.state.isSubscriptionCompleted ||
            this.state.isSubscriptionFailed
          }
          autoHideDuration={6000}
          onClose={this.onCloseSnackbar}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert
            onClose={this.onCloseSnackbar}
            severity={this.state.isSubscriptionCompleted ? "success" : "error"}
          >
            {this.state.isSubscriptionCompleted
              ? configJSON.successPaymentMsg
              : configJSON.failPaymentMsg}
          </Alert>
        </Snackbar>
        <Box style={webStyle.payWithStripeContainer}>
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            height={"inherit"}
            margin={"auto"}
            width={"100%"}
            data-test-id="payment-details-title"
          >
            {this.state.isAllPageLoaded && (
              <Box width={"551px"}>
                <Box sx={webStyle.paymentDetailsContainer}>
                  <Box
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    padding={"0px 0px 32px"}
                  >
                    <img src={logo} alt="logo" height={81} width={187} />
                  </Box>
                  <Box textAlign={"center"} style={webStyle.payWithStripeTitle}>
                    {configJSON.paymentDetailsLabel}
                  </Box>
                  <Box
                    textAlign={"center"}
                    style={webStyle.paymentDetailsDescription}
                  >
                    {configJSON.welcomePaymentDetailsLabel}
                  </Box>
                  <Box
                    borderBottom={"1px solid #44403C"}
                    borderTop={"1px solid #44403C"}
                    padding={"16px"}
                    margin={"32px 0px"}
                  >
                    <Box
                      alignItems={"center"}
                      display={"flex"}
                      justifyContent={"space-between"}
                    >
                      <Box>
                        <Box style={webStyle.subscriptionLabel}>
                          {configJSON.subscriptionLabel}
                        </Box>
                        <Box style={webStyle.premiumLabel}>
                          {configJSON.premiumLabel}
                        </Box>
                      </Box>
                      <Box>
                        <Box alignItems={"baseline"} display={"flex"}>
                          <Box style={webStyle.priceLabel}>
                            {configJSON.priceAmountLabel}
                          </Box>
                          <Box
                            style={webStyle.subscriptionLabel}
                            paddingLeft={"8px"}
                          >
                            {configJSON.monthlyLabel}
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    alignItems={"center"}
                    display={"flex"}
                    justifyContent={"center"}
                  >
                    <CustomButton
                      variant="contained"
                      label={configJSON.payWithStripeLabel}
                      onClick={this.payWithStripe}
                      width={265}
                      icon={stripeIcon}
                    />
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
        {this.state.isOpenStripeGateWay && (
          <StripeSubscription
            onSuccess={this.getAllMonthList}
            onCloseDialog={this.handleCloseStripeGateway}
            onFail={this.onFailSubscription}
          />
        )}
      </Box>
    );
  }
}

const webStyle = {
  payWithStripeContainer: {
    backgroundImage: `url(${verifyEmailBackground})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundColor: "#001327",
    minHeight: "100vh",
    height: "100%",
    display: "flex",
  },
  paymentDetailsContainer: {
    padding: "32px 40px",
    borderRadius: "16px",
    backdropFilter: "blur(8px)",
    background: "rgba(0, 19, 39, 0.60)",
  },
  payWithStripeTitle: {
    color: "#FFF",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontSize: "36px",
    lineHeight: 1.22,
    fontWeight: 700,
    letterSpacing: -0.36,
  },
  paymentDetailsDescription: {
    fontFamily: "Open Sans",
    color: "#FFF",
    fontStyle: "normal",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: 1.5,
    paddingTop: "8px",
  },
  subscriptionLabel: {
    color: "#FFF",
    fontFamily: "Open Sans",
    fontWeight: 400,
    fontStyle: "normal",
    lineHeight: 1.57,
    fontSize: "14px",
  },
  premiumLabel: {
    background: "linear-gradient(99deg, #01AA7F 2.64%, #00AED2 100%)",
    backgroundClip: "text",
    WebkitTextFillColor: "transparent",
    WebkitBackgroundClip: "text",
    fontSize: "20px",
    fontStyle: "normal",
    fontFamily: "Open Sans",
    fontWeight: 700,
    lineHeight: 1.4,
  },
  priceLabel: {
    color: "#FFF",
    fontStyle: "normal",
    fontFamily: "Open Sans",
    fontWeight: 700,
    lineHeight: 1.33,
    fontSize: "24px",
    letterSpacing: -0.12,
  },
};
