Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.labelTitleText = "Bulk Upload";
exports.uploadBtnLabel = "Upload";
exports.clearFileBtnLabel = "Clear files";
exports.submitBtnLabel = "Submit";
exports.getUploadedFileLabel = "Get All Uploaded File";
exports.idLabel = "ID:";
exports.statusLabel = "Status: ";
exports.deleteBtnLabel = "Delete";
exports.removeBtnLabel = "Remove";
exports.downloadBtnLabel = "Download";
exports.apiContentType = "application/json";
exports.createContentType = "multipart/form-data";
exports.createBulkUploadEndpoint = "bulk_uploading/attachments";
exports.createBulkUploadMethod = "POST";
exports.uploadingSuccessMsg = "File uploaded Successfully!";
exports.getBulkUploadEndpoint = "bulk_uploading/attachments";
exports.getBulkUploadMethod = "GET";
exports.deleteBulkUploadSingleEndpoint =
  "bulk_uploading/attachments/delete_attachment";
exports.deleteBulkUploadEndpoint = "bulk_uploading/attachments";
exports.deleteBulkUploadMethod = "DELETE";
exports.deletedSuccessMsg = "Attachment file deleted successfully!";
exports.fileSizeErrorMsg = " is too large. Allowed maximum size is 5MB!";
exports.downloadedSuccessMsg = "file has been downloaded! File Path: ";
exports.downloadingFailedMsg = "Something went wrong!";
exports.somethingWentWrongMsg = "Something went wrong!";
exports.loginAlertMessage = "Please login first.";
exports.detailsPageHeader =
  "Upload your Chart of Accounts and Vendor Information";
exports.detailsPageOptionSelection =
  "Here you can select the best option that fits with your needs.";
exports.importText = "Import";
exports.useTemplateText = "Use a Template";
exports.importDescription =
  "Import your chart of accounts in .xls or .csv format. The uploaded file needs to at least contain a column with Account Identifiers and a column with Account Names or Descriptions. Including a column with Vendor Names is optional, but recommended.";
exports.templateDescription =
  "Download and fill in a template to provide the required information in the required format.";
exports.continueBtn = "Continue";
exports.downloadTemplate = "Download Template";
exports.downloadAndFillTemplates = "Download and fill in the template below.";
exports.downloadText = "Download";
exports.importFileDescription =
  "You can import your ledger in .xls or .cvs format. The uploaded file needs to at least contain a column with Account Identifiers and a column with Account Names or Descriptions.";
exports.uploadedText =
  "If you want to change the document and upload another, please ensure to delete the one already uploaded.";
exports.accountIdentifier = "Account Identifiers";
exports.accountNameDescription = "Account Name / Description";
exports.vendorName = "Vendor Name";
exports.identifyFields = "Identify Fields";
exports.selectAccountIdentifier =
  "Select the column with your account numbers:";
exports.selectAccountName = "Select the column with your account name:";
exports.selectAccountVendorName = "Select the column with your vendor name:";
exports.backBtn = "Back";
exports.identifyPrepaidAccounts = "Identify Prepaid Accounts";
exports.selectRows = "Select the rows with your prepaid accounts:";
exports.reviewIdentifiers = "Review Prepaid Account Selections";
exports.downloadTemplateApiEndPoint =
  "bx_block_bulk_uploading/download_xls_file";
exports.importFileApiEndPoint = "bx_block_bulk_uploading/import_xls_file";
exports.importCSVFileApiEndPoint = "bx_block_bulk_uploading/import_csv_file";
exports.fileError =
  "Invalid file format. Please upload only XLS or XLSX files.";
exports.search = "Search";
exports.importTemplate = "Import Template";
exports.importTemplateDescription =
  "Here you can upload the filled in template that you chose previously.";
exports.templateUploadedText =
  "If you want to modify or change the document you uploaded, please delete the already uploaded document above.";
exports.error = "Error";
exports.inconsistentDataError = "Inconsistent Prepaid Account Identifiers";
exports.inconsistentVendorError = "Inconsistent Vendors";
exports.inconsistentExpenseAccountsError =
  "Inconsistent Expense Account Identifiers";
exports.inconsistentDataDetails =
  "<p>The uploaded information contains a<b> different set of prepaid account identifiers </b>than what was previously identified. This could indicate a discrepancy or error in the data provided.</p> <p>Please <b>review the uploaded information</b> and ensure that <b>the correct prepaid account identifiers are included.</b></p>";
exports.reviewIdentifier = "Review Identifiers";
exports.inconsistentVendorDetails =
  "<p>The uploaded information contains a<b> different set of vendors </b>than what was previously identified. This could indicate a discrepancy or error in the data provided.</p> <p>Please <b>review the uploaded information</b> and ensure that <b>the correct vendors are included.</b></p>";
exports.inconsistentExpenseAccountsDetails =
  "<p>The uploaded information contains a<b> different set of expense account identifiers </b>than what was previously identified. This could indicate a discrepancy or error in the data provided.</p> <p>Please <b>review the uploaded information</b> and ensure that <b>the correct expense account identifiers are included.</b></p>";
exports.reviewVendor = "Review Vendors";
exports.useTemplate = "Use a Template";
exports.uploadDetailedData = "Upload your Prepaid Account Detailed Information";
exports.recommendedText = "(Recommended)";
exports.useTemplateDescription =
  "You can download and fill in a template to bulk upload your existing prepaid account detailed information.";
exports.startFromScratch = "Start from Scratch";
exports.startFromScratchDescription =
  "You can add your prepaid account detailed information one row at a time.";
exports.downloadDetailedTemplateApiEndPoint =
  "bx_block_bulk_uploading/download_prepaid_account_file";
exports.createIdentifiersApiEndPoint =
  "bx_block_bulk_uploading/create_identifiers";
exports.createBulkDetailsApiEndPoint =
  "bx_block_bulk_uploading/create_bulk_details";
exports.getCurrentMonthsDetailsDataApiEndPoint =
  "bx_block_bulk_uploading/view_details?time=";
exports.getVendorListApiEndPoint = "bx_block_bulk_uploading/view_vendor_names";
exports.getIdentifierListApiEndPoint =
  "bx_block_bulk_uploading/view_prepaid_account_identifiers";
exports.detailsText = "Details";
exports.detailsDescriptionText =
  "In this spreadsheet section for prepaid expenses, we maintain detailed records for effective financial management. Vendors are selected from dropdown options for ease of use, and attachments can be added and removed as needed. Essential invoice details like number, date, and description are recorded alongside amortization information, with start and end dates automatically calculated. Expense amounts are auto-calculated but can be adjusted with notes for transparency. This streamlined system ensures thorough documentation and efficient tracking of prepaid expenses.";
exports.addNewRow = "Add New Row";
exports.detailedSheetColumns = [
  {
    name: "Vendor Name",
    key: "vendor_name",
    isRequired: true,
    showSorting: true,
  },
  { name: "Attachment", key: "images", isRequired: false, showSorting: false },
  {
    name: "Invoice Date",
    key: "invoice_date",
    isRequired: true,
    showSorting: true,
  },
  {
    name: "Invoice Number",
    key: "invoice_number",
    isRequired: true,
    showSorting: true,
  },
  {
    name: "Invoice Description",
    key: "invoice_description",
    isRequired: true,
    showSorting: true,
  },
  { name: "Check", key: "check", isRequired: false, showSorting: true },
  {
    name: "Prepaid Account Identifier",
    showSorting: true,
    key: "prepaid_account_identifier",
    isRequired: true,
  },
  {
    name: "Amortization Period (months)",
    key: "amortization_period",
    isRequired: true,
    showSorting: true,
  },
  {
    name: "Amortization Start",
    isRequired: true,
    key: "amortization_start",
    showSorting: true,
  },
  {
    name: "Amortization End",
    isRequired: true,
    showSorting: true,
    key: "amortization_end",
  },
  {
    name: "Expense Account",
    showSorting: true,
    key: "expense_account",
    isRequired: true,
  },
  {
    name: "Total Amount (USD)",
    key: "total_amount",
    isRequired: true,
    showSorting: true,
  },
  {
    name: "Expense Amount",
    key: "expense_amount",
    showSorting: true,
    isRequired: true,
  },
  {
    name: "Remaining Balance",
    key: "remaining_balance",
    showSorting: true,
    isRequired: false,
  },
  { name: "Notes", key: "notes", isRequired: false, showSorting: false },
];
exports.unlockToEdit = "Unlock to Edit";
exports.deleteMonthDataApiEndPoint =
  "bx_block_bulk_uploading/delete_month_data?time=";
exports.updateRowDetailsApiEndPoint =
  "bx_block_bulk_uploading/update_detail?id=";
exports.updateIdentifierDetailsApiEndPoint =
  "bx_block_bulk_uploading/update_prepaid_account_in_details";
exports.updateVendorDetailsApiEndPoint =
  "bx_block_bulk_uploading/update_vendor_in_details";
exports.updateExpenseAccountDetailsApiEndPoint =
  "bx_block_bulk_uploading/update_expense_account_in_details";
exports.putMethod = "PUT";
exports.deleteMonthData = "Delete Month Data";
exports.cancel = "Cancel";
exports.deleteMonthDataTitle =
  "Are you sure you want to delete all of the details for this month?";
exports.deleteMonthDataDescription =
  "This action cannot be undone and will also affect your Journal Entries and Summary.";
exports.deleteMonthDataResponse = "Details has been successfully deleted.";
exports.updateSuccessResponse = "Details updated successfully";
exports.createSuccessResponse = "Detail added successfully";
exports.numberRegex = /^\d+$/;
exports.missMatchedIdentifiersTitle = "Mismatched prepaid account identifiers";
exports.missMatchedVendorsTitle = "Mismatched vendors";
exports.missMatchedExpenseAccountTitle =
  "Mismatched expense account identifiers";
exports.invalidSelectedField = "Please select valid identity fields";
exports.vendorMissMatchColumns = [
  { name: "Vendors from Detail Sheet Upload", key: "vendor_name" },
  { name: "Update Vendors", key: "updatedVendor" },
];
exports.identifierMissMatchColumns = [
  {
    name: "Prepaid Account Identifier From Detail Sheet Upload",
    key: "prepaid_account_identifier",
  },
  {
    name: "Update Prepaid Account Identifier",
    key: "updatedAccountIdentifier",
  },
];
exports.expenseAccountMissMatchColumns = [
  {
    name: "Expense Account Identifier From Detail Sheet Upload",
    key: "exp_account",
  },
  {
    name: "Update Expense Account Identifier",
    key: "updatedExpenseAccount",
  },
];
exports.emptyRowObject = {
  images: [],
  vendor_name_id: 0,
  vendor_name: "",
  prepaid_account_identifier_id: 0,
  prepaid_account_identifier: "",
  invoice_date: "",
  invoice_number: "",
  invoice_description: "",
  check: "",
  amortization_period: "",
  amortization_start: "",
  amortization_end: "",
  expense_account: "",
  exp_account_id: 0,
  total_amount: "",
  expense_amount: "",
  notes: "",
  account_id: 0,
  time_of_creation: "",
  remaining_balance: 0,
  edit: true,
  error: [
    "invoice_number",
    "expense_account",
    "total_amount",
    "invoice_description",
    "prepaid_account_identifier",
    "vendor_name",
    "invoice_date",
  ],
};
exports.createSingleDataApiEndPoint = "bx_block_bulk_uploading/details";
exports.downloadCSV = "Download .csv";
exports.downloadXLS = "Download .xls";
exports.downloadXLSApiEndPoint =
  "bx_block_bulk_uploading/download_xls_detail?time=";
exports.downloadCSVApiEndPoint =
  "bx_block_bulk_uploading/download_csv_detail?time=";
exports.searchDetailsApiEndPoint =
  "bx_block_bulk_uploading/search_details?time=";
exports.deleteImageApiEndPoint = "bx_block_bulk_uploading/delete_image?";
exports.deleteAttachmentSuccessMsg =
  "Attachment has been successfully deleted.";
exports.deleteVendorApiEndpoint =
  "bx_block_bulk_uploading/vendor_name_delete?id=";
exports.deleteIdentifierApiEndpoint =
  "bx_block_bulk_uploading/prepaid_account_identifier_delete?id=";
exports.updateVendorApiEndpoint =
  "bx_block_bulk_uploading/vendor_name_update?id=";
exports.updateIdentifierApiEndpoint =
  "bx_block_bulk_uploading/prepaid_account_identifier_update?id=";
exports.createVendorApiEndpoint = "bx_block_bulk_uploading/vendor_names";
exports.createIdentifierApiEndpoint =
  "bx_block_bulk_uploading/prepaid_account_identifiers";
exports.getMonthListApiEndPoint = "bx_block_bulk_uploading/months_to_lock";
exports.unlockDialogTitle = "You are about to make changes in a prior period.";
exports.unlockDialogSubTitle =
  "Are you sure you want to move forward with these changes?";
exports.getDetailsByIdsApiEndPoint =
  "bx_block_bulk_uploading/show_details?ids=";
exports.downloadPdfApiEndPoint =
  "bx_block_bulk_uploading/export_pdf_detail?time=";
exports.exportAsPdfLabel = "Export as PDF";
exports.discardChanges = "Discard Changes";
exports.keepEditingLabel = "Keep Editing";
exports.discardChangesDescription =
  "Did you forget to fill out all the required fields? Take a moment to ensure all necessary information is provided before you go.";
exports.monthAndYearRegex = /^(0[1-9]|1[0-2])\/\d{2}$/;
exports.notesPopupTitle =
  "Please enter a note explaining the reason for this change.";
exports.noMonthsDataMessage = "No details found for the account.";
exports.createVendorListApiEndPoint = "bx_block_bulk_uploading/create_vendors";
exports.createExpenseAccountsApiEndPoint =
  "bx_block_bulk_uploading/create_expense_accounts";
exports.getExpenseAccountsApiEndPoint =
  "bx_block_bulk_uploading/view_exp_accounts";
exports.deleteExpenseAccountsApiEndPoint =
  "bx_block_bulk_uploading/exp_account_delete?id=";
exports.createExpenseAccountApiEndPoint =
  "bx_block_bulk_uploading/exp_accounts";
exports.updateExpenseAccountApiEndPoint =
  "bx_block_bulk_uploading/exp_account_update?id=";
exports.deleteAccountsDataApiEndPoint =
  "bx_block_bulk_uploading/delete_all_details";
exports.deleteAccountsDataMessage = "Details deleted successfully";
exports.notesPlaceholder = "Explanation of change";
exports.getMethod = "GET";
exports.deleteMethod = "DELETE";
exports.recordsNotSaved = "Failed to update the selected record:"
// Customizable Area End