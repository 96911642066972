import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import {
  getStorageData,
  setStorageData,
} from "../../../framework/src/Utilities";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
}

interface S {
  fullName: string;
  businessName: string;
  email: string;
  isOpenChangeEmailDialog: boolean;
  isOpenVerificationDialog: boolean;
  isShoPassword: boolean;
  otpCode: string;
  newEmail: string;
  currentPassword: string;
  fullNameError: string;
  businessNameError: string;
  editProfileSuccessMsg: string;
  emailError: string;
  currentPasswordError: string;
  changeEmailError: string;
  emailChangeSuccessMsg: string;
  isResendClicked: boolean;
}

interface SS {}

export default class SettingsEditProfileController extends BlockComponent<
  Props,
  S,
  SS
> {
  editProfileApiId: string = "";
  changeEmailApiId: string = "";
  verifyEmailAccountApiId: string = "";

  constructor(props: Props) {
    super(props);

    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      fullName: "",
      businessName: "",
      email: "",
      isOpenChangeEmailDialog: false,
      isOpenVerificationDialog: false,
      isShoPassword: false,
      otpCode: "",
      newEmail: "",
      currentPassword: "",
      fullNameError: "",
      businessNameError: "",
      editProfileSuccessMsg: "",
      emailError: "",
      currentPasswordError: "",
      changeEmailError: "",
      emailChangeSuccessMsg: "",
      isResendClicked: false,
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.editProfileApiId) {
      if (responseJson?.message) {
        let userDataObj = await getStorageData("userDetails", true);
        userDataObj.full_name = this.state.fullName;
        userDataObj.business_name = this.state.businessName;
        await setStorageData("userDetails", JSON.stringify(userDataObj));
        this.setState({ editProfileSuccessMsg: responseJson.message }, () => {
          setTimeout(() => {
            this.setState({ editProfileSuccessMsg: "" });
          }, 3000);
        });
      }
    }
    if (apiRequestCallId === this.changeEmailApiId) {
      this.setChangeEmailResponse(responseJson);
    }
    if (apiRequestCallId === this.verifyEmailAccountApiId) {
      if (responseJson?.messages && responseJson.messages?.length > 0) {
        let userData = await getStorageData("userDetails", true);
        userData.email = this.state.newEmail;
        await setStorageData("userDetails", JSON.stringify(userData));

        this.setState({
          isOpenVerificationDialog: false,
          otpCode: "",
          email: this.state.newEmail,
          emailChangeSuccessMsg: responseJson.messages[0]?.pin,
        });
        setTimeout(() => {
          this.setState({ emailChangeSuccessMsg: "" });
        }, 3000);
      } else if (responseJson?.errors && responseJson.errors?.length > 0) {
        this.setState(
          {
            changeEmailError:
              responseJson.errors[0]?.pin || configJSON.somethingWentWrong,
            isOpenVerificationDialog: false,
            otpCode: "",
          },
          () => {
            setTimeout(() => {
              this.setState({ changeEmailError: "" });
            }, 3000);
          }
        );
      }
    }
  }

  async componentDidMount() {
    const userData = await getStorageData("userDetails", true);
    if (userData) {
      this.setState({
        email: userData?.email || "",
        businessName: userData?.business_name || "",
        fullName: userData?.full_name || "",
      });
    }
  }

  setChangeEmailResponse = (responseJson: {
    message?: string;
    errors?: string;
  }) => {
    if (responseJson?.message) {
      this.setState({
        isOpenChangeEmailDialog: false,
        isOpenVerificationDialog: true,
        currentPasswordError: "",
        emailError: "",
        isShoPassword: false,
      });
      if (this.state.isResendClicked) {
        setTimeout(() => {
          this.setState({ isResendClicked: false });
        }, 180000);
      }
    } else {
      this.handleCloseEmailDialog();
      this.setState(
        {
          changeEmailError:
            typeof responseJson?.errors === "string"
              ? responseJson?.errors
              : configJSON.somethingWentWrongMsg,
        },
        () => {
          setTimeout(() => {
            this.setState({ changeEmailError: "" });
          }, 3000);
        }
      );
    }
  };

  handleChangeProfileField = (
    event: React.ChangeEvent<HTMLInputElement>,
    field: string
  ) => {
    const value = event.target.value;
    if (field === "fullName") {
      const isValidFullName = value
        ? configJSON.fullNameRegex.test(value)
        : true;
      this.setState({
        ...this.state,
        [field]: value,
        fullNameError: isValidFullName ? "" : configJSON.fullNameError,
      });
    } else if (field === "businessName") {
      const isValidBusinessName = value
        ? configJSON.businessNameRegex.test(value)
        : true;
      this.setState({
        ...this.state,
        [field]: value,
        businessNameError: isValidBusinessName
          ? ""
          : configJSON.businessNameError,
      });
    } else if (field === "newEmail") {
      this.setNewEmailField(value);
    } else if (field === "currentPassword") {
      this.setCurrentPassword(value);
    }
  };

  setNewEmailField = (value: string) => {
    let emailError = "";
    const isValidEmail = configJSON.emailRegex.test(value);
    if (!isValidEmail) {
      emailError = value ? configJSON.emailInvalid : configJSON.emailRequired;
    }
    this.setState({
      newEmail: value,
      emailError: emailError,
    });
  };

  setCurrentPassword = (value: string) => {
    let passwordError = "";
    const capitalRegex = /[A-Z]/;
    const lowercaseRegex = /[a-z]/;
    const numberRegex = /\d/;
    const spaceRegex = /^\S+$/;
    const capitalPresents = capitalRegex.test(value);
    const numberPresents = numberRegex.test(value);
    const lowerPresents = lowercaseRegex.test(value);
    const lengthCheck = value.replace(/\s/g, "").length >= 8;
    const noSpacePresents = spaceRegex.test(value);
    const isValidPassword =
      capitalPresents &&
      lowerPresents &&
      numberPresents &&
      lengthCheck &&
      noSpacePresents;
    if (!isValidPassword) {
      passwordError = value
        ? configJSON.passwordInvalid
        : configJSON.passwordRequired;
    }
    this.setState({
      currentPasswordError: passwordError,
      currentPassword: value,
    });
  };

  handleOpenChangeEmailDialog = () => {
    this.setState({
      isOpenChangeEmailDialog: true,
      isOpenVerificationDialog: false,
      otpCode: "",
      newEmail: "",
      emailError: "",
      currentPassword: "",
      currentPasswordError: "",
    });
  };

  handleCloseEmailDialog = () => {
    this.setState({
      isOpenChangeEmailDialog: false,
      currentPassword: "",
      newEmail: "",
      currentPasswordError: "",
      emailError: "",
      isShoPassword: false,
    });
  };

  togglePassword = () => {
    this.setState({ isShoPassword: !this.state.isShoPassword });
  };

  onChangeOTP = (otpCode: string) => {
    this.setState({ otpCode: otpCode });
  };

  checkForSaveButtonDisabled = () => {
    return !!(
      this.state.businessNameError ||
      this.state.fullNameError ||
      (!this.state.businessName && !this.state.fullName)
    );
  };

  saveProfile = async () => {
    const headers = {
      "Content-Type": undefined,
      token: await getStorageData("token"),
    };

    const formData = new FormData();
    formData.append("full_name", this.state.fullName);
    formData.append("business_name", this.state.businessName);

    const editProfileRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.editProfileApiId = editProfileRequestMsg.messageId;

    editProfileRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.editProfileApiEndPoint
    );

    editProfileRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    editProfileRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutMethod
    );

    editProfileRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    runEngine.sendMessage(editProfileRequestMsg.id, editProfileRequestMsg);
  };

  changeEmail = async (isResend: boolean) => {
    this.setState({ isResendClicked: isResend });

    const headers = {
      "Content-Type": undefined,
      token: await getStorageData("token"),
    };

    const formData = new FormData();
    formData.append("email", this.state.newEmail);
    formData.append("current_password", this.state.currentPassword);

    const changeEmailRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.changeEmailApiId = changeEmailRequestMsg.messageId;

    changeEmailRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.changeEmailApiEndPoint
    );

    changeEmailRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    changeEmailRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutMethod
    );

    changeEmailRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    runEngine.sendMessage(changeEmailRequestMsg.id, changeEmailRequestMsg);
  };

  verifyEmailAccount = async () => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const userObj = await getStorageData("userDetails", true);
    const accountId = userObj?.id || -1;

    const verifyEmailRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.verifyEmailAccountApiId = verifyEmailRequestMsg.messageId;

    verifyEmailRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.verifyEmailApiEndPoint
    );

    verifyEmailRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    verifyEmailRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    verifyEmailRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        data: {
          attributes: {
            email: this.state.newEmail,
            pin: this.state.otpCode,
            request_key: "change_email",
            account_id: accountId,
          },
        },
      })
    );

    runEngine.sendMessage(verifyEmailRequestMsg.id, verifyEmailRequestMsg);
  };
}
