Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "settings2";
exports.labelBodyText = "settings2 Body";

exports.btnExampleTitle = "CLICK ME";
exports.settingsTitle = "Settings";
exports.settingsTabList = [
  "Edit Profile",
  "Change Password",
  "Edit Payments",
  "Terms and Conditions",
  "Delete Account",
];
exports.editSubscriptionBtn = "Edit Subscription on Stripe";
exports.subscriptionText = "Subscription";
exports.statusText = "Status";
exports.premiumText = "Premium";
exports.dollar100Monthly = "$100 monthly";
exports.activeStatus = "Active";
exports.billingDate = "March 31, 2024";
exports.nextBillingDate = "Next Billing Date";
exports.deleteAccountDescription =
  "We understand that sometimes you may want to say goodbye. If you wish to permanently delete your account and all associated data, you can do so here. Please note that this action is irreversible.";
exports.deleteAccountConfirmation = "Are you sure you want to delete your account?";
exports.keepAccount = "Keep Account";
exports.show = "Show";
exports.hide = "Hide";
exports.privacyPolicy = "Privacy Policy";
exports.lowercaseLetterLabel = "At least one lowercase letter";
exports.capitalLetterLabel = "At least one capital letter";
exports.eightCharLabel = "Minimum character length is 8 characters";
exports.oneNumberLabel = "At least one number";
exports.currentPassword = "Current password";
exports.confirmNewPassword = "Confirm New password";
exports.newPassword = "New password";
exports.saveChanges = "Save changes";
exports.backToSettings = "Back to Settings";
exports.passwordTypeDetails = "Your password must contain:";
exports.forgotPasswordLabel = "Forgot your password?";
exports.changePasswordSuccessMessage = "Your password has been changed successfully";
exports.fullName = "Full Name";
exports.businessName = "Business Name";
exports.email = "Email";
exports.changeEmail = "Change email";
exports.newEmail = "New Email";
exports.newEmailPlaceholder = "Enter a new email address";
exports.currentPasswordLabel = "Current Password";
exports.currentPasswordPlaceholder = "Enter your current password";
exports.verifyYourEmailAddress = "Verify your email address";
exports.sendChangeEmailCode = "Send Change Email Code";
exports.enterCode = "Enter the 6-digit code that we sent to your email";
exports.resendCode = "Resend the code";
exports.verify = "Verify";
exports.goBack = "Go Back";
exports.changeEmailDescription = "We'll send a code to the new email address.";
exports.termsAndConditionsApiEndPoint = "bx_block_terms_and_conditions/terms_and_conditions";
exports.privacyPolicyApiEndPoint = "bx_block_terms_and_conditions/privacy_policies";
exports.deleteAccountApiEndPoint = "account_block/account_delete";
exports.changePasswordApiEndPoint = "account_block/change_password";
exports.deleteApiMethodType = "DELETE";
exports.passwordInvalid = "Invalid Password";
exports.passwordRequired = "Password is required";
exports.passwordMissMatch = "Confirm new password is not matched with the new password";
exports.somethingWentWrongMsg = "Something went wrong!";
exports.httpPutMethod = "PUT";
exports.businessNameRegex = /^$|^[A-Za-z0-9 .-]{1,60}$/;
exports.fullNameRegex = /^[a-zA-Z\s]+$/;
exports.fullNameError = "Invalid Full name";
exports.businessNameError = "Invalid Business name";
exports.editProfileApiEndPoint = "account_block/profile_edit";
exports.changeEmailApiEndPoint = "account_block/change_email";
exports.verifyEmailApiEndPoint = "account_block/verify_account";
exports.emailInvalid = "Invalid Email";
exports.emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
exports.emailRequired = "Email is required";
exports.getSubscriptionStatusApiEndPoint = "bx_block_stripe_integration/check_subscription_status";
exports.successPaymentMsg = "Your card has been successfully updated for all future subscriptions.";
exports.subscriptionEndDate = "Subscription End Date";
// Customizable Area End