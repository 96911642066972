// App.js - WEB
import React, { Component } from 'react';
import { View } from 'react-native';
import firebase from 'firebase';
import WebRoutesGenerator from '../../components/src/NativeWebRouteWrapper';
import HomeScreen from '../../components/src/HomeScreen';
import InfoPage from '../../blocks/info-page/src/InfoPageBlock';
import AlertBlock from '../../blocks/alert/src/AlertBlock.web';
import AccountGroups from '../../blocks/accountgroups/src/AccountGroups';
import BulkUploading from '../../blocks/bulkuploading/src/BulkUploading';
import StripePayments from '../../blocks/stripepayments/src/StripePayments';
import SocialMediaAccountLogin from '../../blocks/social-media-account/src/SocialMediaAccountLogin';
import SocialMediaAccountRegistration from '../../blocks/social-media-account/src/SocialMediaAccountRegistration';
import ForgotPassword from '../../blocks/forgot-password/src/ForgotPassword';
import ForgotPasswordOTP from '../../blocks/forgot-password/src/ForgotPasswordOTP';
import TermsConditions from '../../blocks/termsconditions/src/TermsConditions';
import TermsConditionsDetail from '../../blocks/termsconditions/src/TermsConditionsDetail';
import TermsConditionsUsers from '../../blocks/termsconditions/src/TermsConditionsUsers';
import Sorting from '../../blocks/sorting/src/Sorting';
import Cfspreadsheetjournal from '../../blocks/cfspreadsheetjournal/src/Cfspreadsheetjournal';
import CountryCodeSelector from '../../blocks/country-code-selector/src/CountryCodeSelector';
import UserProfileBasicBlock from '../../blocks/user-profile-basic/src/UserProfileBasicBlock';
import SaveAsPdf from '../../blocks/saveaspdf/src/SaveAsPdf';
import PhoneNumberInput from '../../blocks/mobile-account-registration/src/PhoneNumberInput';
import AdditionalDetailForm from '../../blocks/mobile-account-registration/src/AdditionalDetailForm';
import Settings2 from '../../blocks/settings2/src/Settings2';
import ContactUsPage from '../../blocks/contactus/src/ContactUsPage.web';
import AddContactus from '../../blocks/contactus/src/AddContactus';
import Paymentadmin2 from '../../blocks/paymentadmin2/src/Paymentadmin2';
import MobileAccountLoginBlock from '../../blocks/mobile-account-login/src/MobileAccountLoginBlock';
import EmailAccountRegistration from '../../blocks/email-account-registration/src/EmailAccountRegistration';
import Categoriessubcategories from '../../blocks/categoriessubcategories/src/Categoriessubcategories';
import Adminconsole2 from '../../blocks/adminconsole2/src/Adminconsole2';
import EmailAccountLoginBlock from '../../blocks/email-account-login/src/EmailAccountLoginBlock';
import NavigationMenu from '../../blocks/navigationmenu/src/NavigationMenu';
import LandingPage from '../../blocks/landingpage/src/LandingPage';
import AdvancedSearch from '../../blocks/advancedsearch/src/AdvancedSearch';
import EducationalUserProfile from '../../blocks/educational-user-profile/src/EducationalUserProfile';
import Tasks from '../../blocks/tasks/src/Tasks';
import TaskList from '../../blocks/tasks/src/TaskList';
import Task from '../../blocks/tasks/src/Task';
import OTPInputAuth from '../../blocks/otp-input-confirmation/src/OTPInputAuth';
import LandingPageWeb from '../../blocks/landingpage/src/LandingPage.web';
import SignUp from '../../blocks/email-account-registration/src/SignUp.web';
import VerifyEmail from '../../blocks/email-account-registration/src/VerifyEmail.web';
import PaymentDetails from '../../blocks/email-account-registration/src/PaymentDetails.web';
import Login from '../../blocks/email-account-login/src/Login.web';
import PasswordRecovery from '../../blocks/forgot-password/src/PasswordRecovery.web';
import NewPassword from '../../blocks/forgot-password/src/NewPassword.web';
import Summary from '../../blocks/dashboard/src/Summary.web';
import Settings from '../../blocks/settings2/src/Settings.web';
import Details from '../../blocks/bulkuploading/src/Details.web';
import JournalEntries from '../../blocks/cfspreadsheetjournal/src/JournalEntries.web';

const routeMap = {
  AccountGroups: {
    component: AccountGroups,
    path: '/AccountGroups',
  },
  BulkUploading: {
    component: BulkUploading,
    path: '/BulkUploading',
  },
  StripePayments: {
    component: StripePayments,
    path: '/StripePayments',
  },
  SocialMediaAccountLogin: {
    component: SocialMediaAccountLogin,
    path: '/SocialMediaAccountLogin',
  },
  SocialMediaAccountRegistration: {
    component: SocialMediaAccountRegistration,
    path: '/SocialMediaAccountRegistration',
  },
  ForgotPassword: {
    component: ForgotPassword,
    path: '/ForgotPassword',
  },
  ForgotPasswordOTP: {
    component: ForgotPasswordOTP,
    path: '/ForgotPasswordOTP',
  },
  NewPassword: {
    component: NewPassword,
    path: '/NewPassword',
  },
  TermsConditions: {
    component: TermsConditions,
    path: '/TermsConditions',
  },
  TermsConditionsDetail: {
    component: TermsConditionsDetail,
    path: '/TermsConditionsDetail',
  },
  TermsConditionsUsers: {
    component: TermsConditionsUsers,
    path: '/TermsConditionsUsers',
  },
  Sorting: {
    component: Sorting,
    path: '/Sorting',
  },
  Cfspreadsheetjournal: {
    component: Cfspreadsheetjournal,
    path: '/Cfspreadsheetjournal',
  },
  CountryCodeSelector: {
    component: CountryCodeSelector,
    path: '/CountryCodeSelector',
  },
  UserProfileBasicBlock: {
    component: UserProfileBasicBlock,
    path: '/UserProfileBasicBlock',
  },
  SaveAsPdf: {
    component: SaveAsPdf,
    path: '/SaveAsPdf',
  },
  PhoneNumberInput: {
    component: PhoneNumberInput,
    path: '/PhoneNumberInput',
  },
  AdditionalDetailForm: {
    component: AdditionalDetailForm,
    path: '/AdditionalDetailForm',
  },
  Settings2: {
    component: Settings2,
    path: '/Settings2',
  },
  ContactUs: {
    component: ContactUsPage,
    path: '/ContactUs',
  },
  AddContactus: {
    component: AddContactus,
    path: '/AddContactus',
  },
  Paymentadmin2: {
    component: Paymentadmin2,
    path: '/Paymentadmin2',
  },
  MobileAccountLoginBlock: {
    component: MobileAccountLoginBlock,
    path: '/MobileAccountLoginBlock',
  },
  EmailAccountRegistration: {
    component: EmailAccountRegistration,
    path: '/EmailAccountRegistration',
  },
  Categoriessubcategories: {
    component: Categoriessubcategories,
    path: '/Categoriessubcategories',
  },
  Adminconsole2: {
    component: Adminconsole2,
    path: '/Adminconsole2',
  },
  EmailAccountLoginBlock: {
    component: EmailAccountLoginBlock,
    path: '/EmailAccountLoginBlock',
  },
  NavigationMenu: {
    component: NavigationMenu,
    path: '/NavigationMenu',
  },
  LandingPage: {
    component: LandingPage,
    path: '/LandingPage',
  },
  AdvancedSearch: {
    component: AdvancedSearch,
    path: '/AdvancedSearch',
  },
  EducationalUserProfile: {
    component: EducationalUserProfile,
    path: '/EducationalUserProfile',
  },
  Tasks: {
    component: Tasks,
    path: '/Tasks',
  },
  TaskList: {
    component: TaskList,
    path: '/TaskList',
  },
  Task: {
    component: Task,
    path: '/Task',
  },
  OTPInputAuth: {
    component: OTPInputAuth,
    path: '/OTPInputAuth',
  },
  Home: {
    component: LandingPageWeb,
    path: '/',
    exact: true,
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage',
  },
  AlertWeb: {
    component: AlertBlock,
    path: '*/AlertWeb',
    modal: true,
  },
  SignUp: {
    component: SignUp,
    path: '/SignUp',
  },
  VerifyEmail: {
    component: VerifyEmail,
    path: '/VerifyEmail',
  },
  PaymentDetails: {
    component: PaymentDetails,
    path: '/PaymentDetails',
  },
  Login: {
    component: Login,
    path: '/Login',
  },
  PasswordRecovery: {
    component: PasswordRecovery,
    path: '/PasswordRecovery',
  },
  Summary: {
    component: Summary,
    path: '/Summary',
  },
  Settings: {
    component: Settings,
    path: '/Settings',
  },
  Details: {
    component: Details,
    path: '/Details',
  },
  JournalEntries: {
    component: JournalEntries,
    path: '/JournalEntries',
  },
};

const firebaseAPI = firebase.initializeApp({
  apiKey: 'AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4',
  authDomain: 'rnmasterapp-c11e9.firebaseapp.com',
  databaseURL: 'https://rnmasterapp-c11e9.firebaseio.com',
  projectId: 'rnmasterapp-c11e9',
  storageBucket: 'rnmasterapp-c11e9.appspot.com',
  messagingSenderId: '649592030497',
  appId: '1:649592030497:web:7728bee3f2baef208daa60',
  measurementId: 'G-FYBCF3Z2W3',
});

class App extends Component {
  render() {
    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');

    return <View>{WebRoutesGenerator({ routeMap })}</View>;
  }
}

export default App;
