import React from "react";
import { Box, Dialog, DialogContent } from "@material-ui/core";

import SettingsChangePasswordController, {
  Props,
  configJSON,
} from "./SettingsChangePasswordController.web";
import CustomInput from "../../../components/src/CustomInput.web";
import CustomPasswordInput from "../../../components/src/CustomPasswordInput.web";
import CustomButton from "../../../components/src/CustomButton.web";
import {
  checkIcon,
  dialogBackground,
  roundPass,
  passIcon,
  failIcon,
} from "./assets";

export default class SettingsChangePassword extends SettingsChangePasswordController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const {
      currentPassword,
      confirmPassword,
      newPassword,
      validationCheckItems,
      isOpenSuccessDialog,
      currentPasswordError,
      newPasswordError,
      confirmPasswordError,
      passwordChangeFailMsg,
      passwordToggle,
    } = this.state;

    const showCheckIcon = (isPassed: boolean | undefined) => {
      let iconImg = checkIcon;
      if (typeof isPassed === "boolean") {
        iconImg = isPassed ? passIcon : failIcon;
      }
      return iconImg;
    };
    return (
      <Box>
        <Box style={webStyle.changePasswordLabel}>
          {configJSON.settingsTabList[1]}
        </Box>
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          paddingTop={"16px"}
        >
          <Box
            sx={{
              width: {
                sm: "auto",
                md: "480px",
              },
            }}
          >
            <Box paddingTop={"8px"}>
              <Box style={webStyle.inputLabel}>
                {configJSON.currentPassword}
              </Box>
              <CustomInput
                value={currentPassword}
                handleChange={(event) =>
                  this.handleChangePasswordField(event, "currentPassword")
                }
                placeholder={configJSON.currentPassword}
                errorMessage={currentPasswordError}
                isError={!!currentPasswordError}
                isPasswordType
              />
            </Box>
            <Box paddingBottom={"24px"} paddingTop={"24px"}>
              <Box style={webStyle.inputLabel}>{configJSON.newPassword}</Box>
              <CustomPasswordInput
                value={newPassword}
                handleChange={(event) =>
                  this.handleChangePasswordField(event, "newPassword")
                }
                placeholder={configJSON.newPassword}
                isError={!!newPasswordError}
                errorMessage={newPasswordError}
                isShow={passwordToggle[0]}
                handleToggle={() => this.togglePasswordField(0)}
              />
            </Box>
            <Box paddingBottom={"8px"}>
              <Box style={webStyle.inputLabel}>
                {configJSON.confirmNewPassword}
              </Box>
              <CustomPasswordInput
                value={confirmPassword}
                handleChange={(event) =>
                  this.handleChangePasswordField(event, "confirmPassword")
                }
                placeholder={configJSON.confirmNewPassword}
                isError={!!confirmPasswordError}
                errorMessage={confirmPasswordError}
                isShow={passwordToggle[1]}
                handleToggle={() => this.togglePasswordField(1)}
              />
            </Box>
            {passwordChangeFailMsg && (
              <Box
                style={webStyle.passwordChangeFailMsg}
                data-test-id="error-message"
              >
                {passwordChangeFailMsg}
              </Box>
            )}
            <Box margin={"16px 0px"}>
              <Box style={webStyle.passwordContainText}>
                {configJSON.passwordTypeDetails}
              </Box>
              {validationCheckItems.map((item) => (
                <Box
                  key={item.name}
                  display={"flex"}
                  paddingBottom={"8px"}
                  alignItems={"center"}
                >
                  <Box paddingRight={"8px"}>
                    <img
                      src={showCheckIcon(item.isPassed)}
                      height={18}
                      alt="check"
                    />
                  </Box>
                  <Box style={webStyle.passwordRequirementsText}>
                    {item.name}
                  </Box>
                </Box>
              ))}
            </Box>
            <Box
              margin={"16px 0px"}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Box>
                <CustomButton
                  variant="outlined"
                  onClick={this.saveNewPassword}
                  width={144}
                  label={configJSON.saveChanges}
                  disabled={
                    !currentPassword ||
                    !newPassword ||
                    !confirmPassword ||
                    !!currentPasswordError ||
                    !!newPasswordError ||
                    !!confirmPasswordError
                  }
                />
              </Box>
              <Box
                style={webStyle.forgotPasswordText}
                marginLeft={"24px"}
                data-test-id="forgot-password-btn"
                onClick={this.navigateToForgotPassword}
              >
                {configJSON.forgotPasswordLabel}
              </Box>
            </Box>
          </Box>
        </Box>
        {isOpenSuccessDialog && (
          <Dialog
            open={isOpenSuccessDialog}
            PaperProps={{
              style: webStyle.changePasswordDialogPaper,
            }}
            fullWidth
          >
            <DialogContent style={webStyle.changePasswordDialogContent}>
              <Box
                textAlign={"center"}
                style={webStyle.changePasswordDialogTitle}
                padding={"0px 8px 8px"}
              >
                {configJSON.settingsTabList[1]}
              </Box>
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <img src={roundPass} height={57} alt="pass" />
              </Box>
              <Box
                style={webStyle.changePasswordDialogDescription}
                padding={"8px 8px 16px"}
                textAlign={"center"}
              >
                {configJSON.changePasswordSuccessMessage}
              </Box>
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Box>
                  <CustomButton
                    variant="contained"
                    label={configJSON.backToSettings}
                    onClick={this.backToSettings}
                    width={173}
                  />
                </Box>
              </Box>
            </DialogContent>
          </Dialog>
        )}
      </Box>
    );
  }
}

const webStyle = {
  changePasswordLabel: {
    fontFamily: "Brown",
    fontWeight: 700,
    fontStyle: "normal",
    fontSize: "32px",
    color: "#FFF",
  },
  inputLabel: {
    color: "#FFF",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "16px",
    paddingBottom: "4px",
    lineHeight: 1.5,
  },
  passwordContainText: {
    fontFamily: "Open Sans",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 700,
    paddingBottom: "8px",
    lineHeight: 1.5,
    color: "#FFF",
  },
  passwordRequirementsText: {
    fontFamily: "Open Sans",
    color: "#FFF",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: 1.57,
    fontSize: "14px",
  },
  forgotPasswordText: {
    fontFamily: "Open Sans",
    fontSize: "14px",
    fontStyle: "normal",
    lineHeight: 1.57,
    fontWeight: 700,
    cursor: "pointer",
    borderBottom: "1px solid #FFF",
    color: "#FFF",
  },
  changePasswordDialogPaper: {
    borderRadius: "16px",
    backgroundColor: "#001327",
    width: "537px",
  },
  changePasswordDialogContent: {
    backgroundImage: `url(${dialogBackground})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    padding: "32px 0px",
  },
  changePasswordDialogTitle: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontSize: "30px",
    fontWeight: 700,
    lineHeight: 1.33,
    letterSpacing: -0.15,
    color: "#FFF",
  },
  changePasswordDialogDescription: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: 1.5,
    color: "#FFF",
  },
  passwordChangeFailMsg: {
    fontFamily: "Open Sans",
    fontSize: "12px",
    color: "#DC2626",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: 1.5,
    backgroundColor: "#FEE2E2",
    margin: "26px 0px",
    borderLeft: "2px solid #DC2626",
    padding: "21px 16px",
    borderRadius: "4px",
  },
};
