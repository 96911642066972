import React from "react";
import { Box } from "@material-ui/core";

import SettingsTermsConditionsController, {
  Props,
  configJSON,
} from "./SettingsTermsConditionsController.web";
import { upArrow, downArrow } from "../src/assets";
import HtmlParser from "html-react-parser";

export default class SettingsTermsConditions extends SettingsTermsConditionsController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const {
      isOpenPrivacyPolicy,
      isOpenTermsAndConditions,
      termsAndConditionsData,
      privacyPolicyData,
    } = this.state;
    return (
      <Box>
        <Box
          borderTop={"1px solid #57534E"}
          style={webStyle.pointer}
          onClick={this.toggleTermsAndConditions}
          data-test-id="terms-conditions-tab"
        >
          <Box
            padding={"24px 32px"}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Box style={webStyle.titleLabel}>
              {configJSON.settingsTabList[3]}
            </Box>
            {isOpenTermsAndConditions ? (
              <Box
                style={webStyle.showHideWrapper}
                alignItems={"baseline"}
                display={"flex"}
              >
                {configJSON.hide}
                <img
                  width={16}
                  style={webStyle.arrowIcon}
                  src={upArrow}
                  alt="up-arrow-icon"
                />
              </Box>
            ) : (
              <Box
                style={webStyle.showHideWrapper}
                display={"flex"}
                alignItems={"baseline"}
              >
                {configJSON.show}
                <img
                  width={16}
                  style={webStyle.arrowIcon}
                  src={downArrow}
                  alt="down-arrow-icon"
                />
              </Box>
            )}
          </Box>
          {isOpenTermsAndConditions && (
            <Box
              style={webStyle.accordionContent}
              padding={"0px 32px"}
              marginBottom={"16px"}
            >
              {HtmlParser(termsAndConditionsData)}
            </Box>
          )}
        </Box>
        <Box
          borderTop={"1px solid #57534E"}
          borderBottom={"1px solid #57534E"}
          style={webStyle.pointer}
          onClick={this.togglePrivacyPolicy}
          data-test-id="privacy-policy-tab"
        >
          <Box
            padding={"24px 32px"}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Box style={webStyle.titleLabel}>{configJSON.privacyPolicy}</Box>
            {isOpenPrivacyPolicy ? (
              <Box
                style={webStyle.showHideWrapper}
                display={"flex"}
                alignItems={"baseline"}
              >
                {configJSON.hide}
                <img
                  style={webStyle.arrowIcon}
                  src={upArrow}
                  width={16}
                  alt="up-arrow"
                />
              </Box>
            ) : (
              <Box
                style={webStyle.showHideWrapper}
                display={"flex"}
                alignItems={"baseline"}
              >
                {configJSON.show}
                <img
                  style={webStyle.arrowIcon}
                  src={downArrow}
                  width={16}
                  alt="down-arrow"
                />
              </Box>
            )}
          </Box>
          {isOpenPrivacyPolicy && (
            <Box
              style={webStyle.accordionContent}
              padding={"0px 32px"}
              marginBottom={"16px"}
            >
              {HtmlParser(privacyPolicyData)}
            </Box>
          )}
        </Box>
      </Box>
    );
  }
}

const webStyle = {
  titleLabel: {
    fontFamily: "Open Sans",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: 1.5,
    color: "#FFF",
  },
  showHideWrapper: {
    fontFamily: "Open Sans",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: 1.57,
    color: "#FFF",
    cursor: "pointer",
  },
  pointer: {
    cursor: "pointer",
  },
  arrowIcon: {
    paddingLeft: "16px",
  },
  accordionContent: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 400,
    color: "#FFF",
    overflow: "auto",
    fontSize: "16px",
    lineHeight: 1.5,
    maxHeight: "514px",
  },
};
