import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
}

export interface S {
  email: string;
  isEmailSent: boolean;
  isTouched: boolean;
  error: string;
  apiError: string;
  resendClicked: boolean;
}

export interface SS {}

export default class PasswordRecoveryController extends BlockComponent<
  Props,
  S,
  SS
> {
  forgotPasswordApiId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.state = {
      email: "",
      isEmailSent: false,
      isTouched: false,
      error: "",
      apiError: "",
      resendClicked: false
    };
  }

  async receive(from: string, message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.forgotPasswordApiId) {
      if (responseJson?.message) {
        this.setState({ isEmailSent: true });
        await setStorageData("recoveryEmail", JSON.stringify(this.state.email));
        if (this.state.resendClicked) {
          setTimeout(() => {
            this.setState({ resendClicked: false });
          }, 180000);
        }
      } else if (
        responseJson.errors &&
        typeof responseJson.errors === "string"
      ) {
        this.setState({ apiError: responseJson.errors }, () => {
          setTimeout(() => {
            this.setState({ apiError: "" });
          }, 3000);
        });
      }
    }
  }

  async componentDidMount() {
    const token = await getStorageData("token");
    const isUserVerified = await getStorageData("isUserVerified");
    if (token || isUserVerified !== "true") {
      this.redirectToPage("Home");
    }
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const isValidEmail = configJSON.emailRegex.test(value);
    let errorMsg = "";
    if (!isValidEmail) {
      errorMsg = value ? configJSON.emailInvalid : configJSON.emailRequired;
    }
    this.setState({
      ...this.state,
      email: value,
      isTouched: true,
      error: errorMsg,
    });
  };

  handleSendRecoveryLink = (isResend: boolean) => {
    this.setState({ resendClicked: isResend });
    const headers = {
      "Content-Type": undefined,
    };

    const formData = new FormData();
    formData.append("email", this.state.email);

    const forgotPasswordRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.forgotPasswordApiId = forgotPasswordRequestMsg.messageId;

    forgotPasswordRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.forgotPasswordApiEndPoint
    );

    forgotPasswordRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    forgotPasswordRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutMethod
    );

    forgotPasswordRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    runEngine.sendMessage(
      forgotPasswordRequestMsg.id,
      forgotPasswordRequestMsg
    );
  };

  backToLogin = () => {
    this.redirectToPage("Login");
  };


  redirectToPage = (page: string) => {
    const navigationMsg = new Message(getName(MessageEnum.NavigationMessage));
    navigationMsg.addData(getName(MessageEnum.NavigationTargetMessage), page);
    navigationMsg.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(navigationMsg);
  };
}
