import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Step,
  Stepper,
  StepLabel,
  Box,
  StepConnector,
} from "@material-ui/core";

const completedStep = require("../src/complete-step.svg");
const inActiveStep = require("../src/inactive-step.svg");
const activeStepIcon = require("../src/active-step.svg");

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: "transparent",
    "& .MuiStepLabel-label": {
      color: "#FFF",
      fontFamily: "Open Sans",
      fontSize: "12px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: 1.5,
    },
    "& .MuiStepLabel-active": {
      color: "#01AA7F",
    },
    "& .MuiStepLabel-completed": {
      color: "#01AA7F",
    },
    "& .step-count": {
      fontFamily: "Poppins",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 700,
    },
  },
  alternativeLabel: {
    top: 8,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  active: {
    "& $line": {
      borderColor: "#01AA7F",
    },
  },
  completed: {
    "& $line": {
      borderColor: "#01AA7F",
    },
  },
  line: {
    borderColor: "#B4B4B4",
    borderTopWidth: 2,
    borderRadius: 80,
  },
}));

interface ICustomStepperProps {
  stepList: string[];
  activeStep: number;
}

const CustomStepper = ({ stepList, activeStep }: ICustomStepperProps) => {
  const classes = useStyles();

  return (
    <Stepper
      activeStep={activeStep}
      alternativeLabel
      className={classes.root}
      connector={
        <StepConnector
          classes={{
            alternativeLabel: classes.alternativeLabel,
            line: classes.line,
            active: classes.active,
            completed: classes.completed,
          }}
        />
      }
    >
      {stepList.map((label, index) => (
        <Step key={label} completed={activeStep > index}>
          <StepLabel
            StepIconComponent={() => (
              <div>
                {activeStep > index && (
                  <img src={completedStep} width={16} alt="completed" />
                )}
                {activeStep === index && (
                  <img src={activeStepIcon} width={16} alt="active" />
                )}
                {activeStep < index && (
                  <img src={inActiveStep} width={16} alt="in-active" />
                )}
              </div>
            )}
          >
            <Box className="step-count">{index + 1}</Box>
            <Box>{label}</Box>
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};

export default CustomStepper;
