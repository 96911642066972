import React from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  makeStyles,
  Box,
} from "@material-ui/core";
import CustomSearchableDropdown from "./CustomSearchableDropdown.web";

const useStyles = makeStyles((theme) => ({
  table: {
    overflowX: "auto",
    maxHeight: "430px",
    paddingBottom: "16px",
    [theme.breakpoints.down("sm")]: {
      maxWidth: `calc(100vw - 96px)`,
      [theme.breakpoints.down("xs")]: {
        maxWidth: `calc(100vw - 16px)`,
      },
    },
    [theme.breakpoints.up("sm")]: {
      maxWidth: (props: ICustomMissMatchDataTableProps) =>
        `calc(100vw - ${props.width}px)`,
    },
    "& .table-body-cell": {
      backgroundColor: "#FFF",
      color: "#0F172A",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "14px",
      padding: "12px 24px",
    },
    "& .table-head-cell": {
      backgroundColor: "#001327",
      color: "#FFF",
      fontFamily: "Open Sans",
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "14px",
      padding: "8px 24px",
    },
    "& .MuiCheckbox-root": {
      padding: "0px !important",
    },
  },
}));

interface IIdentifierRawData {
  prepaid_account_identifier: string;
  updatedAccountIdentifier?: string;
}

interface IVendorRawData {
  vendor_name: string;
  updatedVendor?: string;
}

interface IExpenseAccountRawData {
  exp_account: string;
  updatedExpenseAccount?: string;
}
interface ICustomMissMatchDataTableProps {
  columns: {
    name: string;
    key:
      | keyof IIdentifierRawData
      | keyof IVendorRawData
      | keyof IExpenseAccountRawData;
  }[];
  rows: {
    vendor_name?: string;
    updatedVendor?: string;
    updatedVendorId?: string;
    prepaid_account_identifier?: string;
    updatedAccountIdentifier?: string;
    updatedAccountIdentifierId?: string;
    exp_account?: string;
    updatedExpenseAccount?: string;
    updatedExpenseAccountId?: string;
  }[];
  width: number;
  dropdownList: { name: string; value: string }[];
  handleDropdownChange?: (
    value: string,
    valueId: string,
    param: string,
    id: number
  ) => void;
  deleteOption: (value: string) => void;
  updateOption: (value: string, optionId: string) => void;
}

const CustomMissMatchDataTable: React.FC<ICustomMissMatchDataTableProps> = ({
  columns,
  rows,
  width,
  dropdownList,
  handleDropdownChange,
  deleteOption,
  updateOption,
}) => {
  const classes = useStyles({
    columns,
    rows,
    width,
    dropdownList,
    handleDropdownChange,
    deleteOption,
    updateOption,
  });

  return (
    <Box className={classes.table}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell
                className="table-head-cell"
                key={column.name}
                align="center"
              >
                {column.name}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((rowData: any, index: number) => (
            <TableRow key={rowData?.id + index}>
              {columns.map((columnRow, colIndex: number) => (
                <TableCell
                  align="center"
                  className="table-body-cell"
                  key={rowData[columnRow.name] + colIndex}
                >
                  {columnRow.key === "updatedAccountIdentifier" ||
                  columnRow.key === "updatedVendor" ||
                  columnRow.key === "updatedExpenseAccount" ? (
                    <CustomSearchableDropdown
                      value={rowData[columnRow.key]}
                      name=""
                      error={false}
                      options={dropdownList}
                      handleChange={(value: string, id: string) =>
                        handleDropdownChange &&
                        handleDropdownChange(value, id, "", index)
                      }
                      isDark={false}
                      deleteOption={(value: string) => deleteOption(value)}
                      updateOption={(value: string, optionId: string) =>
                        updateOption(value, optionId)
                      }
                    />
                  ) : (
                    rowData[columnRow.key]
                  )}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};

export default CustomMissMatchDataTable;
