import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import { handleSort } from "../../../components/src/Utilities";
import { getStorageData } from "../../../framework/src/Utilities";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";

export const configJSON = require("./config");

interface ITableData {
  [key: string]: string | number | Date | boolean | null;
}

interface ITableColumn {
  name: string;
  checked: boolean;
  showRadioButton: boolean;
  selectedColumn: boolean;
}

export interface Props {
  tableWidth: number;
  accountsData: ITableData[];
  moveToDetailedUploadScreen: () => void;
  backToFileUpload: () => void;
}

interface S {
  activeStep: number;
  accountsColumnData: ITableColumn[];
  accountsColumnFirstStepData: ITableColumn[];
  accountsColumnSecondStepData: ITableColumn[];
  accountsColumnThirdStepData: ITableColumn[];
  stepDescription: string;
  accountsDataWithIdentifiers: ITableData[];
  filteredAccountsData: ITableData[];
  sortedColumn: { name: string; isAsc: boolean };
}

interface SS {}

export default class AccountIdentifiersStepperController extends BlockComponent<
  Props,
  S,
  SS
> {
  createVendorsApiId: string = "";
  constructor(props: Props) {
    super(props);

    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    this.state = {
      activeStep: 0,
      accountsColumnData: [],
      accountsColumnFirstStepData: [],
      accountsColumnSecondStepData: [],
      accountsColumnThirdStepData: [],
      stepDescription: configJSON.selectAccountIdentifier,
      accountsDataWithIdentifiers: [],
      filteredAccountsData: [],
      sortedColumn: { name: "", isAsc: false },
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    if (this.props.accountsData.length > 0) {
      const objKeySet: Set<string> = new Set();

      this.props.accountsData.forEach((object) => {
        Object.keys(object).forEach((obKey) => {
          objKeySet.add(obKey.trim());
        });
      });

      const keyList = Array.from(objKeySet);
      const columnsData: ITableColumn[] = keyList.map((column: string) => ({
        name: column,
        checked: false,
        showRadioButton: true,
        selectedColumn: false,
      }));
      this.setState({
        accountsColumnData: columnsData,
        filteredAccountsData: this.props.accountsData,
      });
    }
  }

  onSelectColumn = (column: string) => {
    const columnsData = [...this.state.accountsColumnData];
    const objectIndex = columnsData.findIndex(
      (columnOb) => columnOb.name === column
    );
    if (objectIndex > -1) {
      columnsData.forEach((object) => (object.checked = false));
      columnsData[objectIndex].checked = true;
      this.setState({ accountsColumnData: columnsData });
    }
  };

  onContinue = () => {
    const accountsColumns = [...this.state.accountsColumnData];
    const columnsStepData = JSON.parse(
      JSON.stringify(this.state.accountsColumnData)
    );
    const objIndex = accountsColumns.findIndex(
      (columnOb) => columnOb.checked === true
    );
    if (objIndex > -1) {
      accountsColumns[objIndex].checked = false;
      accountsColumns[objIndex].selectedColumn = true;
      accountsColumns[objIndex].showRadioButton = false;
      if (this.state.activeStep === 0) {
        this.setState({
          accountsColumnData: accountsColumns,
          activeStep: this.state.activeStep + 1,
          accountsColumnFirstStepData: columnsStepData,
          stepDescription: configJSON.selectAccountName,
        });
      } else if (this.state.activeStep === 1) {
        this.setState({
          accountsColumnData: accountsColumns,
          activeStep: this.state.activeStep + 1,
          accountsColumnSecondStepData: columnsStepData,
          stepDescription: configJSON.selectAccountVendorName,
        });
      } else {
        const filteredArray = this.state.filteredAccountsData.map((item) => {
          const newItem: {
            [key: string]: string | number | boolean | Date | null;
          } = {};
          this.state.accountsColumnData.forEach((column) => {
            if (column.selectedColumn) {
              newItem[column.name] = item[column.name];
            }
          });
          return newItem;
        });
        const updatedList = filteredArray.map((item) => {
          const entries = Object.entries(item);
          if (entries.length > 2) {
            entries.splice(2, 1);
          }
          return Object.fromEntries(entries);
        });
        this.createVendorList(filteredArray);

        this.setState({
          accountsDataWithIdentifiers: updatedList,
          accountsColumnThirdStepData: columnsStepData,
        });
      }
    }
  };

  onBack = () => {
    if (this.state.activeStep === 1) {
      this.setState({
        activeStep: this.state.activeStep - 1,
        accountsColumnData: this.state.accountsColumnFirstStepData,
        stepDescription: configJSON.selectAccountIdentifier,
      });
    } else if (this.state.activeStep === 2) {
      this.setState({
        activeStep: this.state.activeStep - 1,
        accountsColumnData: this.state.accountsColumnSecondStepData,
        stepDescription: configJSON.selectAccountName,
      });
    } else {
      this.props.backToFileUpload();
    }
  };

  backToStepper = () => {
    this.setState({
      activeStep: 2,
      accountsDataWithIdentifiers: [],
      accountsColumnData: this.state.accountsColumnThirdStepData,
    });
  };

  sortTableData = (column: string, isAsc: boolean) => {
    const sortedTableData = handleSort(
      column,
      isAsc,
      this.state.filteredAccountsData
    );
    this.setState({
      filteredAccountsData: sortedTableData,
      sortedColumn: { name: column, isAsc: isAsc },
    });
  };

  createVendorList = async (
    requestBody: {
      [key: string]: string | number | boolean | Date | null;
    }[]
  ) => {
    const headers = {
      "Content-Type": configJSON.apiContentType,
      token: await getStorageData("token"),
    };

    const createVendorsApiRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createVendorsApiId = createVendorsApiRequestMsg.messageId;

    createVendorsApiRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createVendorListApiEndPoint
    );

    createVendorsApiRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    createVendorsApiRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.createBulkUploadMethod
    );

    createVendorsApiRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestBody)
    );

    runEngine.sendMessage(
      createVendorsApiRequestMsg.id,
      createVendorsApiRequestMsg
    );
  };
}
