import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "../../../framework/src/Utilities";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import moment, { Moment } from "moment";
import {
  handleSummaryTableSort,
  decimalRegex,
  extractFileNameFromUrl,
  downloadFile,
} from "../../../components/src/Utilities";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
}

export interface IIdentifiers {
  id: string;
  attributes: {
    prepaid_account_identifier: string;
  };
}

export interface IVendors {
  id: string;
  attributes: {
    vendor_name: string;
  };
}

export interface IAdditionsAttributes {
  id: number;
  vendor_name_id: number;
  vendor_name: string;
  prepaid_account_identifier_id: number;
  prepaid_account_identifier: string;
  invoice_number: string;
  amortization_period: string;
  amortization_start: string;
  total_amount: string;
  amortization_end: string;
  adjustment_amount: string;
  notes: string;
}

interface IAdditionsResponse {
  data: {
    attributes: IAdditionsAttributes;
  }[];
  meta: {
    additions_row_count: number;
    total_amount_sum: number;
  };
}

interface IFalloffsResponse {
  data: {
    attributes: IAdditionsAttributes;
  }[];
  meta: {
    falloffs_row_count: number;
  };
}

interface IAdjustmentsResponse {
  data: {
    attributes: IAdditionsAttributes;
  }[];
  meta: {
    adjustment_row_count: number;
  };
}

interface ISummaryTableRow {
  id?: number;
  vendor_name_id?: number;
  vendor_name?: string;
  prepaid_account_identifier_id?: number;
  prepaid_account_identifier?: string;
  invoice_number?: string;
  amortization_period?: string;
  amortization_start?: string;
  amortization_end?: string;
  total_amount?: string;
  adjustment_amount?: string;
  notes?: string;
}

interface S {
  summarySidebarWidth: number;
  identifierList: { name: string; value: string }[];
  vendorList: { name: string; value: string }[];
  selectedDate: Moment | null;
  isSetupCompleted: boolean;
  selectedMonthData: { name: string; value: string; isLocked: boolean };
  monthList: { name: string; value: string; isLocked: boolean }[];
  fromDate: Moment | null;
  toDate: Moment | null;
  filteredIdentifiers: string[];
  summaryLoader: boolean;
  additionsData: ISummaryTableRow[];
  falloffsData: ISummaryTableRow[];
  adjustmentsData: ISummaryTableRow[];
  sortedAdditionsColumn: { name: string; isAsc: boolean };
  sortedFalloffsColumn: { name: string; isAsc: boolean };
  sortedAdjustmentsColumn: { name: string; isAsc: boolean };
  additionsAmount: number;
  additionsRowCount: number;
  additionsPage: number;
  isShowMoreAdditionsClicked: boolean;
  filteredAdditionsData: ISummaryTableRow[];
  falloffsRowCount: number;
  falloffsPage: number;
  filteredFalloffsData: ISummaryTableRow[];
  isShowMoreFalloffsClicked: boolean;
  expenseAmount: number;
  adjustmentsRowCount: number;
  adjustmentsPage: number;
  isShowMoreAdjustmentsClicked: boolean;
  filteredAdjustmentsData: ISummaryTableRow[];
  isAllScreenLoaded: boolean;
  endingPrepaidAmount: number;
}

interface SS {}

export default class SummaryController extends BlockComponent<Props, S, SS> {
  getIdentifiersListApiId: string = "";
  getMonthListApiId: string = "";
  summarySetupApiId: string = "";
  getVendorListApiId: string = "";
  getAdditionsDataApiId: string = "";
  getFalloffsDataApiId: string = "";
  getExpenseAmountApiId: string = "";
  getAdjustmentsDataApiId: string = "";
  checkSummaryDataApiId: string = "";
  endingPrepaidAmountApiId: string = "";
  exportAsPdfApiId: string = "";

  constructor(props: Props) {
    super(props);

    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      summarySidebarWidth: 100,
      identifierList: [],
      vendorList: [],
      selectedDate: null,
      isSetupCompleted: false,
      selectedMonthData: { name: "", value: "", isLocked: false },
      monthList: [],
      fromDate: null,
      toDate: null,
      filteredIdentifiers: [],
      summaryLoader: true,
      additionsData: [],
      falloffsData: [],
      adjustmentsData: [],
      sortedAdditionsColumn: { name: "", isAsc: false },
      sortedFalloffsColumn: { name: "", isAsc: false },
      sortedAdjustmentsColumn: { name: "", isAsc: false },
      additionsAmount: 0,
      additionsRowCount: 0,
      additionsPage: 0,
      isShowMoreAdditionsClicked: false,
      filteredAdditionsData: [],
      falloffsPage: 0,
      falloffsRowCount: 0,
      filteredFalloffsData: [],
      isShowMoreFalloffsClicked: false,
      expenseAmount: 0,
      adjustmentsPage: 0,
      adjustmentsRowCount: 0,
      filteredAdjustmentsData: [],
      isShowMoreAdjustmentsClicked: false,
      isAllScreenLoaded: false,
      endingPrepaidAmount: 0,
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (
      responseJson?.errors &&
      responseJson.errors?.length > 0 &&
      responseJson.errors[0]?.token
    ) {
      this.summaryNavigation("Home");
    }

    if (apiRequestCallId === this.getIdentifiersListApiId) {
      this.setIdentifierListResponse(responseJson);
    }

    if (apiRequestCallId === this.getVendorListApiId) {
      this.setVendorListResponse(responseJson);
    }

    if (apiRequestCallId === this.getMonthListApiId) {
      this.setMonthListDataResponse(responseJson);
    }

    if (apiRequestCallId === this.summarySetupApiId) {
      this.setSummarySetupResponse(responseJson);
    }

    if (apiRequestCallId === this.getAdditionsDataApiId) {
      this.setAdditionsDataResponse(responseJson);
    }

    if (apiRequestCallId === this.getFalloffsDataApiId) {
      this.setFalloffsDataResponse(responseJson);
    }

    if (apiRequestCallId === this.getExpenseAmountApiId) {
      this.setExpenseAmountResponse(responseJson);
    }

    if (apiRequestCallId === this.getAdjustmentsDataApiId) {
      this.setAdjustmentsDataResponse(responseJson);
    }

    if (apiRequestCallId === this.checkSummaryDataApiId) {
      this.setSummaryDataForAccountResponse(responseJson);
    }

    if (apiRequestCallId === this.endingPrepaidAmountApiId) {
      this.setEndingPrepaidResponse(responseJson);
    }

    if (apiRequestCallId === this.exportAsPdfApiId) {
      this.setExportAsPdfResponse(responseJson);
    }
  }

  async componentDidMount() {
    const isFirstLoad = await getStorageData("isFirstLoad");
    if (isFirstLoad === "true") {
      this.summaryNavigation("Details");
    }
    this.checkSummaryDataForAccount();
    window.scrollTo({
      behavior: "smooth",
      top: 0,
    });
  }

  setExportAsPdfResponse = (responseJson?: {
    data?: { attributes?: { document: string } };
  }) => {
    this.setState({ summaryLoader: false });
    const filePath = responseJson?.data?.attributes?.document as string;
    const fileName = extractFileNameFromUrl(
      responseJson?.data?.attributes?.document as string
    );
    if (fileName) {
      downloadFile(filePath, fileName);
    }
  };

  setEndingPrepaidResponse = (responseJson?: { total_ending_gl: number }) => {
    if (responseJson?.total_ending_gl) {
      this.setState({
        endingPrepaidAmount: responseJson?.total_ending_gl,
      });
    }
  };

  setIdentifierListResponse = (responseJson: { data: IIdentifiers[] }) => {
    if (responseJson?.data && responseJson.data.length > 0) {
      this.setState({
        identifierList: responseJson.data.map((object: IIdentifiers) => ({
          name: object.attributes.prepaid_account_identifier,
          value: "",
        })),
      });
    } else {
      this.setState({ identifierList: [] });
    }
  };

  setVendorListResponse = (responseJson: { data: IVendors[] }) => {
    if (responseJson?.data && responseJson.data.length > 0) {
      this.setState({
        vendorList: responseJson.data.map((object: IVendors) => ({
          name: object.attributes.vendor_name,
          value: "",
        })),
      });
    } else {
      this.setState({ vendorList: [] });
    }
  };

  setSummarySetupResponse = (responseJson: { message: string }) => {
    if (responseJson?.message === configJSON.setupSummarySuccessMsg) {
      this.setState({ isSetupCompleted: true }, () => {
        if (this.state.monthList.length > 0) {
          this.onUpdateAPICall();
        }
      });
    } else {
      this.setState({ summaryLoader: false });
    }
  };

  setSummaryDataForAccountResponse = (responseJson: { message: string }) => {
    this.getIdentifierList();
    this.getVendorList();
    this.getMonthList();
    this.setState({
      isSetupCompleted: responseJson?.message === configJSON.summaryFoundMsg,
      summaryLoader: responseJson?.message === configJSON.summaryFoundMsg,
    });
  };

  setAdditionsDataResponse = (responseJson: IAdditionsResponse) => {
    if (responseJson?.data && responseJson?.meta) {
      const additionsResponseData = responseJson?.data.map(
        (item: { attributes: IAdditionsAttributes }) => ({
          ...item.attributes,
        })
      );
      if (additionsResponseData.length > 0) {
        if (this.state.additionsPage === 0) {
          this.setState({
            additionsAmount: responseJson?.meta?.total_amount_sum || 0,
            additionsData: additionsResponseData,
            additionsRowCount: responseJson?.meta?.additions_row_count || 0,
            additionsPage: 1,
            filteredAdditionsData: additionsResponseData.slice(0, 3),
            summaryLoader: false,
          });
        } else {
          this.setState((prevState) => ({
            additionsAmount: responseJson?.meta?.total_amount_sum || 0,
            additionsData: [
              ...prevState.additionsData,
              ...additionsResponseData,
            ],
            additionsRowCount: responseJson?.meta?.additions_row_count || 0,
            additionsPage: prevState.additionsPage + 1,
            filteredAdditionsData: [
              ...prevState.additionsData,
              ...additionsResponseData,
            ],
            summaryLoader: false,
          }));
        }
      }
    } else {
      this.setState({ additionsAmount: 0, summaryLoader: false });
    }
  };

  setFalloffsDataResponse = (responseJson: IFalloffsResponse) => {
    if (responseJson?.data && responseJson?.meta) {
      const falloffsResponseData = responseJson?.data.map(
        (item: { attributes: IAdditionsAttributes }) => ({
          ...item.attributes,
        })
      );
      if (falloffsResponseData.length > 0) {
        if (this.state.falloffsPage === 0) {
          this.setState({
            falloffsData: falloffsResponseData,
            falloffsRowCount: responseJson?.meta?.falloffs_row_count || 0,
            falloffsPage: 1,
            filteredFalloffsData: falloffsResponseData.slice(0, 3),
            summaryLoader: false,
          });
        } else {
          this.setState((prevState) => ({
            falloffsData: [...prevState.falloffsData, ...falloffsResponseData],
            falloffsRowCount: responseJson?.meta?.falloffs_row_count || 0,
            falloffsPage: prevState.falloffsPage + 1,
            filteredFalloffsData: [
              ...prevState.falloffsData,
              ...falloffsResponseData,
            ],
            summaryLoader: false,
          }));
        }
      }
    } else {
      this.setState({ summaryLoader: false });
    }
  };

  setAdjustmentsDataResponse = (responseJson: IAdjustmentsResponse) => {
    if (responseJson?.data && responseJson?.meta) {
      const adjustmentsResponseData = responseJson?.data.map(
        (item: { attributes: IAdditionsAttributes }) => ({
          ...item.attributes,
        })
      );
      if (adjustmentsResponseData.length > 0) {
        if (this.state.adjustmentsPage === 0) {
          this.setState({
            adjustmentsData: adjustmentsResponseData,
            adjustmentsRowCount: responseJson?.meta?.adjustment_row_count || 0,
            adjustmentsPage: 1,
            filteredAdjustmentsData: adjustmentsResponseData.slice(0, 3),
            summaryLoader: false,
          });
        } else {
          this.setState((prevState) => ({
            adjustmentsData: [
              ...prevState.adjustmentsData,
              ...adjustmentsResponseData,
            ],
            adjustmentsRowCount: responseJson?.meta?.adjustment_row_count || 0,
            adjustmentsPage: prevState.adjustmentsPage + 1,
            filteredAdjustmentsData: [
              ...prevState.adjustmentsData,
              ...adjustmentsResponseData,
            ],
            summaryLoader: false,
          }));
        }
      }
    } else {
      this.setState({ summaryLoader: false });
    }
  };

  setExpenseAmountResponse = (responseJson: {
    sum_of_expense_amount?: number;
  }) => {
    this.setState({ expenseAmount: responseJson?.sum_of_expense_amount || 0 });
  };

  showMoreAdditions = () => {
    if (this.state.additionsPage === 1) {
      if (this.state.isShowMoreAdditionsClicked) {
        this.setState({ summaryLoader: true });
        this.getAdditionsData(
          2,
          this.state.fromDate as Moment,
          this.state.toDate as Moment,
          this.state.filteredIdentifiers
        );
      } else {
        this.setState({
          filteredAdditionsData: this.state.additionsData,
          isShowMoreAdditionsClicked: true,
        });
      }
    } else {
      this.setState({ summaryLoader: true });
      this.getAdditionsData(
        this.state.additionsPage + 1,
        this.state.fromDate as Moment,
        this.state.toDate as Moment,
        this.state.filteredIdentifiers
      );
    }
  };

  showLessAdditions = () => {
    if (this.state.additionsPage === 1) {
      this.setState((prevState) => ({
        additionsData: prevState.additionsData.slice(0, 10),
        filteredAdditionsData: prevState.filteredAdditionsData.slice(0, 3),
        isShowMoreAdditionsClicked: false,
      }));
    } else {
      this.setState((prevState) => ({
        additionsData: prevState.additionsData.slice(
          0,
          10 * (prevState.additionsPage - 1)
        ),
        filteredAdditionsData: prevState.filteredAdditionsData.slice(
          0,
          10 * (prevState.additionsPage - 1)
        ),
        additionsPage: prevState.additionsPage - 1,
      }));
    }
  };

  showMoreFalloffs = () => {
    if (this.state.falloffsPage === 1) {
      if (this.state.isShowMoreFalloffsClicked) {
        this.setState({ summaryLoader: true });
        this.getFalloffsData(
          2,
          this.state.fromDate as Moment,
          this.state.toDate as Moment,
          this.state.filteredIdentifiers
        );
      } else {
        this.setState({
          filteredFalloffsData: this.state.falloffsData,
          isShowMoreFalloffsClicked: true,
        });
      }
    } else {
      this.setState({ summaryLoader: true });
      this.getFalloffsData(
        this.state.falloffsPage + 1,
        this.state.fromDate as Moment,
        this.state.toDate as Moment,
        this.state.filteredIdentifiers
      );
    }
  };

  showLessFalloffs = () => {
    if (this.state.falloffsPage === 1) {
      this.setState((prevState) => ({
        falloffsData: prevState.falloffsData.slice(0, 10),
        filteredFalloffsData: prevState.filteredFalloffsData.slice(0, 3),
        isShowMoreFalloffsClicked: false,
      }));
    } else {
      this.setState((prevState) => ({
        falloffsData: prevState.falloffsData.slice(
          0,
          10 * (prevState.falloffsPage - 1)
        ),
        filteredFalloffsData: prevState.filteredFalloffsData.slice(
          0,
          10 * (prevState.falloffsPage - 1)
        ),
        falloffsPage: prevState.falloffsPage - 1,
      }));
    }
  };

  showMoreAdjustments = () => {
    if (this.state.adjustmentsPage === 1) {
      if (this.state.isShowMoreAdjustmentsClicked) {
        this.setState({ summaryLoader: true });
        this.getAdjustmentsData(
          2,
          this.state.fromDate as Moment,
          this.state.toDate as Moment,
          this.state.filteredIdentifiers
        );
      } else {
        this.setState({
          filteredAdjustmentsData: this.state.adjustmentsData,
          isShowMoreAdjustmentsClicked: true,
        });
      }
    } else {
      this.setState({ summaryLoader: true });
      this.getAdjustmentsData(
        this.state.adjustmentsPage + 1,
        this.state.fromDate as Moment,
        this.state.toDate as Moment,
        this.state.filteredIdentifiers
      );
    }
  };

  showLessAdjustments = () => {
    if (this.state.adjustmentsPage === 1) {
      this.setState((prevState) => ({
        adjustmentsData: prevState.adjustmentsData.slice(0, 10),
        filteredAdjustmentsData: prevState.filteredAdjustmentsData.slice(0, 3),
        isShowMoreAdjustmentsClicked: false,
      }));
    } else {
      this.setState((prevState) => ({
        adjustmentsData: prevState.adjustmentsData.slice(
          0,
          10 * (prevState.adjustmentsPage - 1)
        ),
        filteredAdjustmentsData: prevState.filteredAdjustmentsData.slice(
          0,
          10 * (prevState.adjustmentsPage - 1)
        ),
        adjustmentsPage: prevState.adjustmentsPage - 1,
      }));
    }
  };

  setMonthListDataResponse = async (responseJson: {
    months?: string[];
    message?: string;
  }) => {
    const newDate = moment();
    const updatedMonthListData = responseJson?.months?.filter((month) =>
      moment(month, "MMMM YYYY").isSameOrBefore(newDate)
    );
    if (updatedMonthListData && updatedMonthListData?.length > 0) {
      const pastMonthsData: {
        name: string;
        value: string;
        isLocked: boolean;
      }[] = [];
      updatedMonthListData.forEach((item) => {
        const monthName = moment(item, "MMMM YYYY").format("MMM ´YY");
        pastMonthsData.push({
          name: monthName,
          value: item,
          isLocked: false,
        });
      });
      const activeMonthObject = pastMonthsData[pastMonthsData.length - 1];
      const startDate = moment(activeMonthObject?.value, "MMMM YYYY").startOf(
        "month"
      );
      const endDate = moment(activeMonthObject?.value, "MMMM YYYY").endOf(
        "month"
      );
      this.setState(
        {
          monthList: pastMonthsData,
          selectedMonthData: activeMonthObject,
          fromDate: startDate,
          toDate: endDate,
          isAllScreenLoaded: true,
        },
        () => {
          if (this.state.isSetupCompleted) {
            this.onUpdateAPICall();
          }
        }
      );
    } else if (
      responseJson?.message === configJSON.noMonthsDataMessage ||
      !updatedMonthListData ||
      !updatedMonthListData.length
    ) {
      this.setState({
        monthList: [],
        selectedMonthData: { name: "", value: "", isLocked: false },
      });
    }
  };

  handleSetupAMountChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ) => {
    const amount = event.target.value;
    const splitValue = amount.includes("$") ? amount?.split("$")[1] : amount;
    const identifiers = this.state.identifierList;
    let identifierObject = identifiers[index];
    if (identifierObject && decimalRegex.test(splitValue)) {
      identifierObject.value = splitValue;
      identifiers.splice(index, 1, identifierObject);
    }
    this.setState({ identifierList: identifiers });
  };

  handleDateChange = (date: Moment | null, param: string) => {
    let activeMonth = {
      name: "",
      value: "",
      isLocked: false,
    };
    if (param === "selectedDate") {
      this.setState({ selectedDate: date });
    } else if (param === "fromDate") {
      this.setState({ fromDate: date }, () => {
        this.setInitialState();
        this.onUpdateAPICall();
        if (
          moment(this.state.toDate, "MMMM YYYY").format("MMMM YYYY") ===
          moment(this.state.fromDate, "MMMM YYYY").format("MMMM YYYY")
        ) {
          activeMonth = {
            name: moment(this.state.fromDate, "MMMM YYYY").format("MMM ´YY"),
            value: moment(this.state.fromDate, "MMMM YYYY").format("MMMM YYYY"),
            isLocked: false,
          };
        }
        this.setState({ selectedMonthData: activeMonth });
      });
    } else if (param === "toDate") {
      const endDate = moment(date).endOf("month");
      this.setState({ toDate: endDate }, () => {
        this.setInitialState();
        this.onUpdateAPICall();
        if (
          moment(this.state.fromDate, "MMMM YYYY").format("MMMM YYYY") ===
          moment(this.state.toDate, "MMMM YYYY").format("MMMM YYYY")
        ) {
          activeMonth = {
            name: moment(this.state.toDate, "MMMM YYYY").format("MMM ´YY"),
            value: moment(this.state.toDate, "MMMM YYYY").format("MMMM YYYY"),
            isLocked: false,
          };
        }
        this.setState({ selectedMonthData: activeMonth });
      });
    }
  };

  handleChangeFilteredIdentifiers = (selectedIdentifiers: string[]) => {
    this.setState({ filteredIdentifiers: selectedIdentifiers }, () => {
      this.setInitialState();
      this.onUpdateAPICall();
    });
  };

  onChangeMonth = (event: React.ChangeEvent<{}>, step: string) => {
    let updatedMonthList = this.state.monthList;

    const activeMonthObject = this.state.monthList.find(
      (object) => object.name === step
    ) || { name: "", value: "", isLocked: false };
    const startDate = moment(activeMonthObject?.value, "MMMM YYYY").startOf(
      "month"
    );
    const endDate = moment(activeMonthObject?.value, "MMMM YYYY").endOf(
      "month"
    );
    this.setInitialState();
    this.setState(
      {
        selectedMonthData: activeMonthObject,
        monthList: updatedMonthList,
        fromDate: startDate,
        toDate: endDate,
      },
      () => {
        this.onUpdateAPICall();
      }
    );
  };

  sortAdditionsTableData = (column: keyof ISummaryTableRow, isAsc: boolean) => {
    let additionsData = [...this.state.filteredAdditionsData];
    const sortedData = handleSummaryTableSort(column, isAsc, additionsData);
    this.setState({
      filteredAdditionsData: sortedData,
      sortedAdditionsColumn: { name: column, isAsc: isAsc },
    });
  };

  sortFalloffsTableData = (column: keyof ISummaryTableRow, isAsc: boolean) => {
    let falloffsData = [...this.state.filteredFalloffsData];
    const sortedData = handleSummaryTableSort(column, isAsc, falloffsData);
    this.setState({
      filteredFalloffsData: sortedData,
      sortedFalloffsColumn: { name: column, isAsc: isAsc },
    });
  };

  sortAdjustmentsTableData = (
    column: keyof ISummaryTableRow,
    isAsc: boolean
  ) => {
    let adjustmentsData = [...this.state.filteredAdjustmentsData];
    const sortedData = handleSummaryTableSort(column, isAsc, adjustmentsData);
    this.setState({
      filteredAdjustmentsData: sortedData,
      sortedAdjustmentsColumn: { name: column, isAsc: isAsc },
    });
  };

  setInitialState = () => {
    this.setState({
      additionsData: [],
      falloffsData: [],
      adjustmentsData: [],
      sortedAdditionsColumn: { name: "", isAsc: false },
      sortedFalloffsColumn: { name: "", isAsc: false },
      sortedAdjustmentsColumn: { name: "", isAsc: false },
      additionsAmount: 0,
      additionsRowCount: 0,
      additionsPage: 0,
      isShowMoreAdditionsClicked: false,
      filteredAdditionsData: [],
      falloffsPage: 0,
      falloffsRowCount: 0,
      filteredFalloffsData: [],
      isShowMoreFalloffsClicked: false,
      expenseAmount: 0,
      adjustmentsPage: 0,
      isShowMoreAdjustmentsClicked: false,
      filteredAdjustmentsData: [],
      adjustmentsRowCount: 0,
      summaryLoader: true,
      endingPrepaidAmount: 0,
    });
  };

  formatToDollar = (value: string | number) => {
    const numericVal = typeof value === "string" ? parseFloat(value) : value;

    if (isNaN(numericVal)) {
      return `$${numericVal}`;
    }

    const formattedValue = Math.abs(numericVal).toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });

    return numericVal < 0 ? `-${formattedValue}` : formattedValue;
  };

  onUpdateAPICall = () => {
    this.getAdditionsData(
      1,
      this.state.fromDate as Moment,
      this.state.toDate as Moment,
      this.state.filteredIdentifiers
    );
    this.getFalloffsData(
      1,
      this.state.fromDate as Moment,
      this.state.toDate as Moment,
      this.state.filteredIdentifiers
    );
    this.getAdjustmentsData(
      1,
      this.state.fromDate as Moment,
      this.state.toDate as Moment,
      this.state.filteredIdentifiers
    );
    this.getExpenseAmount(
      this.state.fromDate as Moment,
      this.state.toDate as Moment,
      this.state.filteredIdentifiers
    );
    this.getPrepaidGLAmount(
      this.state.fromDate as Moment,
      this.state.toDate as Moment,
      this.state.filteredIdentifiers
    );
  };

  checkSummaryDataForAccount = async () => {
    const headers = {
      token: await getStorageData("token"),
      "Content-Type": configJSON.apiContentType,
    };

    const checkSummaryDataForAccountRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.checkSummaryDataApiId = checkSummaryDataForAccountRequestMsg.messageId;

    checkSummaryDataForAccountRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSummaryRecordApiEndPoint
    );

    checkSummaryDataForAccountRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    checkSummaryDataForAccountRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiGetMethod
    );

    runEngine.sendMessage(
      checkSummaryDataForAccountRequestMsg.id,
      checkSummaryDataForAccountRequestMsg
    );
  };

  getIdentifierList = async () => {
    const headers = {
      token: await getStorageData("token"),
      "Content-Type": configJSON.apiContentType,
    };

    const getIdentifierListRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getIdentifiersListApiId = getIdentifierListRequestMsg.messageId;

    getIdentifierListRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getIdentifierListApiEndPoint
    );

    getIdentifierListRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getIdentifierListRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiGetMethod
    );

    runEngine.sendMessage(
      getIdentifierListRequestMsg.id,
      getIdentifierListRequestMsg
    );
  };

  getVendorList = async () => {
    const headers = {
      token: await getStorageData("token"),
      "Content-Type": configJSON.apiContentType,
    };

    const getVendorListRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getVendorListApiId = getVendorListRequestMsg.messageId;

    getVendorListRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getVendorListApiEndPoint
    );

    getVendorListRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getVendorListRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiGetMethod
    );

    runEngine.sendMessage(getVendorListRequestMsg.id, getVendorListRequestMsg);
  };

  getMonthList = async () => {
    const headers = {
      "Content-Type": configJSON.apiContentType,
      token: await getStorageData("token"),
    };

    const getMonthListRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getMonthListApiId = getMonthListRequestMsg.messageId;

    getMonthListRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getMonthListApiEndPoint
    );

    getMonthListRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getMonthListRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiGetMethod
    );

    runEngine.sendMessage(getMonthListRequestMsg.id, getMonthListRequestMsg);
  };

  setUpSummary = async () => {
    this.setState({ summaryLoader: true });
    const headers = {
      token: await getStorageData("token"),
      "Content-Type": configJSON.apiContentType,
    };

    const monthEnd = moment(this.state.selectedDate).format("MM/DD/YY");
    const startingGL = this.state.identifierList.map((item) => {
      return Number(item.value);
    });
    const prepaidVal = this.state.identifierList.map((item) => {
      return item.name;
    });

    const setUpSummaryRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.summarySetupApiId = setUpSummaryRequestMsg.messageId;

    setUpSummaryRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.summarySetupApiEndPoint +
        `${JSON.stringify(startingGL)}&prepaid=${JSON.stringify(prepaidVal)}`
    );

    setUpSummaryRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    setUpSummaryRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiPostMethod
    );

    setUpSummaryRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        month_end: monthEnd,
      })
    );

    runEngine.sendMessage(setUpSummaryRequestMsg.id, setUpSummaryRequestMsg);
  };

  getAdditionsData = async (
    page: number,
    startDate: Moment,
    endDate: Moment,
    identifiers: string[]
  ) => {
    const formattedStartDate = moment(startDate).format("MMMM YYYY");
    const formattedEndDate = moment(endDate).format("MMMM YYYY");
    const formattedIdentifiers = JSON.stringify(identifiers);
    const headers = {
      token: await getStorageData("token"),
      "Content-Type": configJSON.apiContentType,
    };

    const getAdditionsDataRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAdditionsDataApiId = getAdditionsDataRequestMsg.messageId;

    getAdditionsDataRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.additionsDataApiEndPoint +
        `start_date=${formattedStartDate}&end_date=${formattedEndDate}&page=${page}&prepaid_account_identifiers=${formattedIdentifiers}`
    );

    getAdditionsDataRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getAdditionsDataRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiGetMethod
    );

    runEngine.sendMessage(
      getAdditionsDataRequestMsg.id,
      getAdditionsDataRequestMsg
    );
  };

  getFalloffsData = async (
    page: number,
    startDate: Moment,
    endDate: Moment,
    identifiers: string[]
  ) => {
    const formattedFromDate = moment(startDate).format("MMMM YYYY");
    const formattedToDate = moment(endDate).format("MMMM YYYY");
    const formattedIdentifiers = JSON.stringify(identifiers);

    const headers = {
      token: await getStorageData("token"),
      "Content-Type": configJSON.apiContentType,
    };

    const getFalloffsDataRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getFalloffsDataApiId = getFalloffsDataRequestMsg.messageId;

    getFalloffsDataRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.falloffsDataApiEndPoint +
        `start_date=${formattedFromDate}&end_date=${formattedToDate}&page=${page}&prepaid_account_identifiers=${formattedIdentifiers}`
    );

    getFalloffsDataRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getFalloffsDataRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiGetMethod
    );

    runEngine.sendMessage(
      getFalloffsDataRequestMsg.id,
      getFalloffsDataRequestMsg
    );
  };

  getAdjustmentsData = async (
    page: number,
    startDate: Moment,
    endDate: Moment,
    identifiers: string[]
  ) => {
    const formattedStartDate = moment(startDate).format("MMMM YYYY");
    const formattedEndDate = moment(endDate).format("MMMM YYYY");
    const selectedIdentifiers = JSON.stringify(identifiers);

    const headers = {
      token: await getStorageData("token"),
      "Content-Type": configJSON.apiContentType,
    };

    const getAdjustmentsDataRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAdjustmentsDataApiId = getAdjustmentsDataRequestMsg.messageId;

    getAdjustmentsDataRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.adjustmentsDataApiEndPoint +
        `start_date=${formattedStartDate}&end_date=${formattedEndDate}&page=${page}&prepaid_account_identifiers=${selectedIdentifiers}`
    );

    getAdjustmentsDataRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getAdjustmentsDataRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiGetMethod
    );

    runEngine.sendMessage(
      getAdjustmentsDataRequestMsg.id,
      getAdjustmentsDataRequestMsg
    );
  };

  getExpenseAmount = async (
    startDate: Moment,
    endDate: Moment,
    identifiers: string[]
  ) => {
    const formattedStartDate = moment(startDate).format("MMMM YYYY");
    const formattedEndDate = moment(endDate).format("MMMM YYYY");

    const headers = {
      token: await getStorageData("token"),
      "Content-Type": configJSON.apiContentType,
    };

    const getExpenseAmountRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getExpenseAmountApiId = getExpenseAmountRequestMsg.messageId;

    getExpenseAmountRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.expenseAmountApiEndPoint +
        `start_date=${formattedStartDate}&end_date=${formattedEndDate}&prepaid_account_identifiers=${JSON.stringify(
          identifiers
        )}`
    );

    getExpenseAmountRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getExpenseAmountRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiGetMethod
    );

    runEngine.sendMessage(
      getExpenseAmountRequestMsg.id,
      getExpenseAmountRequestMsg
    );
  };

  getPrepaidGLAmount = async (
    startDate: Moment,
    endDate: Moment,
    identifiers: string[]
  ) => {
    const formattedStartDate = moment(startDate).format("MMMM YYYY");
    const formattedEndDate = moment(endDate).format("MMMM YYYY");
    const headers = {
      token: await getStorageData("token"),
      "Content-Type": configJSON.apiContentType,
    };

    const getPrepaidGLAmountRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.endingPrepaidAmountApiId = getPrepaidGLAmountRequestMsg.messageId;
    const endPoint = !identifiers.length
      ? configJSON.endingPrepaidAmountApiEndPoint +
        `start_month=${formattedStartDate}&end_month=${formattedEndDate}`
      : configJSON.endingPrepaidAmountApiEndPoint +
        `start_month=${formattedStartDate}&end_month=${formattedEndDate}&prepaid=${JSON.stringify(
          identifiers
        )}`;
    getPrepaidGLAmountRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    getPrepaidGLAmountRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getPrepaidGLAmountRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiGetMethod
    );

    runEngine.sendMessage(
      getPrepaidGLAmountRequestMsg.id,
      getPrepaidGLAmountRequestMsg
    );
  };

  exportFilteredData = async () => {
    this.setState({ summaryLoader: true });
    const startDate = moment(this.state.fromDate).format("MMMM YYYY");
    const endDate = moment(this.state.toDate).format("MMMM YYYY");
    const filteredIdentifiers = !this.state.filteredIdentifiers.length
      ? []
      : JSON.stringify(this.state.filteredIdentifiers);
    const allIdentifiers = !this.state.filteredIdentifiers.length
      ? JSON.stringify(this.state.identifierList.map((item) => item.name))
      : JSON.stringify(this.state.filteredIdentifiers);
    const headers = {
      token: await getStorageData("token"),
      "Content-Type": configJSON.apiContentType,
    };

    const exportsAsPdfRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.exportAsPdfApiId = exportsAsPdfRequestMsg.messageId;

    exportsAsPdfRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.exportAsPdfApiEndPoint +
        `start_date=${startDate}&end_date=${endDate}&addition_amt=${this.state.additionsAmount}&expense_amt=${this.state.expenseAmount}&ending_prepaid=${this.state.endingPrepaidAmount}&identifiers=${allIdentifiers}&prepaid_account_identifiers=${filteredIdentifiers}`
    );

    exportsAsPdfRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    exportsAsPdfRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiGetMethod
    );

    runEngine.sendMessage(exportsAsPdfRequestMsg.id, exportsAsPdfRequestMsg);
  };

  summaryNavigation = (path: string) => {
    const summaryMsg = new Message(getName(MessageEnum.NavigationMessage));
    summaryMsg.addData(getName(MessageEnum.NavigationTargetMessage), path);
    summaryMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(summaryMsg);
  };

  onResizeSummarySidebarWidth = (isWideBar: boolean) => {
    this.setState({ summarySidebarWidth: isWideBar ? 241 : 100 });
  };
}
