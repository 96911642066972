import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { downloadFile, getFileSize } from "../../../components/src/Utilities";
import {
  getStorageData,
  removeStorageData,
} from "../../../framework/src/Utilities";

export const configJSON = require("./config");

interface ITableData {
  [key: string]: string | number | Date | null;
}

export interface Props {
  setAccountsData: (accountsData: ITableData[]) => void;
  tableWidth: number;
  moveToDetailedUploadScreen: () => void;
}

interface S {
  optionSelection: string;
  isOptionSelected: boolean;
  selectedFile: File | null;
  isFileUploadInProgress: boolean;
  progress: number;
  fileError: string;
  templateUrl: string;
  templateFileName: string;
  templateFileSize: string;
  importTitle: string;
  importDescription: string;
  importedFileDescription: string;
  uploadedAccountsData: ITableData[];
}

interface SS {}

export default class DetailsHomePageController extends BlockComponent<
  Props,
  S,
  SS
> {
  uploadFileTimer: NodeJS.Timeout | null = null;
  uploadFileApiId: string = "";
  downloadTemplateApiId: string = "";
  createVendorsObjectApiId: string = "";

  constructor(props: Props) {
    super(props);

    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      optionSelection: "import",
      isOptionSelected: false,
      selectedFile: null,
      isFileUploadInProgress: false,
      progress: 0,
      templateUrl: "",
      fileError: "",
      templateFileName: "",
      templateFileSize: "",
      importTitle: configJSON.importText,
      importDescription: configJSON.importFileDescription,
      importedFileDescription: configJSON.uploadedText,
      uploadedAccountsData: [],
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.downloadTemplateApiId) {
      if (responseJson?.data && responseJson.data.length > 0) {
        const attributes = responseJson.data[0]?.attributes;
        const file = attributes?.document;
        const fileName = attributes?.file_name;
        const fileSize = attributes?.file_size;

        this.setState({
          templateUrl: file,
          templateFileName: fileName,
          templateFileSize: getFileSize(fileSize),
        });
      }
    }
    if (apiRequestCallId === this.uploadFileApiId) {
      if (responseJson?.length > 0) {
        this.setUploadedAccountsData(responseJson);
      } else if (responseJson?.message) {
        this.setState({ fileError: responseJson.message }, () => {
          setTimeout(() => {
            this.setState({ fileError: "" });
          }, 3000);
        });
      }
    }
  }

  componentDidMount = async () => {
    const isOpenImportFile = await getStorageData("redirectToImportPage");
    if (isOpenImportFile === "true") {
      this.setState({ isOptionSelected: true, optionSelection: "import" });
      await removeStorageData("redirectToImportPage");
    }
    this.getSampleTemplate();
  };

  setUploadedAccountsData = (responseJson: ITableData[]) => {
    if (this.state.optionSelection === "import_template") {
      const filteredData = responseJson.map((item) => {
        const dataOb = Object.entries(item);
        if (dataOb.length > 2) {
          dataOb.splice(2, 1);
        }
        return Object.fromEntries(dataOb);
      });
      this.createVendorsObject(responseJson);
      this.setState({
        uploadedAccountsData: filteredData,
        optionSelection: "",
        isOptionSelected: false,
        selectedFile: null,
        isFileUploadInProgress: false,
        progress: 0,
      });
    } else {
      this.props.setAccountsData(responseJson);
    }
  };

  handleChangeSelection = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ optionSelection: event.target.value });
  };

  handleChangeOptionSelection = (type: string) => {
    this.setState({ optionSelection: type });
  };

  onSelectOption = () => {
    this.setState({ isOptionSelected: true });
  };

  handleFileDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    this.setFileAndError(file);
  };

  handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    this.setFileAndError(file);
  };

  backToDownloadTemplate = () => {
    this.setState({
      optionSelection: "template",
      isOptionSelected: true,
      isFileUploadInProgress: false,
      progress: 0,
      selectedFile: null,
      uploadedAccountsData: [],
    });
  };

  setFileAndError = (file: File | undefined) => {
    if (file) {
      const fileType = file.name.split(".").pop()?.toLowerCase();
      if (fileType === "xls" || fileType === "xlsx" || fileType === "csv") {
        this.setState({ selectedFile: file, isFileUploadInProgress: true });
        this.handleUploadTimer();
      } else {
        this.setState({ fileError: configJSON.fileError }, () => {
          setTimeout(() => {
            this.setState({ fileError: "" });
          }, 3000);
        });
      }
    }
  };

  onOpenFileInput = () => {
    const fileInput = document.getElementById("fileInput");
    fileInput?.click();
  };

  onDeleteFile = () => {
    this.setState({ selectedFile: null });
  };

  handleUploadTimer = () => {
    this.uploadFileTimer = setInterval(() => {
      this.setState(
        (prevState) => ({
          progress: prevState.progress >= 90 ? 100 : prevState.progress + 49,
        }),
        () => {
          if (this.state.progress === 100 && this.uploadFileTimer) {
            clearInterval(this.uploadFileTimer);
            this.setState({ isFileUploadInProgress: false, progress: 0 });
          }
        }
      );
    }, 500);
  };

  downloadTemplate = () => {
    this.setState({
      optionSelection: "import_template",
      isOptionSelected: true,
      importTitle: configJSON.importTemplate,
      importDescription: configJSON.importTemplateDescription,
      importedFileDescription: configJSON.templateUploadedText,
    });

    downloadFile(this.state.templateUrl, this.state.templateFileName);
  };

  backToSelection = () => {
    this.setState({
      isOptionSelected: false,
      optionSelection: "import",
      isFileUploadInProgress: false,
      progress: 0,
      selectedFile: null,
    });
  };

  uploadSelectedFile = () => {
    const headers = {
      "Content-Type": undefined,
    };
    const formData = new FormData();
    let apiEndPoint = configJSON.importFileApiEndPoint;
    if (this.state.selectedFile) {
      const fileFormatType = this.state.selectedFile.name
        .split(".")
        .pop()
        ?.toLowerCase();
      if (fileFormatType === "csv") {
        formData.append("csv_path", this.state.selectedFile);
        apiEndPoint = configJSON.importCSVFileApiEndPoint;
      } else {
        formData.append("xls_path", this.state.selectedFile);
      }
    }
    const uploadInitialFileRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.uploadFileApiId = uploadInitialFileRequestMsg.messageId;

    uploadInitialFileRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint
    );

    uploadInitialFileRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    uploadInitialFileRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    uploadInitialFileRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.createBulkUploadMethod
    );

    runEngine.sendMessage(
      uploadInitialFileRequestMsg.id,
      uploadInitialFileRequestMsg
    );
  };

  getSampleTemplate = () => {
    const headers = {
      "Content-Type": configJSON.apiContentType,
    };

    const initialTemplateRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.downloadTemplateApiId = initialTemplateRequestMsg.messageId;

    initialTemplateRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.downloadTemplateApiEndPoint
    );

    initialTemplateRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    initialTemplateRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getBulkUploadMethod
    );

    runEngine.sendMessage(
      initialTemplateRequestMsg.id,
      initialTemplateRequestMsg
    );
  };

  createVendorsObject = async (
    apiBody: {
      [key: string]: string | number | boolean | Date | null;
    }[]
  ) => {
    const headers = {
      "Content-Type": configJSON.apiContentType,
      token: await getStorageData("token"),
    };

    const createVendorListApiRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createVendorsObjectApiId = createVendorListApiRequestMsg.messageId;

    createVendorListApiRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createVendorListApiEndPoint
    );

    createVendorListApiRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    createVendorListApiRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.createBulkUploadMethod
    );

    createVendorListApiRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(apiBody)
    );

    runEngine.sendMessage(
      createVendorListApiRequestMsg.id,
      createVendorListApiRequestMsg
    );
  };
}
