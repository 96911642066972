Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "cfspreadsheetjournal";
exports.labelBodyText = "cfspreadsheetjournal Body";

exports.btnExampleTitle = "CLICK ME";
exports.journalEntriesTitle = "Journal Entries";
exports.journalEntriesSubTitle =
  "This feature condenses your monthly financial data into a single entry, ready to export to your accounting software. Auto-generated on the last day of each month, it streamlines your record-keeping process. Just review and export - effortless accounting made simple!";
exports.getMonthListApiEndPoint = "bx_block_bulk_uploading/months_to_lock?journal_entries=true";
exports.noMonthsDataMessage = "No details found for the account.";
exports.journalEntriesColumns = [
  {
    name: "Vendor Name",
    key: "vendor_name",
    attribute: "vendor",
    isSelected: false,
  },
  { name: "Attachment", key: "images", attribute: "images", isSelected: false },
  {
    name: "Invoice Number",
    key: "invoice_number",
    attribute: "invoice_number",
    isSelected: false,
  },
  {
    name: "Description",
    key: "invoice_description",
    attribute: "invoice_description",
    isSelected: true,
  },
  { name: "Check", key: "check", attribute: "check", isSelected: false },
  {
    name: "Prepaid Account Identifier",
    key: "prepaid_account_identifier",
    attribute: "prepaid_account",
    isSelected: false,
  },
  {
    name: "Amortization Period (months)",
    key: "amortization_period",
    attribute: "amortization_period",
    isSelected: false,
  },
  {
    name: "Amortization Start",
    key: "amortization_start",
    attribute: "amortization_start",
    isSelected: false,
  },
  {
    name: "Amortization End",
    key: "amortization_end",
    attribute: "amortization_end",
    isSelected: false,
  },
  {
    name: "Expense Account",
    key: "expense_account",
    attribute: "expense_account",
    isSelected: true,
  },
  {
    name: "Total Amount (USD)",
    key: "total_amount",
    attribute: "total_amount",
    isSelected: false,
  },
  {
    name: "Expense Amount",
    key: "expense_amount",
    attribute: "expense_amount",
    isSelected: true,
  },
  {
    name: "Remaining Balance",
    key: "remaining_balance",
    attribute: "remaining_balance",
    isSelected: false,
  },
  { name: "Notes", key: "notes", attribute: "notes", isSelected: false },
];
exports.getMonthlyJournalEntriesApiEndPoint =
  "bx_block_bulk_uploading/view_details?time=";
exports.getExpenseAccountsApiEndPoint =
  "bx_block_bulk_uploading/view_exp_accounts";
exports.getVendorListApiEndPoint = "bx_block_bulk_uploading/view_vendor_names";
exports.getIdentifierListApiEndPoint =
  "bx_block_bulk_uploading/view_prepaid_account_identifiers";
exports.downloadXLS = "Download .xls";
exports.downloadCSV = "Download .csv";
exports.addAnotherFieldBtn = "Add another Field";
exports.downloadCSVApiEndPoint = "bx_block_bulk_uploading/download_csv_journal_entries?";
exports.downloadXLSApiEndPoint = "bx_block_bulk_uploading/download_xls_journal_entries?";
// Customizable Area End