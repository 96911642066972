import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import {
  getStorageData,
  setStorageData,
} from "../../../framework/src/Utilities";

export const configJSON = require("./config");

export interface IValidationChecks {
  name: string;
  check: boolean;
}

interface ISignUpFieldObject {
  value: string;
  error: string;
  isTouched: boolean;
}

export interface Props {
  navigation: any;
}

export interface S {
  isPasswordVisible: boolean;
  validationCheckList: IValidationChecks[];
  iAgreeOnTermsAndConditions: boolean;
  isOpenTermsAndPrivacyDialog: string;
  emailField: ISignUpFieldObject;
  passwordField: ISignUpFieldObject;
  businessNameField: ISignUpFieldObject;
  signUpError: string;
}

export interface SS {}

export default class SignUpController extends BlockComponent<Props, S, SS> {
  signUpApiId: string = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.state = {
      isPasswordVisible: false,
      validationCheckList: [
        { name: configJSON.capitalLetterLabel, check: false },
        { name: configJSON.lowercaseLetterLabel, check: false },
        { name: configJSON.oneNumberLabel, check: false },
        { name: configJSON.eightCharLabel, check: false },
      ],
      iAgreeOnTermsAndConditions: false,
      isOpenTermsAndPrivacyDialog: "",
      emailField: { value: "", error: "", isTouched: false },
      passwordField: { value: "", error: "", isTouched: false },
      businessNameField: { value: "", error: "", isTouched: false },
      signUpError: "",
    };
  }

  async receive(from: string, message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.signUpApiId) {
      if (responseJson?.meta && responseJson?.data) {
        const userDetails = {
          id: responseJson.data?.id,
          ...responseJson.data?.attributes,
        };
        await setStorageData("token", responseJson.meta?.token);
        await setStorageData("userDetails", JSON.stringify(userDetails));
        this.handleNavigation("VerifyEmail");
      } else if (responseJson.errors && responseJson.errors.length > 0) {
        this.setState(
          {
            signUpError:
              responseJson.errors[0]?.account || configJSON.somethingWentWrong,
          },
          () => {
            setTimeout(() => {
              this.setState({ signUpError: "" });
            }, 3000);
          }
        );
      }
    }
  }

  async componentDidMount() {
    const tokenKey = await getStorageData("token");
    const isUserVerified = await getStorageData("isUserVerified");
    if (tokenKey || isUserVerified !== "true") {
      this.handleNavigation("Home");
    }
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  handleChangeSignUpField = (
    event: React.ChangeEvent<HTMLInputElement>,
    field: string
  ) => {
    let value: string | boolean = "";
    if (field === "iAgreeOnTermsAndConditions") {
      value = event.target.checked;
      this.setState({ iAgreeOnTermsAndConditions: value });
    } else {
      value = event.target.value;
      if (field === "email") {
        this.setEmailField(value);
      } else if (field === "password") {
        this.setPasswordField(value);
      } else if (field === "businessName") {
        const isValidBusinessName = configJSON.businessNameRegex.test(value);
        let businessNameErrorMsg = "";
        if (!isValidBusinessName) {
          businessNameErrorMsg = configJSON.businessNameError;
        }
        this.setState({
          businessNameField: {
            value: value,
            isTouched: true,
            error: businessNameErrorMsg,
          },
        });
      }
    }
  };

  setEmailField = (value: string) => {
    let errorMessage = "";
    const isValidEmail = configJSON.emailRegex.test(value);
    if (!isValidEmail) {
      errorMessage = value ? configJSON.emailInvalid : configJSON.emailRequired;
    }
    this.setState({
      emailField: {
        value: value,
        isTouched: true,
        error: errorMessage,
      },
    });
  };

  setPasswordField = (value: string) => {
    let passwordErrorMsg = "";
    let validationChecks = [...this.state.validationCheckList];
    const capitalRegex = /[A-Z]/;
    const lowercaseRegex = /[a-z]/;
    const numberRegex = /\d/;
    const spaceRegex = /^\S+$/;
    validationChecks[0].check = capitalRegex.test(value);
    validationChecks[1].check = lowercaseRegex.test(value);
    validationChecks[2].check = numberRegex.test(value);
    validationChecks[3].check = value.replace(/\s/g, "").length >= 8;
    const noSpacePresents = spaceRegex.test(value);
    const isValidPassword =
      validationChecks.every((element) => element.check === true) &&
      noSpacePresents;
    if (!isValidPassword) {
      passwordErrorMsg = value
        ? configJSON.passwordInvalid
        : configJSON.passwordRequired;
    }
    this.setState({
      passwordField: {
        value: value,
        isTouched: true,
        error: passwordErrorMsg,
      },
      validationCheckList: validationChecks,
    });
  };

  toggleShowPassword = () => {
    this.setState({
      ...this.state,
      isPasswordVisible: !this.state.isPasswordVisible,
    });
  };

  handleSignUp = () => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const signUpRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.signUpApiId = signUpRequestMsg.messageId;

    signUpRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.signUpAPIEndPoint
    );

    signUpRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    signUpRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    signUpRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        data: {
          type: "email_account",
          attributes: {
            email: this.state.emailField.value,
            password: this.state.passwordField.value,
            business_name: this.state.businessNameField.value,
          },
        },
      })
    );

    runEngine.sendMessage(signUpRequestMsg.id, signUpRequestMsg);
  };

  handleLoginRedirection = () => {
    this.handleNavigation("Login");
  };

  handleNavigation = (page: string) => {
    const routeMsg = new Message(getName(MessageEnum.NavigationMessage));
    routeMsg.addData(getName(MessageEnum.NavigationTargetMessage), page);
    routeMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(routeMsg);
  };

  handleToggleTermsAndPrivacyDialog = (type: string) => {
    this.setState({
      isOpenTermsAndPrivacyDialog: type,
    });
  };

  handleCloseTermsAndPrivacyDialog = (isAgree: boolean) => {
    this.setState({
      iAgreeOnTermsAndConditions:
        isAgree || this.state.iAgreeOnTermsAndConditions,
      isOpenTermsAndPrivacyDialog: "",
    });
  };
}
