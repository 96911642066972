import React from "react";
import { Box, Dialog, DialogContent } from "@material-ui/core";

import CustomButton from "../../../components/src/CustomButton.web";
import { dialogBG } from "./assets";
import InconsistentDetailsViewController, {
  Props,
  configJSON,
} from "./InconsistentDetailsViewController.web";
import CustomSearchInput from "../../../components/src/CustomSearchInput.web";
import CustomMissMatchDataTable from "../../../components/src/CustomMissMatchDataTable.web";

export default class InconsistentDetailsView extends InconsistentDetailsViewController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const {
      isOpenErrorDialog,
      missMatchView,
      searchItem,
    } = this.state;
    return (
      <>
        {missMatchView && (
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            width={"100%"}
            height={"inherit"}
            margin={"auto"}
          >
            <Box>
              {missMatchView === "identifier" && (
                <Box
                  textAlign={"center"}
                  style={webStyle.identifierErrorDialogTitle}
                >
                  {configJSON.missMatchedIdentifiersTitle}
                </Box>
              )}
              {missMatchView === "vendor" && (
                <Box
                  textAlign={"center"}
                  style={webStyle.identifierErrorDialogTitle}
                >
                  {configJSON.missMatchedVendorsTitle}
                </Box>
              )}
              {missMatchView === "expenseAccounts" && (
                <Box
                  textAlign={"center"}
                  style={webStyle.identifierErrorDialogTitle}
                >
                  {configJSON.missMatchedExpenseAccountTitle}
                </Box>
              )}
              <Box
                margin={"32px 0px"}
                display={"flex"}
                justifyContent={"center"}
              >
                <Box
                  sx={{
                    width: {
                      lg: "414px",
                      md: "414px",
                    },
                  }}
                >
                  <CustomSearchInput
                    value={searchItem}
                    handleChange={this.handleSearch}
                    onClear={this.clearSearchRecords}
                    placeholder={configJSON.search}
                  />
                </Box>
              </Box>
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                margin={"auto"}
              >
                <CustomMissMatchDataTable
                  columns={this.getDropdownListColumns()}
                  rows={this.getDropdownListRows()}
                  width={this.props.tableWidth}
                  dropdownList={this.getDropdownList()}
                  handleDropdownChange={this.handleUpdateMissMatchData}
                  deleteOption={this.deleteDropdownOption}
                  updateOption={this.updateDropdownOption}
                />
              </Box>
              <Box
                margin={"48px 0px"}
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Box
                  sx={{
                    paddingRight: {
                      xs: "8px",
                      sm: "12px",
                      md: "24px",
                      lg: "48px",
                      xl: "48px",
                    },
                  }}
                >
                  <CustomButton
                    width={197}
                    label={configJSON.backBtn}
                    variant="outlined"
                    onClick={this.backToDetails}
                  />
                </Box>
                <Box>
                  <CustomButton
                    label={configJSON.continueBtn}
                    variant="contained"
                    width={197}
                    disabled={!this.checkContinueEnabled()}
                    onClick={this.saveUpdatedData}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        )}
        {isOpenErrorDialog && (
          <Dialog
            open={!!isOpenErrorDialog}
            PaperProps={{
              style: webStyle.identifierErrorDialogPaper,
            }}
            maxWidth="md"
          >
            <DialogContent style={webStyle.identifierErrorDialogContent}>
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                style={webStyle.identifierErrorDialogTitle}
              >
                {configJSON.error}
              </Box>
              <Box
                style={webStyle.identifierErrorDialogSubTitle}
                padding={"16px 0px"}
                textAlign={"center"}
              >
                {this.getInconsistentErrorTitle()}
              </Box>
              <Box
                style={webStyle.identifierErrorDialogDescription}
                textAlign={"center"}
              >
                {this.getInconsistentErrorDescription()}
              </Box>
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                margin={"24px 0px 0px"}
              >
                <CustomButton
                  variant="contained"
                  label={
                    isOpenErrorDialog === "identifier" ||
                    isOpenErrorDialog === "expenseAccounts"
                      ? configJSON.reviewIdentifier
                      : configJSON.reviewVendor
                  }
                  onClick={this.backToReview}
                  width={173}
                />
              </Box>
            </DialogContent>
          </Dialog>
        )}
      </>
    );
  }
}

const webStyle = {
  identifierErrorDialogPaper: {
    borderRadius: "16px",
    backgroundColor: "#001327",
    width: "687px",
  },
  identifierErrorDialogContent: {
    backgroundImage: `url(${dialogBG})`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    padding: "32px",
  },
  identifierErrorDialogTitle: {
    fontFamily: "Open Sans",
    fontSize: "30px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: 1.33,
    letterSpacing: -0.15,
    color: "#FFF",
  },
  identifierErrorDialogSubTitle: {
    fontFamily: "Open Sans",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "32px",
    color: "#FFF",
  },
  identifierErrorDialogDescription: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: 1.5,
    color: "#FFF",
  },
};
