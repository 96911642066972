import React from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Snackbar,
  TextField,
} from "@material-ui/core";
import DetailsTableViewController, {
  Props,
  configJSON,
} from "./DetailsTableViewController.web";
import CustomSearchInput from "../../../components/src/CustomSearchInput.web";
import {
  addIcon,
  dialogBG,
  lockIcon,
  downloadIcon,
  exportPdfIcon,
} from "./assets";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import CustomEditableTable from "../../../components/src/CustomEditableTable.web";
import CustomMonthTabStepper from "../../../components/src/CustomMonthTabStepper.web";
import CustomButton from "../../../components/src/CustomButton.web";
import { Alert } from "@material-ui/lab";

export default class DetailsTableView extends DetailsTableViewController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const {
      detailedSheetColumns,
      searchValue,
      detailedSheetData,
      activeMonth,
      monthList,
      vendorList,
      prepaidAccountIdentifierList,
      isOpenDeleteMonthDataDialog,
      isOpenDiscardWarningDialog,
      isOpenUnlockDialog,
      isOpenNotesDialog,
      expenseAccountList,
      notesValue,
      sortedColumn,
      snackbarErrorMsg,
    } = this.state;
    return (
      <Box height={"inherit"} width={"100%"}>
        <Snackbar
          open={!!snackbarErrorMsg}
          autoHideDuration={6000}
          onClose={this.handleCloseSnackbar}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert onClose={this.handleCloseSnackbar} severity="error">
            {snackbarErrorMsg}
          </Alert>
        </Snackbar>
        <Box
          sx={{
            padding: {
              xs: "32px 8px",
              sm: "36px 16px",
              md: "40px 24px",
              lg: "48px 32px",
            },
          }}
        >
          <Box style={webStyle.detailsTableTitle}>{configJSON.detailsText}</Box>
          <Box marginTop={"32px"} style={webStyle.detailsTableSubtitle}>
            {configJSON.detailsDescriptionText}
          </Box>
          <Box style={webStyle.tableContainer}>
            <Box marginBottom={"16px"}>
              <CustomMonthTabStepper
                stepList={monthList}
                onStepClick={this.handleMonthChange}
                activeStep={activeMonth}
              />
            </Box>
            <Box
              alignItems={"center"}
              justifyContent={"space-between"}
              sx={{
                padding: {
                  xs: "0px",
                  sm: "0px 8px",
                  md: "0px 16px",
                  lg: "0px 24px",
                },
                display: {
                  xs: "block",
                  sm: "flex",
                  md: "flex",
                  lg: "flex",
                },
              }}
            >
              <Box
                sx={{
                  width: {
                    xs: "166px",
                    sm: "166px",
                    md: "184px",
                    lg: "184px",
                  },
                }}
              >
                {this.checkIsMonthLocked() ? (
                  <Button
                    fullWidth
                    variant="contained"
                    style={webStyle.lockButton}
                    onClick={this.toggleLockMonthDialog}
                    data-test-id="unlock-btn"
                  >
                    <span style={webStyle.addImageWrapper}>
                      <img src={lockIcon} height={15} width={15} alt="" />
                    </span>
                    {configJSON.unlockToEdit}
                  </Button>
                ) : (
                  <Button
                    fullWidth
                    style={webStyle.addRowBtn}
                    variant="outlined"
                    onClick={this.addNewRow}
                    data-test-id="add-new-btn"
                  >
                    <span style={webStyle.addImageWrapper}>
                      <img src={addIcon} height={15} width={15} alt="" />
                    </span>
                    {configJSON.addNewRow}
                  </Button>
                )}
              </Box>
              <Box
                sx={{
                  width: {
                    md: "414x",
                    lg: "414px",
                  },
                  padding: {
                    sm: "0px 0px 0px 8px",
                    xs: "8px 0px",
                  },
                }}
              >
                <CustomSearchInput
                  handleChange={this.handleSearchInDetailedTable}
                  value={searchValue}
                  onClear={this.onClearSearchValue}
                  placeholder={configJSON.search}
                />
              </Box>
            </Box>
            <Box>
              <CustomEditableTable
                rows={detailedSheetData}
                columns={detailedSheetColumns}
                vendorList={vendorList}
                width={this.props.tableWidth}
                prepaidAccountIdentifierList={prepaidAccountIdentifierList}
                expenseAccountList={expenseAccountList}
                handleDropdownChange={this.handleUpdateDropdownValue}
                showEditIcon={!this.checkIsMonthLocked()}
                onEdit={this.toggleEditRow}
                handleCellValueChange={this.handleUpdateTableCellValue}
                onSort={this.sortTableData}
                searchedText={searchValue}
                handleFileDelete={this.deleteFileData}
                handleOpenFileInput={this.onOpenFileInput}
                handleFileUpload={this.handleUpload}
                deleteOption={this.deleteDropdownOption}
                updateOption={this.updateTableDropdownOption}
                handleSaveCellValue={this.handleSaveTableCellValue}
                sortedColumn={sortedColumn}
                key={activeMonth}
                disabledCalculatedField={
                  !this.checkForDisabledCalculatedField()
                }
              />
            </Box>
            {detailedSheetData.length > 0 && (
              <Box
                marginTop={"16px"}
                display={"flex"}
                justifyContent={"space-between"}
                sx={{
                  flexDirection: {
                    xs: "column",
                    sm: "column",
                    md: "row",
                  },
                  alignItems: {
                    xs: "start",
                    sm: "start",
                    md: "center",
                  },
                }}
              >
                <Box>
                  {activeMonth === this.getCurrentMonth() && (
                    <Button
                      data-test-id="delete-month-data"
                      variant="outlined"
                      style={webStyle.deleteMonthDataBtn}
                      onClick={() => this.toggleDeleteMonthDialog(true)}
                    >
                      {configJSON.deleteMonthData}
                    </Button>
                  )}
                </Box>
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  sx={{
                    marginTop: {
                      xs: "10px",
                      sm: "10px",
                      md: "0px",
                    },
                    flexDirection: {
                      xs: "column",
                      sm: "column",
                      md: "row",
                    },
                  }}
                >
                  <Box
                    sx={{
                      marginRight: {
                        xs: "0px",
                        sm: "0px",
                        md: "18px",
                      },
                    }}
                  >
                    <Button
                      variant="outlined"
                      style={webStyle.downloadBtn}
                      data-test-id="download-pdf"
                      fullWidth
                      onClick={this.downloadPdf}
                    >
                      {configJSON.exportAsPdfLabel}
                      <img
                        src={exportPdfIcon}
                        width={"22px"}
                        style={webStyle.downloadImage}
                        alt="download"
                      />
                    </Button>
                  </Box>
                  <Box
                    sx={{
                      marginRight: {
                        xs: "0px",
                        sm: "0px",
                        md: "18px",
                      },
                      marginTop: {
                        xs: "10px",
                        sm: "10px",
                        md: "0px",
                      },
                    }}
                  >
                    <Button
                      style={webStyle.downloadBtn}
                      variant="outlined"
                      fullWidth
                      data-test-id="download-xls"
                      onClick={() => this.downloadActiveMonthData(true)}
                    >
                      {configJSON.downloadXLS}
                      <img
                        src={downloadIcon}
                        width={"24px"}
                        alt="download"
                        style={webStyle.downloadImage}
                      />
                    </Button>
                  </Box>
                  <Box
                    sx={{
                      marginTop: {
                        xs: "10px",
                        sm: "10px",
                        md: "0px",
                      },
                    }}
                  >
                    <Button
                      style={webStyle.downloadBtn}
                      variant="outlined"
                      fullWidth
                      data-test-id="download-csv"
                      onClick={() => this.downloadActiveMonthData(false)}
                    >
                      {configJSON.downloadCSV}
                      <img
                        src={downloadIcon}
                        style={webStyle.downloadImage}
                        alt="download"
                        width={"24px"}
                      />
                    </Button>
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
        {isOpenDeleteMonthDataDialog && (
          <Dialog
            open={isOpenDeleteMonthDataDialog}
            PaperProps={{
              style: webStyle.deleteDialogPaper,
            }}
            fullWidth
          >
            <DialogContent style={webStyle.deleteMonthDataDialogContent}>
              <Box
                display={"flex"}
                alignItems={"center"}
                style={webStyle.deleteMonthDialogTitle}
                justifyContent={"center"}
              >
                {configJSON.deleteMonthDataTitle}
              </Box>
              <Box style={webStyle.dialogDescription} padding={"28px 0px"}>
                {configJSON.deleteMonthDataDescription}
              </Box>
              <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Box paddingRight={"8px"}>
                  <CustomButton
                    variant="outlined"
                    width={173}
                    label={configJSON.cancel}
                    onClick={() => this.toggleDeleteMonthDialog(false)}
                  />
                </Box>
                <Box>
                  <Button
                    style={webStyle.deleteMonthDataDialogBtn}
                    data-test-id="confirm-delete-data"
                    variant="outlined"
                    onClick={this.deleteMonthData}
                  >
                    {configJSON.deleteMonthData}
                  </Button>
                </Box>
              </Box>
            </DialogContent>
          </Dialog>
        )}
        {isOpenUnlockDialog && (
          <Dialog
            open={isOpenUnlockDialog}
            fullWidth
            PaperProps={{
              style: webStyle.deleteDialogPaper,
            }}
          >
            <DialogContent style={webStyle.deleteMonthDataDialogContent}>
              <Box
                display={"flex"}
                alignItems={"center"}
                style={webStyle.unlockDialogText}
              >
                {configJSON.unlockDialogTitle}
              </Box>
              <Box style={webStyle.unlockDialogText} padding={"28px 0px"}>
                {configJSON.unlockDialogSubTitle}
              </Box>
              <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Box paddingRight={"8px"}>
                  <CustomButton
                    variant="outlined"
                    width={173}
                    label={configJSON.cancel}
                    onClick={this.toggleLockMonthDialog}
                  />
                </Box>
                <Box>
                  <CustomButton
                    variant="contained"
                    label={configJSON.continueBtn}
                    width={173}
                    onClick={this.toggleLockMonth}
                  />
                </Box>
              </Box>
            </DialogContent>
          </Dialog>
        )}
        {isOpenDiscardWarningDialog && (
          <Dialog
            open={isOpenDiscardWarningDialog}
            fullWidth
            PaperProps={{
              style: webStyle.deleteDialogPaper,
            }}
          >
            <DialogContent style={webStyle.deleteMonthDataDialogContent}>
              <Box
                display={"flex"}
                alignItems={"center"}
                marginBottom={"32px"}
                style={webStyle.unlockDialogText}
              >
                {configJSON.discardChangesDescription}
              </Box>
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Box paddingRight={"8px"}>
                  <CustomButton
                    variant="outlined"
                    width={173}
                    label={configJSON.discardChanges}
                    onClick={this.discardChanges}
                  />
                </Box>
                <Box>
                  <CustomButton
                    variant="contained"
                    width={173}
                    label={configJSON.keepEditingLabel}
                    onClick={this.toggleDiscardEditDialog}
                  />
                </Box>
              </Box>
            </DialogContent>
          </Dialog>
        )}
        {isOpenNotesDialog && (
          <Dialog
            fullWidth
            open={isOpenNotesDialog}
            PaperProps={{
              style: webStyle.deleteDialogPaper,
            }}
          >
            <DialogContent style={webStyle.deleteMonthDataDialogContent}>
              <Box
                display={"flex"}
                alignItems={"center"}
                style={webStyle.unlockDialogText}
              >
                {configJSON.notesPopupTitle}
              </Box>
              <Box margin={"32px 0px"}>
                <TextField
                  InputProps={{
                    style: webStyle.notesInput,
                  }}
                  multiline
                  placeholder={configJSON.notesPlaceholder}
                  minRows={3}
                  variant="outlined"
                  fullWidth
                  value={notesValue}
                  onChange={this.handleChangeNotes}
                  data-test-id="notes-text-area"
                />
              </Box>
              <Box
                justifyContent={"center"}
                alignItems={"center"}
                display={"flex"}
              >
                <Box paddingRight={"8px"}>
                  <CustomButton
                    variant="outlined"
                    label={configJSON.cancel}
                    width={173}
                    onClick={this.toggleNotesDialog}
                  />
                </Box>
                <Box>
                  <CustomButton
                    variant="contained"
                    label={configJSON.continueBtn}
                    width={173}
                    onClick={this.updatesNotesValue}
                    disabled={!notesValue.trim()}
                  />
                </Box>
              </Box>
            </DialogContent>
          </Dialog>
        )}
      </Box>
    );
  }
}

const webStyle = {
  detailsTableTitle: {
    fontSize: "40px",
    fontFamily: "Brown",
    fontStyle: "normal",
    color: "#FFF",
    fontWeight: 700,
    lineHeight: "39.2px",
  },
  detailsTableSubtitle: {
    fontStyle: "normal",
    fontSize: "16px",
    fontFamily: "Open Sans",
    fontWeight: 400,
    color: "#FFF",
    lineHeight: "24px",
  } as CSSProperties,
  tableContainer: {
    backgroundColor: "#171717",
    padding: "16px 0px",
    margin: "16px 0px",
  } as CSSProperties,
  addRowBtn: {
    color: "#FFF",
    border: "1px solid #78716C",
    fontSize: "14px",
    fontWeight: 700,
    fontFamily: "Open Sans",
    lineHeight: "22px",
    textTransform: "none",
    borderRadius: "4px",
    paddingTop: "8px",
    paddingBottom: "8px",
  } as CSSProperties,
  addImageWrapper: {
    paddingRight: "10px",
  },
  lockButton: {
    color: "#FFF",
    fontWeight: 700,
    fontSize: "14px",
    background: "linear-gradient(99deg, #01AA7F 2.64%, #00AED2 100%)",
    lineHeight: "22px",
    fontFamily: "Open Sans",
    textTransform: "none",
    borderRadius: "50px",
    paddingTop: "8px",
    paddingBottom: "8px",
  } as CSSProperties,
  deleteMonthDataBtn: {
    borderRadius: "8px",
    color: "#DC2626",
    textTransform: "none",
    borderColor: "#DC2626",
    fontWeight: 700,
    fontSize: "14px",
    fontStyle: "normal",
    fontFamily: "Open Sans",
    minHeight: "40px",
  } as React.CSSProperties,
  deleteMonthDataDialogBtn: {
    borderRadius: "8px",
    fontFamily: "Open Sans",
    color: "#DC2626",
    textTransform: "none",
    borderColor: "#DC2626",
    fontSize: "1rem",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "1.5rem",
    minWidth: "144px",
    minHeight: "44px",
  } as React.CSSProperties,
  deleteDialogPaper: {
    backgroundColor: "#001327",
    borderRadius: "16px",
    width: "501px",
  },
  backgroundImage: `url(${dialogBG})`,
  deleteMonthDataDialogContent: {
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    padding: "32px",
  },
  deleteMonthDialogTitle: {
    fontSize: "16px",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: 1.33,
    color: "#DC2626",
  },
  dialogDescription: {
    fontFamily: "Open Sans",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: 1.5,
    color: "#FFF",
  },
  downloadBtn: {
    borderRadius: "8px",
    fontFamily: "Open Sans",
    color: "#FFF",
    textTransform: "none",
    borderColor: "#FFF",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 700,
    minWidth: "132px",
    lineHeight: "18px",
    minHeight: "40px",
  } as React.CSSProperties,
  downloadImage: {
    paddingLeft: "8px",
  },
  unlockDialogText: {
    fontSize: "14px",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: 700,
    color: "#FFF",
    lineHeight: 1.5,
  },
  notesInput: {
    backgroundColor: "#FFF",
    borderRadius: "8px",
    color: "#000",
    fontFamily: "Open Sans",
    borderColor: "#FFF",
  } as CSSProperties,
};
