import React from "react";
import PasswordRecoveryController, {
  Props,
  configJSON,
} from "./PasswordRecoveryController";
import { Box } from "@material-ui/core";
import CustomInput from "../../../components/src/CustomInput.web";
import { backgroundImg, logo } from "./assets";
import CustomButton from "../../../components/src/CustomButton.web";

export default class PasswordRecovery extends PasswordRecoveryController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { email, isEmailSent, error, isTouched, apiError, resendClicked } =
      this.state;
    return (
      <Box height={"auto"}>
        <Box style={webStyle.mainContainer}>
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            height={"inherit"}
            width={"100%"}
            margin={"auto"}
          >
            <Box width={"551px"}>
              <Box sx={webStyle.passwordRecoveryContainer}>
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  style={webStyle.pointer}
                  justifyContent={"center"}
                  onClick={() => this.redirectToPage("Home")}
                >
                  <img src={logo} alt="logo" width={250} height={160} />
                </Box>
                <Box
                  marginBottom={"32px"}
                  textAlign={"center"}
                  style={webStyle.passwordRecoveryTitle}
                >
                  {configJSON.passwordRecoveryLabel}
                </Box>
                <Box style={webStyle.subTitleText}>
                  {isEmailSent
                    ? configJSON.checkYourEmailText
                    : configJSON.forgotPasswordLabel}
                </Box>
                <Box style={webStyle.forgotPasswordDescription}>
                  {isEmailSent
                    ? `${configJSON.emailSentMessage} ${email}`
                    : configJSON.forgotPasswordDescription}
                </Box>
                {!isEmailSent && (
                  <>
                    {apiError && (
                      <Box
                        style={webStyle.apiError}
                        data-test-id="error-message"
                      >
                        {apiError}
                      </Box>
                    )}
                    <Box style={webStyle.inputContainer}>
                      <Box style={webStyle.textFieldLabel}>
                        {configJSON.firstInputPlaceholder}
                      </Box>
                      <CustomInput
                        value={email}
                        handleChange={this.handleChangeEmail}
                        placeholder={configJSON.emailPlaceholder}
                        isError={!!error}
                        errorMessage={error}
                      />
                    </Box>
                    <Box marginTop={"32px"}>
                      <CustomButton
                        fullWidth
                        variant="contained"
                        onClick={() => this.handleSendRecoveryLink(false)}
                        label={configJSON.recoveryLinkBtn}
                        disabled={!!error || !isTouched}
                      />
                    </Box>
                  </>
                )}
                <Box
                  display={"flex"}
                  justifyContent={"center"}
                  marginTop={isEmailSent ? "8px" : "16px"}
                >
                  {isEmailSent && (
                    <Box
                      data-test-id="resend-btn"
                      style={webStyle.resendLink}
                      paddingRight={"24px"}
                      sx={{ display: resendClicked ? "none" : "block" }}
                      onClick={() => this.handleSendRecoveryLink(true)}
                    >
                      {configJSON.resendEmailBtn}
                    </Box>
                  )}
                  <Box
                    data-test-id="back-to-login-btn"
                    style={webStyle.backToLoginText}
                    onClick={this.backToLogin}
                  >
                    {configJSON.backToLoginBtn}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  }
}

const webStyle = {
  mainContainer: {
    backgroundImage: `url(${backgroundImg})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundColor: "#001327",
    minHeight: "100vh",
    height: "100%",
    display: "flex",
  },
  passwordRecoveryContainer: {
    padding: "32px 95px",
    background: "rgba(0, 19, 39, 0.60)",
    borderRadius: "16px",
    backdropFilter: "blur(8px)",
    "@media (max-width: 600px)": {
      padding: "32px 40px",
    },
  },
  passwordRecoveryTitle: {
    color: "#FFF",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "36px",
    lineHeight: 1.22,
    letterSpacing: -0.36,
  },
  subTitleText: {
    color: "#FFF",
    fontSize: "14px",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: 1.57,
  },
  inputContainer: {
    padding: "8px 0px",
  },
  textFieldLabel: {
    color: "#FFF",
    fontFamily: "Open Sans",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: 1.5,
    paddingBottom: "4px",
  },
  backToLoginText: {
    color: "#FFF",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    lineHeight: 1.5,
    fontWeight: 700,
    fontSize: "16px",
    cursor: "pointer",
    borderBottom: "1px solid #FFF",
  },
  forgotPasswordDescription: {
    fontFamily: "Open Sans",
    fontSize: "12px",
    fontWeight: 400,
    fontStyle: "normal",
    lineHeight: 1.5,
    color: "#FFF",
    padding: "2px 0px 24px",
  },
  resendLink: {
    background: "linear-gradient(99deg, #01AA7F 2.64%, #00AED2 100%)",
    backgroundClip: "text",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: 1.5,
    cursor: "pointer",
  },
  apiError: {
    fontSize: "12px",
    fontStyle: "normal",
    fontFamily: "Open Sans",
    fontWeight: 700,
    lineHeight: 1.5,
    color: "#DC2626",
    backgroundColor: "#FEE2E2",
    borderLeft: "2px solid #DC2626",
    borderRadius: "4px",
    padding: "21px 16px",
    marginBottom: "26px",
  },
  pointer: {
    cursor: "pointer",
  },
};
