import React from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  makeStyles,
  Box,
  TextField,
} from "@material-ui/core";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CustomSearchableDropdown from "./CustomSearchableDropdown.web";
import { CreateOutlined } from "@material-ui/icons";
import CustomInlineFileUpload from "./CustomInlineFileUpload.web";

const useStyles = makeStyles((theme) => ({
  table: {
    overflowX: "auto",
    paddingBottom: "16px",
    [theme.breakpoints.down("sm")]: {
      maxWidth: `calc(100vw - 96px)`,
      [theme.breakpoints.down("xs")]: {
        maxWidth: `calc(100vw - 16px)`,
      },
    },
    [theme.breakpoints.up("sm")]: {
      maxWidth: (props: ICustomEditableTableProps) =>
        `calc(100vw - ${props.width}px)`,
    },
    "& .table-body-cell": {
      backgroundColor: "#171717",
      color: "#FFF",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "14px",
      padding: "0px",
      minWidth: "160px",
      maxWidth: "180px",
      height: "54px",
    },
    "& .table-disable-cell": {
      backgroundColor: "#292524",
      color: "#A8A29E",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "14px",
      padding: "0px",
      minWidth: "160px",
      maxWidth: "180px",
      height: "54px",
      position: "relative",
    },
    "& .table-body-cell-input": {
      backgroundColor: "#44403C",
      color: "#FFF",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "14px",
      padding: "0px",
      minWidth: "160px",
      maxWidth: "180px",
      position: "relative",
      height: "54px",
      "& textarea": {
        maxHeight: "50px",
      },
    },
    "& td:first-child": {
      paddingLeft: "24px",
    },
    "& .table-head-empty-cell": {
      backgroundColor: "#171717",
    },
    "& .table-body-cell-input-edit": {
      backgroundColor: "#44403C",
    },
    "& .table-head-cell": {
      backgroundColor: "#171717",
      color: "#FFF",
      fontFamily: "Open Sans",
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "14px",
      padding: "8px 24px",
      minWidth: "160px",
      maxWidth: "180px",
    },
    "& .required-text": {
      fontWeight: 400,
      fontSize: "12px",
    },
  },
  textField: {
    height: "100%",
    "& textarea": {
      color: "#FFF !important",
      fontFamily: "Poppins",
      fontSize: "14px",
      textAlign: "center",
    },
    "& input": {
      color: "#FFF !important",
      fontFamily: "Poppins",
      fontSize: "14px",
      textAlign: "center",
      height: "100%",
      boxSizing: "border-box",
    },
    "& .MuiOutlinedInput-root": {
      height: "100%",
      "& fieldset": {
        border: "2px solid transparent",
        borderRadius: "0px !important",
      },
      "&:hover fieldset": {
        border: "2px solid #00AED2 !important",
      },
      "&.Mui-focused fieldset": {
        border: "2px solid #00AED2 !important",
      },
      "&.Mui-disabled fieldset": {
        border: "2px solid transparent !important",
      },
      "&.Mui-error fieldset": {
        border: "2px solid #DC2626 !important",
      },
    },
  },
}));

interface IRawData {
  id?: number;
  images: { name: string; url: string | File | null; id: number }[];
  vendor_name_id: number;
  vendor_name: string;
  prepaid_account_identifier_id: number;
  prepaid_account_identifier: string;
  invoice_date: string;
  invoice_number: string;
  invoice_description: string;
  check: string;
  amortization_period: string;
  amortization_start: string;
  amortization_end: string;
  expense_account: string;
  exp_account_id: number;
  total_amount: string;
  expense_amount: string;
  notes: string;
  account_id: number;
  time_of_creation: string;
  edit?: boolean;
  error?: string[];
  remaining_balance: number;
}

interface ICustomEditableTableProps {
  columns: {
    name: string;
    isRequired: boolean;
    showSorting: boolean;
    key: keyof IRawData;
  }[];
  rows: IRawData[];
  width: number;
  onSort?: (column: keyof IRawData, isAsc: boolean) => void;
  onSelectColumn?: (column: string) => void;
  handleDropdownChange?: (
    value: string,
    valueId: string,
    param: keyof IRawData,
    paramId: keyof IRawData,
    id: number
  ) => void;
  prepaidAccountIdentifierList: { name: string; value: string }[];
  vendorList: { name: string; value: string }[];
  expenseAccountList: { name: string; value: string }[];
  showEditIcon?: boolean;
  onEdit?: (id: number) => void;
  handleCellValueChange?: (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    param: keyof IRawData,
    id: number
  ) => void;
  handleFileDelete?: (imageId: number, id: number) => void;
  handleOpenFileInput: (rowId: number) => void;
  handleFileUpload: (
    event: React.ChangeEvent<HTMLInputElement>,
    rowId: number
  ) => void;
  searchedText?: string;
  deleteOption: (option: string, param: keyof IRawData) => void;
  updateOption: (
    value: string,
    optionId: string,
    param: keyof IRawData
  ) => void;
  handleSaveCellValue: (id: number) => void;
  sortedColumn: { name: string; isAsc: boolean };
  disabledCalculatedField?: boolean;
}

const CustomEditableTable: React.FC<ICustomEditableTableProps> = ({
  columns,
  rows,
  width,
  onSort,
  onSelectColumn,
  handleDropdownChange,
  prepaidAccountIdentifierList,
  vendorList,
  expenseAccountList,
  showEditIcon,
  onEdit,
  handleCellValueChange,
  handleFileDelete,
  handleOpenFileInput,
  handleFileUpload,
  searchedText,
  deleteOption,
  updateOption,
  handleSaveCellValue,
  sortedColumn,
  disabledCalculatedField,
}) => {
  const classes = useStyles({
    columns,
    rows,
    width,
    onSort,
    onSelectColumn,
    handleDropdownChange,
    prepaidAccountIdentifierList,
    vendorList,
    expenseAccountList,
    showEditIcon,
    onEdit,
    handleCellValueChange,
    handleFileDelete,
    handleOpenFileInput,
    handleFileUpload,
    searchedText,
    deleteOption,
    updateOption,
    handleSaveCellValue,
    sortedColumn,
    disabledCalculatedField,
  });

  const highlightSearch = (key: keyof IRawData, value: string | number) => {
    let isPassed: boolean = false;
    if (
      (key === "invoice_description" ||
        key === "check" ||
        key === "notes" ||
        key === "invoice_number" ||
        key === "vendor_name" ||
        key === "prepaid_account_identifier") &&
      value?.toString() &&
      searchedText &&
      value?.toString().toLowerCase().includes(searchedText.toLowerCase())
    ) {
      isPassed = true;
    }
    return isPassed;
  };

  const handleSort = (column: {
    name: string;
    isRequired: boolean;
    showSorting: boolean;
    key: keyof IRawData;
  }) => {
    if (column.showSorting) {
      if (
        !sortedColumn.name ||
        sortedColumn.name !== column.key ||
        (sortedColumn.name === column.key && !sortedColumn.isAsc)
      ) {
        onSort && onSort(column.key, true);
      } else if (sortedColumn.name === column.key && sortedColumn.isAsc) {
        onSort && onSort(column.key, false);
      }
    }
  };

  return (
    <Box className={classes.table}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            {showEditIcon && <TableCell className="table-head-empty-cell" />}
            {columns.map((column) => (
              <TableCell className="table-head-cell" key={column.name}>
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  onClick={() => handleSort(column)}
                  style={
                    column.showSorting
                      ? webStyle.pointer
                      : webStyle.defaultPointer
                  }
                >
                  <Box width={"max-content"} padding={"0px 4px"}>
                    {column.name}
                    {column.isRequired && (
                      <Box className="required-text">(required)</Box>
                    )}
                  </Box>
                  {column.showSorting && (
                    <Box>
                      {!(
                        sortedColumn.name === column.key && !sortedColumn.isAsc
                      ) && (
                        <Box
                          style={webStyle.pointer}
                          onClick={() => onSort && onSort(column.key, true)}
                          data-test-id="asc-sort"
                        >
                          <ExpandLessIcon />
                        </Box>
                      )}
                      {!(
                        sortedColumn.name === column.key && sortedColumn.isAsc
                      ) && (
                        <Box
                          style={webStyle.pointer}
                          onClick={() => onSort && onSort(column.key, false)}
                          data-test-id="desc-sort"
                        >
                          <ExpandMoreIcon />
                        </Box>
                      )}
                    </Box>
                  )}
                </Box>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((rowData: IRawData | any, rowIndex: number) => (
            <TableRow key={`${rowData?.id}-${rowIndex}-row`}>
              {showEditIcon && (
                <TableCell
                  className={rowData.edit ? "table-body-cell-input-edit" : ""}
                >
                  <Box
                    style={webStyle.pointer}
                    data-test-id="edit-icon"
                    onClick={() => onEdit && onEdit(rowIndex)}
                  >
                    <CreateOutlined
                      style={
                        rowData.edit ? webStyle.editOpenIcon : webStyle.editIcon
                      }
                      fontSize="small"
                    />
                  </Box>
                </TableCell>
              )}
              {columns.map((columnRow, colIndex: number) => (
                <TableCell
                  align="center"
                  className={
                    columnRow.key === "amortization_end" ||
                    columnRow.key === "remaining_balance" ||
                    (columnRow.key === "expense_amount" && !rowData.id) ||
                    (columnRow.key === "amortization_start" && rowData.id) ||
                    (disabledCalculatedField &&
                      (columnRow.key === "amortization_period" ||
                        columnRow.key === "expense_amount" ||
                        columnRow.key === "total_amount") &&
                      rowData?.id)
                      ? "table-disable-cell"
                      : rowData.edit
                      ? "table-body-cell-input"
                      : "table-body-cell"
                  }
                  style={
                    highlightSearch(columnRow.key, rowData[columnRow.key])
                      ? webStyle.tableCell
                      : webStyle.boxField
                  }
                  key={columnRow.key + colIndex + "table-cell"}
                >
                  {columnRow.key === "vendor_name" && (
                    <Box>
                      <CustomSearchableDropdown
                        value={rowData[columnRow.key]}
                        name=""
                        error={
                          rowData.error?.includes("vendor_name") && rowData.edit
                        }
                        options={vendorList}
                        handleChange={(value: string, id: string) =>
                          handleDropdownChange &&
                          handleDropdownChange(
                            value,
                            id,
                            "vendor_name",
                            "vendor_name_id",
                            rowData.id
                          )
                        }
                        isDark={true}
                        disabled={!rowData.edit}
                        deleteOption={(option: string) =>
                          deleteOption(option, "vendor_name")
                        }
                        updateOption={(value: string, optionId: string) =>
                          updateOption(value, optionId, "vendor_name")
                        }
                      />
                    </Box>
                  )}
                  {columnRow.key === "prepaid_account_identifier" && (
                    <Box>
                      <CustomSearchableDropdown
                        value={rowData[columnRow.key]}
                        name=""
                        error={
                          rowData.error?.includes(
                            "prepaid_account_identifier"
                          ) && rowData.edit
                        }
                        options={prepaidAccountIdentifierList}
                        handleChange={(value: string, id: string) =>
                          handleDropdownChange &&
                          handleDropdownChange(
                            value,
                            id,
                            "prepaid_account_identifier",
                            "prepaid_account_identifier_id",
                            rowData.id
                          )
                        }
                        isDark={true}
                        disabled={!rowData.edit}
                        deleteOption={(option: string) =>
                          deleteOption(option, "prepaid_account_identifier")
                        }
                        updateOption={(value: string, optionId: string) =>
                          updateOption(
                            value,
                            optionId,
                            "prepaid_account_identifier"
                          )
                        }
                      />
                    </Box>
                  )}
                  {columnRow.key === "expense_account" && (
                    <Box>
                      <CustomSearchableDropdown
                        value={rowData[columnRow.key]}
                        name=""
                        error={
                          rowData.error?.includes("expense_account") &&
                          rowData.edit
                        }
                        options={expenseAccountList}
                        handleChange={(value: string, id: string) =>
                          handleDropdownChange &&
                          handleDropdownChange(
                            value,
                            id,
                            "expense_account",
                            "exp_account_id",
                            rowData.id
                          )
                        }
                        isDark={true}
                        disabled={!rowData.edit}
                        deleteOption={(option: string) =>
                          deleteOption(option, "expense_account")
                        }
                        updateOption={(value: string, optionId: string) =>
                          updateOption(value, optionId, "expense_account")
                        }
                      />
                    </Box>
                  )}
                  {columnRow.key !== "prepaid_account_identifier" &&
                    columnRow.key !== "vendor_name" &&
                    columnRow.key !== "expense_account" &&
                    columnRow.key !== "images" && (
                      <Box
                        style={
                          rowData.edit ? webStyle.textField : webStyle.boxField
                        }
                      >
                        <TextField
                          className={classes.textField}
                          variant="outlined"
                          value={
                            rowData[columnRow.key] !== undefined &&
                            rowData[columnRow.key] !== null &&
                            rowData[columnRow.key] !== "null" &&
                            rowData[columnRow.key] !== "undefined"
                              ? `${
                                  columnRow.key === "total_amount" ||
                                  columnRow.key === "expense_amount" ||
                                  columnRow.key === "remaining_balance"
                                    ? "$" + rowData[columnRow.key]
                                    : rowData[columnRow.key]
                                }`
                              : ""
                          }
                          onClick={(e) => e.stopPropagation()}
                          onChange={(e) =>
                            handleCellValueChange &&
                            handleCellValueChange(e, columnRow.key, rowData.id)
                          }
                          onBlur={(e) => handleSaveCellValue(rowIndex)}
                          data-test-id="input-field"
                          error={
                            rowData.error?.includes(columnRow.key) &&
                            rowData.edit
                          }
                          disabled={
                            !rowData.edit ||
                            columnRow.key === "amortization_end" ||
                            columnRow.key === "remaining_balance" ||
                            (columnRow.key === "expense_amount" &&
                              !rowData.id) ||
                            (columnRow.key === "amortization_start" &&
                              rowData.id) ||
                            (disabledCalculatedField &&
                              (columnRow.key === "amortization_period" ||
                                columnRow.key === "expense_amount" ||
                                columnRow.key === "total_amount") &&
                              rowData?.id)
                          }
                          multiline={
                            !rowData.edit ||
                            columnRow.key === "notes" ||
                            columnRow.key === "invoice_description"
                          }
                          fullWidth
                          placeholder={
                            columnRow.key === "amortization_start" ||
                            columnRow.key === "amortization_end"
                              ? "MM/YY"
                              : columnRow.key === "invoice_date"
                              ? "MM/DD/YY"
                              : ""
                          }
                        />
                      </Box>
                    )}
                  {columnRow.key === "images" && (
                    <CustomInlineFileUpload
                      images={rowData.images}
                      handleFileChange={(e) => handleFileUpload(e, rowData.id)}
                      deleteFile={(imageId: number) =>
                        handleFileDelete &&
                        handleFileDelete(imageId, rowData?.id)
                      }
                      isEdit={rowData.edit}
                      handleOpenFileInput={() =>
                        handleOpenFileInput(rowData?.id)
                      }
                      rowId={rowData?.id}
                    />
                  )}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};

const webStyle = {
  pointer: {
    cursor: "pointer",
  },
  editOpenIcon: {
    color: "#01AA7F",
  },
  editIcon: {
    color: "#FFF",
  },
  uploadSection: {
    color: "#FFF",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "12px",
  },
  defaultPointer: {
    cursor: "default",
  },
  textField: {
    position: "absolute",
    left: 0,
    top: 0,
    height: "100%",
    width: "100%",
  },
  boxField: {},
  tableCell: {
    backgroundColor: "#01AA7F99",
  },
};

export default CustomEditableTable;
