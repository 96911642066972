import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { downloadFile, getFileSize, setFileUploadErrors } from "../../../components/src/Utilities";
import { getStorageData } from "../../../framework/src/Utilities";

export const configJSON = require("./config");

export interface Props {
  tableWidth: number;
  startFromScratch: () => void;
  uploadDetailedData: () => void;
  toggleLoader: (isStart: boolean) => void;
  showInconsistencyPage: (
    vendorIds: string[],
    identifierIds: string[],
    expenseAccountIds: string[]
  ) => void;
  isOpenUploadPage: boolean;
}

interface S {
  selectedDetailedOption: string;
  isDetailedOptionSelected: boolean;
  uploadedFile: File | null;
  isFileUploadInProgress: boolean;
  progressCount: number;
  fileUploadError: string;
  templateUrl: string;
  templateFileName: string;
  templateFileSize: string;
  isTemplateDownloaded: boolean;
}

interface SS {}

export default class UploadDetailedInformationController extends BlockComponent<
  Props,
  S,
  SS
> {
  fileUploadTimer: NodeJS.Timeout | null = null;
  downloadDetailedTemplateApiId: string = "";
  fileUploadApiId: string = "";
  detailedSheetDataApiId: string = "";
  deleteAccountsDataApiId: string = "";

  constructor(props: Props) {
    super(props);

    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      selectedDetailedOption: "template",
      isDetailedOptionSelected: false,
      uploadedFile: null,
      isFileUploadInProgress: false,
      progressCount: 0,
      templateUrl: "",
      fileUploadError: "",
      templateFileName: "",
      templateFileSize: "",
      isTemplateDownloaded: false,
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.downloadDetailedTemplateApiId) {
      if (responseJson?.data && responseJson.data.length > 0) {
        const attributes = responseJson.data[0]?.attributes;
        const fileObject = attributes?.document;
        const fileName = attributes?.file_name;
        const fileSize = attributes?.file_size;
        this.setState({
          templateFileName: fileName,
          templateUrl: fileObject,
          templateFileSize: getFileSize(fileSize),
        });
      }
    }
    if (apiRequestCallId === this.fileUploadApiId) {
      if (responseJson?.length > 0) {
        this.deleteAccountsDetailedData();
        this.uploadDetailedSheetData(responseJson);
      } else if (responseJson?.message) {
        this.setState({ fileUploadError: responseJson.message }, () => {
          setTimeout(() => {
            this.setState({ fileUploadError: "" });
          }, 3000);
        });
        this.props.toggleLoader(false);
      }
    }
    if (apiRequestCallId === this.detailedSheetDataApiId) {
      this.setDetailedSheetDataResponse(responseJson);
    }
  }

  componentDidMount = async () => {
    this.getDetailedTemplate();
    if (this.props.isOpenUploadPage) {
      this.setState({
        selectedDetailedOption: "template",
        isDetailedOptionSelected: true,
        isTemplateDownloaded: true,
      });
    }
  };

  setDetailedSheetDataResponse = (responseJson: {
    message?: string;
    wrong_prepaid_account?: string[];
    wrong_vendor?: string[];
    wrong_expense?: string[];
    errors?: string;
  }) => {
    if (responseJson?.message === "Details added successfully") {
      this.props.uploadDetailedData();
    } else {
      this.props.toggleLoader(false);
      if (responseJson?.wrong_vendor || responseJson?.wrong_prepaid_account) {
        this.props.showInconsistencyPage(
          responseJson?.wrong_vendor || [],
          responseJson?.wrong_prepaid_account || [],
          responseJson?.wrong_expense || []
        );
      } else if (responseJson?.errors) {
        this.setFileUploadErrorMessage(responseJson.errors);
      }
    }
  };

  setFileUploadErrorMessage = (
    errors:
      | string
      | {
          invoice_date?: string;
          check?: string;
          invoice_number?: string;
          amortization_period?: string;
          total_amount?: string;
          expense_amount?: string;
          invoice_description?: string;
          amortization_start?: string;
        }[]
  ) => {
    let errorMessage: string = configJSON.somethingWentWrongMsg;
    if (typeof errors === "string") {
      errorMessage = errors;
    } else {
      errorMessage = setFileUploadErrors(errors);
    }
    this.setState({ fileUploadError: errorMessage }, () => {
      setTimeout(() => {
        this.setState({ fileUploadError: "" });
      }, 5000);
    });
  };

  handleChangeDetailedUploadSelection = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    this.setState({ selectedDetailedOption: event.target.value });
  };

  handleSelectDetailedUploadSelection = (type: string) => {
    this.setState({ selectedDetailedOption: type });
  };

  continueToSelection = () => {
    if (this.state.selectedDetailedOption === "template") {
      this.setState({ isDetailedOptionSelected: true });
    } else {
      this.props.startFromScratch();
    }
  };

  onFileDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const fileObj = event.dataTransfer.files[0];
    this.handleFileSaving(fileObj);
  };

  handleUploadFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    this.handleFileSaving(file);
  };

  backToDownloadTemplate = () => {
    this.setState({
      selectedDetailedOption: "template",
      isDetailedOptionSelected: true,
      isTemplateDownloaded: false,
      isFileUploadInProgress: false,
      progressCount: 0,
      uploadedFile: null,
    });
  };

  handleFileSaving = (file: File | undefined) => {
    if (file) {
      const fileType = file.name.split(".").pop()?.toLowerCase();
      if (fileType === "xls" || fileType === "xlsx" || fileType === "csv") {
        this.setState({ uploadedFile: file, isFileUploadInProgress: true });
        this.handleFileUploadTimer();
      } else {
        this.setState({ fileUploadError: configJSON.fileUploadError }, () => {
          setTimeout(() => {
            this.setState({ fileUploadError: "" });
          }, 3000);
        });
      }
    }
  };

  onOpenFileInput = () => {
    const fileInputDiv = document.getElementById("fileInput");
    fileInputDiv?.click();
  };

  onDeleteUploadedFile = () => {
    this.setState({ uploadedFile: null });
  };

  handleFileUploadTimer = () => {
    this.fileUploadTimer = setInterval(() => {
      this.setState(
        (prevState) => ({
          progressCount:
            prevState.progressCount >= 90 ? 100 : prevState.progressCount + 49,
        }),
        () => {
          if (this.state.progressCount === 100 && this.fileUploadTimer) {
            clearInterval(this.fileUploadTimer);
            this.setState({ isFileUploadInProgress: false, progressCount: 0 });
          }
        }
      );
    }, 500);
  };

  downloadDetailedTemplate = () => {
    this.setState({
      isTemplateDownloaded: true,
    });
    downloadFile(this.state.templateUrl, this.state.templateFileName);
  };

  backToDetailedUploadSelection = () => {
    this.setState({
      selectedDetailedOption: "template",
      isDetailedOptionSelected: false,
      isFileUploadInProgress: false,
      progressCount: 0,
      uploadedFile: null,
      isTemplateDownloaded: false,
    });
  };

  getDetailedTemplate = () => {
    const headers = {
      "Content-Type": configJSON.apiContentType,
    };

    const detailedTemplateRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.downloadDetailedTemplateApiId = detailedTemplateRequestMsg.messageId;

    detailedTemplateRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.downloadDetailedTemplateApiEndPoint
    );

    detailedTemplateRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    detailedTemplateRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getBulkUploadMethod
    );

    runEngine.sendMessage(
      detailedTemplateRequestMsg.id,
      detailedTemplateRequestMsg
    );
  };

  uploadDetailedSheet = () => {
    this.props.toggleLoader(true);
    const headers = {
      "Content-Type": undefined,
    };
    const formData = new FormData();
    let endPoint = configJSON.importFileApiEndPoint;
    if (this.state.uploadedFile) {
      const fileType = this.state.uploadedFile.name
        .split(".")
        .pop()
        ?.toLowerCase();
      if (fileType === "csv") {
        formData.append("csv_path", this.state.uploadedFile);
        endPoint = configJSON.importCSVFileApiEndPoint;
      } else {
        formData.append("xls_path", this.state.uploadedFile);
      }
    }
    const uploadDetailedFileRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.fileUploadApiId = uploadDetailedFileRequestMsg.messageId;

    uploadDetailedFileRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    uploadDetailedFileRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    uploadDetailedFileRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    uploadDetailedFileRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.createBulkUploadMethod
    );

    runEngine.sendMessage(
      uploadDetailedFileRequestMsg.id,
      uploadDetailedFileRequestMsg
    );
  };

  uploadDetailedSheetData = async (
    sheetData: { [key: string]: string | number | Date | null }[]
  ) => {
    const headers = {
      "Content-Type": configJSON.apiContentType,
      token: await getStorageData("token"),
    };

    const uploadDetailedDataRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.detailedSheetDataApiId = uploadDetailedDataRequestMsg.messageId;

    uploadDetailedDataRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createBulkDetailsApiEndPoint
    );

    uploadDetailedDataRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    uploadDetailedDataRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(sheetData)
    );
    uploadDetailedDataRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.createBulkUploadMethod
    );

    runEngine.sendMessage(
      uploadDetailedDataRequestMsg.id,
      uploadDetailedDataRequestMsg
    );
  };

  deleteAccountsDetailedData = async () => {
    const headers = {
      "Content-Type": configJSON.apiContentType,
      token: await getStorageData("token"),
    };

    const deleteAccountsDetailedDataRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteAccountsDataApiId =
      deleteAccountsDetailedDataRequestMsg.messageId;

    deleteAccountsDetailedDataRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteAccountsDataApiEndPoint
    );

    deleteAccountsDetailedDataRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    deleteAccountsDetailedDataRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteBulkUploadMethod
    );

    runEngine.sendMessage(
      deleteAccountsDetailedDataRequestMsg.id,
      deleteAccountsDetailedDataRequestMsg
    );
  };
}
