import React, { CSSProperties } from "react";
import ReactivateStripeSubscriptionController, {
  Props,
  configJSON,
} from "./ReactivateStripeSubscriptionController.web";
import {
  CircularProgress,
  Backdrop,
  Box,
  Button,
  Dialog,
  DialogContent,
} from "@material-ui/core";

export default class ReactivateStripeSubscription extends ReactivateStripeSubscriptionController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const {
      isLoading,
    } = this.state;
    return (
      <Dialog
        open={true}
        fullWidth
        PaperProps={{
          style: webStyle.dialogPaper,
        }}
      >
        <DialogContent style={webStyle.dialogContent}>
          {isLoading && (
            <Box>
              <Backdrop style={{ zIndex: 9999 }} open={isLoading}>
                <CircularProgress size={60} style={webStyle.loader} />
              </Backdrop>
            </Box>
          )}
          <Box>
            <Box
              display={"flex"}
              justifyContent={"center"}
              style={webStyle.dialogHeader}
              alignItems={"center"}
            >
              <Box
                style={{
                  ...webStyle.dialogTitle,
                  ...webStyle.dialogTitleWithBorder,
                }}
              >
                {configJSON.reactivateSubscriptionTitle}
              </Box>
            </Box>
            <Box style={webStyle.radioLabelDescription}>
              {configJSON.reactivateConfirmMsg}
            </Box>
            <Box>
              <Box
                sx={{
                  flexDirection: {
                    xs: "column-reverse",
                    sm: "row",
                    md: "row",
                    lg: "row",
                    xl: "row",
                  },
                }}
                marginTop={"16px"}
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Box
                  sx={{
                    margin: {
                      xs: "8px 0px 0px",
                      sm: "0px 16px 0px 0px",
                      md: "0px 16px 0px 0px",
                      lg: "0px 16px 0px 0px",
                      xl: "0px 16px 0px 0px",
                    },
                  }}
                >
                  <Button
                    variant={"outlined"}
                    onClick={this.onCloseStripeGateway}
                    style={webStyle.cancelBtn}
                  >
                    {configJSON.close}
                  </Button>
                </Box>
                <Box>
                  <Button
                    style={webStyle.reactivateBtn}
                    onClick={this.reactivateSubscription}
                  >
                    {configJSON.reactivate}
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    );
  }
}

const webStyle = {
  reactivateBtn: {
    color: "#FFF",
    fontWeight: 700,
    fontSize: "14px",
    background: "linear-gradient(99deg, #01AA7F 2.64%, #00AED2 100%)",
    lineHeight: "22px",
    textTransform: "none",
    borderRadius: "8px",
    fontFamily: "Open Sans",
    paddingBottom: "8px",
    paddingTop: "8px",
    width: "144px",
  } as CSSProperties,
  cancelBtn: {
    color: "#FFF",
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "22px",
    textTransform: "none",
    borderRadius: "8px",
    fontFamily: "Open Sans",
    paddingBottom: "7px",
    paddingTop: "7px",
    width: "144px",
    border: "1px solid #FFF",
  } as CSSProperties,
  dialogPaper: {
    width: "600px",
    borderRadius: "16px",
    backgroundColor: "#001327",
  },
  loader: {
    color: "#01AA7F",
  },
  dialogContent: {
    padding: "16px 32px",
  },
  dialogTitle: {
    fontFamily: "Open Sans",
    fontSize: "30px",
    fontStyle: "normal",
    fontWeight: 700,
    letterSpacing: -0.15,
    lineHeight: 1.33,
    color: "#FFF",
  },
  dialogTitleWithBorder: {
    marginBottom: "8px",
  },
  radioLabelDescription: {
    fontFamily: "Open Sans",
    color: "#FFF",
    fontSize: "14px",
    paddingTop: "16px",
    fontWeight: 400,
    textAlign: "center",
    paddingBottom: "6px",
  } as CSSProperties,
  dialogHeader: {
    margin: "0px -32px",
    borderBottom: "1px solid grey",
  },
};
