import { Box } from "@material-ui/core";
import React from "react";
import { reducedFileName } from "./Utilities";
const uploadDoc = require("../src/upload-doc.svg");
const pinIcon = require("../src/pin.svg");
const trash = require("../src/trash.svg");

interface ICustomInlineFileUploadProps {
  images: { name: string; url: string; id: number }[];
  handleFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  deleteFile: (imageId: number) => void;
  isEdit: boolean;
  handleOpenFileInput: () => void;
  rowId?: number;
}

const CustomInlineFileUpload = (props: ICustomInlineFileUploadProps) => {
  return (
    <Box>
      <Box
        style={webStyle.uploadSection}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
        marginRight={"26px"}
        marginLeft={"26px"}
        marginTop={props?.images?.length > 0 ? "10px" : "0px"}
      >
        <Box>Upload</Box>
        <Box
          style={props.isEdit ? webStyle.pointer : webStyle.defaultPointer}
          onClick={() => props.isEdit && props.handleOpenFileInput()}
          data-test-id="upload-btn"
        >
          <input
            id={`fileInput-${props.rowId}`}
            type="file"
            onChange={props.handleFileChange}
            style={webStyle.inputStyle}
            disabled={!props.isEdit}
            data-test-id="upload-input"
          />
          <img src={uploadDoc} alt="upload" />
        </Box>
      </Box>
      {props.images?.map((image) => (
        <Box
          margin={"6px 26px"}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
          key={image.url}
        >
          <Box display={"flex"} alignItems={"center"}>
            <Box marginRight={"12px"}>
              <img src={pinIcon} height={14} alt="pin" />
            </Box>
            <Box>{reducedFileName(image.name)}</Box>
          </Box>
          <Box
            data-test-id="delete-image"
            style={props.isEdit ? webStyle.pointer : webStyle.defaultPointer}
            onClick={() => props.isEdit && props.deleteFile(image.id)}
          >
            <img src={trash} height={14} alt="trash" />
          </Box>
        </Box>
      ))}
    </Box>
  );
};

const webStyle = {
  uploadSection: {
    color: "#FFF",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "12px",
  },
  pointer: {
    cursor: "pointer",
  },
  defaultPointer: {
    cursor: "default",
  },
  inputStyle: {
    display: "none",
  },
};

export default CustomInlineFileUpload;
