import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { filterData, handleSort } from "../../../components/src/Utilities";
import { getStorageData } from "../../../framework/src/Utilities";

export const configJSON = require("./config");

export interface Props {
  tableWidth: number;
  accountsData: ITableData[];
  backToStepper: () => void;
  moveToDetailedUploadScreen: () => void;
  showSearchBar?: boolean;
  showReviewScreen?: boolean;
}

interface ITableData {
  [key: string]: string | number | Date | boolean | null;
}

interface S {
  accountDataWithIdentifiers: ITableData[];
  accountColumnsWithIdentifiers: { name: string }[];
  selectedAccountsData: ITableData[];
  searchText: string;
  filteredAccountDataWithIdentifiers: ITableData[];
  createIdentifiersError: string;
  sortedColumn: { name: string; isAsc: boolean };
}

interface SS {}

export default class IdentifyPrepaidAccountsController extends BlockComponent<
  Props,
  S,
  SS
> {
  createIdentifiersApiId: string = "";
  createExpenseIdentifiersApiId: string = "";
  constructor(props: Props) {
    super(props);

    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    this.state = {
      accountDataWithIdentifiers: [],
      accountColumnsWithIdentifiers: [],
      selectedAccountsData: [],
      searchText: "",
      filteredAccountDataWithIdentifiers: [],
      createIdentifiersError: "",
      sortedColumn: { name: "", isAsc: false },
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (
      apiRequestCallId === this.createIdentifiersApiId ||
      apiRequestCallId === this.createExpenseIdentifiersApiId
    ) {
      if (responseJson?.message) {
        this.props.moveToDetailedUploadScreen();
      } else {
        this.setState(
          { createIdentifiersError: configJSON.somethingWentWrongMsg },
          () => {
            setTimeout(() => {
              this.setState({ createIdentifiersError: "" });
            }, 3000);
          }
        );
      }
    }
  }

  componentDidMount = async () => {
    const objKeySet: Set<string> = new Set();

    this.props.accountsData.forEach((object) => {
      Object.keys(object).forEach((obKey) => {
        objKeySet.add(obKey.trim());
      });
    });

    const keyList = Array.from(objKeySet);
    const columnsData = keyList.map((column: string) => ({
      name: column,
    }));
    this.setState({
      accountColumnsWithIdentifiers: columnsData,
      accountDataWithIdentifiers: this.props.accountsData.map((item) => ({
        ...item,
        isSelected: false,
      })),
      filteredAccountDataWithIdentifiers: this.props.accountsData.map(
        (item) => ({
          ...item,
          isSelected: false,
        })
      ),
    });
  };

  onSelectRow = (
    event: React.ChangeEvent<HTMLInputElement>,
    isAllCheck: boolean,
    index?: number
  ) => {
    let rowData = this.state.accountDataWithIdentifiers;
    let filteredRowData = this.state.filteredAccountDataWithIdentifiers;
    if (isAllCheck) {
      rowData = rowData.map((item) => ({
        ...item,
        isSelected: event.target.checked,
      }));
      filteredRowData = filteredRowData.map((item) => ({
        ...item,
        isSelected: event.target.checked,
      }));
    } else {
      rowData = rowData.map((item, rowIndex) => ({
        ...item,
        isSelected:
          rowIndex === index ? event.target.checked : item?.isSelected,
      }));
      filteredRowData = filteredRowData.map((item, rowIndex) => ({
        ...item,
        isSelected:
          rowIndex === index ? event.target.checked : item?.isSelected,
      }));
    }
    this.setState({
      accountDataWithIdentifiers: rowData,
      filteredAccountDataWithIdentifiers: filteredRowData,
    });
  };

  onBackClick = () => {
    if (!this.state.selectedAccountsData.length) {
      this.props.backToStepper();
    } else {
      this.setState({ selectedAccountsData: [] });
    }
  };

  onContinue = () => {
    if (!this.state.selectedAccountsData.length) {
      const filteredData = this.state.accountDataWithIdentifiers
        .filter((item) => item?.isSelected === true)
        .map((object) => ({ ...object, isSelected: false }));
      if (this.props.showReviewScreen) {
        this.setState({ selectedAccountsData: filteredData });
      } else {
        this.createAccountIdentifiers();
        this.createExpenseAccountIdentifiers();
      }
    } else {
      this.createAccountIdentifiers();
      this.createExpenseAccountIdentifiers();
    }
  };

  handleSortTable = (column: string, isAsc: boolean) => {
    const sortedData = handleSort(
      column,
      isAsc,
      this.state.accountDataWithIdentifiers
    );
    const sortedFilteredData = handleSort(
      column,
      isAsc,
      this.state.filteredAccountDataWithIdentifiers
    );
    this.setState({
      accountDataWithIdentifiers: sortedData,
      filteredAccountDataWithIdentifiers: sortedFilteredData,
      sortedColumn: { name: column, isAsc: isAsc },
    });
  };

  handleSearchInTable = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ searchText: event.target.value }, () => {
      const filteredData = filterData(
        this.state.accountDataWithIdentifiers,
        this.state.searchText
      );
      this.setState({ filteredAccountDataWithIdentifiers: filteredData });
    });
  };

  onClearSearch = () => {
    const filteredSearchedData = filterData(
      this.state.accountDataWithIdentifiers,
      ""
    );
    this.setState({
      searchText: "",
      filteredAccountDataWithIdentifiers: filteredSearchedData,
    });
  };

  createAccountIdentifiers = async () => {
    const headers = {
      "Content-Type": configJSON.apiContentType,
      token: await getStorageData("token"),
    };

    const selectedRecords = this.state.filteredAccountDataWithIdentifiers
      .filter((object) => object?.isSelected)
      .map(({ isSelected, ...items }) => items);

    const createIdentifiersRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createIdentifiersApiId = createIdentifiersRequestMsg.messageId;

    createIdentifiersRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createIdentifiersApiEndPoint
    );

    createIdentifiersRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    createIdentifiersRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(selectedRecords)
    );
    createIdentifiersRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.createBulkUploadMethod
    );

    runEngine.sendMessage(
      createIdentifiersRequestMsg.id,
      createIdentifiersRequestMsg
    );
  };

  createExpenseAccountIdentifiers = async () => {
    const headers = {
      "Content-Type": configJSON.apiContentType,
      token: await getStorageData("token"),
    };

    const expenseAccountRecords = this.state.filteredAccountDataWithIdentifiers
      .filter((object) => !object?.isSelected)
      .map(({ isSelected, ...items }) => items);

    const expenseAccountsRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createExpenseIdentifiersApiId = expenseAccountsRequestMsg.messageId;

    expenseAccountsRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createExpenseAccountsApiEndPoint
    );

    expenseAccountsRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    expenseAccountsRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(expenseAccountRecords)
    );
    expenseAccountsRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.createBulkUploadMethod
    );

    runEngine.sendMessage(
      expenseAccountsRequestMsg.id,
      expenseAccountsRequestMsg
    );
  };
}
