import React from "react";
import SignUpController, { Props, configJSON } from "./SignUpController";
import { Box } from "@material-ui/core";
import CustomInput from "../../../components/src/CustomInput.web";
import CustomPasswordInput from "../../../components/src/CustomPasswordInput.web";
import { background, failIcon, logo, passIcon } from "./assets";
import CustomButton from "../../../components/src/CustomButton.web";
import CommonCheckBox from "../../../components/src/CommonCheckBox.web";
import TermsConditionsPrivacyPolicy from "../../../blocks/termsconditions/src/TermsConditionsPrivacyPolicy.web";

export default class SignUp extends SignUpController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const {
      emailField,
      businessNameField,
      passwordField,
      isPasswordVisible,
      validationCheckList,
      iAgreeOnTermsAndConditions,
      isOpenTermsAndPrivacyDialog,
      signUpError,
    } = this.state;

    const isSignUpButtonDisabled = () => {
      return (
        !!emailField.error ||
        !emailField.isTouched ||
        !!passwordField.error ||
        !passwordField.isTouched ||
        !!businessNameField.error ||
        !iAgreeOnTermsAndConditions
      );
    };
    return (
      <Box height={"auto"}>
        <Box sx={webStyle.container}>
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            height={"inherit"}
            width={"100%"}
            margin={"auto"}
          >
            <Box width={"551px"}>
              <Box sx={webStyle.signUpContainer}>
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  onClick={() => this.handleNavigation("Home")}
                  style={webStyle.pointerStyle}
                >
                  <img src={logo} alt="logo" width={200} height={128} />
                </Box>
                <Box textAlign={"center"} style={webStyle.title}>
                  {configJSON.welcomeLabel}
                </Box>
                <Box textAlign={"center"} style={webStyle.signUpDescription}>
                  {configJSON.signUpDescription}
                </Box>
                {signUpError && (
                  <Box
                    style={webStyle.signUpError}
                    data-test-id="error-message"
                  >
                    {signUpError}
                  </Box>
                )}
                <Box style={webStyle.inputContainer}>
                  <Box style={webStyle.inputLabel}>{configJSON.labelEmail}</Box>
                  <CustomInput
                    value={emailField.value}
                    handleChange={(event) =>
                      this.handleChangeSignUpField(event, "email")
                    }
                    placeholder={configJSON.emailPlaceholder}
                    isError={!!emailField.error}
                    errorMessage={emailField.error}
                  />
                </Box>
                <Box style={webStyle.inputContainer}>
                  <Box style={webStyle.inputLabel}>
                    {configJSON.labelPassword}
                  </Box>
                  <CustomPasswordInput
                    value={passwordField.value}
                    handleChange={(event) =>
                      this.handleChangeSignUpField(event, "password")
                    }
                    placeholder={configJSON.passwordPlaceholder}
                    handleToggle={this.toggleShowPassword}
                    isShow={isPasswordVisible}
                    isError={!!passwordField.error}
                    errorMessage={passwordField.error}
                  />
                </Box>
                <Box style={webStyle.inputContainer}>
                  <Box style={webStyle.inputLabel}>
                    {configJSON.labelBusinessName}
                  </Box>
                  <CustomInput
                    value={businessNameField.value}
                    handleChange={(event) =>
                      this.handleChangeSignUpField(event, "businessName")
                    }
                    placeholder={configJSON.businessNamePlaceholder}
                    errorMessage={businessNameField.error}
                    isError={!!businessNameField.error}
                  />
                </Box>
                {passwordField.isTouched && (
                  <Box margin={"24px 0px 16px"}>
                    {validationCheckList.map((item) => (
                      <Box
                        key={item.name}
                        display={"flex"}
                        alignItems={"center"}
                        paddingBottom={"8px"}
                      >
                        <Box paddingRight={"8px"}>
                          <img
                            src={item.check ? passIcon : failIcon}
                            height={18}
                            alt="check"
                          />
                        </Box>
                        <Box style={webStyle.haveAnAccountText}>
                          {item.name}
                        </Box>
                      </Box>
                    ))}
                  </Box>
                )}
                <Box paddingTop={"8px"} display={"flex"} alignItems={"start"}>
                  <CommonCheckBox
                    checked={iAgreeOnTermsAndConditions}
                    onChange={(event) =>
                      this.handleChangeSignUpField(
                        event,
                        "iAgreeOnTermsAndConditions"
                      )
                    }
                  />
                  <Box padding={"3px 0px 0px 4px"}>
                    <span style={webStyle.termsAndConditionsText}>
                      {configJSON.iAgreeWithText}
                    </span>
                    <span
                      onClick={() =>
                        this.handleToggleTermsAndPrivacyDialog("privacyPolicy")
                      }
                      style={webStyle.termsAndConditionsBtnText}
                      data-test-id="privacy-policy-btn"
                    >
                      {configJSON.privacyPolicy}
                    </span>
                    <span style={webStyle.termsAndConditionsText}>
                      {configJSON.andText}
                    </span>
                    <span
                      onClick={() =>
                        this.handleToggleTermsAndPrivacyDialog(
                          "termsAndConditions"
                        )
                      }
                      style={webStyle.termsAndConditionsBtnText}
                      data-test-id="terms-and-condition-btn"
                    >
                      {configJSON.termsAndConditions}
                    </span>
                  </Box>
                </Box>
                <Box margin={"24px 0px"}>
                  <CustomButton
                    fullWidth
                    variant="contained"
                    onClick={this.handleSignUp}
                    label={configJSON.btnTextSignUp}
                    disabled={isSignUpButtonDisabled()}
                  />
                </Box>
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <Box style={webStyle.haveAnAccountText}>
                    {configJSON.alreadyHaveAccountText}
                  </Box>
                  <Box
                    style={webStyle.logInText}
                    onClick={this.handleLoginRedirection}
                  >
                    {configJSON.loginLabel}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          {!!isOpenTermsAndPrivacyDialog && (
            <TermsConditionsPrivacyPolicy
              navigation={this.props.navigation}
              isOpenTermsAndPolicyDialog={isOpenTermsAndPrivacyDialog}
              handleClose={(isAgree: boolean) =>
                this.handleCloseTermsAndPrivacyDialog(isAgree)
              }
              isChecked={iAgreeOnTermsAndConditions}
            />
          )}
        </Box>
      </Box>
    );
  }
}

const webStyle = {
  container: {
    backgroundImage: `url(${background})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundColor: "#001327",
    height: "100%",
    minHeight: "100vh",
    display: "flex",
  },
  signUpContainer: {
    borderRadius: "16px",
    padding: "32px 95px",
    background: "rgba(0, 19, 39, 0.60)",
    backdropFilter: "blur(8px)",
    "@media (max-width: 600px)": {
      padding: "32px 46px",
    },
  },
  title: {
    color: "#FFF",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "36px",
    lineHeight: 1.22,
    letterSpacing: -0.36,
  },
  signUpDescription: {
    color: "#FFF",
    fontFamily: "Open Sans",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: 1.5,
    paddingTop: "4px",
    paddingBottom: "16px",
  },
  inputContainer: {
    padding: "8px 0px",
  },
  inputLabel: {
    color: "#FFF",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: 1.5,
    paddingBottom: "4px",
  },
  signUpBtn: {
    height: "56px",
    backgroundColor: "#A8A29E",
    color: "#FFF",
    lineHeight: 1.5,
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "16px",
    textTransform: "none",
    borderRadius: "8px",
  },
  haveAnAccountText: {
    color: "#FFF",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: 1.57,
  },
  logInText: {
    background: "linear-gradient(99deg, #01AA7F 2.64%, #00AED2 100%)",
    backgroundClip: "text",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    fontFamily: "Open Sans",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: 1.5,
    cursor: "pointer",
    borderBottom: "1px solid #00AED2",
    marginLeft: "24px",
  },
  termsAndConditionsText: {
    fontFamily: "Open Sans",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: 1.5,
    color: "#FFF",
  },
  termsAndConditionsBtnText: {
    cursor: "pointer",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: 1.5,
    color: "#FFF",
    borderBottom: "1px solid #FFF",
  },
  signUpError: {
    fontSize: "12px",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: 700,
    borderLeft: "2px solid #DC2626",
    lineHeight: 1.5,
    color: "#DC2626",
    backgroundColor: "#FEE2E2",
    borderRadius: "4px",
    marginBottom: "14px",
    padding: "21px 16px",
  },
  pointerStyle: {
    cursor: "pointer",
  },
};
