import React from "react";
import { Box } from "@material-ui/core";
import CommonRadioButton from "../../../components/src/CommonRadioButton.web";
import CustomButton from "../../../components/src/CustomButton.web";
import CustomFileUpload from "../../../components/src/CustomFileUpload.web";
import UploadDetailedInformationController, {
  Props,
  configJSON,
} from "./UploadDetailedInformationController.web";
import { excel } from "./assets";
import { reducedFileName } from "../../../components/src/Utilities";

export default class UploadDetailedInformation extends UploadDetailedInformationController {
  constructor(props: Props) {
    super(props);
  }
  render() {
    const {
      selectedDetailedOption,
      uploadedFile,
      isDetailedOptionSelected,
      isFileUploadInProgress,
      fileUploadError,
      templateFileName,
      progressCount,
      templateFileSize,
      isTemplateDownloaded,
    } = this.state;
    return (
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        margin={"auto"}
        height={"inherit"}
        width={"100%"}
      >
        {!isDetailedOptionSelected && (
          <Box
            sx={{
              width: {
                md: "682px",
                lg: "682px",
              },
            }}
            data-test-id="detailed-selection-wrapper"
          >
            <Box
              style={webStyle.uploadDetailsContainer}
              sx={{
                padding: {
                  xs: "12px",
                  sm: "16px",
                  md: "32px 56px",
                  lg: "32px 56px",
                },
              }}
            >
              <Box textAlign={"center"} sx={webStyle.uploadDetailsHeader}>
                {configJSON.uploadDetailedData}
              </Box>
              <Box
                textAlign={"center"}
                marginTop={"8px"}
                style={webStyle.uploadDetailsDescription}
              >
                {configJSON.detailsPageOptionSelection}
              </Box>
              <Box margin={"32px 0px"}>
                <Box
                  alignItems={"center"}
                  display={"flex"}
                  style={
                    selectedDetailedOption === "template"
                      ? webStyle.selectionWrapperSelected
                      : webStyle.detailsSelectionWrapper
                  }
                  data-test-id="selection-tab"
                  onClick={() =>
                    this.handleSelectDetailedUploadSelection("template")
                  }
                >
                  <Box>
                    <CommonRadioButton
                      value="template"
                      checked={selectedDetailedOption === "template"}
                      onChange={this.handleChangeDetailedUploadSelection}
                    />
                  </Box>
                  <Box paddingLeft={"16px"}>
                    <Box
                      style={webStyle.detailsSelectionHeader}
                      marginBottom={"8px"}
                    >
                      {configJSON.useTemplate}
                      <span style={webStyle.subHeader}>
                        {configJSON.recommendedText}
                      </span>
                    </Box>
                    <Box style={webStyle.detailsSelectionDescription}>
                      {configJSON.useTemplateDescription}
                    </Box>
                  </Box>
                </Box>
                <Box
                  alignItems={"center"}
                  display={"flex"}
                  style={
                    selectedDetailedOption === "scratch"
                      ? webStyle.selectionWrapperSelected
                      : webStyle.detailsSelectionWrapper
                  }
                  marginTop={"32px"}
                  data-test-id="selection-tab"
                  onClick={() =>
                    this.handleSelectDetailedUploadSelection("scratch")
                  }
                >
                  <Box>
                    <CommonRadioButton
                      value="scratch"
                      checked={selectedDetailedOption === "scratch"}
                      onChange={this.handleChangeDetailedUploadSelection}
                    />
                  </Box>
                  <Box paddingLeft={"16px"}>
                    <Box
                      style={webStyle.detailsSelectionHeader}
                      marginBottom={"8px"}
                    >
                      {configJSON.startFromScratch}
                    </Box>
                    <Box style={webStyle.detailsSelectionDescription}>
                      {configJSON.startFromScratchDescription}
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box
                justifyContent={"center"}
                display={"flex"}
                alignItems={"center"}
              >
                <CustomButton
                  label={configJSON.continueBtn}
                  variant="contained"
                  onClick={this.continueToSelection}
                  width={197}
                />
              </Box>
            </Box>
          </Box>
        )}
        {isDetailedOptionSelected && !isTemplateDownloaded && (
          <Box
            sx={{
              width: {
                lg: "585px",
                md: "585px",
              },
            }}
            data-test-id="download-template-wrapper"
          >
            <Box
              sx={{
                padding: {
                  xs: "12px",
                  sm: "16px",
                  md: "32px 56px",
                  lg: "32px 56px",
                },
              }}
              style={webStyle.uploadDetailsContainer}
            >
              <Box textAlign={"center"} sx={webStyle.uploadDetailsHeader}>
                {configJSON.downloadTemplate}
              </Box>
              <Box
                textAlign={"center"}
                style={webStyle.uploadDetailsDescription}
              >
                {configJSON.downloadAndFillTemplates}
              </Box>
              <Box justifyContent={"center"} display={"flex"}>
                <Box
                  style={webStyle.downloadTemplateWrapper}
                  display={"flex"}
                  alignItems={"center"}
                  margin={"24px 0px"}
                >
                  <Box paddingRight={"16px"}>
                    <img src={excel} alt="detailed-file" width={56} />
                  </Box>
                  <Box>
                    <Box style={webStyle.fileNameStyle}>{reducedFileName(templateFileName)}</Box>
                    <Box style={webStyle.fileSizeStyle}>{templateFileSize}</Box>
                  </Box>
                </Box>
              </Box>
              <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Box>
                  <CustomButton
                    label={configJSON.backBtn}
                    onClick={this.backToDetailedUploadSelection}
                    width={197}
                    variant="outlined"
                  />
                </Box>
                <Box
                  sx={{
                    paddingLeft: {
                      xs: "8px",
                      sm: "12px",
                      md: "24px",
                      lg: "24px",
                      xl: "24px",
                    },
                  }}
                >
                  <CustomButton
                    label={configJSON.downloadText}
                    onClick={this.downloadDetailedTemplate}
                    variant="contained"
                    width={197}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        )}
        {isDetailedOptionSelected && isTemplateDownloaded && (
          <Box
            sx={{
              width: {
                lg: "712px",
                md: "712px",
              },
            }}
            data-test-id="import-wrapper"
          >
            <Box
              style={webStyle.uploadDetailsContainer}
              sx={{
                padding: {
                  xs: "12px",
                  sm: "16px",
                  md: "32px 56px",
                  lg: "32px 56px",
                },
              }}
            >
              <Box
                textAlign={"center"}
                marginBottom={"8px"}
                sx={webStyle.uploadDetailsHeader}
              >
                {configJSON.importTemplate}
              </Box>
              {!uploadedFile && (
                <Box
                  style={webStyle.uploadDetailsDescription}
                  textAlign={"center"}
                >
                  {configJSON.importTemplateDescription}
                </Box>
              )}
              <Box margin={"24px 0px"}>
                {fileUploadError && (
                  <Box style={webStyle.fileUploadError}>{fileUploadError}</Box>
                )}
                <CustomFileUpload
                  handleFileChange={this.handleUploadFileChange}
                  handleOpenFileInput={this.onOpenFileInput}
                  handleFileDrop={this.onFileDrop}
                  deleteFile={this.onDeleteUploadedFile}
                  selectedFile={uploadedFile}
                  uploadProgress={progressCount}
                  isFileUploadInProgress={isFileUploadInProgress}
                />
              </Box>
              {uploadedFile && !isFileUploadInProgress && (
                <Box
                  marginBottom={"24px"}
                  style={webStyle.uploadDetailsDescription}
                  textAlign={"center"}
                >
                  {configJSON.uploadedText}
                </Box>
              )}
              <Box
                alignItems={"center"}
                justifyContent={"center"}
                display={"flex"}
              >
                <Box>
                  <CustomButton
                    variant="outlined"
                    label={configJSON.backBtn}
                    onClick={this.backToDownloadTemplate}
                    width={197}
                  />
                </Box>
                <Box
                  sx={{
                    paddingLeft: {
                      xs: "8px",
                      sm: "12px",
                      md: "24px",
                      lg: "24px",
                      xl: "24px",
                    },
                  }}
                >
                  <CustomButton
                    label={configJSON.continueBtn}
                    onClick={this.uploadDetailedSheet}
                    variant="contained"
                    width={197}
                    disabled={!uploadedFile || isFileUploadInProgress}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    );
  }
}

const webStyle = {
  uploadDetailsContainer: {
    borderRadius: "16px",
    backdropFilter: "blur(8px)",
    background: "#00132799",
  },
  uploadDetailsHeader: {
    color: "#FFF",
    fontStyle: "normal",
    fontFamily: "Open Sans",
    fontWeight: 700,
    fontSize: "30px",
  },
  uploadDetailsDescription: {
    color: "#FFF",
    fontFamily: "Open Sans",
    fontSize: "12px",
    fontWeight: 400,
    fontStyle: "normal",
    lineHeight: 1.5,
  },
  detailsSelectionHeader: {
    color: "#FFF",
    fontFamily: "Brown",
    fontStyle: "normal",
    fontSize: "24px",
    fontWeight: 700,
    lineHeight: "23.52px",
  },
  detailsSelectionDescription: {
    color: "#FFF",
    fontSize: "16px",
    fontStyle: "normal",
    fontFamily: "Brown",
    fontWeight: 400,
    lineHeight: "15.2px",
  },
  detailsSelectionWrapper: {
    borderRadius: "16px",
    border: "4px solid transparent",
    backgroundColor: "#171717cc",
    padding: "24px",
    cursor: "pointer",
  },
  selectionWrapperSelected: {
    backgroundColor: "#171717cc",
    borderRadius: "16px",
    padding: "24px",
    border: "4px solid #6EE7B7",
    cursor: "pointer",
  },
  downloadTemplateWrapper: {
    borderRadius: "8px",
    padding: "8px 40px",
    background: "#01AA7F",
  },
  fileNameStyle: {
    fontFamily: "Inter",
    color: "#FFF",
    fontSize: "16px",
    fontWeight: 400,
    fontStyle: "normal",
    lineHeight: 1.5,
  },
  fileSizeStyle: {
    color: "#D6D3D1",
    fontFamily: "Brown",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: 1.5,
  },
  fileUploadError: {
    fontFamily: "Open Sans",
    fontSize: "12px",
    color: "#DC2626",
    fontWeight: 700,
    fontStyle: "normal",
    backgroundColor: "#FEE2E2",
    padding: "21px 16px",
    lineHeight: 1.5,
    borderLeft: "2px solid #DC2626",
    margin: "10px 0px",
    borderRadius: "4px",
  },
  subHeader: {
    color: "#FFF",
    fontSize: "14px",
    fontFamily: "Brown",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "13.3px",
    paddingLeft: "12px",
  },
};
