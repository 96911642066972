import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  isOpenTermsAndPolicyDialog: string;
  handleClose: (isAgree: boolean) => void;
  isChecked: boolean;
}

export interface S {
  showTermsAndConditions: boolean;
  showPrivacyPolicy: boolean;
  isTermsAndPrivacyPolicyChecked: boolean;
  termsAndConditionsDataObject: string;
  privacyPolicyDataObject: string;
}

export interface SS {}

export default class TermsConditionsPrivacyPolicyController extends BlockComponent<
  Props,
  S,
  SS
> {
  termsAndConditionsApiId: string = "";
  privacyPolicyApiId: string = "";

  constructor(props: Props) {
    super(props);

    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      showPrivacyPolicy: false,
      showTermsAndConditions: false,
      isTermsAndPrivacyPolicyChecked: false,
      privacyPolicyDataObject: "",
      termsAndConditionsDataObject: "",
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  componentDidMount = async () => {
    this.getPrivacyPolicyData();
    this.getTermsAndConditions();
    if (this.props.isOpenTermsAndPolicyDialog === "termsAndConditions") {
      this.setState({ showTermsAndConditions: true, isTermsAndPrivacyPolicyChecked: this.props.isChecked });
    } else if (this.props.isOpenTermsAndPolicyDialog === "privacyPolicy") {
      this.setState({ showPrivacyPolicy: true, isTermsAndPrivacyPolicyChecked: this.props.isChecked });
    }
  };

  async receive(from: string, message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.termsAndConditionsApiId) {
      if (responseJson?.data && responseJson.data?.length > 0) {
        this.setState({
          termsAndConditionsDataObject: responseJson.data[0]?.description,
        });
      }
    }

    if (apiRequestCallId === this.privacyPolicyApiId) {
      if (responseJson?.data && responseJson.data?.length > 0) {
        this.setState({
          privacyPolicyDataObject: responseJson.data[0]?.description,
        });
      }
    }
  }

  handleChangeTermsAndPrivacyCheck = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    this.setState({ isTermsAndPrivacyPolicyChecked: event.target.checked });
  };

  toggleAccordion = (count: number) => {
    if (count === 0) {
      this.setState({
        showTermsAndConditions: !this.state.showTermsAndConditions,
        showPrivacyPolicy: !this.state.showTermsAndConditions
          ? false
          : this.state.showPrivacyPolicy,
      });
    } else {
      this.setState({
        showPrivacyPolicy: !this.state.showPrivacyPolicy,
        showTermsAndConditions: !this.state.showPrivacyPolicy
          ? false
          : this.state.showTermsAndConditions,
      });
    }
  };

  getTermsAndConditions = () => {
    const headers = {
      "Content-Type": configJSON.apiContentType,
    };

    const termsAndConditionsDataRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.termsAndConditionsApiId = termsAndConditionsDataRequestMsg.messageId;

    termsAndConditionsDataRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.termsAndConditionsApiEndPoint
    );

    termsAndConditionsDataRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    termsAndConditionsDataRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(
      termsAndConditionsDataRequestMsg.id,
      termsAndConditionsDataRequestMsg
    );
  };

  getPrivacyPolicyData = () => {
    const headers = {
      "Content-Type": configJSON.apiContentType,
    };

    const privacyPolicyDataRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.privacyPolicyApiId = privacyPolicyDataRequestMsg.messageId;

    privacyPolicyDataRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.privacyPolicyApiEndPoint
    );

    privacyPolicyDataRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    privacyPolicyDataRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(
      privacyPolicyDataRequestMsg.id,
      privacyPolicyDataRequestMsg
    );
  };
}
