import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import {
  getStorageData,
  removeStorageData,
} from "../../../framework/src/Utilities";

export const configJSON = require("./config");

export interface IValidationCheckObject {
  name: string;
  isPassed?: boolean;
}

export interface Props {
  navigation: any;
}

export interface S {
  password: string;
  confirmPassword: string;
  validationChecks: IValidationCheckObject[];
  validationFailedError: string;
  recoveryEmail: string;
  passwordChangedMsg: string;
  passwordError: string;
  confirmPasswordError: string;
  showPassword: boolean;
  showConfirmPassword: boolean;
}

export interface SS {}

export default class NewPasswordController extends BlockComponent<
  Props,
  S,
  SS
> {
  tokenVerificationApiId: string = "";
  resetPasswordApiId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.state = {
      password: "",
      confirmPassword: "",
      validationChecks: [
        { name: configJSON.capitalLetterLabel },
        { name: configJSON.lowercaseLetterLabel },
        { name: configJSON.oneNumberLabel },
        { name: configJSON.eightCharLabel },
      ],
      validationFailedError: "",
      recoveryEmail: "",
      passwordChangedMsg: "",
      passwordError: "",
      confirmPasswordError: "",
      showConfirmPassword: false,
      showPassword: false,
    };
  }

  async receive(from: string, message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.tokenVerificationApiId) {
      if (!responseJson?.message) {
        this.setState(
          {
            validationFailedError: configJSON.verificationFailedMsg,
          },
          () => {
            setTimeout(() => {
              this.handleNavigation("PasswordRecovery");
            }, 3000);
          }
        );
      }
    }
    if (apiRequestCallId === this.resetPasswordApiId) {
      if (responseJson?.message) {
        this.setState({ passwordChangedMsg: responseJson.message }, () => {
          removeStorageData("recoveryEmail");
          setTimeout(() => {
            this.handleNavigation("Login");
          }, 3000);
        });
      } else {
        this.setState(
          { validationFailedError: configJSON.somethingWentWrongMsg },
          () => {
            setTimeout(() => {
              this.setState({ validationFailedError: "" });
            }, 3000);
          }
        );
      }
    }
  }

  async componentDidMount() {
    const email = await getStorageData("recoveryEmail", true);
    const queryParams = window.location.search;
    if (!email || !queryParams) {
      this.handleNavigation("Home");
    }
    this.setState({ recoveryEmail: email });
    this.tokenVerification(queryParams);
  }

  toggleShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  toggleShowConfirmPassword = () => {
    this.setState({ showConfirmPassword: !this.state.showConfirmPassword });
  };

  handleChangeNewPasswordField = (
    event: React.ChangeEvent<HTMLInputElement>,
    field: string
  ) => {
    const fieldValue = event.target.value;
    if (field === "password") {
      this.setNewPassword(fieldValue);
    } else if (field === "confirmPassword") {
      let confirmedPasswordError = this.setFieldValidation(fieldValue);
      if (this.state.password !== fieldValue) {
        confirmedPasswordError =
          confirmedPasswordError || configJSON.passwordMissMatch;
      }
      this.setState({
        ...this.state,
        [field]: fieldValue,
        confirmPasswordError: confirmedPasswordError,
      });
    }
  };

  setNewPassword = (fieldValue: string) => {
    let validationCheckList = [...this.state.validationChecks];
    const capitalRegex = /[A-Z]/;
    const lowercaseRegex = /[a-z]/;
    const numberRegex = /\d/;
    validationCheckList[0].isPassed = capitalRegex.test(fieldValue);
    validationCheckList[1].isPassed = lowercaseRegex.test(fieldValue);
    validationCheckList[2].isPassed = numberRegex.test(fieldValue);
    validationCheckList[3].isPassed = fieldValue.replace(/\s/g, "").length >= 8;

    const passwordError = this.setFieldValidation(fieldValue);
    let confirmPasswordError = this.state.confirmPasswordError;
    if (this.state.confirmPassword) {
      if (this.state.confirmPassword !== fieldValue) {
        confirmPasswordError =
          this.state.confirmPasswordError || configJSON.passwordMissMatch;
      } else {
        confirmPasswordError =
          this.setFieldValidation(this.state.confirmPassword) || "";
      }
    }
    this.setState({
      ...this.state,
      password: fieldValue,
      passwordError: passwordError,
      confirmPasswordError: confirmPasswordError,
      validationChecks: validationCheckList
    });
  };

  setFieldValidation = (fieldValue: string) => {
    const upperCaseRegex = /[A-Z]/;
    const lowerCaseRegex = /[a-z]/;
    const numericRegex = /\d/;
    const spaceRegex = /^\S+$/;
    const capitalTest = upperCaseRegex.test(fieldValue);
    const numberTest = numericRegex.test(fieldValue);
    const lowerTest = lowerCaseRegex.test(fieldValue);
    const lengthTest = fieldValue.replace(/\s/g, "").length >= 8;
    const noSpacePresents = spaceRegex.test(fieldValue);
    const isValidPassword =
      capitalTest && lowerTest && numberTest && lengthTest && noSpacePresents;
    let passwordError = "";
    if (!isValidPassword) {
      passwordError = fieldValue
        ? configJSON.invalidPassword
        : configJSON.passwordRequiredMsg;
    }
    return passwordError;
  };

  handleLoginRedirection = () => {
    this.handleNavigation("Login");
  };

  handleNavigation = (page: string) => {
    const routeMsg = new Message(getName(MessageEnum.NavigationMessage));
    routeMsg.addData(getName(MessageEnum.NavigationTargetMessage), page);
    routeMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(routeMsg);
  };

  tokenVerification = (token: string) => {
    const headers = {
      "Content-Type": configJSON.forgotPasswordAPiContentType,
    };

    const tokenVerificationRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.tokenVerificationApiId = tokenVerificationRequestMsg.messageId;

    tokenVerificationRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.tokenVerificationApiEndPoint + token
    );

    tokenVerificationRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    tokenVerificationRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(
      tokenVerificationRequestMsg.id,
      tokenVerificationRequestMsg
    );
  };

  resetPassword = () => {
    const headers = {
      "Content-Type": undefined,
    };

    const formData = new FormData();
    formData.append("email", this.state.recoveryEmail);
    formData.append("new_password", this.state.confirmPassword);

    const resetPasswordRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.resetPasswordApiId = resetPasswordRequestMsg.messageId;

    resetPasswordRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.resetPasswordApiEndPoint
    );

    resetPasswordRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    resetPasswordRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutMethod
    );

    resetPasswordRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    runEngine.sendMessage(resetPasswordRequestMsg.id, resetPasswordRequestMsg);
  };
}
