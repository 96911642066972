import React from "react";
import { Checkbox } from "@material-ui/core";
const checkboxIcon = require("../src/checkbox.png");
const checkedCheckboxIcon = require("../src/checkbox-checked.svg");
const tableCheckboxIcon = require("../src/table-checkbox.svg");
const tableCheckedCheckboxIcon = require("../src/table-checked-checkbox.svg");
interface ICommonCheckBoxProps {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  checked: boolean;
  isTableCheckbox?: boolean;
}

const CommonCheckBox = (props: ICommonCheckBoxProps) => {
  return (
    <Checkbox
      checked={props.checked}
      onChange={props.onChange}
      name="checkedBox"
      icon={
        <img
          src={props.isTableCheckbox ? tableCheckboxIcon : checkboxIcon}
          alt="checkbox"
          width={props.isTableCheckbox ? 16 : 20}
          height={props.isTableCheckbox ? 16 : 20}
        />
      }
      checkedIcon={
        <img
          src={
            props.isTableCheckbox
              ? tableCheckedCheckboxIcon
              : checkedCheckboxIcon
          }
          alt="checkbox"
          width={props.isTableCheckbox ? 16 : 20}
          height={props.isTableCheckbox ? 16 : 20}
        />
      }
      style={webStyle.checkBox}
      disableRipple
    />
  );
};

const webStyle = {
  checkBox: {
    paddingLeft: "0px",
    paddingTop: "2px",
  },
};

export default CommonCheckBox;
