Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.placeHolderEmail = "Email";

exports.labelHeader = "Value proposition: why users should sign up.";
exports.labelFirstName = "First name";
exports.lastName = "Last name";
exports.labelEmail = "Email";
exports.labelPassword = "Password";
exports.labelRePassword = "Re-Type Password";
exports.errorCountryCodeNotSelected = "Please select country code";
exports.errorBlankField = "can't be blank";

exports.labelLegalText =
  "Legal text: By signing up you agree to our Terms &amp; conditions and Privacy policy.";

exports.labelLegalTermCondition = "Terms and conditions";
exports.labelLegalPrivacyPolicy = "Privacy policy";
exports.btnTextSignUp = "Sign up";
exports.errorPasswordNotValid = "Password not valid.";

exports.errorTitle = "Error";

exports.errorBothPasswordsNotSame = "Passwords should be same.";
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";

exports.contentTypeApiAddDetail = "application/json";

exports.accountsAPiEndPoint =
  "account/accounts";

exports.apiMethodTypeAddDetail = "POST";

exports.errorEmailNotValid = "Email not valid.";
exports.urlGetValidations =
  "profile/validations";
exports.validationApiContentType = "application/json";

exports.validationApiMethodType = "GET";
exports.welcomeLabel = "Welcome";
exports.signUpDescription = "Sign up and start your journey with us";
exports.labelBusinessName = "Business Name (optional)";
exports.emailPlaceholder = "Your email";
exports.passwordPlaceholder = "Your password";
exports.businessNamePlaceholder = "Your Business Name";
exports.alreadyHaveAccountText = "Have already an account?";
exports.loginLabel = "Log in";
exports.capitalLetterLabel = "At least one capital letter";
exports.lowercaseLetterLabel = "At least one lowercase letter";
exports.oneNumberLabel = "At least one number";
exports.eightCharLabel = "Minimum character length is 8 characters";
exports.iAgreeWithText = "I agree with the ";
exports.verifyEmailLabel = "Verify your email address";
exports.verifyEmailDescriptionLabel =
  "Enter the 6-digit code that we sent to your email";
exports.goBackLabel = "Go Back";
exports.verifyLabel = "Verify";
exports.paymentDetailsLabel = "Payment Details";
exports.welcomePaymentDetailsLabel = "Welcome, You're almost there";
exports.subscriptionLabel = "Subscription";
exports.premiumLabel = "Premium";
exports.priceAmountLabel = "$100";
exports.monthlyLabel = "monthly";
exports.payWithStripeLabel = "Pay with Stripe";
exports.privacyPolicy = "Privacy Policy";
exports.termsAndConditions = "Terms and Conditions";
exports.andText = " and ";
exports.emailRequired = "Email is required";
exports.passwordRequired = "Password is required";
exports.emailInvalid = "Invalid Email";
exports.emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
exports.businessNameRegex = /^$|^[A-Za-z0-9 .-]{1,60}$/;
exports.businessNameError = "Invalid business name";
exports.passwordInvalid = "Invalid Password"
exports.signUpAPIEndPoint = "account_block/accounts";
exports.verifyEmailAPIEndPoint = "account_block/verify_account";
exports.somethingWentWrong = "Something went wrong!";
exports.getMonthListApiEndPoint = "bx_block_bulk_uploading/months_to_lock";
exports.successPaymentMsg = "Subscription activated!";
exports.failPaymentMsg = "Subscription failed! Unable to activate your subscription.";
// Customizable Area End
