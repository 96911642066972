import React from "react";
import { Box } from "@material-ui/core";
import AccountIdentifiersStepperController, {
  Props,
  configJSON,
} from "./AccountIdentifiersStepperController.web";
import CustomStepper from "../../../components/src/CustomStepper.web";
import CustomTable from "../../../components/src/CustomTable.web";
import CustomButton from "../../../components/src/CustomButton.web";
import IdentifyPrepaidAccounts from "./IdentifyPrepaidAccounts.web";

export default class AccountIdentifiersStepper extends AccountIdentifiersStepperController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const {
      activeStep,
      accountsColumnData,
      stepDescription,
      accountsDataWithIdentifiers,
      filteredAccountsData,
      sortedColumn
    } = this.state;
    return (
      <>
        {accountsDataWithIdentifiers.length > 0 ? (
          <IdentifyPrepaidAccounts
            tableWidth={this.props.tableWidth}
            accountsData={accountsDataWithIdentifiers}
            backToStepper={this.backToStepper}
            moveToDetailedUploadScreen={this.props.moveToDetailedUploadScreen}
            showSearchBar={true}
            showReviewScreen={true}
          />
        ) : (
          <Box
            display={"flex"}
            justifyContent={"center"}
            width={"100%"}
            margin={"auto"}
          >
            <Box margin={"48px 0px"}>
              <Box style={webStyle.identifyFieldHeader} textAlign={"center"}>
                {configJSON.identifyFields}
              </Box>
              <Box display={"flex"} justifyContent={"center"}>
                <CustomStepper
                  stepList={[
                    configJSON.accountIdentifier,
                    configJSON.accountNameDescription,
                    configJSON.vendorName,
                  ]}
                  activeStep={activeStep}
                />
              </Box>
              <Box
                margin={"16px 0px"}
                textAlign={"center"}
                style={webStyle.identifyFieldSubHeader}
              >
                {stepDescription}
              </Box>
              <CustomTable
                columns={accountsColumnData}
                rows={filteredAccountsData}
                width={this.props.tableWidth}
                onSort={this.sortTableData}
                onSelectColumn={this.onSelectColumn}
                showSortIcons
                sortedColumn={sortedColumn}
              />
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                margin={"48px 0px 0px"}
              >
                <Box
                  sx={{
                    paddingRight: {
                      xs: "8px",
                      sm: "12px",
                      md: "24px",
                      lg: "48px",
                      xl: "48px",
                    },
                  }}
                >
                  <CustomButton
                    label={configJSON.backBtn}
                    variant="outlined"
                    onClick={this.onBack}
                    width={197}
                  />
                </Box>
                <Box>
                  <CustomButton
                    label={configJSON.continueBtn}
                    variant="contained"
                    onClick={this.onContinue}
                    width={197}
                    disabled={
                      !accountsColumnData.some(
                        (object) => object.checked === true
                      )
                    }
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        )}
      </>
    );
  }
}

const webStyle = {
  identifyFieldHeader: {
    color: "#FFF",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "30px",
  },
  identifyFieldSubHeader: {
    color: "#FFF",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "16px",
  },
};
