import React, { CSSProperties } from "react";
import { Box, Button } from "@material-ui/core";
import NavigationSidebar from "../../../blocks/navigationmenu/src/NavigationSidebar.web";
import SummaryController, { Props, configJSON } from "./SummaryController.web";
import CustomMainPageWrapper from "../../../components/src/CustomMainPageWrapper.web";
import CustomSummarySetup from "../../../components/src/CustomSummarySetup.web";
import CustomMonthTabStepper from "../../../components/src/CustomMonthTabStepper.web";
import CustomSearchableFilter from "../../../components/src/CustomSearchableFilter.web";
import CustomDateRangePicker from "../../../components/src/CustomDateRangePicker.web";
import { formatDateRange } from "../../../components/src/Utilities";
import {
  additionsIcon,
  downloadIcon,
  expensesIcon,
  filterIcon,
  graphIcon,
} from "./assets";
import CustomSummaryTable from "../../../components/src/CustomSummaryTable.web";
import moment from "moment";

export default class Summary extends SummaryController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const {
      summarySidebarWidth,
      identifierList,
      selectedDate,
      isSetupCompleted,
      monthList,
      selectedMonthData,
      fromDate,
      toDate,
      filteredIdentifiers,
      summaryLoader,
      filteredAdditionsData,
      vendorList,
      sortedAdditionsColumn,
      sortedAdjustmentsColumn,
      sortedFalloffsColumn,
      additionsAmount,
      additionsRowCount,
      filteredFalloffsData,
      falloffsRowCount,
      adjustmentsRowCount,
      filteredAdjustmentsData,
      expenseAmount,
      isAllScreenLoaded,
      endingPrepaidAmount,
    } = this.state;
    return (
      <Box
        display={"flex"}
        sx={{ flexDirection: { xs: "column", sm: "row", md: "row" } }}
      >
        <Box>
          <NavigationSidebar
            navigation={this.props.navigation}
            onResize={this.onResizeSummarySidebarWidth}
          />
        </Box>
        <CustomMainPageWrapper
          width={summarySidebarWidth}
          loader={summaryLoader}
        >
          <Box height={"inherit"} width={"100%"}>
            <Box
              sx={{
                padding: {
                  xs: "32px 8px",
                  sm: "36px 16px",
                  md: "40px 24px",
                  lg: "48px 32px",
                },
              }}
            >
              <Box
                display={"flex"}
                alignItems={"center"}
                flexWrap={"wrap"}
                justifyContent={"space-between"}
                gridGap={"8px"}
              >
                <Box style={webStyle.summaryTitle} data-test-id="title-text">
                  {configJSON.summaryTitle}
                </Box>
                {isSetupCompleted && isAllScreenLoaded && (
                  <Box style={webStyle.dateRangeLabel}>
                    {formatDateRange(fromDate, toDate)}
                  </Box>
                )}
              </Box>
              {!isSetupCompleted && isAllScreenLoaded && (
                <Box
                  sx={webStyle.setupContainer}
                  display={"flex"}
                  margin={"auto"}
                >
                  <CustomSummarySetup
                    identifierList={identifierList}
                    handleAmountChange={this.handleSetupAMountChange}
                    selectedDate={selectedDate}
                    handleDateChange={(date) =>
                      this.handleDateChange(date, "selectedDate")
                    }
                    onContinue={this.setUpSummary}
                    maxDate={moment(
                      monthList[monthList.length - 1].value,
                      "MMMM YYYY"
                    ).endOf("month")}
                    minDate={moment(monthList[0].value, "MMMM YYYY").startOf(
                      "month"
                    )}
                  />
                </Box>
              )}
              {isSetupCompleted && isAllScreenLoaded && (
                <Box>
                  <Box marginTop={"24px"} marginBottom={"16px"}>
                    <CustomMonthTabStepper
                      stepList={monthList}
                      onStepClick={this.onChangeMonth}
                      activeStep={selectedMonthData.name}
                    />
                  </Box>
                  <Box
                    display={"flex"}
                    alignItems={"stretch"}
                    justifyContent={"flex-start"}
                    flexWrap={"wrap"}
                    gridGap={"16px"}
                  >
                    <Box flex={1}>
                      <Box
                        sx={webStyle.statsBox}
                        display={"flex"}
                        justifyContent={"flex-start"}
                      >
                        <Box display={"flex"} alignItems={"center"}>
                          <img src={additionsIcon} height={32} alt="amount" />
                        </Box>
                        <Box marginLeft={"32px"}>
                          <Box marginBottom={"4px"} sx={webStyle.statsLabel}>
                            {configJSON.additionsAmount}
                          </Box>
                          <Box sx={webStyle.statsValue}>
                            {this.formatToDollar(additionsAmount)}
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                    <Box flex={1}>
                      <Box
                        sx={webStyle.statsBox}
                        display={"flex"}
                        justifyContent={"flex-start"}
                      >
                        <Box display={"flex"} alignItems={"center"}>
                          <img src={expensesIcon} height={32} alt="expenses" />
                        </Box>
                        <Box marginLeft={"32px"}>
                          <Box marginBottom={"4px"} sx={webStyle.statsLabel}>
                            {configJSON.expenseAmount}
                          </Box>
                          <Box sx={webStyle.statsValue}>
                            {this.formatToDollar(expenseAmount)}
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                    <Box flex={1}>
                      <Box
                        sx={webStyle.statsBox}
                        display={"flex"}
                        justifyContent={"flex-start"}
                      >
                        <Box display={"flex"} alignItems={"center"}>
                          <img src={graphIcon} height={32} alt="amount" />
                        </Box>
                        <Box marginLeft={"32px"}>
                          <Box marginBottom={"4px"} sx={webStyle.statsLabel}>
                            {configJSON.endingGLAmount}
                          </Box>
                          <Box sx={webStyle.statsValue}>
                            {this.formatToDollar(endingPrepaidAmount)}
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    marginTop={"16px"}
                    style={webStyle.filterContainer}
                    display={"flex"}
                    alignItems={"center"}
                    flexWrap={"wrap"}
                    gridGap={"16px"}
                  >
                    <Box display={"flex"} alignItems={"center"}>
                      <Box marginRight={"8px"}>
                        <img src={filterIcon} alt="filter" height={18} />
                      </Box>
                      <Box style={webStyle.filterLabel}>
                        {configJSON.filtersLabel}
                      </Box>
                    </Box>
                    <Box
                      display={"flex"}
                      alignItems={"center"}
                      flexWrap={"wrap"}
                      gridGap={"16px"}
                    >
                      <Box
                        style={webStyle.identifierFilterWrapper}
                        sx={{
                          width: {
                            xs: "inherit",
                            sm: "246px",
                            md: "246px",
                            lg: "246px",
                          },
                        }}
                      >
                        <Box style={webStyle.filterStatsLabel}>
                          {configJSON.accountIdentifiers}
                        </Box>
                        <Box
                          margin={"11px 0px"}
                          display={"flex"}
                          justifyContent={"center"}
                        >
                          <CustomSearchableFilter
                            value={filteredIdentifiers}
                            options={identifierList.map((item) => item.name)}
                            handleChange={this.handleChangeFilteredIdentifiers}
                          />
                        </Box>
                      </Box>
                      <Box
                        style={webStyle.identifierFilterWrapper}
                        sx={{
                          width: {
                            xs: "inherit",
                            sm: "368px",
                            md: "368px",
                            lg: "368px",
                          },
                        }}
                      >
                        <Box style={webStyle.filterStatsLabel}>
                          {configJSON.dateRange}
                        </Box>
                        <Box
                          margin={"6px 0px 0px"}
                          display={"flex"}
                          justifyContent={"center"}
                          alignItems={"center"}
                          flexWrap={"wrap"}
                          gridGap={"16px"}
                          sx={{
                            flexDirection: {
                              xs: "column",
                              sm: "row",
                              md: "row",
                              lg: "row",
                            },
                          }}
                        >
                          <Box>
                            <CustomDateRangePicker
                              selectedDate={fromDate}
                              handleDateChange={(date) =>
                                this.handleDateChange(date, "fromDate")
                              }
                              minDate={moment(
                                monthList[0]?.value,
                                "MMMM YYYY"
                              ).startOf("month")}
                              maxDate={moment(
                                this.state.toDate,
                                "MMMM YYYY"
                              ).startOf("month")}
                            />
                          </Box>
                          <Box style={webStyle.toLabel}>
                            {configJSON.toLabel}
                          </Box>
                          <Box>
                            <CustomDateRangePicker
                              selectedDate={toDate}
                              handleDateChange={(date) =>
                                this.handleDateChange(date, "toDate")
                              }
                              maxDate={moment(
                                monthList[monthList.length - 1]?.value,
                                "MMMM YYYY"
                              ).endOf("month")}
                              minDate={moment(
                                this.state.fromDate,
                                "MMMM YYYY"
                              ).startOf("month")}
                            />
                          </Box>
                        </Box>
                      </Box>
                      <Box
                        style={webStyle.identifierFilterWrapper}
                        sx={{
                          width: {
                            xs: "inherit",
                            sm: "246px",
                            md: "246px",
                            lg: "246px",
                          },
                        }}
                      >
                        <Box style={webStyle.filterStatsLabel}>
                          {configJSON.exportLabel}
                        </Box>
                        <Box
                          margin={"10px 0px 0px"}
                          display={"flex"}
                          justifyContent={"center"}
                        >
                          <Button
                            style={webStyle.downloadBtn}
                            fullWidth
                            variant="outlined"
                            onClick={this.exportFilteredData}
                          >
                            {configJSON.exportAsPDF}
                            <img
                              src={downloadIcon}
                              width={"18px"}
                              alt="download"
                              style={webStyle.downloadImage}
                            />
                          </Button>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  <Box style={webStyle.tableContainer} margin={"32px 0px"}>
                    <Box style={webStyle.tableTitleWrapper}>
                      <Box style={webStyle.tableTitle}>
                        {configJSON.additions}
                      </Box>
                    </Box>
                    <Box margin={"10px 0px"}>
                      <CustomSummaryTable
                        columns={configJSON.additionsColumns}
                        rows={filteredAdditionsData}
                        width={summarySidebarWidth + 64}
                        prepaidAccountIdentifierList={identifierList}
                        vendorList={vendorList}
                        sortedColumn={sortedAdditionsColumn}
                        onSort={this.sortAdditionsTableData}
                      />
                    </Box>
                    <Box display={"flex"} alignItems={"flex-end"}>
                      {filteredAdditionsData.length > 3 && (
                        <Box paddingRight={"16px"}>
                          <Button
                            variant="outlined"
                            style={webStyle.tableShowLessButton}
                            data-test-id="show-less-additions"
                            onClick={this.showLessAdditions}
                          >
                            {configJSON.showFewer} {configJSON.additions}
                          </Button>
                        </Box>
                      )}
                      {additionsRowCount > filteredAdditionsData.length && (
                        <Box>
                          <Box style={webStyle.rowCount} paddingBottom={"4px"}>
                            {filteredAdditionsData.length} out of{" "}
                            {additionsRowCount} additions
                          </Box>
                          <Button
                            variant="contained"
                            style={webStyle.tableShowButton}
                            onClick={this.showMoreAdditions}
                            data-test-id="show-more-additions"
                          >
                            {configJSON.showMore} {configJSON.additions}
                          </Button>
                        </Box>
                      )}
                    </Box>
                  </Box>
                  <Box style={webStyle.tableContainer} margin={"32px 0px"}>
                    <Box style={webStyle.tableTitleWrapper}>
                      <Box style={webStyle.tableTitle}>
                        {configJSON.falloffs}
                      </Box>
                    </Box>
                    <Box margin={"10px 0px"}>
                      <CustomSummaryTable
                        columns={configJSON.falloffsColumns}
                        rows={filteredFalloffsData}
                        width={summarySidebarWidth + 64}
                        prepaidAccountIdentifierList={identifierList}
                        vendorList={vendorList}
                        sortedColumn={sortedFalloffsColumn}
                        onSort={this.sortFalloffsTableData}
                      />
                    </Box>
                    <Box display={"flex"} alignItems={"flex-end"}>
                      {filteredFalloffsData.length > 3 && (
                        <Box paddingRight={"16px"}>
                          <Button
                            variant="outlined"
                            style={webStyle.tableShowLessButton}
                            data-test-id="show-less-falloffs"
                            onClick={this.showLessFalloffs}
                          >
                            {configJSON.showFewer} {configJSON.falloffs}
                          </Button>
                        </Box>
                      )}
                      {falloffsRowCount > filteredFalloffsData.length && (
                        <Box>
                          <Box style={webStyle.rowCount} paddingBottom={"4px"}>
                            {filteredFalloffsData.length} out of{" "}
                            {falloffsRowCount} falloffs
                          </Box>
                          <Button
                            variant="contained"
                            style={webStyle.tableShowButton}
                            onClick={this.showMoreFalloffs}
                            data-test-id="show-more-falloffs"
                          >
                            {configJSON.showMore} {configJSON.falloffs}
                          </Button>
                        </Box>
                      )}
                    </Box>
                  </Box>
                  <Box style={webStyle.tableContainer}>
                    <Box style={webStyle.tableTitleWrapper}>
                      <Box style={webStyle.tableTitle}>
                        {configJSON.adjustments}
                      </Box>
                    </Box>
                    <Box margin={"10px 0px"}>
                      <CustomSummaryTable
                        columns={configJSON.adjustmentsColumns}
                        rows={filteredAdjustmentsData}
                        width={summarySidebarWidth + 64}
                        prepaidAccountIdentifierList={identifierList}
                        vendorList={vendorList}
                        sortedColumn={sortedAdjustmentsColumn}
                        onSort={this.sortAdjustmentsTableData}
                      />
                    </Box>
                    <Box display={"flex"} alignItems={"flex-end"}>
                      {filteredAdjustmentsData.length > 3 && (
                        <Box paddingRight={"16px"}>
                          <Button
                            variant="outlined"
                            style={webStyle.tableShowLessButton}
                            data-test-id="show-less-adjustments"
                            onClick={this.showLessAdjustments}
                          >
                            {configJSON.showFewer} {configJSON.adjustments}
                          </Button>
                        </Box>
                      )}
                      {adjustmentsRowCount > filteredAdjustmentsData.length && (
                        <Box>
                          <Box style={webStyle.rowCount} paddingBottom={"4px"}>
                            {filteredAdjustmentsData.length} out of{" "}
                            {adjustmentsRowCount} adjustments
                          </Box>
                          <Button
                            variant="contained"
                            style={webStyle.tableShowButton}
                            onClick={this.showMoreAdjustments}
                            data-test-id="show-more-adjustments"
                          >
                            {configJSON.showMore} {configJSON.adjustments}
                          </Button>
                        </Box>
                      )}
                    </Box>
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        </CustomMainPageWrapper>
      </Box>
    );
  }
}

const webStyle = {
  summaryTitle: {
    color: "#FFF",
    fontFamily: "Brown",
    fontSize: "40px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "39.2px",
  },
  setupContainer: {
    height: "calc(100% - 135px)",
    minHeight: "calc(100vh - 135px)",
    "@media (max-width: 960px)": {
      height: "calc(100% - 135px)",
      minHeight: "calc(100vh - 135px)",
    },
    "@media (max-width: 600px)": {
      height: "calc(100% - 135px)",
      minHeight: "calc(100vh - 135px)",
    },
  } as CSSProperties,
  dateRangeLabel: {
    background: "linear-gradient(99deg, #01AA7F 2.64%, #00AED2 100%)",
    backgroundClip: "text",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    fontFamily: "Brown",
    fontSize: "28px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "27.44px",
    letterSpacing: "1.5px",
  },
  statsBox: {
    backgroundColor: "#002348",
    padding: "16px 24px",
    borderRadius: "16px",
    height: "calc(100% - 32px)",
    "@media (max-width: 960px)": {
      padding: "16px 16px",
    },
  } as CSSProperties,
  statsLabel: {
    fontFamily: "Open Sans",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    color: "#A8A29E",
    "@media (max-width: 960px)": {
      fontSize: "14px",
    },
  } as CSSProperties,
  statsValue: {
    fontFamily: "Open Sans",
    fontSize: "36px",
    fontWeight: 700,
    lineHeight: "44px",
    color: "#FFF",
    letterSpacing: "-0.01em",
    "@media (max-width: 960px)": {
      fontSize: "28px",
    },
  } as CSSProperties,
  filterContainer: {
    backgroundColor: "#002348",
    padding: "16px 24px",
    borderRadius: "16px",
  },
  filterLabel: {
    fontFamily: "Open Sans",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    color: "#A8A29E",
  },
  identifierFilterWrapper: {
    padding: "8px 16px",
    borderRadius: "16px",
    border: "1px solid #44403C",
  },
  filterStatsLabel: {
    fontFamily: "Open Sans",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    color: "#FFF",
  },
  downloadBtn: {
    borderRadius: "8px",
    fontFamily: "Open Sans",
    borderColor: "#FFF",
    color: "#FFF",
    textTransform: "none",
    fontSize: "12px",
    fontStyle: "normal",
    maxWidth: "132px",
    fontWeight: 700,
    lineHeight: "18px",
    padding: "8px",
  } as React.CSSProperties,
  downloadImage: {
    paddingLeft: "8px",
  },
  toLabel: {
    fontFamily: "Brown",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "15.2px",
    color: "#FFF",
  },
  tableContainer: {
    padding: "16px 0px",
    borderRadius: "16px",
    background: "#171717CC",
  },
  tableTitleWrapper: {
    borderBottom: "2px solid #01AA7F",
  },
  tableTitle: {
    padding: "4px 16px",
    fontFamily: "Open Sans",
    fontSize: "30px",
    fontWeight: 700,
    lineHeight: "40px",
    color: "#FFF",
    letterSpacing: "-0.005em",
  },
  tableShowButton: {
    color: "#FFF",
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "22px",
    background: "linear-gradient(99deg, #01AA7F 2.64%, #00AED2 100%)",
    fontFamily: "Open Sans",
    textTransform: "none",
    borderRadius: "50px",
    width: "200px",
    padding: "8px",
    height: "38px",
  } as CSSProperties,
  tableShowLessButton: {
    color: "#FFF",
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "22px",
    fontFamily: "Open Sans",
    textTransform: "none",
    borderRadius: "50px",
    width: "204px",
    padding: "8px",
    height: "38px",
    border: "1px solid #FFF",
  } as CSSProperties,
  rowCount: {
    color: "#FFF",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "18px",
    fontFamily: "Open Sans",
    textAlign: "center",
  } as CSSProperties,
};
