import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import {
  getStorageData,
  setStorageData,
} from "../../../framework/src/Utilities";
import moment from "moment";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
}

export interface S {
  isOpenStripeGateWay: boolean;
  isSubscriptionCompleted: boolean;
  isSubscriptionFailed: boolean;
  isAllPageLoaded: boolean;
}

export interface SS {}

export default class PaymentDetailsController extends BlockComponent<
  Props,
  S,
  SS
> {
  getAllMonthsApiId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      isOpenStripeGateWay: false,
      isSubscriptionCompleted: false,
      isAllPageLoaded: false,
      isSubscriptionFailed: false,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.getAllMonthsApiId) {
      this.setMonthListResponse(responseJson);
    }
  }

  componentDidMount = async () => {
    const userDetails = await getStorageData("userDetails", true);
    const isActiveSubscription = await getStorageData(
      "activeSubscription",
      true
    );
    if (!userDetails?.email) {
      this.handlePageRedirection("Home");
    }
    if (!isActiveSubscription) {
      this.setState({ isAllPageLoaded: true });
    } else {
      this.getAllMonthList();
    }
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  setMonthListResponse = async (responseJson: {
    months?: string[];
    message?: string;
  }) => {
    this.setState({
      isSubscriptionCompleted: true,
      isOpenStripeGateWay: false,
    });
    let loadedPage = "Details";
    let isFirstLoad = "true";
    if (responseJson?.months && responseJson.months?.length > 0) {
      const newDate = moment();
      const newMonthList = responseJson.months.filter((month) =>
        moment(month, "MMMM YYYY").isSameOrBefore(newDate)
      );
      if (newMonthList.length > 0) {
        loadedPage = "Summary";
        isFirstLoad = "false";
      }
    }
    await setStorageData("isFirstLoad", isFirstLoad);
    await setStorageData("activeSubscription", "true");
    this.handlePageRedirection(loadedPage);
  };

  payWithStripe = async () => {
    this.setState({ isOpenStripeGateWay: true });
  };

  handleCloseStripeGateway = () => {
    this.setState({ isOpenStripeGateWay: false });
  };

  onFailSubscription = () => {
    this.setState({ isSubscriptionFailed: true, isOpenStripeGateWay: false });
  };

  onCloseSnackbar = () => {
    this.setState({
      isSubscriptionFailed: false,
      isSubscriptionCompleted: false,
    });
  };

  getAllMonthList = async () => {
    const headers = {
      token: await getStorageData("token"),
      "Content-Type": configJSON.validationApiContentType,
    };

    const getAllMonthListRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAllMonthsApiId = getAllMonthListRequestMsg.messageId;

    getAllMonthListRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getMonthListApiEndPoint
    );

    getAllMonthListRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getAllMonthListRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(getAllMonthListRequestMsg.id, getAllMonthListRequestMsg);
  };

  handlePageRedirection = (path: string) => {
    const pageRedirectionMsg = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    pageRedirectionMsg.addData(
      getName(MessageEnum.NavigationTargetMessage),
      path
    );
    pageRedirectionMsg.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(pageRedirectionMsg);
  };
}
