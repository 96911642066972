import React from "react";
import LoginController, { Props, configJSON } from "./LoginController";
import { Box } from "@material-ui/core";
import CustomInput from "../../../components/src/CustomInput.web";
import CustomPasswordInput from "../../../components/src/CustomPasswordInput.web";
import { background, logo } from "./assets";
import CustomButton from "../../../components/src/CustomButton.web";

export default class Login extends LoginController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { emailObject, passwordObject, showPassword, loginError } =
      this.state;

    const isLogInButtonDisabled = () => {
      return (
        (emailObject.isTouched && !!emailObject.error) ||
        !emailObject.isTouched ||
        !passwordObject.isTouched ||
        (passwordObject.isTouched && !!passwordObject.error)
      );
    };

    return (
      <Box height={"auto"}>
        <Box style={webStyle.mainContainer}>
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            height={"inherit"}
            width={"100%"}
            margin={"auto"}
          >
            <Box width={"551px"}>
              <Box sx={webStyle.logInContainer}>
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  onClick={() => this.navigateToPage("Home")}
                  style={webStyle.pointer}
                >
                  <img src={logo} alt="logo" width={250} height={160} />
                </Box>
                <Box
                  marginBottom={"24px"}
                  textAlign={"center"}
                  style={webStyle.signInTitle}
                >
                  {configJSON.signInLabel}
                </Box>
                {loginError && (
                  <Box style={webStyle.loginError} data-test-id="error-message">
                    {loginError}
                  </Box>
                )}
                <Box style={webStyle.inputContainer}>
                  <Box style={webStyle.textFieldLabel}>
                    {configJSON.placeHolderEmail}
                  </Box>
                  <CustomInput
                    value={emailObject.value}
                    handleChange={(event) =>
                      this.handleChangeLoginField(event, "email")
                    }
                    placeholder={configJSON.emailPlaceholder}
                    isError={!!emailObject.error}
                    errorMessage={emailObject.error}
                  />
                </Box>
                <Box style={webStyle.inputContainer}>
                  <Box style={webStyle.textFieldLabel}>
                    {configJSON.placeHolderPassword}
                  </Box>
                  <CustomPasswordInput
                    value={passwordObject.value}
                    handleChange={(event) =>
                      this.handleChangeLoginField(event, "password")
                    }
                    placeholder={configJSON.passwordPlaceholder}
                    handleToggle={this.toggleShowPassword}
                    isShow={showPassword}
                    errorMessage={passwordObject.error}
                    isError={!!passwordObject.error}
                  />
                </Box>
                <Box display={"flex"} justifyContent={"flex-end"}>
                  <Box
                    data-test-id="forgot-password-btn"
                    style={webStyle.secondaryText}
                    onClick={this.handleForgotPasswordClick}
                  >
                    {configJSON.forgotPasswordLabel}
                  </Box>
                </Box>
                <Box margin={"16px 0px"}>
                  <CustomButton
                    fullWidth
                    variant="contained"
                    onClick={this.handleLogin}
                    label={configJSON.logInLabel}
                    disabled={isLogInButtonDisabled()}
                  />
                </Box>
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <Box style={webStyle.notHaveAccountText}>
                    {configJSON.dontHaveAnAccountLabel}
                  </Box>
                  <Box
                    data-test-id="sign-up-btn"
                    style={webStyle.signUpText}
                    onClick={this.handleSignUpClick}
                  >
                    {configJSON.signUpLabel}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  }
}

const webStyle = {
  mainContainer: {
    backgroundImage: `url(${background})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundColor: "#001327",
    height: "100%",
    minHeight: "100vh",
    display: "flex",
  },
  logInContainer: {
    padding: "32px 95px",
    borderRadius: "16px",
    background: "rgba(0, 19, 39, 0.60)",
    backdropFilter: "blur(8px)",
    "@media (max-width: 600px)": {
      padding: "32px 40px",
    },
  },
  signInTitle: {
    color: "#FFF",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "36px",
    lineHeight: 1.22,
    letterSpacing: -0.36,
  },
  secondaryText: {
    color: "#FFF",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: 1.57,
    borderBottom: "1px solid #FFF",
    cursor: "pointer",
  },
  inputContainer: {
    padding: "8px 0px",
  },
  textFieldLabel: {
    color: "#FFF",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: 1.5,
    paddingBottom: "4px",
  },
  notHaveAccountText: {
    color: "#FFF",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: 1.57,
  },
  signUpText: {
    background: "#FFF",
    backgroundClip: "text",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    fontFamily: "Open Sans",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: 1.5,
    cursor: "pointer",
    borderBottom: "1px solid #FFF",
    marginLeft: "24px",
  },
  loginError: {
    fontFamily: "Open Sans",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: 1.5,
    color: "#DC2626",
    backgroundColor: "#FEE2E2",
    borderRadius: "4px",
    borderLeft: "2px solid #DC2626",
    padding: "21px 16px",
    marginBottom: "26px",
  },
  pointer: {
    cursor: "pointer",
  },
};
