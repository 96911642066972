import React, { CSSProperties } from "react";
import StripeSubscriptionController, {
  Props,
  configJSON,
} from "./StripeSubscriptionController.web";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
} from "@material-ui/core";
import {
  PaymentElement,
  Elements,
  ElementsConsumer,
} from "@stripe/react-stripe-js";

export default class StripeSubscription extends StripeSubscriptionController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <Dialog
        open={true}
        PaperProps={{
          style: webStyle.dialogPaper,
        }}
        fullWidth
      >
        <DialogContent style={webStyle.dialogContent}>
          {this.state.isLoading && (
            <Box>
              <Backdrop style={{ zIndex: 9999 }} open={this.state.isLoading}>
                <CircularProgress size={60} style={webStyle.loader} />
              </Backdrop>
            </Box>
          )}
          <Box style={webStyle.btnList}>
            <Button onClick={this.onCloseStripeGateway} />
            <Button onClick={() => this.createSubscription("")} />
            <Button onClick={(event) => this.handleSubmit(event, null, null)} />
          </Box>
          <Elements stripe={this.stripePromise} options={this.options}>
            <ElementsConsumer>
              {({ stripe, elements }) => (
                <form
                  onSubmit={(event) =>
                    this.handleSubmit(event, stripe, elements)
                  }
                >
                  <PaymentElement />
                  <Box
                    marginTop={"16px"}
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    sx={{
                      flexDirection: {
                        xs: "column-reverse",
                        sm: "row",
                        md: "row",
                        lg: "row",
                        xl: "row",
                      },
                    }}
                  >
                    <Box
                      sx={{
                        margin: {
                          xs: "8px 0px 0px",
                          sm: "0px 16px 0px 0px",
                          md: "0px 16px 0px 0px",
                          lg: "0px 16px 0px 0px",
                          xl: "0px 16px 0px 0px",
                        },
                      }}
                    >
                      <Button
                        variant={"outlined"}
                        onClick={this.onCloseStripeGateway}
                        style={webStyle.cancelBtn}
                        disabled={this.state.isLoading}
                      >
                        {configJSON.cancel}
                      </Button>
                    </Box>
                    <Box>
                      <Button
                        type="submit"
                        style={webStyle.saveBtn}
                        disabled={!stripe || this.state.isLoading}
                      >
                        {configJSON.submit}
                      </Button>
                    </Box>
                  </Box>
                </form>
              )}
            </ElementsConsumer>
          </Elements>
        </DialogContent>
      </Dialog>
    );
  }
}

const webStyle = {
  saveBtn: {
    color: "#FFF",
    fontWeight: 700,
    fontSize: "14px",
    background: "linear-gradient(99deg, #01AA7F 2.64%, #00AED2 100%)",
    lineHeight: "22px",
    textTransform: "none",
    fontFamily: "Open Sans",
    borderRadius: "8px",
    paddingBottom: "8px",
    paddingTop: "8px",
    width: "144px",
  } as CSSProperties,
  cancelBtn: {
    color: "#FFF",
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "22px",
    textTransform: "none",
    fontFamily: "Open Sans",
    borderRadius: "8px",
    paddingBottom: "7px",
    paddingTop: "7px",
    width: "144px",
    border: "1px solid #FFF",
  } as CSSProperties,
  dialogPaper: {
    borderRadius: "16px",
    width: "600px",
    backgroundColor: "#001327",
  },
  dialogContent: {
    padding: "32px 32px 0px",
  },
  btnList: {
    display: "none",
  },
  loader: {
    color: "#01AA7F",
  },
};
