import React from "react";
import { Box, Grid } from "@material-ui/core";
import { InlineWidget } from "react-calendly";

import ContactUsPageController, {
  Props,
  configJSON,
} from "./ContactUsPageController.web";
import { calendar, chart, email } from "../src/assets";
import NavigationSidebar from "../../../blocks/navigationmenu/src/NavigationSidebar.web";
import CustomButton from "../../../components/src/CustomButton.web";
import CustomMainPageWrapper from "../../../components/src/CustomMainPageWrapper.web";

export default class ContactUsPage extends ContactUsPageController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { showCalendarView, contactUsSidebarWidth } = this.state;
    return (
      <Box
        display={"flex"}
        sx={{ flexDirection: { xs: "column", sm: "row", md: "row" } }}
      >
        <Box>
          <NavigationSidebar
            navigation={this.props.navigation}
            onResize={this.onPageWidthResize}
          />
        </Box>
        <CustomMainPageWrapper width={contactUsSidebarWidth}
        >
          <Box
            sx={{
              padding: {
                xs: "32px 8px",
                sm: "36px 16px",
                md: "40px 24px",
                lg: "48px 32px",
              },
            }}
            width={"100%"}
          >
            <Box style={webStyle.contactUsTitle} data-test-id="title-text">
              {configJSON.contactUsTitle}
            </Box>
            <Box
              padding={"16px 0px 56px"}
              style={webStyle.contactUsDescription}
            >
              {configJSON.contactUsDescription}
            </Box>
            <Box>
              <Box style={webStyle.cardContainer}>
                <Grid container spacing={2} alignItems="center">
                  <Grid item lg={2} md={2} sm={3}>
                    <Box display={"flex"} justifyContent={"center"}>
                      <img src={email} alt="email" height={56} width={56} />
                    </Box>
                  </Grid>
                  <Grid item lg={7} md={6} sm={9}>
                    <Box style={webStyle.cardTitle}>
                      {configJSON.reachOutText}
                    </Box>
                    <Box paddingTop={"16px"} style={webStyle.cardSubTitle}>
                      {configJSON.haveQuestionText}
                    </Box>
                  </Grid>
                  <Grid item lg={3} md={4} sm={12}>
                    <CustomButton
                      label={configJSON.sendEmail}
                      width={197}
                      variant="contained"
                      onClick={() => this.redirectToMailPage(false)}
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box style={webStyle.cardContainer} margin={"24px 0px"}>
                <Grid container spacing={2} alignItems={"center"}>
                  <Grid item lg={2} md={2} sm={3}>
                    <Box display={"flex"} justifyContent={"center"}>
                      <img
                        src={calendar}
                        alt="calendar"
                        height={56}
                        width={56}
                      />
                    </Box>
                  </Grid>
                  <Grid item lg={7} md={6} sm={9}>
                    <Box style={webStyle.cardTitle}>
                      {configJSON.scheduleMeetingText}
                    </Box>
                    <Box paddingTop={"16px"} style={webStyle.cardSubTitle}>
                      {configJSON.wantToChatText}
                    </Box>
                  </Grid>
                  <Grid item lg={3} md={4} sm={12}>
                    <CustomButton
                      label={configJSON.scheduleMeeting}
                      width={197}
                      variant="contained"
                      onClick={this.toggleCalendarView}
                    />
                  </Grid>
                </Grid>
                {showCalendarView && (
                  <Box padding={"16px 0px"} data-test-id="calendar-view">
                    <InlineWidget
                      url={configJSON.calendlyUrl}
                      styles={webStyle.calendarContainer}
                      pageSettings={{
                        hideGdprBanner: true,
                      }}
                    />
                  </Box>
                )}
              </Box>
              <Box style={webStyle.cardContainer}>
                <Grid container spacing={2} alignItems="center">
                  <Grid item lg={2} md={2} sm={3}>
                    <Box display={"flex"} justifyContent={"center"}>
                      <img src={chart} alt="chart" height={56} width={56} />
                    </Box>
                  </Grid>
                  <Grid item lg={7} md={6} sm={9}>
                    <Box style={webStyle.cardTitle}>
                      {configJSON.suggestFeatureText}
                    </Box>
                    <Box paddingTop={"16px"} style={webStyle.cardSubTitle}>
                      {configJSON.suggestionDescription}
                    </Box>
                  </Grid>
                  <Grid item lg={3} md={4} sm={12}>
                    <CustomButton
                      label={configJSON.suggestFeature}
                      width={197}
                      variant="contained"
                      onClick={() => this.redirectToMailPage(true)}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Box>
        </CustomMainPageWrapper>
      </Box>
    );
  }
}

const webStyle = {
  contactUsTitle: {
    fontFamily: "Brown",
    fontSize: "40px",
    fontStyle: "normal",
    fontWeight: 700,
    color: "#FFF",
  },
  contactUsDescription: {
    fontFamily: "Brown",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    color: "#FFF",
    lineHeight: 1.5,
  },
  cardContainer: {
    padding: "28px 48px",
    background: "rgba(23, 23, 23, 0.80)",
    borderRadius: "16px",
    borderTop: "4px solid #6EE7B7",
  },
  cardTitle: {
    fontFamily: "Brown",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: 700,
    color: "#FFF",
  },
  cardSubTitle: {
    fontFamily: "Brown",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    color: "#FFF",
  },
  calendarContainer: {
    height: "660px",
  },
};
