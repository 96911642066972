Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.dashboardGetUrl = "/bx_block_dashboard/candidates";
exports.dashboarContentType = "application/json";
exports.dashboarApiMethodType = "GET";
exports.dashboardHost = "<calculated when request is sent>";
exports.dashboarUserAgent = "PostmanRuntime/7.26.5";
exports.dashboarAccept = "*/*";
exports.dashboarAcceptEncoding = "gzip, deflate, br";
exports.dashboarConnection = "keep-alive";
exports.dashboartoken = "";
exports.labelTitleText = "dashboard";
exports.totalCandidateslabelTitleText = "Total Candidates";
exports.viewDetailsBtn = "View Details";
exports.candidateslabelTitleText = "Candidates";
exports.baseUrl = "";
exports.labelBodyText = "dashboard Body";
exports.summaryTitle = "Summary";
exports.getIdentifierListApiEndPoint =
  "bx_block_bulk_uploading/view_prepaid_account_identifiers";
exports.getVendorListApiEndPoint = "bx_block_bulk_uploading/view_vendor_names";
exports.apiContentType = "application/json";
exports.apiGetMethod = "GET";
exports.apiPostMethod = "POST";
exports.summarySetupApiEndPoint = "bx_block_bulk_uploading/summaries?starting_gl=";
exports.getMonthListApiEndPoint = "bx_block_bulk_uploading/months_to_lock";
exports.noMonthsDataMessage = "No details found for the account.";
exports.additionsAmount = "Additions Amount";
exports.expenseAmount = "Expense Amount";
exports.endingGLAmount = "Ending Prepaid GL Amount";
exports.filtersLabel = "Filters";
exports.accountIdentifiers = "Account identifier:";
exports.dateRange = "Date Range:";
exports.toLabel = "to";
exports.exportLabel = "Export:";
exports.exportAsPDF = "Export as PDF";
exports.additions = "Additions";
exports.falloffs = "Falloffs";
exports.adjustments = "Adjustments";
exports.showMore = "Show More";
exports.showFewer = "Show Fewer";
exports.additionsColumns = [
  {
    name: "Prepaid Account Identifier",
    key: "prepaid_account_identifier",
    showSorting: true,
  },
  {
    name: "Vendor Name",
    key: "vendor_name",
    showSorting: true,
  },
  {
    name: "Total Amount (USD)",
    key: "total_amount",
    showSorting: true,
  },
  {
    name: "Invoice Number",
    key: "invoice_number",
    showSorting: true,
  },
  {
    name: "Amortization Period (months)",
    key: "amortization_period",
    showSorting: true,
  },
];
exports.falloffsColumns = [
  {
    name: "Prepaid Account Identifier",
    key: "prepaid_account_identifier",
    showSorting: true,
  },
  {
    name: "Vendor Name",
    key: "vendor_name",
    showSorting: true,
  },
  {
    name: "Total Amount (USD)",
    key: "total_amount",
    showSorting: true,
  },
  {
    name: "Invoice Number",
    key: "invoice_number",
    showSorting: true,
  },
  {
    name: "Amortization Period (months)",
    key: "amortization_period",
    showSorting: true,
  },
  {
    name: "Amortization Start",
    key: "amortization_start",
    showSorting: true,
  },
];
exports.adjustmentsColumns = [
  {
    name: "Prepaid Account Identifier",
    key: "prepaid_account_identifier",
    showSorting: true,
  },
  {
    name: "Vendor Name",
    key: "vendor_name",
    showSorting: true,
  },
  {
    name: "Adjustment Amount",
    key: "adjustment_amount",
    showSorting: true,
  },
  {
    name: "Total Amount (USD)",
    key: "total_amount",
    showSorting: true,
  },
  {
    name: "Explanation",
    key: "notes",
    showSorting: false,
  },
  {
    name: "Amortization End",
    key: "amortization_end",
    showSorting: true,
  },
];
exports.additionsDataApiEndPoint =
  "bx_block_bulk_uploading/additions_in_summary?";
exports.falloffsDataApiEndPoint =
  "bx_block_bulk_uploading/falloffs_in_summary?";
exports.adjustmentsDataApiEndPoint =
  "bx_block_bulk_uploading/adjustments_in_summary?";
exports.expenseAmountApiEndPoint =
  "bx_block_bulk_uploading/expense_amount_in_summary?";
exports.getSummaryRecordApiEndPoint = "bx_block_bulk_uploading/account_has_summary";
exports.setupSummarySuccessMsg = "Summary added successfully";
exports.summaryFoundMsg = "Summary found for this account";
exports.endingPrepaidAmountApiEndPoint = "bx_block_bulk_uploading/fileterd_ending_gl?";
exports.exportAsPdfApiEndPoint = "bx_block_bulk_uploading/summary_as_pdf?";
// Customizable Area End