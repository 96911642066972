import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Tab, Tabs } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#002348",
    borderRadius: "50px",
    padding: "8px",
    overflowX: "auto",
    "& .MuiTabs-flexContainer": {
      justifyContent: "space-between",
    },
    "& .MuiTabScrollButton-root": {
      width: "auto",
      color: "#FFF",
      [theme.breakpoints.up("md")]: {
        display: "none",
      },
    },
    [theme.breakpoints.down("md")]: {
      padding: "4px",
    },
  },
  tab: {
    fontFamily: "Brown",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 700,
    color: "#E7E5E4",
    padding: "16px 24px",
    cursor: "pointer",
    textTransform: "none",
    opacity: 1,
    minWidth: "auto",
    [theme.breakpoints.down("md")]: {
      padding: "10px 12px",
      fontSize: "14px",
    },
    "&:hover": {
      borderRadius: "50px",
      background: "linear-gradient(99deg, #01AA7F 2.64%, #00AED2 100%)",
      color: "#FFF",
    },
    "&.Mui-selected": {
      borderRadius: "50px",
      background: "linear-gradient(99deg, #01AA7F 2.64%, #00AED2 100%)",
      color: "#FFF",
    },
  },
  indicator: {
    display: "none",
  },
  centered: {
    justifyContent: "center",
  },
}));

interface ICustomTabStepperProps {
  stepList: string[];
  activeStep: string;
  onStepClick: (event: React.ChangeEvent<{}>, step: string) => void;
}

const CustomTabStepper = ({
  stepList,
  activeStep,
  onStepClick,
}: ICustomTabStepperProps) => {
  const classes = useStyles();

  return (
    <Tabs
      className={classes.root}
      value={activeStep}
      onChange={onStepClick}
      centered
      scrollButtons="on"
      variant="scrollable"
      classes={{ indicator: classes.indicator, centered: classes.centered }}
    >
      {stepList.map((step: string) => (
        <Tab className={classes.tab} label={step} value={step} key={step} />
      ))}
    </Tabs>
  );
};

export default CustomTabStepper;
