import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import {
  getStorageData,
  setStorageData,
} from "../../../framework/src/Utilities";
import moment from "moment";

export const configJSON = require("./config");

interface IFieldObject {
  value: string;
  error: string;
  isTouched: boolean;
}

export interface Props {
  navigation: any;
}

export interface S {
  showPassword: boolean;
  loginError: string;
  isOpenTermsAndPrivacyDialog: boolean;
  emailObject: IFieldObject;
  passwordObject: IFieldObject;
}

export interface SS {}

export default class LoginController extends BlockComponent<Props, S, SS> {
  loginApiId: string = "";
  getDetailSheetApiId: string = "";
  checkSubscriptionStatusApiId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.state = {
      showPassword: false,
      loginError: "",
      isOpenTermsAndPrivacyDialog: false,
      emailObject: { value: "", isTouched: false, error: "" },
      passwordObject: { value: "", isTouched: false, error: "" },
    };
  }

  async receive(from: string, message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.loginApiId) {
      if (responseJson?.meta) {
        await setStorageData("userDetails", JSON.stringify(responseJson.meta));
        await setStorageData("token", responseJson.meta?.token);
        this.checkActiveSubscriptionStatus();
      } else if (responseJson.errors && responseJson.errors.length > 0) {
        this.setState(
          {
            loginError:
              responseJson.errors[0]?.failed_login ||
              configJSON.somethingWentWrong,
          },
          () => {
            setTimeout(() => {
              this.setState({ loginError: "" });
            }, 3000);
          }
        );
      }
    }
    if (apiRequestCallId === this.getDetailSheetApiId) {
      this.setDetailSheetResponse(responseJson);
    }
    if (apiRequestCallId === this.checkSubscriptionStatusApiId) {
      if (
        responseJson &&
        responseJson.message === configJSON.subscriptionFoundMsg
      ) {
        this.getDetailSheet();
      } else {
        this.navigateToPage("PaymentDetails");
      }
    }
  }

  async componentDidMount() {
    const token = await getStorageData("token");
    const isUserVerified = await getStorageData("isUserVerified");
    if (token || isUserVerified !== "true") {
      this.navigateToPage("Home");
    }
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  setDetailSheetResponse = async (responseJson: {
    months?: string[];
    message?: string;
  }) => {
    let page = "Details";
    let isFirstLoad = "true";
    if (responseJson?.months && responseJson.months?.length > 0) {
      const currentDate = moment();
      const updatedMonths = responseJson.months.filter((month) =>
        moment(month, "MMMM YYYY").isSameOrBefore(currentDate)
      );
      if (updatedMonths.length > 0) {
        page = "Summary";
        isFirstLoad = "false";
      }
    }
    await setStorageData("isFirstLoad", isFirstLoad);
    await setStorageData("activeSubscription", "true");
    this.navigateToPage(page);
  };

  handleChangeLoginField = (
    event: React.ChangeEvent<HTMLInputElement>,
    field: string
  ) => {
    const value = event.target.value;
    if (field === "email") {
      let errorMessage = "";
      const isValidEmail = configJSON.emailRegex.test(value);
      if (!isValidEmail) {
        errorMessage = value
          ? configJSON.emailInvalid
          : configJSON.emailRequired;
      }
      this.setState({
        emailObject: {
          value: value,
          isTouched: true,
          error: errorMessage,
        },
      });
    } else {
      const capitalRegex = /[A-Z]/;
      const lowercaseRegex = /[a-z]/;
      const numberRegex = /\d/;
      const spaceRegex = /^\S+$/;
      const capitalTest = capitalRegex.test(value);
      const lowerTest = lowercaseRegex.test(value);
      const numberTest = numberRegex.test(value);
      const lengthTest = value.replace(/\s/g, "").length >= 8;
      const noSpacePresents = spaceRegex.test(value);
      const isValidPassword =
        capitalTest && lowerTest && numberTest && lengthTest && noSpacePresents;
      let passwordErrorMsg = "";
      if (!isValidPassword) {
        passwordErrorMsg = value
          ? configJSON.passwordInvalid
          : configJSON.passwordRequired;
      }
      this.setState({
        passwordObject: {
          value: value,
          isTouched: true,
          error: passwordErrorMsg,
        },
      });
    }
  };

  toggleShowPassword = () => {
    this.setState({
      ...this.state,
      showPassword: !this.state.showPassword,
    });
  };

  handleSignUpClick = () => {
    this.navigateToPage("SignUp");
  };

  handleForgotPasswordClick = () => {
    this.navigateToPage("PasswordRecovery");
  };

  handleLogin = () => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const loginRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.loginApiId = loginRequestMsg.messageId;

    loginRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loginEndPoint
    );

    loginRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    loginRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );

    loginRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        data: {
          type: "email_account",
          attributes: {
            email: this.state.emailObject.value,
            password: this.state.passwordObject.value,
          },
        },
      })
    );

    runEngine.sendMessage(loginRequestMsg.id, loginRequestMsg);
  };

  getDetailSheet = async () => {
    const headers = {
      "Content-Type": configJSON.apiContentType,
      token: await getStorageData("token"),
    };

    const getDetailSheetRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getDetailSheetApiId = getDetailSheetRequestMsg.messageId;

    getDetailSheetRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getMonthListApiEndPoint
    );

    getDetailSheetRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getDetailSheetRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(
      getDetailSheetRequestMsg.id,
      getDetailSheetRequestMsg
    );
  };

  checkActiveSubscriptionStatus = async () => {
    const headers = {
      token: await getStorageData("token"),
      "Content-Type": configJSON.validationApiContentType,
    };

    const checkActiveSubscriptionRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.checkSubscriptionStatusApiId =
      checkActiveSubscriptionRequestMsg.messageId;

    checkActiveSubscriptionRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.checkSubscriptionStatusApiEndPoint
    );

    checkActiveSubscriptionRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    checkActiveSubscriptionRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(
      checkActiveSubscriptionRequestMsg.id,
      checkActiveSubscriptionRequestMsg
    );
  };

  navigateToPage = (page: string) => {
    const navigationMsg = new Message(getName(MessageEnum.NavigationMessage));
    navigationMsg.addData(getName(MessageEnum.NavigationTargetMessage), page);
    navigationMsg.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(navigationMsg);
  };
}
