import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "../../../framework/src/Utilities";
import { clearStorage } from "../../../components/src/Utilities";

export const configJSON = require("./config");

export interface IValidationChecks {
  name: string;
  isPassed?: boolean;
}
export interface Props {
  navigation: any;
  onTabChange: (tabName: string) => void;
}

interface S {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
  validationCheckItems: IValidationChecks[];
  isOpenSuccessDialog: boolean;
  currentPasswordError: string;
  newPasswordError: string;
  confirmPasswordError: string;
  passwordChangeFailMsg: string;
  passwordToggle: boolean[];
}

interface SS {}

export default class SettingsChangePasswordController extends BlockComponent<
  Props,
  S,
  SS
> {
  changePasswordApiId: string = "";

  constructor(props: Props) {
    super(props);

    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      confirmPassword: "",
      newPassword: "",
      currentPassword: "",
      validationCheckItems: [
        { name: configJSON.capitalLetterLabel },
        { name: configJSON.lowercaseLetterLabel },
        { name: configJSON.oneNumberLabel },
        { name: configJSON.eightCharLabel },
      ],
      isOpenSuccessDialog: false,
      currentPasswordError: "",
      newPasswordError: "",
      confirmPasswordError: "",
      passwordChangeFailMsg: "",
      passwordToggle: [false, false],
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.changePasswordApiId) {
      if (responseJson?.message) {
        this.setState({ isOpenSuccessDialog: true });
      } else {
        this.setState(
          {
            passwordChangeFailMsg:
              responseJson?.errors || configJSON.somethingWentWrongMsg,
          },
          () => {
            setTimeout(() => {
              this.setState({ passwordChangeFailMsg: "" });
            }, 3000);
          }
        );
      }
    }
  }

  togglePasswordField = (index: number) => {
    const passwordToggleArray = [...this.state.passwordToggle];
    passwordToggleArray[index] = !passwordToggleArray[index];
    this.setState({ passwordToggle: passwordToggleArray });
  };

  handleChangePasswordField = (
    event: React.ChangeEvent<HTMLInputElement>,
    field: string
  ) => {
    const value = event.target.value;
    if (field === "currentPassword") {
      let currentPasswordError = this.setPasswordFieldValidation(value);
      this.setState({
        ...this.state,
        currentPassword: value,
        currentPasswordError: currentPasswordError,
      });
    } else if (field === "newPassword") {
      this.setNewPasswordField(value);
    } else {
      this.setConfirmPasswordField(value);
    }
  };

  setNewPasswordField = (value: string) => {
    let validationChecks = [...this.state.validationCheckItems];
    const capitalRegex = /[A-Z]/;
    const lowercaseRegex = /[a-z]/;
    const numberRegex = /\d/;
    validationChecks[0].isPassed = capitalRegex.test(value);
    validationChecks[1].isPassed = lowercaseRegex.test(value);
    validationChecks[2].isPassed = numberRegex.test(value);
    validationChecks[3].isPassed = value.replace(/\s/g, "").length >= 8;

    const newPasswordError = this.setPasswordFieldValidation(value);
    let confirmPasswordError = this.state.confirmPasswordError;
    if (this.state.confirmPassword) {
      if (this.state.confirmPassword !== value) {
        confirmPasswordError =
          this.state.confirmPasswordError || configJSON.passwordMissMatch;
      } else {
        confirmPasswordError =
          this.setPasswordFieldValidation(this.state.confirmPassword) || "";
      }
    }
    this.setState({
      ...this.state,
      newPassword: value,
      newPasswordError: newPasswordError,
      confirmPasswordError: confirmPasswordError,
      validationCheckItems: validationChecks,
    });
  };

  setConfirmPasswordField = (value: string) => {
    let confirmedPasswordError = this.setPasswordFieldValidation(value);
    if (this.state.newPassword !== value) {
      confirmedPasswordError =
        confirmedPasswordError || configJSON.passwordMissMatch;
    }
    this.setState({
      ...this.state,
      confirmPassword: value,
      confirmPasswordError: confirmedPasswordError,
    });
  };

  navigateToForgotPassword = () => {
    const forgotPasswordMsg = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    forgotPasswordMsg.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "PasswordRecovery"
    );
    forgotPasswordMsg.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(forgotPasswordMsg);
    clearStorage();
  };

  saveNewPassword = async () => {
    const headers = {
      "Content-Type": undefined,
      token: await getStorageData("token"),
    };

    const formData = new FormData();
    formData.append("current_password", this.state.currentPassword);
    formData.append("new_password", this.state.newPassword);
    formData.append("confirm_password", this.state.confirmPassword);

    const changePasswordRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.changePasswordApiId = changePasswordRequestMsg.messageId;

    changePasswordRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.changePasswordApiEndPoint
    );

    changePasswordRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    changePasswordRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutMethod
    );

    changePasswordRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    runEngine.sendMessage(
      changePasswordRequestMsg.id,
      changePasswordRequestMsg
    );
  };

  backToSettings = () => {
    this.setState({ isOpenSuccessDialog: false });
    this.props.onTabChange(configJSON.settingsTabList[0]);
  };

  setPasswordFieldValidation = (value: string) => {
    const lowerCaseCheck = /[a-z]/;
    const upperCaseCheck = /[A-Z]/;
    const numericCheck = /\d/;
    const spaceCheck = /^\S+$/;
    const lowerTest = lowerCaseCheck.test(value);
    const capitalTest = upperCaseCheck.test(value);
    const numberTest = numericCheck.test(value);
    const lengthTest = value.replace(/\s/g, "").length >= 8;
    const noSpaceCheck = spaceCheck.test(value);
    const isPasswordValid =
      capitalTest && lowerTest && numberTest && lengthTest && noSpaceCheck;
    let passwordError = "";
    if (!isPasswordValid) {
      passwordError = value
        ? configJSON.passwordInvalid
        : configJSON.passwordRequired;
    }
    return passwordError;
  };
}
