import React from "react";
import { Box, Drawer, IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
const leftArrow = require("../src/left-arrow.svg");
const rightArrow = require("../src/right-arrow.svg");
const logo = require("../src/logo.svg");
const mobileLogo = require("../src/mobile-logo.svg");
const logoWithName = require("../src/logo-with-name.svg");

const useStyles = makeStyles(() => ({
  sidebarContainer: (props: ISidebarNavigationProps) => ({
    width: props.toggleSidebar ? "241px" : "100px",
    height: "100%",
    backgroundColor: "#002348",
    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.25)",
    "& .tab": {
      "&:hover": {
        background: "linear-gradient(99deg, #01AA7F 2.64%, #00AED2 100%)",
      },
      justifyContent: props.toggleSidebar ? "normal" : "center",
      color: "#FFF",
      cursor: "pointer",
    },
    "& .activeTab": {
      background: "linear-gradient(99deg, #01AA7F 2.64%, #00AED2 100%)",
      justifyContent: props.toggleSidebar ? "normal" : "center",
      color: "#FFF",
      cursor: "pointer",
    },
    "& .disabledTab": {
      color: "#57534E",
      cursor: "default",
      pointerEvents: "none",
      justifyContent: props.toggleSidebar ? "normal" : "center",
    },
  }),
  drawerContainer: (props: ISidebarNavigationProps) => ({
    "& .MuiPaper-root": {
      backgroundColor: "#002348 !important",
      boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.25)",
      width: "100%",
    },
    "& .tab": {
      "&:hover": {
        background: "linear-gradient(99deg, #01AA7F 2.64%, #00AED2 100%)",
      },
      justifyContent: "normal",
      color: "#FFF",
      cursor: "pointer",
    },
    "& .activeTab": {
      background: "linear-gradient(99deg, #01AA7F 2.64%, #00AED2 100%)",
      color: "#FFF",
      cursor: "pointer",
    },
    "& .disabledTab": {
      color: "#57534E",
      cursor: "default",
      pointerEvents: "none",
    },
  }),
}));

interface ISidebarNavigationProps {
  onTabClick: (tab: string) => void;
  activeTab: string;
  toggleSidebar: boolean;
  isMobileView: boolean;
  toggleMobileView: boolean;
  setToggleSidebar: (toggle: boolean) => void;
  setToggleMobileView: (toggle: boolean) => void;
  menuList: {
    tab: string;
    icon: string;
    path: string;
    disabledIcon?: string;
    isDisabled?: boolean;
  }[];
}

const SidebarNavigation = (props: ISidebarNavigationProps) => {
  const classes = useStyles(props);
  const {
    activeTab,
    toggleSidebar,
    onTabClick,
    setToggleSidebar,
    toggleMobileView,
    setToggleMobileView,
    isMobileView,
    menuList,
  } = props;

  return (
    <>
      {!isMobileView ? (
        <Box className={classes.sidebarContainer}>
          <Box position={"relative"} minHeight={"100vh"}>
            <Box
              style={webStyle.arrowContainer}
              position={"absolute"}
              onClick={() => setToggleSidebar(toggleSidebar)}
              data-test-id="toggle-btn"
            >
              <img
                src={toggleSidebar ? leftArrow : rightArrow}
                height={"12px"}
                alt="arrow-img"
              />
            </Box>
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              padding={"14px 0px 16px"}
              onClick={() => onTabClick("Summary")}
            >
              {toggleSidebar ? (
                <img
                  src={logoWithName}
                  alt="logo-with-name"
                  width={85}
                  height={62}
                />
              ) : (
                <img src={logo} alt="logo" width={40} height={36} />
              )}
            </Box>
            {menuList.map((item) => (
              <Box
                style={webStyle.tabStyle}
                className={
                  item.isDisabled
                    ? "disabledTab"
                    : activeTab.toLocaleLowerCase() ===
                      item.path.toLocaleLowerCase()
                    ? "activeTab"
                    : "tab"
                }
                key={item.tab}
                data-test-id="sidebar-tab"
                onClick={() => onTabClick(item.path)}
              >
                <Box>
                  <img
                    src={item.isDisabled ? item.disabledIcon : item.icon}
                    height={16}
                    width={16}
                    alt={item.tab}
                  />
                </Box>
                <Box
                  paddingLeft={"16px"}
                  display={toggleSidebar ? "block" : "none"}
                >
                  {item.tab}
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      ) : (
        <Box
          style={webStyle.appBar}
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Box>
            <img src={mobileLogo} alt="logo-img" width={42} height={40} />
          </Box>
          <Box>
            <IconButton
              size="small"
              onClick={() => setToggleMobileView(toggleMobileView)}
            >
              <MenuIcon style={webStyle.menuIcon} />
            </IconButton>
          </Box>
          <Drawer
            open={toggleMobileView}
            onClose={() => setToggleMobileView(toggleMobileView)}
            className={classes.drawerContainer}
          >
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
              padding={"16px 24px"}
              marginBottom={"24px"}
            >
              <Box>
                <img src={mobileLogo} alt="logo-img" width={42} height={40} />
              </Box>
              <Box>
                <IconButton
                  size="small"
                  onClick={() => setToggleMobileView(toggleMobileView)}
                >
                  <CloseIcon style={webStyle.menuIcon} />
                </IconButton>
              </Box>
            </Box>
            {menuList.map((sidebarItem) => (
              <Box
                style={webStyle.drawerTabStyle}
                className={
                  sidebarItem.isDisabled
                    ? "disabledTab"
                    : activeTab.toLocaleLowerCase() ===
                      sidebarItem.path.toLocaleLowerCase()
                    ? "activeTab"
                    : "tab"
                }
                onClick={() => onTabClick(sidebarItem.path)}
                key={sidebarItem.tab}
              >
                <Box>
                  <img
                    src={
                      sidebarItem.isDisabled
                        ? sidebarItem.disabledIcon
                        : sidebarItem.icon
                    }
                    alt={sidebarItem.tab}
                    width={16}
                    height={16}
                  />
                </Box>
                <Box display={"block"} paddingLeft={"16px"}>
                  {sidebarItem.tab}
                </Box>
              </Box>
            ))}
          </Drawer>
        </Box>
      )}
    </>
  );
};

const webStyle = {
  arrowContainer: {
    borderRadius: "50px",
    height: "24px",
    width: "24px",
    backgroundColor: "#F5F5F5",
    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.16)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    right: "-12px",
    top: "50%",
    cursor: "pointer",
    zIndex: 100,
  },
  tabStyle: {
    padding: "16px 32px",
    margin: "16px 0px",
    display: "flex",
    alignItems: "center",
    fontFamily: "Open Sans",
    fontWeight: 700,
    fontStyle: "normal",
    fontSize: "16px",
  },
  drawerTabStyle: {
    padding: "16px 32px",
    margin: "8px 0px",
    display: "flex",
    fontFamily: "Open Sans",
    alignItems: "center",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "16px",
  },
  appBar: {
    backgroundColor: "#002348",
    padding: "16px 24px",
  },
  menuIcon: {
    color: "#FFF",
  },
};

export default SidebarNavigation;
