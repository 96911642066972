import React from "react";
import {
  TextField,
  ThemeProvider,
  createTheme,
  makeStyles,
} from "@material-ui/core";

const updatedTheme = createTheme({
  overrides: {
    MuiInputBase: {
      input: {
        "&:-webkit-autofill": {
          WebkitTextFillColor: "black",
          WebkitBoxShadow: "0 0 0 1000px white inset",
        },
        "&:-internal-autofill-selected": {
          backgroundColor: "white !important",
          WebkitTextFillColor: "black !important",
          WebkitBoxShadow: "0 0 0 1000px white inset",
        },
      },
    },
  },
});

const useStyles = makeStyles(() => ({
  root: {
    "& .MuiOutlinedInput-root": {
      backgroundColor: "#FFF",
      borderRadius: "8px",
      "& .Mui-disabled": {
        color: "rgba(0,0,0,0.87)",
      },
      "& fieldset": {
        borderColor: "#CBD5E1 !important",
      },
      "& input::placeholder": {
        color: "#000",
        fontFamily: "Open Sans",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "24px",
      },
    },
    "& .MuiOutlinedInput-root.Mui-error": {
      "& fieldset": {
        borderColor: "#DC2626 !important",
      },
    },
    "& .MuiFormHelperText-contained": {
      marginLeft: "0px",
      fontFamily: "Open Sans",
    },
  },
}));

interface ITextFieldProps {
  value: string | number | undefined;
  placeholder?: string;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isError?: boolean;
  errorMessage?: string;
  isPasswordType?: boolean;
  disabled?: boolean;
}

const CustomInput: React.FC<ITextFieldProps> = ({
  value,
  handleChange,
  placeholder,
  isError,
  errorMessage,
  isPasswordType,
  disabled,
}) => {
  const classes = useStyles();

  return (
    <ThemeProvider theme={updatedTheme}>
      <TextField
        className={classes.root}
        fullWidth
        value={value}
        onChange={handleChange}
        variant="outlined"
        placeholder={placeholder}
        error={isError}
        type={isPasswordType ? "password" : "text"}
        helperText={errorMessage}
        InputLabelProps={{
          style: {
            color: "#000",
            fontFamily: "Open Sans",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "24px",
          },
        }}
        autoComplete="off"
        disabled={disabled}
      />
    </ThemeProvider>
  );
};

export default CustomInput;
