import React from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import CustomButton from "./CustomButton.web";

import { makeStyles } from "@material-ui/core/styles";
const logo = require("../src/logo-landing.svg");
const background = require("../src/background-landing.svg");
const backgroundMedium = require("../src/background-medium.svg");
const backgroundSmall = require("../src/background-small.svg");
const trueIcon = require("../src/true-icon.svg");

const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.up("xs")]: {
      backgroundImage: `url(${backgroundSmall})`,
      padding: "25px 16px",
    },
    [theme.breakpoints.up("sm")]: {
      backgroundImage: `url(${backgroundMedium})`,
      padding: "20px 40px",
    },
    [theme.breakpoints.up("md")]: {
      backgroundImage: `url(${background})`,
      padding: "20px 40px",
    },
    "@media (min-width:1280px)": {
      backgroundImage: `url(${background})`,
      padding: "48px 80px",
    },
    "@media (min-width:1440px)": {
      backgroundImage: `url(${background})`,
      padding: "48px 160px",
    },
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundColor: "#001327",
    minHeight: "calc(100vh - 96px)",
  },
  logo: {
    [theme.breakpoints.up("xs")]: {
      justifyContent: "center",
    },
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
    },
    [theme.breakpoints.up("md")]: {
      justifyContent: "flex-start",
    },
  },
  logoImg: {
    [theme.breakpoints.up("xs")]: {
      height: "120px",
    },
    [theme.breakpoints.up("sm")]: {
      height: "150px",
    },
    [theme.breakpoints.up("lg")]: {
      height: "182px",
    },
  },
  title: {
    color: "#FFF",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: 700,
    [theme.breakpoints.down("sm")]: {
      fontSize: "24px",
      lineHeight: 1.33,
      letterSpacing: -0.12,
      textAlign: "center",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "36px",
      lineHeight: 1.22,
      letterSpacing: -0.36,
      textAlign: "left",
    },
  },
  subTitle: {
    color: "#FFF",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: 400,
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
      lineHeight: 1.5,
      textAlign: "center",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "18px",
      lineHeight: 1.44,
      textAlign: "left",
    },
  },
  btnContainer: {
    [theme.breakpoints.up("xs")]: {
      justifyContent: "center",
    },
    [theme.breakpoints.up("sm")]: {
      justifyContent: "center",
    },
    [theme.breakpoints.up("md")]: {
      justifyContent: "flex-start",
    },
  },
  videoContainer: {
    [theme.breakpoints.up("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.up("md")]: {
      width: "100%",
    },
  },
  videoImg: {
    [theme.breakpoints.up("md")]: {
      width: "550px",
      height: "446px",
      objectFit: "cover",
      aspectRatio: 1.77,
      borderRadius: "12px",
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
      height: "auto",
      objectFit: "cover",
      aspectRatio: 2,
      borderRadius: "12px",
    },
  },
  premiumContainer: {
    [theme.breakpoints.up("xs")]: {
      width: "100%",
    },
    [theme.breakpoints.up("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.up("md")]: {
      width: "100%",
    },
    [theme.breakpoints.up("lg")]: {
      width: "550px",
    },
  },
  premiumBenefitsContainer: {
    [theme.breakpoints.up("xs")]: {
      padding: "2px 6px",
    },
    [theme.breakpoints.up("sm")]: {
      padding: "24px 10px",
    },
    [theme.breakpoints.up("md")]: {
      padding: "40px 24px",
    },
  },
  titleContainerGrid: {
    width: "100%",
    order: 1,
  },
  videoContainerGrid: {
    order: 2,
  },
  videoContainerBox: {
    [theme.breakpoints.up("lg")]: {
      display: "flex",
      height: "100%",
      justifyContent: "center",
      alignItems: "center",
    },
    [theme.breakpoints.down("md")]: {
      paddingTop: "32px",
    },
  },
  premiumContainerGrid: {
    width: "100%",
    [theme.breakpoints.up("xs")]: {
      order: 4,
    },
    [theme.breakpoints.up("sm")]: {
      order: 4,
    },
    [theme.breakpoints.up("md")]: {
      order: 4,
      paddingTop: "50px !important",
    },
    [theme.breakpoints.up("lg")]: {
      order: 3,
      paddingTop: "50px !important",
    },
  },
  subscriptionsContainerGrid: {
    [theme.breakpoints.up("xs")]: {
      order: 3,
    },
    [theme.breakpoints.up("sm")]: {
      order: 3,
    },
    [theme.breakpoints.up("md")]: {
      order: 3,
    },
    [theme.breakpoints.up("lg")]: {
      order: 4,
    },
  },
  subscriptionsContainer: {
    [theme.breakpoints.up("lg")]: {
      display: "flex",
      height: "100%",
      justifyContent: "center",
      alignItems: "center",
      paddingLeft: "16px",
      maxWidth: "550px",
    },
    [theme.breakpoints.down("md")]: {
      paddingTop: "32px",
      paddingBottom: "32px",
    },
  },
  premiumLabel: {
    background: "linear-gradient(99deg, #01AA7F 2.64%, #00AED2 100%)",
    backgroundClip: "text",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    fontFamily: "Open Sans",
    fontSize: "36px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: 1.22,
    letterSpacing: -0.36,
  },
  price: {
    color: "#FFF",
    fontFamily: "Open Sans",
    fontSize: "48px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: 1.16,
    letterSpacing: -0.72,
  },
  monthly: {
    color: "#78716C",
    fontFamily: "Open Sans",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: 1.5,
  },
  benefit: {
    color: "#FFF",
    fontFamily: "Open Sans",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: 1.57,
    paddingLeft: "16px",
  },
}));
interface ILandingPageContainerProps {
  onLogin: () => void;
  onSubscribe: () => void;
  videoUrl: string;
}

const premiumPlansBenefits = [
  "Upload chart of accounts and vendors",
  "Identify your prepaid accounts",
  "Work and generate your prepaid details and let Amortize do the rest",
  "Save time and resources by auto generating a monthly journal entry",
  "Roll forward summary reconciliations and gain quick insights to monthly activities",
  "Feel confident in your data and be in control of your Prepaid Module!",
];

const LandingPageContainer: React.FC<ILandingPageContainerProps> = (
  props: ILandingPageContainerProps
) => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Grid container spacing={2}>
        <Grid item lg={6} md={12} className={classes.titleContainerGrid}>
          <Box display="flex" className={classes.logo}>
            <img src={logo} alt="logo" className={classes.logoImg} />
          </Box>
          <Box paddingTop={"32px"}>
            <Typography className={classes.title} data-test-id="title-text">
              Your prepaid accounting module
            </Typography>
          </Box>
          <Box paddingTop={"16px"} paddingBottom={"32px"}>
            <Typography className={classes.subTitle}>
              Amortize is THE stand alone Prepaid Module for ALL accounting
              departments.
            </Typography>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            className={classes.btnContainer}
          >
            <Box paddingRight={"24px"}>
              <CustomButton
                variant="outlined"
                label="Login"
                onClick={props.onLogin}
              />
            </Box>
            <CustomButton
              variant="contained"
              label="Subscribe now"
              onClick={props.onSubscribe}
            />
          </Box>
        </Grid>
        <Grid item lg={6} md={12} className={classes.videoContainerGrid}>
          <Box className={classes.videoContainerBox}>
            <Box className={classes.videoContainer}>
              {props.videoUrl ? (
                <video
                  src={props.videoUrl}
                  controls
                  autoPlay={false}
                  className={classes.videoImg}
                  preload="auto"
                  playsInline
                >
                  <track kind="captions" src="" srcLang="en" label="English" />
                </video>
              ) : (
                <Skeleton variant="rect" className={classes.videoImg} />
              )}
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          lg={6}
          md={12}
          className={classes.subscriptionsContainerGrid}
        >
          <Box className={classes.subscriptionsContainer}>
            <Box>
              <Box>
                <Typography className={classes.title}>
                  Our monthly subscriptions
                </Typography>
              </Box>
              <Box paddingTop={"16px"} paddingBottom={"32px"}>
                <Typography className={classes.subTitle}>
                  Amortize provides a cost efficient solution to accounting
                  departments wanting to simplify their month end process. New
                  ERP implementations can cost an accounting department too much
                  time and money. Amortize allows you to be up and running in
                  minutes! Summary reconciliations and monthly Journal Entries
                  pull from your detail with accuracy and efficiency so you have
                  Data you can rely on!
                </Typography>
              </Box>
              <Box
                display="flex"
                alignItems="center"
                className={classes.btnContainer}
              >
                <Box paddingRight={"24px"}>
                  <CustomButton
                    variant="outlined"
                    label="Login"
                    onClick={props.onLogin}
                  />
                </Box>
                <CustomButton
                  variant="contained"
                  label="Subscribe now"
                  onClick={props.onSubscribe}
                />
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item lg={6} md={12} className={classes.premiumContainerGrid}>
          <Box
            border="2px solid #FFF"
            borderRadius="16px"
            className={classes.premiumContainer}
            padding={"16px 0px"}
          >
            <Box display="flex" justifyContent="center">
              <Box textAlign="center">
                <Typography component="div" className={classes.premiumLabel}>
                  Premium
                </Typography>
                <Typography component="div" className={classes.price}>
                  $100
                </Typography>
                <Typography component="div" className={classes.monthly}>
                  monthly
                </Typography>
              </Box>
            </Box>
            <Box className={classes.premiumBenefitsContainer}>
              {premiumPlansBenefits.map((benefit: string) => (
                <Box
                  key={benefit}
                  display="flex"
                  alignItems="center"
                  padding={"5px 0px"}
                >
                  <img src={trueIcon} alt="true" height={24} />
                  <Typography className={classes.benefit}>{benefit}</Typography>
                </Box>
              ))}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default LandingPageContainer;
