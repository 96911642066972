import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import {
  getStorageData,
  removeStorageData,
} from "../../../framework/src/Utilities";
import moment from "moment";
import { IDetailedResponseData } from "./DetailsController.web";
import {
  handleDetailedTableSort,
  downloadFile,
  extractFileNameFromUrl,
  getAmortizationEnd,
  isValidDate,
  decimalRegex,
  alphaNumericRegex,
  isAmortizationStartValid,
} from "../../../components/src/Utilities";

export const configJSON = require("./config");

interface IDetailedTableColumn {
  name: string;
  isRequired: boolean;
  showSorting: boolean;
  key: keyof IDetailedTableData;
}

export interface IDetailedTableData {
  id?: number;
  images: { name: string; url: string | File | null; id: number }[];
  vendor_name_id: number;
  vendor_name: string;
  prepaid_account_identifier_id: number;
  prepaid_account_identifier: string;
  invoice_date: string;
  invoice_number: string;
  invoice_description: string;
  check: string;
  amortization_period: string;
  amortization_start: string;
  amortization_end: string;
  expense_account: string;
  exp_account_id: number;
  total_amount: string;
  expense_amount: string;
  remaining_balance: number;
  notes: string;
  account_id: number;
  time_of_creation: string;
  edit?: boolean;
  error?: string[];
}

export interface IIdentifiers {
  id: string;
  attributes: {
    prepaid_account_identifier: string;
    summ_attached?: boolean;
  };
}

export interface IVendors {
  id: string;
  attributes: {
    vendor_name: string;
  };
}

export interface IExpenseAccounts {
  id: string;
  attributes: {
    exp_account: string;
  };
}

export interface Props {
  tableWidth: number;
  detailedSheetData: IDetailedTableData[];
  toggleLoader: (isLoad: boolean) => void;
  backToDetailedUpload: () => void;
}

interface S {
  searchValue: string;
  detailedSheetColumns: IDetailedTableColumn[];
  detailedSheetData: IDetailedTableData[];
  activeMonth: string;
  monthList: { name: string; value: string; isLocked: boolean }[];
  vendorList: { name: string; value: string }[];
  prepaidAccountIdentifierList: {
    name: string;
    value: string;
    summ_attached?: boolean;
  }[];
  expenseAccountList: { name: string; value: string }[];
  isOpenDeleteMonthDataDialog: boolean;
  deletedAttachmentRecord?: { rowId: number; imageId: number };
  fileUploadRowId?: number;
  isOpenUnlockDialog: boolean;
  isOpenDiscardWarningDialog: boolean;
  activeEditObject: IDetailedTableData | null;
  isOpenNotesDialog: boolean;
  notesValue: string;
  activeVendorObject?: { name: string; value: string };
  activeExpenseObject?: { name: string; value: string };
  activeIdentifierObject?: { name: string; value: string };
  sortedColumn: { name: string; isAsc: boolean };
  snackbarErrorMsg: string;
}

interface SS {}

export default class DetailsTableViewController extends BlockComponent<
  Props,
  S,
  SS
> {
  getVendorListApiId: string = "";
  getIdentifiersListApiId: string = "";
  getMonthlySheetDataApiId: string = "";
  deleteMonthDataApiId: string = "";
  updateDetailsTableRowApiId: string = "";
  isFromScratch: boolean = false;
  downloadMonthDataApiId: string = "";
  getSearchedDetailsApiId: string = "";
  deleteImageApiId: string = "";
  uploadImageApiId: string = "";
  deleteVendorOptionApiId: string = "";
  deleteIdentifierOptionApiId: string = "";
  addEditIdentifierApiId: string = "";
  addEditVendorApiId: string = "";
  getMonthListApiId: string = "";
  downloadMonthDataPdfApiId: string = "";
  getExpenseAccountApiId: string = "";
  deleteExpenseAccountOptionApiId: string = "";
  addEditExpenseAccountOptionApiId: string = "";
  updateInlineEditRowApiId: string = "";

  constructor(props: Props) {
    super(props);

    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    this.state = {
      searchValue: "",
      detailedSheetColumns: configJSON.detailedSheetColumns,
      detailedSheetData: [],
      activeMonth: "",
      monthList: [],
      vendorList: [],
      prepaidAccountIdentifierList: [],
      expenseAccountList: [],
      isOpenDeleteMonthDataDialog: false,
      isOpenUnlockDialog: false,
      isOpenDiscardWarningDialog: false,
      activeEditObject: null,
      isOpenNotesDialog: false,
      notesValue: "",
      sortedColumn: { name: "", isAsc: false },
      snackbarErrorMsg: "",
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (
      responseJson?.errors &&
      responseJson.errors?.length > 0 &&
      responseJson.errors[0]?.token
    ) {
      this.navigateToHome();
    }

    if (
      apiRequestCallId === this.getIdentifiersListApiId ||
      apiRequestCallId === this.getVendorListApiId ||
      apiRequestCallId === this.getMonthlySheetDataApiId ||
      apiRequestCallId === this.getSearchedDetailsApiId ||
      apiRequestCallId === this.getExpenseAccountApiId
    ) {
      this.setVendorAndIdentifierListResponse(apiRequestCallId, responseJson);
    }

    if (
      apiRequestCallId === this.updateDetailsTableRowApiId ||
      apiRequestCallId === this.updateInlineEditRowApiId ||
      apiRequestCallId === this.uploadImageApiId
    ) {
      this.setUpdateRowDataResponse(responseJson, apiRequestCallId);
    }

    if (
      apiRequestCallId === this.downloadMonthDataApiId ||
      this.downloadMonthDataPdfApiId
    ) {
      this.props.toggleLoader(false);
      this.setDownloadMonthDataResponse(responseJson);
    }

    if (
      apiRequestCallId === this.deleteImageApiId ||
      apiRequestCallId === this.deleteMonthDataApiId
    ) {
      this.setDeleteMonthDataAndAttachmentResponse(
        apiRequestCallId,
        responseJson
      );
    }

    if (
      apiRequestCallId === this.deleteVendorOptionApiId ||
      apiRequestCallId === this.addEditVendorApiId
    ) {
      this.getVendorList();
    }

    if (
      apiRequestCallId === this.deleteIdentifierOptionApiId ||
      apiRequestCallId === this.addEditIdentifierApiId
    ) {
      this.getIdentifierList();
    }

    if (
      apiRequestCallId === this.deleteExpenseAccountOptionApiId ||
      apiRequestCallId === this.addEditExpenseAccountOptionApiId
    ) {
      this.getExpenseAccountList();
    }

    if (apiRequestCallId === this.getMonthListApiId) {
      this.setMonthsResponse(responseJson);
    }
  }

  componentDidMount = async () => {
    this.isFromScratch = (await getStorageData("startFromScratch")) || false;
    this.getIdentifierList();
    this.getVendorList();
    this.getExpenseAccountList();
    this.getMonthList();

    this.setState({
      detailedSheetData: this.props.detailedSheetData,
    });
  };

  setVendorAndIdentifierListResponse = (
    apiRequestCallId: string,
    responseJson: {
      data:
        | IVendors[]
        | IIdentifiers[]
        | IDetailedResponseData[]
        | IExpenseAccounts[];
    }
  ) => {
    if (apiRequestCallId === this.getVendorListApiId) {
      this.setVendorListResponse(responseJson as { data: IVendors[] });
    } else if (apiRequestCallId === this.getIdentifiersListApiId) {
      this.setIdentifierListResponse(responseJson as { data: IIdentifiers[] });
    } else if (apiRequestCallId === this.getExpenseAccountApiId) {
      this.setExpenseAccountListResponse(
        responseJson as { data: IExpenseAccounts[] }
      );
    } else {
      this.setMonthlySheetDataResponse(
        responseJson as { data: IDetailedResponseData[] }
      );
    }
  };

  setDeleteMonthDataAndAttachmentResponse = (
    apiRequestCallId: string,
    responseJson: { message: string }
  ) => {
    if (apiRequestCallId === this.deleteImageApiId) {
      this.setDeleteAttachmentResponse(responseJson);
    } else {
      this.setDeleteMonthDataResponse(responseJson);
    }
  };

  setMonthsResponse = async (responseJson: {
    months?: string[];
    message?: string;
  }) => {
    this.props.toggleLoader(false);
    const currentDateOb = moment();
    const updatedMonths = responseJson?.months?.filter((month) =>
      moment(month, "MMMM YYYY").isSameOrBefore(currentDateOb)
    );
    if (updatedMonths && updatedMonths?.length > 0) {
      let isMonthListSame: boolean;
      if (updatedMonths.length !== this.state.monthList.length) {
        isMonthListSame = false;
      } else {
        isMonthListSame = updatedMonths.every(
          (value, index) =>
            value === this.state.monthList.map((object) => object.value)[index]
        );
      }

      const pastMonthsData: {
        name: string;
        value: string;
        isLocked: boolean;
      }[] = [];
      updatedMonths.forEach((object) => {
        const monthName = moment(object, "MMMM YYYY").format("MMM ´YY");
        pastMonthsData.push({
          name: monthName,
          value: object,
          isLocked: moment().format("MMMM YYYY") !== object,
        });
      });
      const activeMonthOb = pastMonthsData.find(
        (object) => object.name === this.state.activeMonth
      );
      const activeMonthVal =
        activeMonthOb?.name || pastMonthsData[pastMonthsData.length - 1].name;
      this.setState(
        {
          activeEditObject: null,
          monthList: isMonthListSame ? this.state.monthList : pastMonthsData,
          activeMonth: activeMonthVal,
        },
        () => {
          this.getActiveMonthSheetData();
        }
      );
    } else if (this.isFromScratch) {
      const cMonth = moment().format("MMMM YYYY");
      const cMonthName = moment().format("MMM ´YY");
      this.setState({
        monthList: [{ name: cMonthName, value: cMonth, isLocked: false }],
        activeMonth: cMonthName,
        activeEditObject: null,
      });
      await removeStorageData("startFromScratch");
      this.isFromScratch = false;
    } else if (
      responseJson?.message === configJSON.noMonthsDataMessage ||
      !updatedMonths ||
      !updatedMonths.length
    ) {
      this.setState({
        monthList: [],
        activeMonth: "",
        activeEditObject: null,
        detailedSheetData: [],
      });
      this.props.backToDetailedUpload();
    }
  };

  setUploadImageResponse = (responseJson: { meta: { message: string } }) => {
    if (responseJson?.meta?.message === configJSON.updateSuccessResponse) {
      this.getActiveMonthSheetData();
    }
  };

  setMonthlySheetDataResponse = (responseJson: {
    data: IDetailedResponseData[];
  }) => {
    this.props.toggleLoader(false);
    let detailedData: IDetailedTableData[] = [];
    let updatedObject = this.state.activeEditObject;
    if (responseJson?.data && responseJson.data?.length > 0) {
      detailedData = responseJson.data.map(
        (item: IDetailedResponseData) => item.attributes
      );
      if (this.state.fileUploadRowId || this.state.activeEditObject?.id) {
        const updatedIndex = detailedData.findIndex(
          (object) =>
            object.id === this.state.fileUploadRowId ||
            object.id === this.state.activeEditObject?.id
        );
        if (updatedIndex > -1) {
          updatedObject = detailedData[updatedIndex];
          updatedObject.edit = true;
          detailedData.splice(updatedIndex, 1, updatedObject);
        }
      }
    }
    this.setState({
      detailedSheetData: detailedData,
      fileUploadRowId: undefined,
      activeEditObject: updatedObject,
    });
  };

  setDeleteAttachmentResponse = (responseJson: { message: string }) => {
    if (responseJson?.message === configJSON.deleteAttachmentSuccessMsg) {
      const detailedData = this.state.detailedSheetData;
      const deletedItems = this.state.deletedAttachmentRecord;
      if (deletedItems?.imageId && deletedItems?.rowId) {
        const dataIndex = detailedData.findIndex(
          (object) => object.id === deletedItems.rowId
        );
        if (dataIndex > -1) {
          const dataObjIndex = detailedData[dataIndex]?.images.findIndex(
            (item) => item.id === deletedItems.imageId
          );
          if (dataObjIndex > -1) {
            detailedData[dataIndex].images.splice(dataObjIndex, 1);
          }
        }
      }
      this.setState({
        detailedSheetData: detailedData,
        deletedAttachmentRecord: undefined,
      });
    }
  };

  setDownloadMonthDataResponse = (responseJson: {
    data: { attributes: { document: string } };
  }) => {
    const fileUrl = responseJson?.data?.attributes?.document;
    const fileName = extractFileNameFromUrl(
      responseJson?.data?.attributes?.document
    );
    if (fileName) {
      downloadFile(fileUrl, fileName);
    }
  };

  setDeleteMonthDataResponse = (responseJson: { message: string }) => {
    if (
      responseJson?.message &&
      responseJson.message === configJSON.deleteMonthDataResponse
    ) {
      this.setState({ isOpenDeleteMonthDataDialog: false });
      this.getMonthList();
    }
  };

  getActiveMonthSheetData = () => {
    const newMonth = this.state.monthList.find(
      (item) => item.name === this.state.activeMonth
    )?.value;
    if (newMonth) {
      this.getDetailedSheetMonthlyData(newMonth);
    }
  };

  handleCloseSnackbar = () => {
    this.setState({ snackbarErrorMsg: "" });
  };

  toggleLockMonth = () => {
    let updatedMonthListData = this.state.monthList;
    const monthInd = updatedMonthListData.findIndex(
      (object) => object.name === this.state.activeMonth
    );
    if (monthInd > -1) {
      updatedMonthListData[monthInd].isLocked = false;
      this.setState({
        monthList: updatedMonthListData,
        isOpenUnlockDialog: false,
      });
    }
  };

  handleChangeNotes = (eventObject: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ notesValue: eventObject.target.value });
  };

  toggleDiscardEditDialog = () => {
    this.setState({
      isOpenDiscardWarningDialog: !this.state.isOpenDiscardWarningDialog,
    });
  };

  setUpdateRowDataResponse = (
    responseJson: {
      meta: { message: string };
      data?: IDetailedResponseData;
      errors?: string;
    },
    apiRequestCallId: string
  ) => {
    if (responseJson?.errors && typeof responseJson.errors === "string") {
      this.setErrorResponseOnSave(
        `${configJSON.recordsNotSaved} ${responseJson.errors}`
      );
    } else if (apiRequestCallId === this.uploadImageApiId) {
      this.setUploadImageResponse(responseJson);
    } else if (
      responseJson?.meta?.message === configJSON.updateSuccessResponse ||
      responseJson?.meta?.message === configJSON.createSuccessResponse
    ) {
      if (
        apiRequestCallId === this.updateInlineEditRowApiId &&
        responseJson?.data?.attributes
      ) {
        const savedObject = responseJson?.data?.attributes;
        const sheetDataList: IDetailedTableData[] = JSON.parse(
          JSON.stringify(this.state.detailedSheetData)
        );
        let dataIndex = -1;
        if (responseJson.meta.message === configJSON.updateSuccessResponse) {
          dataIndex = sheetDataList.findIndex(
            (object: IDetailedTableData) => object.id === savedObject?.id
          );
          if (dataIndex > -1) {
            sheetDataList.splice(dataIndex, 1, { ...savedObject, edit: true });
          }
        } else {
          sheetDataList.splice(sheetDataList.length - 1, 1, {
            ...savedObject,
            edit: true,
          });
        }
        this.setState({
          activeEditObject: savedObject,
          detailedSheetData: sheetDataList,
        });
      } else {
        this.getMonthList();
      }
    }
    this.props.toggleLoader(false);
  };

  setErrorResponseOnSave = (errorMsg: string) => {
    this.props.toggleLoader(false);
    const editableActiveObject = this.state.activeEditObject;
    const sheetDataObjects = this.state.detailedSheetData;
    const objectIndex = sheetDataObjects.findIndex((object) => object?.edit);
    if (objectIndex > -1 && editableActiveObject?.id) {
      sheetDataObjects.splice(objectIndex, 1, {
        ...editableActiveObject,
        edit: false,
      });
      this.setState({
        detailedSheetData: sheetDataObjects,
        activeEditObject: null,
        snackbarErrorMsg: errorMsg,
      });
    }
  };

  setVendorListResponse = (responseJson: { data: IVendors[] }) => {
    const sheetData: IDetailedTableData[] = [...this.state.detailedSheetData];
    let updatedSheet = sheetData;
    updatedSheet = sheetData.map((item) => ({
      ...item,
      vendor_name:
        this.state.activeVendorObject?.value ===
        item?.vendor_name_id?.toString()
          ? this.state.activeVendorObject?.name
          : item?.vendor_name,
    }));
    if (responseJson?.data && responseJson.data.length > 0) {
      this.setState({
        vendorList: responseJson.data.map((item: IVendors) => ({
          name: item.attributes.vendor_name,
          value: item.id,
        })),
        detailedSheetData: updatedSheet,
        activeVendorObject: undefined,
      });
    } else {
      this.setState({ vendorList: [] });
    }
  };

  setIdentifierListResponse = (responseJson: { data: IIdentifiers[] }) => {
    const sheetDataObjectList: IDetailedTableData[] = [...this.state.detailedSheetData];
    let updatedSheet = sheetDataObjectList;
    updatedSheet = sheetDataObjectList.map((item) => ({
      ...item,
      prepaid_account_identifier:
        this.state.activeIdentifierObject?.value ===
        item?.prepaid_account_identifier_id?.toString()
          ? this.state.activeIdentifierObject?.name
          : item?.prepaid_account_identifier,
    }));
    if (responseJson?.data && responseJson.data.length > 0) {
      this.setState({
        prepaidAccountIdentifierList: responseJson.data.map(
          (object: IIdentifiers) => ({
            name: object.attributes.prepaid_account_identifier,
            value: object.id,
            summ_attached: object.attributes.summ_attached,
          })
        ),
        detailedSheetData: updatedSheet,
        activeIdentifierObject: undefined,
      });
    } else {
      this.setState({ prepaidAccountIdentifierList: [] });
    }
  };

  setExpenseAccountListResponse = (responseJson: {
    data: IExpenseAccounts[];
  }) => {
    const sheetDataList = [...this.state.detailedSheetData];
    let updatedSheet = sheetDataList;
    updatedSheet = sheetDataList.map((item) => ({
      ...item,
      expense_account:
        this.state.activeExpenseObject?.value ===
        item?.exp_account_id?.toString()
          ? this.state.activeExpenseObject?.name
          : item?.expense_account,
    }));
    if (responseJson?.data && responseJson.data.length > 0) {
      this.setState({
        expenseAccountList: responseJson.data.map((item: IExpenseAccounts) => ({
          name: item.attributes.exp_account,
          value: item.id,
        })),
        detailedSheetData: updatedSheet,
        activeExpenseObject: undefined,
      });
    } else {
      this.setState({ expenseAccountList: [] });
    }
  };

  handleSearchInDetailedTable = (
    eventOb: React.ChangeEvent<HTMLInputElement>
  ) => {
    const detailedDataSheet = this.state.detailedSheetData;
    const dataIndex = detailedDataSheet.findIndex((item) => item.edit);

    if (dataIndex > -1) {
      let dataObject = detailedDataSheet[dataIndex];
      const newDataObject = this.setDropdownErrors(dataObject);
      if (newDataObject.error?.length > 0) {
        this.toggleDiscardEditDialog();
        return;
      } else {
        detailedDataSheet.splice(dataIndex, 1, {
          ...newDataObject,
          edit: false,
        });
        this.setState({
          detailedSheetData: detailedDataSheet,
          activeEditObject: null,
          fileUploadRowId: undefined,
        });
      }
    }
    this.setState({ searchValue: eventOb.target.value }, () => {
      const monthValue = this.getActiveMonth();
      this.getSearchedMonthlyDetailedSheetData(
        this.state.searchValue,
        monthValue
      );
    });
  };

  deleteDropdownOption = (option: string, params: keyof IDetailedTableData) => {
    if (params === "vendor_name") {
      this.deleteVendorOption(option);
    } else if (params === "prepaid_account_identifier") {
      this.deleteIdentifierOption(option);
    } else {
      this.deleteExpenseAccount(option);
    }
  };

  toggleLockMonthDialog = () => {
    this.setState({ isOpenUnlockDialog: !this.state.isOpenUnlockDialog });
  };

  updateTableDropdownOption = (
    value: string,
    optionId: string,
    params: keyof IDetailedTableData
  ) => {
    if (params === "vendor_name") {
      this.addEditVendor(value, optionId);
    } else if (params === "prepaid_account_identifier") {
      this.addEditIdentifier(value, optionId);
    } else {
      this.addEditExpenseAccount(value, optionId);
    }
  };

  getActiveMonth = () => {
    const activeMonthOb = this.state.monthList.find(
      (item) => item.name === this.state.activeMonth
    );
    let monthVal = "";
    if (activeMonthOb) {
      monthVal = activeMonthOb.value;
    }
    return monthVal;
  };

  onClearSearchValue = () => {
    this.setState({ searchValue: "" }, () => {
      const monthValue = this.getActiveMonth();
      this.getSearchedMonthlyDetailedSheetData("", monthValue);
    });
  };

  onOpenFileInput = (rowId: number) => {
    const fileInput = document.getElementById(`fileInput-${rowId}`);
    fileInput?.click();
  };

  handleUpload = async (
    event: React.ChangeEvent<HTMLInputElement>,
    rowId: number
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      if (rowId) {
        this.setState({ fileUploadRowId: rowId });
        const headers = {
          "Content-Type": undefined,
          token: await getStorageData("token"),
        };

        const rowFormData = new FormData();
        rowFormData.append("images[]", file);

        const uploadImageRequestMsg = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        this.uploadImageApiId = uploadImageRequestMsg.messageId;

        uploadImageRequestMsg.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.updateRowDetailsApiEndPoint + rowId
        );

        uploadImageRequestMsg.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(headers)
        );
        uploadImageRequestMsg.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.putMethod
        );

        uploadImageRequestMsg.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          rowFormData
        );

        runEngine.sendMessage(uploadImageRequestMsg.id, uploadImageRequestMsg);
      } else {
        const detailedData = this.state.detailedSheetData;
        const newRowDataIndex = detailedData.findIndex((object) => !object.id);
        if (newRowDataIndex > -1) {
          const newDataObject = detailedData[newRowDataIndex];
          newDataObject.images.push({
            id: new Date().getTime(),
            url: file,
            name: file.name,
          });
          detailedData.splice(newRowDataIndex, 1, newDataObject);
          this.setState({ detailedSheetData: detailedData });
        }
      }
    }
  };

  handleUpdateTableCellValue = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    param: keyof IDetailedTableData,
    rowId: number
  ) => {
    const detailedData = this.state.detailedSheetData;
    const dataObjectIndex = detailedData.findIndex((item) => item.id === rowId);
    const value = this.setSplitValue(event.target.value, param);

    if (dataObjectIndex > -1) {
      let dataObject = detailedData[dataObjectIndex];
      let error = dataObject.error ?? [];

      let amortizationEnd = dataObject.amortization_end;
      switch (param) {
        case "amortization_period":
          error = this.setErrorObject(
            error,
            param,
            value && Number(value) > 0 && configJSON.numberRegex.test(value)
          );
          if (
            value &&
            configJSON.monthAndYearRegex.test(dataObject.amortization_start) &&
            dataObject.amortization_start &&
            configJSON.numberRegex.test(value)
          ) {
            amortizationEnd = getAmortizationEnd(
              dataObject.amortization_start,
              Number(value)
            );
          } else {
            amortizationEnd = "";
          }
          break;
        case "amortization_start":
          error = this.setErrorObject(
            error,
            param,
            isAmortizationStartValid(value, this.state.activeMonth)
          );
          if (
            value &&
            isAmortizationStartValid(value, this.state.activeMonth) &&
            dataObject.amortization_period &&
            configJSON.numberRegex.test(dataObject.amortization_period)
          ) {
            amortizationEnd = getAmortizationEnd(
              value,
              Number(dataObject.amortization_period)
            );
          } else {
            amortizationEnd = "";
          }
          break;
        case "invoice_number":
          error = this.setErrorObject(
            error,
            param,
            !!value && alphaNumericRegex.test(value)
          );
          break;
        case "expense_amount":
        case "total_amount":
          error = this.setErrorObject(
            error,
            param,
            !!value && decimalRegex.test(value)
          );
          break;
        case "invoice_description":
          error = this.setErrorObject(error, param, value.trim().length > 0);
          break;
        case "invoice_date":
          error = this.setErrorObject(error, param, isValidDate(value));
          break;
        case "check":
          error = this.setErrorObject(
            error,
            param,
            (value && configJSON.numberRegex.test(value)) || !value.trim()
          );
          break;
        default:
          break;
      }

      dataObject = {
        ...dataObject,
        [param]: value,
        error,
        amortization_end: amortizationEnd,
      };
      detailedData[dataObjectIndex] = dataObject;
      this.setState({ detailedSheetData: detailedData });
    }
  };

  setErrorObject = (
    error: string[],
    errorMsg: string,
    testCondition: boolean
  ) => {
    if (testCondition) {
      error = error.filter((item) => item !== errorMsg);
    } else {
      if (!error.includes(errorMsg)) {
        error.push(errorMsg);
      }
    }
    return error;
  };

  setSplitValue = (value: string, param: keyof IDetailedTableData) => {
    let splitValue = value;
    if (param === "expense_amount" || param === "total_amount") {
      splitValue = value.includes("$") ? value?.split("$")[1] : value;
    }
    return splitValue;
  };

  handleSaveTableCellValue = (rowId: number) => {
    const detailedData = this.state.detailedSheetData;
    const dataIndex = detailedData.findIndex((item, index) => index === rowId);

    if (dataIndex > -1) {
      let dataObject = detailedData[dataIndex];
      const newDataObject = this.setDropdownErrors(dataObject);
      const sheetData = this.state.detailedSheetData;
      sheetData.splice(dataIndex, 1, newDataObject);
      this.setState({ detailedSheetData: sheetData }, () => {
        if (!newDataObject.error?.length) {
          const editableObjectData = JSON.parse(
            JSON.stringify(this.state.activeEditObject)
          );
          if (editableObjectData?.id) {
            const updatedObject = detailedData.find(
              (item) => item.id === editableObjectData.id
            );
            if (this.checkOpenNotesDialog(editableObjectData, updatedObject)) {
              this.setState({ isOpenNotesDialog: true, notesValue: "" });
              return;
            } else {
              this.updateTableRowDetails(newDataObject, true);
            }
          } else {
            this.updateTableRowDetails(newDataObject, true);
          }
        }
      });
    }
  };

  checkOpenNotesDialog = (
    prevObject?: IDetailedTableData,
    currentObject?: IDetailedTableData
  ) => {
    return (
      prevObject &&
      (Number(prevObject?.expense_amount) !==
        Number(currentObject?.expense_amount) ||
        Number(prevObject?.amortization_period) !==
          Number(currentObject?.amortization_period) ||
        Number(prevObject?.total_amount) !==
          Number(currentObject?.total_amount)) &&
      (!prevObject?.notes || prevObject?.notes === currentObject?.notes)
    );
  };

  checkIsMonthLocked = () => {
    let isLocked: boolean = false;
    const monthObjectData = this.state.monthList.find(
      (month) => month.name === this.state.activeMonth
    );
    if (monthObjectData) {
      isLocked = monthObjectData.isLocked;
    }
    return isLocked;
  };

  checkForDisabledCalculatedField = () => {
    const providedDate = moment(this.state.activeMonth, "MMM ´YY");
    const currentMonth = moment().month();
    const currentYear = moment().year();
    return (
      providedDate.month() === currentMonth &&
      providedDate.year() === currentYear
    );
  };

  handleUpdateDropdownValue = (
    value: string,
    valueId: string,
    param: keyof IDetailedTableData,
    paramId: keyof IDetailedTableData,
    dataId: number
  ) => {
    const detailedData = this.state.detailedSheetData;
    const dataIndex = detailedData.findIndex((item) => item.id === dataId);
    if (dataIndex > -1) {
      let dataObject = detailedData[dataIndex];
      let updatedErrors: string[] = [];
      if (dataObject.error) {
        updatedErrors = dataObject.error.filter((item) => item !== param);
      }
      dataObject = {
        ...dataObject,
        [param]: value,
        [paramId]: valueId,
        error: updatedErrors,
      };
      detailedData.splice(dataIndex, 1, dataObject);
      this.setState({ detailedSheetData: detailedData });
      if (!dataObject.error?.length) {
        const editableObjectData = JSON.parse(
          JSON.stringify(this.state.activeEditObject)
        );
        if (editableObjectData?.id) {
          const updatedObject = detailedData.find(
            (item) => item.id === editableObjectData.id
          );
          if (this.checkOpenNotesDialog(updatedObject, editableObjectData)) {
            return;
          } else {
            this.updateTableRowDetails(dataObject, true);
          }
        } else {
          this.updateTableRowDetails(dataObject, false);
        }
      }
    }
  };

  handleMonthChange = (event: React.ChangeEvent<{}>, step: string) => {
    const detailedData = this.state.detailedSheetData;
    const openEditObjectIndex = detailedData.findIndex((object) => object.edit);
    const openEditObject = detailedData[openEditObjectIndex];
    if (openEditObject) {
      const newDataObject = this.setDropdownErrors(openEditObject);
      detailedData.splice(openEditObjectIndex, 1, newDataObject);
      this.setState(
        {
          detailedSheetData: detailedData,
        },
        () => {
          if (!newDataObject?.error?.length) {
            this.changeMonth(step);
          } else {
            this.toggleDiscardEditDialog();
            return;
          }
        }
      );
    } else {
      this.changeMonth(step);
    }
  };

  changeMonth = (step: string) => {
    const activeMonth = this.state.monthList.find(
      (item) => item.name === this.state.activeMonth
    );
    let updatedMonths = this.state.monthList;
    const monthObIndex = updatedMonths.findIndex(
      (item) => item.name === this.state.activeMonth
    );
    if (
      activeMonth &&
      !activeMonth.isLocked &&
      moment().format("MMMM YYYY") !== activeMonth.value &&
      monthObIndex > -1
    ) {
      updatedMonths[monthObIndex].isLocked = true;
    }
    this.setState({
      activeMonth: step,
      searchValue: "",
      monthList: updatedMonths,
      activeEditObject: null,
      fileUploadRowId: undefined,
      sortedColumn: { name: "", isAsc: false },
    });
    const monthValue = this.state.monthList.find((item) => item.name === step);
    if (monthValue) {
      this.props.toggleLoader(true);
      this.getDetailedSheetMonthlyData(monthValue.value);
    }
  };

  addNewRow = () => {
    this.props.toggleLoader(true);
    setTimeout(() => {
      const freshObject = JSON.parse(JSON.stringify(configJSON.emptyRowObject));
      this.props.toggleLoader(false);
      const detailedDataList: IDetailedTableData[] = JSON.parse(
        JSON.stringify(this.state.detailedSheetData)
      );
      const dataObIndex = detailedDataList.findIndex((item) => item.edit);
      const dataOb = detailedDataList[dataObIndex];
      const newEmptyOb = {
        ...freshObject,
        images: [],
        amortization_start: moment(this.state.activeMonth, "MMM 'YY").format(
          "MM/YY"
        ),
        amortization_period: "12",
        amortization_end: getAmortizationEnd(
          moment(this.state.activeMonth, "MMM 'YY").format("MM/YY"),
          12
        ),
      };
      if (dataOb) {
        const newDataObject = this.setDropdownErrors(dataOb);
        if (!newDataObject?.error?.length) {
          const editableActiveObject = JSON.parse(
            JSON.stringify(this.state.activeEditObject)
          );
          if (editableActiveObject?.id) {
            const updatedObj = this.state.detailedSheetData.find(
              (itemOb) => itemOb.id === editableActiveObject.id
            );
            if (this.checkOpenNotesDialog(editableActiveObject, updatedObj)) {
              this.setState({ isOpenNotesDialog: true, notesValue: "" });
              return;
            } else {
              detailedDataList.splice(dataObIndex, 1, {
                ...newDataObject,
                edit: false,
              });
              detailedDataList.push(newEmptyOb);
              this.setState({
                detailedSheetData: detailedDataList,
                activeEditObject: newEmptyOb,
              });
            }
          }
        } else {
          detailedDataList.splice(dataObIndex, 1, newDataObject);
          this.setState({
            detailedSheetData: detailedDataList,
          });
          this.toggleDiscardEditDialog();
          return;
        }
      } else {
        detailedDataList.push(newEmptyOb);
        this.setState({
          detailedSheetData: detailedDataList,
          activeEditObject: newEmptyOb,
        });
      }
    }, 1500);
  };

  toggleEditRow = (rowId: number) => {
    const detailedData = this.state.detailedSheetData;
    const dataIndex = detailedData.findIndex((item, index) => index === rowId);

    if (dataIndex > -1) {
      let dataObject = detailedData[dataIndex];
      if (dataObject.edit) {
        this.showErrorOrSaveDetails(dataObject, dataIndex);
      } else if (!dataObject.edit) {
        const updatedDetailedData = JSON.parse(JSON.stringify(detailedData));
        const newDataObIndex = updatedDetailedData.findIndex(
          (object: IDetailedTableData) => object.edit
        );
        const newDataOb = updatedDetailedData[newDataObIndex];
        if (newDataOb) {
          this.showErrorOrSaveDetails(newDataOb, newDataObIndex);
        } else {
          dataObject = { ...dataObject, edit: true };
          detailedData.splice(dataIndex, 1, dataObject);
          this.setState({
            detailedSheetData: detailedData,
            activeEditObject: dataObject,
          });
        }
      }
    }
  };

  showErrorOrSaveDetails = (
    dataObject: IDetailedTableData,
    rowIndex: number
  ) => {
    this.props.toggleLoader(true);
    setTimeout(() => {
      this.props.toggleLoader(false);
      const newDataObject = this.setDropdownErrors(dataObject);
      const sheetData = this.state.detailedSheetData;
      if (!newDataObject.error?.length) {
        const editableObject = JSON.parse(
          JSON.stringify(this.state.activeEditObject)
        );
        if (editableObject?.id) {
          const updatedObj = this.state.detailedSheetData.find(
            (object) => object.id === editableObject.id
          );
          if (this.checkOpenNotesDialog(editableObject, updatedObj)) {
            this.setState({ isOpenNotesDialog: true, notesValue: "" });
            return;
          } else {
            this.getMonthList();
          }
        } else {
          this.getMonthList();
        }
      } else {
        sheetData.splice(rowIndex, 1, newDataObject);
        this.setState({
          detailedSheetData: sheetData,
        });
        this.toggleDiscardEditDialog();
        return;
      }
    }, 1500);
  };

  setDropdownErrors = (dataObject: IDetailedTableData) => {
    const vendorList = this.state.vendorList;
    const identifierList = this.state.prepaidAccountIdentifierList;
    const expenseAccountList = this.state.expenseAccountList;
    const vendorOb = vendorList.find(
      (object) => object.name === dataObject.vendor_name
    );
    const identifierOb = identifierList.find(
      (object) => object.name === dataObject.prepaid_account_identifier
    );
    const expenseAccountOb = expenseAccountList.find(
      (object) => object.name === dataObject.expense_account
    );
    let dropdownObjectErrors = dataObject.error || [];
    const updateDropdownErrorObject = (
      errorMsg: string,
      condition: boolean
    ) => {
      if (condition) {
        dropdownObjectErrors = dropdownObjectErrors.filter(
          (itemOb) => itemOb !== errorMsg
        );
      } else {
        if (!dropdownObjectErrors.includes(errorMsg)) {
          dropdownObjectErrors.push(errorMsg);
        }
      }
    };
    updateDropdownErrorObject("vendor_name", !!vendorOb);
    updateDropdownErrorObject("prepaid_account_identifier", !!identifierOb);
    updateDropdownErrorObject("expense_account", !!expenseAccountOb);
    return {
      ...dataObject,
      error: dropdownObjectErrors,
    };
  };

  toggleNotesDialog = () => {
    this.setState({
      isOpenNotesDialog: !this.state.isOpenNotesDialog,
      notesValue: "",
    });
  };

  updatesNotesValue = () => {
    const updatedObjectIndex = this.state.detailedSheetData.findIndex(
      (object) => object.id === this.state.activeEditObject?.id
    );
    if (updatedObjectIndex > -1 && this.state.notesValue) {
      let detailedDataList = this.state.detailedSheetData;

      let dataObject = detailedDataList[updatedObjectIndex];
      dataObject.notes =
        dataObject.notes &&
        dataObject.notes !== "null" &&
        dataObject.notes !== "undefined"
          ? `${this.state.notesValue?.trim()}: [${moment().format(
              "MM/DD/YY"
            )}]\n ${dataObject.notes?.trim()}`
          : `${this.state.notesValue?.trim()}: [${moment().format(
              "MM/DD/YY"
            )}]`;
      detailedDataList.splice(updatedObjectIndex, 1, dataObject);

      this.setState(
        {
          isOpenNotesDialog: false,
          detailedSheetData: detailedDataList,
          notesValue: "",
        },
        () => {
          if (!dataObject?.error?.length) {
            this.updateTableRowDetails(dataObject);
          }
        }
      );
    }
  };

  sortTableData = (column: keyof IDetailedTableData, isAsc: boolean) => {
    let detailedData = [...this.state.detailedSheetData];
    const sortedData = handleDetailedTableSort(column, isAsc, detailedData);
    this.setState({
      detailedSheetData: sortedData,
      sortedColumn: { name: column, isAsc: isAsc },
    });
  };

  toggleDeleteMonthDialog = (isOpen: boolean) => {
    this.setState({ isOpenDeleteMonthDataDialog: isOpen });
  };

  navigateToHome = () => {
    const navMsg = new Message(getName(MessageEnum.NavigationMessage));
    navMsg.addData(getName(MessageEnum.NavigationTargetMessage), "Home");
    navMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navMsg);
  };

  getCurrentMonth = () => {
    return moment(moment()).format("MMM ´YY").toString();
  };

  discardChanges = () => {
    this.toggleDiscardEditDialog();
    const editableObject = this.state.activeEditObject;
    const sheetDataObjects = this.state.detailedSheetData;
    if (editableObject?.edit) {
      const objectInd = sheetDataObjects.findIndex((object) => object?.edit);
      if (objectInd > -1) {
        if (editableObject?.id) {
          sheetDataObjects.splice(objectInd, 1, {
            ...editableObject,
            edit: false,
          });
        } else {
          sheetDataObjects.splice(objectInd, 1);
        }
        this.setState({
          detailedSheetData: sheetDataObjects,
          activeEditObject: null,
        });
      }
    } else {
      this.setState({
        detailedSheetData: [],
        activeEditObject: null,
      });
    }
  };

  downloadActiveMonthData = async (isXls: boolean) => {
    this.props.toggleLoader(true);
    const headersOb = {
      token: await getStorageData("token"),
      "Content-Type": configJSON.apiContentType,
    };

    const currentMonthValue = this.getActiveMonth();

    const endPointMsg = isXls
      ? configJSON.downloadXLSApiEndPoint + currentMonthValue
      : configJSON.downloadCSVApiEndPoint + currentMonthValue;

    const downloadMonthDataSheetRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.downloadMonthDataApiId = downloadMonthDataSheetRequestMsg.messageId;

    downloadMonthDataSheetRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPointMsg
    );

    downloadMonthDataSheetRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headersOb)
    );
    downloadMonthDataSheetRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );

    runEngine.sendMessage(
      downloadMonthDataSheetRequestMsg.id,
      downloadMonthDataSheetRequestMsg
    );
  };

  downloadPdf = async () => {
    this.props.toggleLoader(true);
    const headersObject = {
      "Content-Type": configJSON.apiContentType,
      token: await getStorageData("token"),
    };

    const downloadPdfSheetRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.downloadMonthDataPdfApiId = downloadPdfSheetRequestMsg.messageId;

    downloadPdfSheetRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.downloadPdfApiEndPoint + this.getActiveMonth()
    );

    downloadPdfSheetRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headersObject)
    );
    downloadPdfSheetRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );

    runEngine.sendMessage(
      downloadPdfSheetRequestMsg.id,
      downloadPdfSheetRequestMsg
    );
  };

  getVendorList = async () => {
    const headersOb = {
      token: await getStorageData("token"),
      "Content-Type": configJSON.apiContentType,
    };

    const getVendorListDataRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getVendorListApiId = getVendorListDataRequestMsg.messageId;

    getVendorListDataRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getVendorListApiEndPoint
    );

    getVendorListDataRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headersOb)
    );
    getVendorListDataRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );

    runEngine.sendMessage(
      getVendorListDataRequestMsg.id,
      getVendorListDataRequestMsg
    );
  };

  getIdentifierList = async () => {
    const headersOb = {
      token: await getStorageData("token"),
      "Content-Type": configJSON.apiContentType,
    };

    const getIdentifierListDataRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getIdentifiersListApiId = getIdentifierListDataRequestMsg.messageId;

    getIdentifierListDataRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getIdentifierListApiEndPoint
    );

    getIdentifierListDataRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headersOb)
    );
    getIdentifierListDataRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );

    runEngine.sendMessage(
      getIdentifierListDataRequestMsg.id,
      getIdentifierListDataRequestMsg
    );
  };

  getExpenseAccountList = async () => {
    const headersOb = {
      token: await getStorageData("token"),
      "Content-Type": configJSON.apiContentType,
    };

    const expenseAccountListDataRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getExpenseAccountApiId = expenseAccountListDataRequestMsg.messageId;

    expenseAccountListDataRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getExpenseAccountsApiEndPoint
    );

    expenseAccountListDataRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headersOb)
    );
    expenseAccountListDataRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );

    runEngine.sendMessage(
      expenseAccountListDataRequestMsg.id,
      expenseAccountListDataRequestMsg
    );
  };

  getDetailedSheetMonthlyData = async (month: string) => {
    const headersOb = {
      "Content-Type": configJSON.apiContentType,
      token: await getStorageData("token"),
    };

    const getMonthWiseDataRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getMonthlySheetDataApiId = getMonthWiseDataRequestMsg.messageId;

    getMonthWiseDataRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCurrentMonthsDetailsDataApiEndPoint + month
    );

    getMonthWiseDataRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headersOb)
    );
    getMonthWiseDataRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );

    runEngine.sendMessage(
      getMonthWiseDataRequestMsg.id,
      getMonthWiseDataRequestMsg
    );
  };

  deleteMonthData = async () => {
    const headers = {
      "Content-Type": configJSON.apiContentType,
      token: await getStorageData("token"),
    };

    const deleteCurrentMonthDataRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteMonthDataApiId = deleteCurrentMonthDataRequestMsg.messageId;

    deleteCurrentMonthDataRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteMonthDataApiEndPoint + this.getActiveMonth()
    );

    deleteCurrentMonthDataRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    deleteCurrentMonthDataRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteMethod
    );

    runEngine.sendMessage(
      deleteCurrentMonthDataRequestMsg.id,
      deleteCurrentMonthDataRequestMsg
    );
  };

  updateTableRowDetails = async (
    detailsObject: IDetailedTableData,
    inlineEdit?: boolean
  ) => {
    this.props.toggleLoader(!inlineEdit);

    const headers = {
      "Content-Type": undefined,
      token: await getStorageData("token"),
    };

    let rowFormData = new FormData();
    let methodType = "";
    let apiEndPoint = "";
    if (detailsObject.id) {
      methodType = configJSON.putMethod;
      apiEndPoint = configJSON.updateRowDetailsApiEndPoint + detailsObject.id;
    } else {
      methodType = configJSON.createBulkUploadMethod;
      apiEndPoint = configJSON.createSingleDataApiEndPoint;
      const currentMonth = this.state.monthList.find(
        (item) => item.name === this.state.activeMonth
      );
      let monthValue = "";
      if (currentMonth) {
        monthValue = currentMonth.value;
      }
      rowFormData.append("time_of_creation", monthValue);
      detailsObject?.images?.forEach((object) => {
        rowFormData.append("images[]", object.url as Blob);
      });
    }
    rowFormData.append("check", detailsObject.check || "");
    rowFormData.append(
      "invoice_description",
      detailsObject.invoice_description?.trim()
    );
    rowFormData.append("invoice_date", detailsObject.invoice_date);
    rowFormData.append("invoice_number", detailsObject.invoice_number);
    rowFormData.append("amortization_start", detailsObject.amortization_start);
    rowFormData.append("amortization_end", detailsObject.amortization_end);
    rowFormData.append("notes", detailsObject.notes?.trim());
    rowFormData = this.setPayloadToUpdate(detailsObject, rowFormData);

    const updateTableRowRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    if (inlineEdit) {
      this.updateInlineEditRowApiId = updateTableRowRequestMsg.messageId;
    } else {
      this.updateDetailsTableRowApiId = updateTableRowRequestMsg.messageId;
    }

    updateTableRowRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint
    );

    updateTableRowRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    updateTableRowRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      methodType
    );

    updateTableRowRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      rowFormData
    );

    runEngine.sendMessage(
      updateTableRowRequestMsg.id,
      updateTableRowRequestMsg
    );
  };

  setPayloadToUpdate = (
    detailsObject: IDetailedTableData,
    rowData: FormData
  ) => {
    if (
      !detailsObject.id ||
      this.state.activeEditObject?.amortization_period !==
        detailsObject.amortization_period
    ) {
      rowData.append("amortization_period", detailsObject.amortization_period);
    }
    if (
      !detailsObject.id ||
      this.state.activeEditObject?.total_amount !== detailsObject.total_amount
    ) {
      rowData.append("total_amount", detailsObject.total_amount);
    }
    if (
      !detailsObject.id ||
      this.state.activeEditObject?.expense_amount !==
        detailsObject.expense_amount
    ) {
      rowData.append("expense_amount", detailsObject.expense_amount);
    }

    const identifierObject = this.state.prepaidAccountIdentifierList.find(
      (object) => object.name === detailsObject.prepaid_account_identifier
    );
    if (identifierObject) {
      rowData.append(
        "prepaid_account",
        detailsObject.prepaid_account_identifier
      );
      rowData.append("prepaid_account_identifier_id", identifierObject.value);
    }

    const vendorObject = this.state.vendorList.find(
      (object) => object.name === detailsObject.vendor_name
    );
    if (vendorObject) {
      rowData.append("vendor", detailsObject.vendor_name);
      rowData.append("vendor_name_id", vendorObject.value);
    }

    const expenseAccount = this.state.expenseAccountList.find(
      (object) => object.name === detailsObject.expense_account
    );
    if (expenseAccount) {
      rowData.append("expense_account", detailsObject.expense_account);
      rowData.append("exp_account_id", expenseAccount.value);
    }
    return rowData;
  };

  deleteFileData = async (imageId: number, rowId: number) => {
    if (!rowId) {
      const detailedSheetData = this.state.detailedSheetData;
      const newRowDataIndex = detailedSheetData.findIndex(
        (object) => !object.id
      );
      if (newRowDataIndex > -1) {
        const newDataObject = detailedSheetData[newRowDataIndex];
        const imageIdex = newDataObject.images?.findIndex(
          (item) => item.id === imageId
        );
        if (imageIdex > -1) {
          newDataObject.images.splice(imageIdex, 1);
          detailedSheetData.splice(newRowDataIndex, 1, newDataObject);
          this.setState({ detailedSheetData: detailedSheetData });
        }
      }
    } else {
      this.setState({ deletedAttachmentRecord: { rowId, imageId } });
      const headers = {
        "Content-Type": configJSON.apiContentType,
        token: await getStorageData("token"),
      };

      const deleteImageRequestMsg = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.deleteImageApiId = deleteImageRequestMsg.messageId;

      deleteImageRequestMsg.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.deleteImageApiEndPoint + `id=${rowId}&image_id=${imageId}`
      );

      deleteImageRequestMsg.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );
      deleteImageRequestMsg.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.deleteMethod
      );

      runEngine.sendMessage(deleteImageRequestMsg.id, deleteImageRequestMsg);
    }
  };

  getSearchedMonthlyDetailedSheetData = async (
    searchString: string,
    month: string
  ) => {
    const headersOb = {
      "Content-Type": configJSON.apiContentType,
      token: await getStorageData("token"),
    };

    const searchedSheetDataRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getSearchedDetailsApiId = searchedSheetDataRequestMsg.messageId;

    searchedSheetDataRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.searchDetailsApiEndPoint + `${month}&search=${searchString}`
    );

    searchedSheetDataRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headersOb)
    );
    searchedSheetDataRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );

    runEngine.sendMessage(
      searchedSheetDataRequestMsg.id,
      searchedSheetDataRequestMsg
    );
  };

  deleteVendorOption = async (vendorId: string) => {
    const headersOb = {
      "Content-Type": configJSON.apiContentType,
      token: await getStorageData("token"),
    };

    const deleteVendorRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteVendorOptionApiId = deleteVendorRequestMsg.messageId;

    deleteVendorRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteVendorApiEndpoint + vendorId
    );

    deleteVendorRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headersOb)
    );
    deleteVendorRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteMethod
    );

    runEngine.sendMessage(deleteVendorRequestMsg.id, deleteVendorRequestMsg);
  };

  deleteIdentifierOption = async (identifierId: string) => {
    const headersObj = {
      "Content-Type": configJSON.apiContentType,
      token: await getStorageData("token"),
    };

    const deleteIdentifierRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteIdentifierOptionApiId = deleteIdentifierRequestMsg.messageId;

    deleteIdentifierRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteIdentifierApiEndpoint + identifierId
    );

    deleteIdentifierRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headersObj)
    );
    deleteIdentifierRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteMethod
    );

    runEngine.sendMessage(
      deleteIdentifierRequestMsg.id,
      deleteIdentifierRequestMsg
    );
  };

  deleteExpenseAccount = async (expenseId: string) => {
    const headersObj = {
      "Content-Type": configJSON.apiContentType,
      token: await getStorageData("token"),
    };

    const deleteExpenseAccountOptionRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteExpenseAccountOptionApiId =
      deleteExpenseAccountOptionRequestMsg.messageId;

    deleteExpenseAccountOptionRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteExpenseAccountsApiEndPoint + expenseId
    );

    deleteExpenseAccountOptionRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headersObj)
    );
    deleteExpenseAccountOptionRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteMethod
    );

    runEngine.sendMessage(
      deleteExpenseAccountOptionRequestMsg.id,
      deleteExpenseAccountOptionRequestMsg
    );
  };

  addEditVendor = async (value: string, vendorId: string) => {
    const headersObject = {
      "Content-Type": configJSON.apiContentType,
      token: await getStorageData("token"),
    };

    let apiEndPoint = configJSON.createVendorApiEndpoint;
    let methodTypeMsg = configJSON.createBulkUploadMethod;

    if (vendorId) {
      this.setState({
        activeVendorObject: { name: value?.trim(), value: vendorId },
      });
      apiEndPoint = configJSON.updateVendorApiEndpoint + vendorId;
      methodTypeMsg = configJSON.putMethod;
    }

    const addEditVendorItemRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.addEditVendorApiId = addEditVendorItemRequestMsg.messageId;

    addEditVendorItemRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint
    );

    addEditVendorItemRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headersObject)
    );
    addEditVendorItemRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      methodTypeMsg
    );

    addEditVendorItemRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({ vendor_name: value?.trim() })
    );

    runEngine.sendMessage(
      addEditVendorItemRequestMsg.id,
      addEditVendorItemRequestMsg
    );
  };

  addEditIdentifier = async (value: string, identifierId: string) => {
    const headersObject = {
      "Content-Type": configJSON.apiContentType,
      token: await getStorageData("token"),
    };

    let apiRequestPoint = configJSON.createIdentifierApiEndpoint;
    let methodOb = configJSON.createBulkUploadMethod;

    if (identifierId) {
      this.setState({
        activeIdentifierObject: { name: value?.trim(), value: identifierId },
      });
      apiRequestPoint = configJSON.updateIdentifierApiEndpoint + identifierId;
      methodOb = configJSON.putMethod;
    }

    const addEditIdentifierItemRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.addEditIdentifierApiId = addEditIdentifierItemRequestMsg.messageId;

    addEditIdentifierItemRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiRequestPoint
    );

    addEditIdentifierItemRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headersObject)
    );
    addEditIdentifierItemRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      methodOb
    );

    addEditIdentifierItemRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({ prepaid_account_identifier: value?.trim() })
    );

    runEngine.sendMessage(
      addEditIdentifierItemRequestMsg.id,
      addEditIdentifierItemRequestMsg
    );
  };

  addEditExpenseAccount = async (value: string, accountId: string) => {
    const headersObj = {
      "Content-Type": configJSON.apiContentType,
      token: await getStorageData("token"),
    };

    let apiPoint = configJSON.createExpenseAccountApiEndPoint;
    let methodTypeMsg = configJSON.createBulkUploadMethod;

    if (accountId) {
      this.setState({
        activeExpenseObject: { name: value?.trim(), value: accountId },
      });
      apiPoint = configJSON.updateExpenseAccountApiEndPoint + accountId;
      methodTypeMsg = configJSON.putMethod;
    }

    const addEditExpenseAccountItemRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.addEditExpenseAccountOptionApiId =
      addEditExpenseAccountItemRequestMsg.messageId;

    addEditExpenseAccountItemRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiPoint
    );

    addEditExpenseAccountItemRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headersObj)
    );
    addEditExpenseAccountItemRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      methodTypeMsg
    );

    addEditExpenseAccountItemRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({ exp_account: value?.trim() })
    );

    runEngine.sendMessage(
      addEditExpenseAccountItemRequestMsg.id,
      addEditExpenseAccountItemRequestMsg
    );
  };

  getMonthList = async () => {
    const headersObj = {
      "Content-Type": configJSON.apiContentType,
      token: await getStorageData("token"),
    };

    const getMonthListDataRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getMonthListApiId = getMonthListDataRequestMsg.messageId;

    getMonthListDataRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getMonthListApiEndPoint
    );

    getMonthListDataRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headersObj)
    );
    getMonthListDataRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );

    runEngine.sendMessage(
      getMonthListDataRequestMsg.id,
      getMonthListDataRequestMsg
    );
  };
}
