import React, { useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, IconButton, Tab, Tabs } from "@material-ui/core";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@material-ui/icons";
const lock = require("../src/lock.svg");

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#002348",
    borderRadius: "50px",
    padding: "8px 16px !important",
    overflowX: "auto",
    minHeight: "32px !important",
    "& .MuiTabScrollButton-root": {
      width: "auto",
      color: "#FFF",
    },
    "& .MuiButtonBase-root.MuiTab-root": {
      [theme.breakpoints.up("md")]: {
        margin: "0px 45px 0px 0px",
      },
    },
    "& .MuiTabs-scrollButtons": {
      fill: "#FFF !important",
    },
  },
  tab: {
    fontFamily: "Brown !important",
    fontSize: "16px",
    lineHeight: "15.2px !important",
    fontStyle: "normal",
    fontWeight: 400,
    color: "#FFF",
    padding: "8px 16px !important",
    cursor: "pointer",
    textTransform: "none",
    opacity: 1,
    minHeight: "32px !important",
    minWidth: "auto",
    "&:hover": {
      borderRadius: "50px",
      background: "linear-gradient(99deg, #01AA7F 2.64%, #00AED2 100%)",
      color: "#FFF",
      fontWeight: 700,
    },
    "&.Mui-selected": {
      borderRadius: "50px",
      background: "linear-gradient(99deg, #01AA7F 2.64%, #00AED2 100%)",
      color: "#FFF",
      fontWeight: 700,
    },
  },
  indicator: {
    display: "none",
  },
  centered: {
    justifyContent: "center",
  },
}));

interface ICustomMonthTabStepperProps {
  stepList: { name: string; isLocked: boolean; value: string }[];
  activeStep: string;
  onStepClick: (event: React.ChangeEvent<{}>, step: string) => void;
}

const CustomMonthTabStepper = ({
  stepList,
  activeStep,
  onStepClick,
}: ICustomMonthTabStepperProps) => {
  const classes = useStyles();
  const activeTabRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (activeTabRef.current) {
      activeTabRef.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "center",
      });
    }
  }, [activeStep]);

  return (
    <Tabs
      className={classes.root}
      value={activeStep}
      onChange={onStepClick}
      centered
      scrollButtons="on"
      variant="scrollable"
      classes={{ indicator: classes.indicator, centered: classes.centered }}
      ScrollButtonComponent={(props) => (
        <>
          {!props.disabled && (
            <IconButton size="small">
              {props.direction === "right" ? (
                <KeyboardArrowLeft {...props} />
              ) : (
                <KeyboardArrowRight {...props} />
              )}
            </IconButton>
          )}
        </>
      )}
    >
      {stepList.map((step: { name: string; isLocked: boolean }) => (
        <Tab
          ref={step.name === activeStep ? activeTabRef : null}
          className={classes.tab}
          label={
            <Box display={"flex"} alignItems={"center"}>
              {step.isLocked && (
                <Box paddingRight={"8px"}>
                  <img src={lock} width={12} alt="lock" />
                </Box>
              )}
              <Box>{step.name}</Box>
            </Box>
          }
          value={step.name}
          key={step.name}
        />
      ))}
    </Tabs>
  );
};

export default CustomMonthTabStepper;
