import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";
import {
  loadStripe,
  Stripe,
  StripeElements,
  StripeElementsOptions,
} from "@stripe/stripe-js";
import { FormEvent } from "react";
import { getStorageData } from "../../../framework/src/Utilities";

export const configJSON = require("./config");

export interface Props {
  onSuccess: () => void;
  onCloseDialog: () => void;
  onFail: () => void;
}

export interface S {
  isLoading: boolean;
}

export interface SS {}

export default class StripeSubscriptionController extends BlockComponent<
  Props,
  S,
  SS
> {
  setAutoPayApiId: string = "";
  stripePromise = loadStripe(configJSON.stripeKey);
  stripeOb: Stripe | null = null;
  options: StripeElementsOptions = {
    mode: configJSON.optionMode,
    amount: configJSON.optionAmount,
    currency: configJSON.optionCurrency,
    paymentMethodCreation: configJSON.optionPaymentMethod,
    appearance: {
      theme: "night",
      variables: {
        colorPrimary: "#01AA7F",
        colorBackground: "#001327",
        colorText: "#FFF",
        colorDanger: "#fa755a",
        fontFamily: "Open Sans, sans-serif",
        borderRadius: "8px",
        spacingUnit: "4px",
        fontSizeBase: "16px",
      },
      rules: {
        ".Input": {
          backgroundColor: "#001327",
          borderColor: "#00AED2",
          color: "#FFF",
        },
        ".Label": {
          color: "#aab7c4",
        },
      },
    },
  };

  constructor(props: Props) {
    super(props);

    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      isLoading: false,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.setAutoPayApiId) {
      if (
        responseJson &&
        responseJson.message === configJSON.subscriptionMessage
      ) {
        this.toggleLoader(false);
        this.props.onSuccess();
      } else if (this.stripeOb && responseJson?.payment_intent_client_secret) {
        const { paymentIntent } = await this.stripeOb.confirmCardPayment(
          responseJson.payment_intent_client_secret
        );
        if (paymentIntent?.status === "succeeded") {
          this.props.onSuccess();
        } else {
          this.props.onFail();
        }
      }
    }
  }

  onCloseStripeGateway = () => {
    this.props.onCloseDialog();
  };

  toggleLoader = (isLoading: boolean) => {
    this.setState({ isLoading: isLoading });
  };

  handleSubmit = async (
    event: FormEvent,
    stripe: Stripe | null,
    elements: StripeElements | null
  ) => {
    this.stripeOb = stripe;
    this.toggleLoader(true);
    event.preventDefault();

    if (!stripe || !elements) {
      this.toggleLoader(false);
      return;
    }
    const { error: submitError } = await elements.submit();
    if (submitError) {
      this.toggleLoader(false);
      return;
    }

    const result = await stripe.createPaymentMethod({
      elements,
    });

    if (result?.paymentMethod && result?.paymentMethod?.id) {
      this.createSubscription(result.paymentMethod.id);
    } else {
      this.toggleLoader(false);
    }
  };

  createSubscription = async (cardToken: string) => {
    const headers = {
      "Content-Type": configJSON.apiContentType,
      token: await getStorageData("token"),
    };

    const setAutoPayRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.setAutoPayApiId = setAutoPayRequestMsg.messageId;

    const apiEndPoint = configJSON.createSubscriptionApiEndPoint + cardToken;

    setAutoPayRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint
    );

    setAutoPayRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    setAutoPayRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.confirmPaymentMethod
    );

    runEngine.sendMessage(setAutoPayRequestMsg.id, setAutoPayRequestMsg);
  };
}
