import React, { useState } from "react";
import {
  MenuItem,
  Select,
  FormControl,
  makeStyles,
  Box,
  TextField,
  InputAdornment,
  Checkbox,
  Button,
} from "@material-ui/core";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import SearchIcon from "@material-ui/icons/Search";
const checkboxIcon = require("../src/checkbox.png");
const checkedCheckboxIcon = require("../src/checkbox-checked.svg");

const useStyles = makeStyles(() => ({
  roundedSelectField: {
    width: "160px",
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: "50px",
      border: `1px solid #FFF !important`,
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: `1px solid #01AA7F !important`,
    },
    "& .MuiOutlinedInput-root .MuiSelect-icon": {
      color: "#FFF !important",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiInputBase-input": {
      color: "#01AA7F !important",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiSelect-icon": {
      color: "#01AA7F !important",
    },
    "& .MuiInputBase-root": {
      fontSize: "14px !important",
      fontFamily: "Poppins",
      color: `#FFF !important`,
    },
  },
  roundedSelectedSelectField: {
    width: "160px",
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: "50px",
      border: "1px solid transparent !important",
    },
    "& .MuiOutlinedInput-root .MuiInputBase-input": {
      color: "#FFF !important",
    },
    "& .MuiOutlinedInput-root .MuiSelect-icon": {
      color: "#FFF !important",
    },
    "& .MuiInputBase-root": {
      fontSize: "14px !important",
      fontFamily: "Poppins",
    },
  },
  textField: {
    "& input": {
      color: "#FFF !important",
      fontFamily: "Poppins",
      fontSize: "14px",
    },
  },
  menuList: {
    paddingTop: "0px",
    paddingBottom: "0px",
    " & .MuiCheckbox-root": {
      padding: "3px 9px 3px 3px",
    },
  },
  selectRoot: {
    boxShadow: "none !important",
    backgroundColor: "transparent !important",
  },
}));

interface ICustomSearchableFilterProps {
  value: string[];
  options: string[];
  handleChange: (value: string[]) => void;
}

const CustomSearchableFilter: React.FC<ICustomSearchableFilterProps> = ({
  value,
  options,
  handleChange,
}) => {
  const classes = useStyles({
    value,
    options,
    handleChange,
  });
  const [open, setOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [savedValue, setSavedValue] = useState<string[]>(
    JSON.parse(JSON.stringify(value))
  );

  const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  const getHighlightedText = (text: string, highlight: string) => {
    if (!highlight) {
      return text;
    }

    const regex = new RegExp(`(${highlight})`, "gi");
    const parts = text.split(regex);

    return parts.map((part, index) =>
      regex.test(part) ? (
        <span key={index} style={webStyle.highlight}>
          {part}
        </span>
      ) : (
        part
      )
    );
  };

  const HighlightedTextComponent = (text: string, highlight: string) => {
    return (
      <div style={{ whiteSpace: "pre-wrap" }}>
        {getHighlightedText(text, highlight)}
      </div>
    );
  };

  const handleSelectOption = (valueData: string) => {
    let valuesOb = JSON.parse(JSON.stringify(savedValue));
    if (!valuesOb.includes(valueData)) {
      valuesOb.push(valueData);
    } else {
      valuesOb = valuesOb.filter((item: string) => item !== valueData);
    }
    setSavedValue(valuesOb);
  };

  const handleCloseMenu = () => {
    setSavedValue(JSON.parse(JSON.stringify(value)));
    setOpen(false);
    setSearchText("");
  };

  const saveFilter = () => {
    handleChange(JSON.parse(JSON.stringify(savedValue)));
    setOpen(false);
    setSearchText("");
  };

  return (
    <>
      <FormControl
        variant="outlined"
        className={
          value.length > 0
            ? classes.roundedSelectedSelectField
            : classes.roundedSelectField
        }
      >
        <Select
          label={undefined}
          name=""
          multiple
          open={open}
          onOpen={() => setOpen(true)}
          onClose={handleCloseMenu}
          value={value}
          style={{
            borderRadius: "50px",
            height: "30px",
            background: value.length > 0 ? "#00AED2" : "transparent",
          }}
          MenuProps={{
            anchorOrigin: { vertical: "bottom", horizontal: "right" },
            transformOrigin: { vertical: "top", horizontal: "right" },
            getContentAnchorEl: null,
            MenuListProps: {
              classes: {
                root: classes.menuList,
              },
            },
            PaperProps: {
              style: {
                borderRadius: "0px 8px 8px 8px",
              },
            },
          }}
          IconComponent={ArrowDropDownIcon}
          renderValue={(selected) =>
            selected && (selected as string[]).length > 0
              ? (selected as string[]).join(", ")
              : "Select Option"
          }
          displayEmpty
          classes={{ root: classes.selectRoot }}
        >
          <Box borderRadius={"0px 8px 8px 8px"} width={"225px"}>
            <Box style={webStyle.searchField}>
              <TextField
                className={classes.textField}
                variant="standard"
                placeholder="Enter your text"
                InputProps={{
                  disableUnderline: true,
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon style={webStyle.searchIcon} />
                    </InputAdornment>
                  ),
                }}
                onClick={(e) => e.stopPropagation()}
                onChange={handleTextChange}
                onKeyDown={(e) => e.stopPropagation()}
                value={searchText}
              />
            </Box>
            <Box
              maxHeight={"190px"}
              style={webStyle.listWrapper}
              overflow={"auto"}
            >
              {options
                .filter((object) =>
                  object.toLowerCase().includes(searchText.toLowerCase())
                )
                .map((item: string) => (
                  <MenuItem
                    key={`${item}-option`}
                    value={item}
                    data-test-id="menu-list"
                    onClick={() => handleSelectOption(item)}
                    style={webStyle.menuItem}
                  >
                    <Checkbox
                      checked={savedValue.includes(item)}
                      icon={
                        <img
                          src={checkboxIcon}
                          alt="icon"
                          height={20}
                          width={20}
                        />
                      }
                      checkedIcon={
                        <img
                          src={checkedCheckboxIcon}
                          alt="checked"
                          height={20}
                          width={20}
                        />
                      }
                    />
                    {HighlightedTextComponent(item, searchText)}
                  </MenuItem>
                ))}
            </Box>
            <Box
              padding={"8px 16px"}
              style={{ ...webStyle.listWrapper, ...webStyle.pointer }}
              display={"flex"}
              alignItems={"center"}
            >
              <Button
                variant="contained"
                style={webStyle.addFieldButton}
                onClick={saveFilter}
              >
                Save Selection
              </Button>
            </Box>
          </Box>
        </Select>
      </FormControl>
    </>
  );
};

const webStyle = {
  searchField: {
    backgroundColor: "#44403C",
    padding: "8px 16px",
    borderBottom: "1px solid #A8A29E",
  },
  searchIcon: {
    color: "#78716C",
  },
  listWrapper: {
    backgroundColor: "#57534E",
    fontSize: "14px",
    fontFamily: "Open Sans",
    fontWeight: 700,
    lineHeight: "22px",
    color: "#FFF",
  },
  pointer: {
    cursor: "pointer",
  },
  highlight: {
    color: "#FFD700",
  },
  menuItem: {
    fontFamily: "Open Sans",
    fontWeight: 700,
    lineHeight: "22px",
    fontSize: "14px",
    minHeight: "inherit",
  },
  addFieldButton: {
    color: "#FFF",
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "22px",
    background: "linear-gradient(99deg, #01AA7F 2.64%, #00AED2 100%)",
    fontFamily: "Open Sans",
    textTransform: "none",
    borderRadius: "50px",
    width: "184px",
    padding: "8px",
    height: "38px",
  } as CSSProperties,
};

export default CustomSearchableFilter;
