import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "../../../framework/src/Utilities";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
}

export interface S {
  otpCode: string;
  verificationFailedMsg: string;
  email: string;
  userId: number;
}

export interface SS {}

export default class VerifyEmailController extends BlockComponent<
  Props,
  S,
  SS
> {
  verifyEmailApiId: string = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.state = {
      otpCode: "",
      verificationFailedMsg: "",
      email: "",
      userId: -1,
    };
  }

  async receive(from: string, message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.verifyEmailApiId) {
      if (responseJson?.messages && responseJson.messages?.length > 0) {
        this.handleNavigate("PaymentDetails");
      } else if (responseJson?.errors && responseJson.errors?.length > 0) {
        this.setState(
          {
            verificationFailedMsg:
              responseJson.errors[0]?.pin || configJSON.somethingWentWrong,
          },
          () => {
            setTimeout(() => {
              this.setState({ verificationFailedMsg: "" });
            }, 3000);
          }
        );
      }
    }
  }

  componentDidMount = async () => {
    const userDetails = await getStorageData("userDetails", true);
    if (userDetails?.email) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      this.setState({ email: userDetails.email, userId: userDetails?.id });
    } else {
      this.handleNavigate("Home");
    }
  };

  handleOTPChange = (otpCode: string) => {
    this.setState({ otpCode: otpCode });
  };

  handleGoBack = () => {
    this.handleNavigate("SignUp");
  };

  handleVerifyEmail = () => {
    const apiHeaders = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const emailVerificationRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.verifyEmailApiId = emailVerificationRequestMsg.messageId;

    emailVerificationRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.verifyEmailAPIEndPoint
    );

    emailVerificationRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(apiHeaders)
    );

    emailVerificationRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    emailVerificationRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        data: {
          attributes: {
            email: this.state.email,
            pin: this.state.otpCode,
            request_key: "signup",
            account_id: this.state.userId,
          },
        },
      })
    );

    runEngine.sendMessage(
      emailVerificationRequestMsg.id,
      emailVerificationRequestMsg
    );
  };

  handleNavigate = (path: string) => {
    const navigateMsg = new Message(getName(MessageEnum.NavigationMessage));
    navigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), path);
    navigateMsg.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(navigateMsg);
  };
}
