import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";

import { getStorageData } from "../../../framework/src/Utilities";

export const configJSON = require("./config");

export interface Props {
  onCloseDialog: () => void;
  onSuccess: () => void;
}


export interface S {
  isLoading: boolean;
}

export interface SS {}

export default class ReactivateStripeSubscriptionController extends BlockComponent<
  Props,
  S,
  SS
> {
  reactivateSubscriptionApiId: string = "";

  constructor(props: Props) {
    super(props);

    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      isLoading: false,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }


  async receive(from: string, message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.reactivateSubscriptionApiId) {
      this.toggleLoader(false);
      if (responseJson?.message === configJSON.reactivateSuccessMsg) {
        this.props.onSuccess();
      }
    }
  }

  onCloseStripeGateway = () => {
    this.props.onCloseDialog();
  };

  toggleLoader = (isLoading: boolean) => {
    this.setState({ isLoading: isLoading });
  };

  reactivateSubscription = async () => {
    this.toggleLoader(true);
    const headers = {
      "Content-Type": configJSON.apiContentType,
      token: await getStorageData("token"),
    };

    const reactivateSubscriptionRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.reactivateSubscriptionApiId = reactivateSubscriptionRequestMsg.messageId;

    reactivateSubscriptionRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.reactivateSubscriptionApiEndPoint
    );

    reactivateSubscriptionRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    reactivateSubscriptionRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putMethod
    );

    runEngine.sendMessage(
      reactivateSubscriptionRequestMsg.id,
      reactivateSubscriptionRequestMsg
    );
  };
}
