import React from "react";
import { Box } from "@material-ui/core";

import DetailsHomePageController, {
  Props,
  configJSON,
} from "./DetailsHomePageController.web";
import CommonRadioButton from "../../../components/src/CommonRadioButton.web";
import CustomButton from "../../../components/src/CustomButton.web";
import { excel } from "./assets";
import CustomFileUpload from "../../../components/src/CustomFileUpload.web";
import IdentifyPrepaidAccounts from "./IdentifyPrepaidAccounts.web";
import { reducedFileName } from "../../../components/src/Utilities";
export default class DetailsHomePage extends DetailsHomePageController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const {
      optionSelection,
      isOptionSelected,
      selectedFile,
      isFileUploadInProgress,
      progress,
      fileError,
      templateFileName,
      templateFileSize,
      importDescription,
      importTitle,
      importedFileDescription,
      uploadedAccountsData,
    } = this.state;
    return (
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        height={"inherit"}
        width={"100%"}
        margin={"auto"}
      >
        {!isOptionSelected && !uploadedAccountsData.length && (
          <Box
            sx={{
              width: {
                lg: "682px",
                md: "682px",
              },
            }}
            data-test-id="selection-wrapper"
          >
            <Box
              style={webStyle.detailsHomeContainer}
              sx={{
                padding: {
                  xs: "12px",
                  sm: "16px",
                  md: "32px 56px",
                  lg: "32px 56px",
                },
              }}
            >
              <Box textAlign={"center"} sx={webStyle.detailsHeader}>
                {configJSON.detailsPageHeader}
              </Box>
              <Box
                textAlign={"center"}
                marginTop={"8px"}
                style={webStyle.detailsDescription}
              >
                {configJSON.detailsPageOptionSelection}
              </Box>
              <Box margin={"32px 0px"}>
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  style={
                    optionSelection === "import"
                      ? webStyle.selectionWrapperSelected
                      : webStyle.selectionWrapper
                  }
                  data-test-id="selection-tab"
                  onClick={() => this.handleChangeOptionSelection("import")}
                >
                  <Box>
                    <CommonRadioButton
                      value="import"
                      checked={optionSelection === "import"}
                      onChange={this.handleChangeSelection}
                    />
                  </Box>
                  <Box paddingLeft={"16px"}>
                    <Box style={webStyle.selectionHeader} marginBottom={"8px"}>
                      {configJSON.importText}
                    </Box>
                    <Box style={webStyle.selectionDescription}>
                      {configJSON.importDescription}
                    </Box>
                  </Box>
                </Box>
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  style={
                    optionSelection === "template"
                      ? webStyle.selectionWrapperSelected
                      : webStyle.selectionWrapper
                  }
                  data-test-id="selection-tab"
                  marginTop={"32px"}
                  onClick={() => this.handleChangeOptionSelection("template")}
                >
                  <Box>
                    <CommonRadioButton
                      value="template"
                      checked={optionSelection === "template"}
                      onChange={this.handleChangeSelection}
                    />
                  </Box>
                  <Box paddingLeft={"16px"}>
                    <Box style={webStyle.selectionHeader} marginBottom={"8px"}>
                      {configJSON.useTemplateText}
                    </Box>
                    <Box style={webStyle.selectionDescription}>
                      {configJSON.templateDescription}
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <CustomButton
                  label={configJSON.continueBtn}
                  variant="contained"
                  onClick={this.onSelectOption}
                  width={197}
                />
              </Box>
            </Box>
          </Box>
        )}
        {isOptionSelected && optionSelection === "template" && (
          <Box
            sx={{
              width: {
                lg: "585px",
                md: "585px",
              },
            }}
            data-test-id="download-template-wrapper"
          >
            <Box
              style={webStyle.detailsHomeContainer}
              sx={{
                padding: {
                  xs: "12px",
                  sm: "16px",
                  md: "32px 56px",
                  lg: "32px 56px",
                },
              }}
            >
              <Box textAlign={"center"} sx={webStyle.detailsHeader}>
                {configJSON.downloadTemplate}
              </Box>
              <Box textAlign={"center"} style={webStyle.detailsDescription}>
                {configJSON.downloadAndFillTemplates}
              </Box>
              <Box display={"flex"} justifyContent={"center"}>
                <Box
                  style={webStyle.templateWrapper}
                  display={"flex"}
                  alignItems={"center"}
                  margin={"24px 0px"}
                >
                  <Box paddingRight={"16px"}>
                    <img src={excel} alt="file" width={56} />
                  </Box>
                  <Box>
                    <Box style={webStyle.fileName}>{reducedFileName(templateFileName)}</Box>
                    <Box style={webStyle.fileSize}>{templateFileSize}</Box>
                  </Box>
                </Box>
              </Box>
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Box>
                  <CustomButton
                    label={configJSON.backBtn}
                    variant="outlined"
                    onClick={this.backToSelection}
                    width={197}
                  />
                </Box>
                <Box
                  sx={{
                    paddingLeft: {
                      xs: "8px",
                      sm: "12px",
                      md: "24px",
                      lg: "24px",
                      xl: "24px",
                    },
                  }}
                >
                  <CustomButton
                    label={configJSON.downloadText}
                    variant="contained"
                    onClick={this.downloadTemplate}
                    width={197}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        )}
        {isOptionSelected && optionSelection.includes("import") && (
          <Box
            sx={{
              width: {
                lg: "712px",
                md: "712px",
              },
            }}
          >
            <Box
              style={webStyle.detailsHomeContainer}
              sx={{
                padding: {
                  xs: "12px",
                  sm: "16px",
                  md: "32px 56px",
                  lg: "32px 56px",
                },
              }}
            >
              <Box
                textAlign={"center"}
                sx={webStyle.detailsHeader}
                marginBottom={"8px"}
              >
                {importTitle}
              </Box>
              {!selectedFile && (
                <Box textAlign={"center"} style={webStyle.detailsDescription}>
                  {importDescription}
                </Box>
              )}
              <Box margin={"24px 0px"}>
                {fileError && <Box style={webStyle.fileError}>{fileError}</Box>}
                <CustomFileUpload
                  handleFileChange={this.handleFileChange}
                  handleFileDrop={this.handleFileDrop}
                  handleOpenFileInput={this.onOpenFileInput}
                  selectedFile={selectedFile}
                  deleteFile={this.onDeleteFile}
                  isFileUploadInProgress={isFileUploadInProgress}
                  uploadProgress={progress}
                />
              </Box>
              {selectedFile && !isFileUploadInProgress && (
                <Box
                  marginBottom={"24px"}
                  textAlign={"center"}
                  style={webStyle.detailsDescription}
                >
                  {importedFileDescription}
                </Box>
              )}
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Box>
                  <CustomButton
                    label={configJSON.backBtn}
                    variant="outlined"
                    onClick={this.backToSelection}
                    width={197}
                  />
                </Box>
                <Box
                  sx={{
                    paddingLeft: {
                      xs: "8px",
                      sm: "12px",
                      md: "24px",
                      lg: "24px",
                      xl: "24px",
                    },
                  }}
                >
                  <CustomButton
                    label={configJSON.continueBtn}
                    variant="contained"
                    onClick={this.uploadSelectedFile}
                    width={197}
                    disabled={!selectedFile || isFileUploadInProgress}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        )}
        {uploadedAccountsData.length > 0 && (
          <IdentifyPrepaidAccounts
            tableWidth={this.props.tableWidth}
            accountsData={uploadedAccountsData}
            backToStepper={this.backToDownloadTemplate}
            moveToDetailedUploadScreen={this.props.moveToDetailedUploadScreen}
          />
        )}
      </Box>
    );
  }
}

const webStyle = {
  detailsHomeContainer: {
    borderRadius: "16px",
    backdropFilter: "blur(8px)",
    background: "#00132799",
  },
  detailsHeader: {
    color: "#FFF",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "30px",
  },
  detailsDescription: {
    color: "#FFF",
    fontFamily: "Open Sans",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: 1.5,
  },
  selectionHeader: {
    color: "#FFF",
    fontFamily: "Brown",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "23.52px",
  },
  selectionDescription: {
    color: "#FFF",
    fontFamily: "Brown",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "15.2px",
  },
  selectionWrapper: {
    borderRadius: "16px",
    backgroundColor: "#171717cc",
    border: "4px solid transparent",
    padding: "24px",
    cursor: "pointer",
  },
  selectionWrapperSelected: {
    borderRadius: "16px",
    backgroundColor: "#171717cc",
    border: "4px solid #6EE7B7",
    padding: "24px",
    cursor: "pointer",
  },
  templateWrapper: {
    background: "#01AA7F",
    borderRadius: "8px",
    padding: "8px 40px",
  },
  fileName: {
    color: "#FFF",
    fontFamily: "Inter",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: 1.5,
  },
  fileSize: {
    color: "#D6D3D1",
    fontFamily: "Brown",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: 1.5,
  },
  fileError: {
    fontSize: "12px",
    fontFamily: "Open Sans",
    color: "#DC2626",
    fontStyle: "normal",
    fontWeight: 700,
    backgroundColor: "#FEE2E2",
    lineHeight: 1.5,
    padding: "21px 16px",
    borderLeft: "2px solid #DC2626",
    borderRadius: "4px",
    margin: "10px 0px",
  },
};
