import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "../../../framework/src/Utilities";
import {
  IExpenseAccounts,
  IIdentifiers,
  IVendors,
} from "./DetailsTableViewController.web";
import HtmlParser from "html-react-parser";

export const configJSON = require("./config");

interface IIdentifier {
  prepaid_account_identifier: string;
  updatedAccountIdentifier?: string;
  updatedAccountIdentifierId?: string;
}

interface IVendor {
  vendor_name: string;
  updatedVendor?: string;
  updatedVendorId?: string;
}

interface IExpenseAccount {
  exp_account: string;
  updatedExpenseAccount?: string;
  updatedExpenseAccountId?: string;
}
export interface Props {
  vendorIds: string[];
  identifierIds: string[];
  expenseAccountIds: string[];
  tableWidth: number;
  moveToDetailsScreen: () => void;
  toggleLoader: (isLoad: boolean) => void;
  backToDetailsUpload: () => void;
}

interface S {
  isOpenErrorDialog: string;
  identifiersObject: IIdentifier[];
  vendorsObject: IVendor[];
  expenseAccountObject: IExpenseAccount[];
  missMatchView: string;
  vendorList: { name: string; value: string }[];
  prepaidAccountIdentifierList: { name: string; value: string }[];
  expenseAccountList: { name: string; value: string }[];
  filteredIdentifiersObject: IIdentifier[];
  filteredVendorsObject: IVendor[];
  filteredExpenseAccountsObject: IExpenseAccount[];
  searchItem: string;
  updatedIdentifierIds: string[];
  updatedVendorIds: string[];
  updatedExpenseAccountIds: string[];
  currentVendorObject?: { name: string; value: string };
  currentExpenseObject?: { name: string; value: string };
  currentIdentifierObject?: { name: string; value: string };
}

interface SS {}

export default class InconsistentDetailsViewController extends BlockComponent<
  Props,
  S,
  SS
> {
  getVendorsApiId: string = "";
  getIdentifiersApiId: string = "";
  getExpenseAccountsApiId: string = "";
  updateVendorsAndIdentifiersApiId: string = "";
  backToDetailsApiId: string = "";
  deleteVendorApiId: string = "";
  deleteIdentifierApiId: string = "";
  editVendorApiId: string = "";
  editIdentifierApiId: string = "";
  deleteExpenseAccountApiId: string = "";
  editExpenseAccountOptionApiId: string = "";

  constructor(props: Props) {
    super(props);

    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    this.state = {
      isOpenErrorDialog: "",
      identifiersObject: [],
      vendorsObject: [],
      expenseAccountObject: [],
      missMatchView: "",
      vendorList: [],
      prepaidAccountIdentifierList: [],
      expenseAccountList: [],
      filteredIdentifiersObject: [],
      filteredVendorsObject: [],
      filteredExpenseAccountsObject: [],
      searchItem: "",
      updatedIdentifierIds: [],
      updatedVendorIds: [],
      updatedExpenseAccountIds: [],
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.getVendorsApiId) {
      this.setVendorsResponse(responseJson);
    }

    if (apiRequestCallId === this.getIdentifiersApiId) {
      this.setIdentifiersResponse(responseJson);
    }

    if (apiRequestCallId === this.getExpenseAccountsApiId) {
      this.setExpenseAccountsResponse(responseJson);
    }

    if (apiRequestCallId === this.updateVendorsAndIdentifiersApiId) {
      this.setUpdateDataResponse(responseJson);
    }

    if (apiRequestCallId === this.backToDetailsApiId) {
      this.setBackToDetailsResponse(responseJson);
    }

    if (
      apiRequestCallId === this.deleteVendorApiId ||
      apiRequestCallId === this.editVendorApiId
    ) {
      this.getVendorListOfActiveAccount();
    }

    if (
      apiRequestCallId === this.deleteIdentifierApiId ||
      apiRequestCallId === this.editIdentifierApiId
    ) {
      this.getIdentifierOfActiveAccount();
    }

    if (
      apiRequestCallId === this.deleteExpenseAccountApiId ||
      apiRequestCallId === this.editExpenseAccountOptionApiId
    ) {
      this.getExpenseAccountsOfActiveAccount();
    }
  }

  async componentDidMount() {
    if (this.props.identifierIds.length > 0) {
      this.setState({ isOpenErrorDialog: "identifier" });
    } else if (this.props.vendorIds.length > 0) {
      this.setState({ isOpenErrorDialog: "vendor" });
    } else if (this.props.expenseAccountIds.length > 0) {
      this.setState({ isOpenErrorDialog: "expenseAccounts" });
    }
    this.getIdentifierOfActiveAccount();
    this.getVendorListOfActiveAccount();
    this.getExpenseAccountsOfActiveAccount();

    let identifierList: IIdentifier[] = [];
    let expenseAccountList: IExpenseAccount[] = [];
    let vendorList: IVendor[] = [];

    if (this.props.identifierIds.length > 0) {
      this.props.identifierIds.forEach((item) => {
        identifierList.push({
          prepaid_account_identifier: item,
        });
      });
    }
    if (this.props.expenseAccountIds.length > 0) {
      this.props.expenseAccountIds.forEach((item) => {
        expenseAccountList.push({
          exp_account: item,
        });
      });
    }
    if (this.props.vendorIds.length > 0) {
      this.props.vendorIds.forEach((item) => {
        vendorList.push({
          vendor_name: item,
        });
      });
    }
    this.setState({
      identifiersObject: identifierList,
      filteredIdentifiersObject: identifierList,
      vendorsObject: vendorList,
      filteredVendorsObject: vendorList,
      expenseAccountObject: expenseAccountList,
      filteredExpenseAccountsObject: expenseAccountList,
    });
  }

  setBackToDetailsResponse = (responseJson: { message: string }) => {
    if (
      responseJson?.message &&
      responseJson.message === configJSON.deleteAccountsDataMessage
    ) {
      this.props.toggleLoader(false);
      this.props.backToDetailsUpload();
    }
  };

  setUpdateDataResponse = (responseJson: { message: string }) => {
    this.props.toggleLoader(false);
    if (responseJson?.message === configJSON.updateSuccessResponse) {
      if (this.state.missMatchView === "identifier") {
        this.setIdentifiersUpdateResponse();
      } else if (this.state.missMatchView === "vendor") {
        this.setVendorUpdateResponse();
      } else {
        const filteredExpenseObject = this.state.expenseAccountObject.filter(
          (item) =>
            !this.state.updatedExpenseAccountIds.includes(item.exp_account)
        );
        if (filteredExpenseObject.length > 0) {
          this.setState({
            expenseAccountObject: filteredExpenseObject,
            filteredExpenseAccountsObject: filteredExpenseObject,
            searchItem: "",
            updatedExpenseAccountIds: [],
          });
        } else {
          this.props.moveToDetailsScreen();
        }
      }
    }
  };

  setVendorUpdateResponse = () => {
    const filteredVendorsObject = this.state.vendorsObject.filter(
      (item) => !this.state.updatedVendorIds.includes(item.vendor_name)
    );
    if (filteredVendorsObject.length > 0) {
      this.setState({
        vendorsObject: filteredVendorsObject,
        filteredVendorsObject: filteredVendorsObject,
        searchItem: "",
        updatedVendorIds: [],
      });
    } else {
      if (this.state.expenseAccountObject.length > 0) {
        this.setState({
          isOpenErrorDialog: "expenseAccounts",
          missMatchView: "",
          searchItem: "",
        });
      } else {
        this.props.moveToDetailsScreen();
      }
    }
  };

  setIdentifiersUpdateResponse = () => {
    const filteredIdentifiers = this.state.identifiersObject.filter(
      (item) =>
        !this.state.updatedIdentifierIds.includes(
          item.prepaid_account_identifier
        )
    );
    if (filteredIdentifiers.length > 0) {
      this.setState({
        identifiersObject: filteredIdentifiers,
        filteredIdentifiersObject: filteredIdentifiers,
        searchItem: "",
        updatedIdentifierIds: [],
      });
    } else {
      if (this.state.vendorsObject.length > 0) {
        this.setState({
          isOpenErrorDialog: "vendor",
          missMatchView: "",
          searchItem: "",
        });
      } else if (this.state.expenseAccountObject.length > 0) {
        this.setState({
          isOpenErrorDialog: "expenseAccounts",
          missMatchView: "",
          searchItem: "",
        });
      } else {
        this.props.moveToDetailsScreen();
      }
    }
  };

  setVendorsResponse = (responseObject: { data: IVendors[] }) => {
    const sheetData: IVendor[] = JSON.parse(
      JSON.stringify(this.state.vendorsObject)
    );
    const filteredSheetData: IVendor[] = JSON.parse(
      JSON.stringify(this.state.filteredVendorsObject)
    );
    let updatedSheet = sheetData;
    let updatedFilteredSheet = filteredSheetData;
    updatedSheet = sheetData.map((item) => ({
      ...item,
      updatedVendor:
        this.state.currentVendorObject?.value ===
        item?.updatedVendorId?.toString()
          ? this.state.currentVendorObject?.name
          : item?.updatedVendor,
    }));
    updatedFilteredSheet = filteredSheetData.map((item) => ({
      ...item,
      updatedVendor:
        this.state.currentVendorObject?.value ===
        item?.updatedVendorId?.toString()
          ? this.state.currentVendorObject?.name
          : item?.updatedVendor,
    }));
    if (responseObject?.data && responseObject.data.length > 0) {
      this.setState({
        vendorList: responseObject.data.map((item: IVendors) => ({
          name: item.attributes.vendor_name,
          value: item.id,
        })),
        vendorsObject: updatedSheet,
        filteredVendorsObject: updatedFilteredSheet,
        currentVendorObject: undefined,
      });
    } else {
      this.setState({ vendorList: [] });
    }
  };

  setIdentifiersResponse = (responseObject: { data: IIdentifiers[] }) => {
    const sheetData: IIdentifier[] = JSON.parse(
      JSON.stringify(this.state.identifiersObject)
    );
    const filteredSheetData: IIdentifier[] = JSON.parse(
      JSON.stringify(this.state.filteredIdentifiersObject)
    );
    let updatedSheet = sheetData;
    let updatedFilteredSheet = filteredSheetData;
    updatedSheet = sheetData.map((item) => ({
      ...item,
      updatedAccountIdentifier:
        this.state.currentIdentifierObject?.value ===
        item?.updatedAccountIdentifierId?.toString()
          ? this.state.currentIdentifierObject?.name
          : item?.updatedAccountIdentifier,
    }));
    updatedFilteredSheet = filteredSheetData.map((item) => ({
      ...item,
      updatedAccountIdentifier:
        this.state.currentIdentifierObject?.value ===
        item?.updatedAccountIdentifierId?.toString()
          ? this.state.currentIdentifierObject?.name
          : item?.updatedAccountIdentifier,
    }));
    if (responseObject?.data && responseObject.data.length > 0) {
      this.setState({
        prepaidAccountIdentifierList: responseObject.data.map(
          (object: IIdentifiers) => ({
            name: object.attributes.prepaid_account_identifier,
            value: object.id,
          })
        ),
        identifiersObject: updatedSheet,
        filteredIdentifiersObject: updatedFilteredSheet,
        currentIdentifierObject: undefined,
      });
    } else {
      this.setState({ prepaidAccountIdentifierList: [] });
    }
  };

  setExpenseAccountsResponse = (responseObject: {
    data: IExpenseAccounts[];
  }) => {
    const sheetData: IExpenseAccount[] = JSON.parse(
      JSON.stringify(this.state.expenseAccountObject)
    );
    const filteredSheetData: IExpenseAccount[] = JSON.parse(
      JSON.stringify(this.state.filteredExpenseAccountsObject)
    );
    let updatedSheet = sheetData;
    let updatedFilteredSheet = filteredSheetData;
    updatedSheet = sheetData.map((item) => ({
      ...item,
      updatedExpenseAccount:
        this.state.currentExpenseObject?.value ===
        item?.updatedExpenseAccountId?.toString()
          ? this.state.currentExpenseObject?.name
          : item?.updatedExpenseAccount,
    }));
    updatedFilteredSheet = filteredSheetData.map((item) => ({
      ...item,
      updatedExpenseAccount:
        this.state.currentExpenseObject?.value ===
        item?.updatedExpenseAccountId?.toString()
          ? this.state.currentExpenseObject?.name
          : item?.updatedExpenseAccount,
    }));
    if (responseObject?.data && responseObject.data.length > 0) {
      this.setState({
        expenseAccountList: responseObject.data.map(
          (object: IExpenseAccounts) => ({
            name: object.attributes.exp_account,
            value: object.id,
          })
        ),
        expenseAccountObject: updatedSheet,
        filteredExpenseAccountsObject: updatedFilteredSheet,
        currentExpenseObject: undefined,
      });
    } else {
      this.setState({ expenseAccountList: [] });
    }
  };

  handleUpdateMissMatchData = (
    value: string,
    valueId: string,
    param: string,
    rowId: number
  ) => {
    let vendorObjectData = this.state.vendorsObject;
    let identifierObjectData = this.state.identifiersObject;
    let expenseAccountObjectData = this.state.expenseAccountObject;
    let filteredIdentifiers = this.state.filteredIdentifiersObject;
    let filteredVendors = this.state.filteredVendorsObject;
    let filteredExpenseAccounts = this.state.filteredExpenseAccountsObject;

    if (this.state.missMatchView === "vendor") {
      if (rowId > -1) {
        let vendorsOb = vendorObjectData[rowId];
        let filteredVendorsOb = filteredVendors[rowId];
        vendorsOb = {
          ...vendorsOb,
          updatedVendor: value,
          updatedVendorId: valueId,
        };
        filteredVendorsOb = {
          ...filteredVendorsOb,
          updatedVendor: value,
          updatedVendorId: valueId,
        };
        vendorObjectData.splice(rowId, 1, vendorsOb);
        filteredVendors.splice(rowId, 1, filteredVendorsOb);
      }
    }
    if (this.state.missMatchView === "identifier") {
      if (rowId > -1) {
        let identifierOb = identifierObjectData[rowId];
        let filteredIdentifierOb = filteredIdentifiers[rowId];
        identifierOb = {
          ...identifierOb,
          updatedAccountIdentifier: value,
          updatedAccountIdentifierId: valueId,
        };
        filteredIdentifierOb = {
          ...filteredIdentifierOb,
          updatedAccountIdentifier: value,
          updatedAccountIdentifierId: valueId,
        };
        identifierObjectData.splice(rowId, 1, identifierOb);
        filteredIdentifiers.splice(rowId, 1, filteredIdentifierOb);
      }
    }
    if (this.state.missMatchView === "expenseAccounts") {
      if (rowId > -1) {
        let expenseAccountOb = expenseAccountObjectData[rowId];
        let filteredExpenseAccountOb = filteredExpenseAccounts[rowId];
        expenseAccountOb = {
          ...expenseAccountOb,
          updatedExpenseAccount: value,
          updatedExpenseAccountId: valueId,
        };
        filteredExpenseAccountOb = {
          ...filteredExpenseAccountOb,
          updatedExpenseAccount: value,
          updatedExpenseAccountId: valueId,
        };
        expenseAccountObjectData.splice(rowId, 1, expenseAccountOb);
        filteredExpenseAccounts.splice(rowId, 1, filteredExpenseAccountOb);
      }
    }
    this.setState({
      vendorsObject: vendorObjectData,
      identifiersObject: identifierObjectData,
      expenseAccountObject: expenseAccountObjectData,
      filteredVendorsObject: filteredVendors,
      filteredIdentifiersObject: filteredIdentifiers,
      filteredExpenseAccountsObject: filteredExpenseAccounts,
    });
  };

  handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    this.setState({ searchItem: value }, () => {
      if (this.state.missMatchView === "identifier") {
        let identifiersMainObject: IIdentifier[] = JSON.parse(
          JSON.stringify(this.state.identifiersObject)
        );
        const filteredData = identifiersMainObject.filter(
          (item) =>
            item?.prepaid_account_identifier
              ?.toLowerCase()
              ?.includes(value?.toLowerCase()) ||
            (item.updatedAccountIdentifier
              ? item.updatedAccountIdentifier
                  ?.toLowerCase()
                  .includes(value.toLowerCase())
              : false)
        );
        this.setState({ filteredIdentifiersObject: filteredData });
      } else if (this.state.missMatchView === "vendor") {
        let vendorsMainObject: IVendor[] = JSON.parse(
          JSON.stringify(this.state.vendorsObject)
        );
        const filteredData = vendorsMainObject.filter(
          (item) =>
            item?.vendor_name?.toLowerCase()?.includes(value?.toLowerCase()) ||
            (item.updatedVendor
              ? item.updatedVendor?.toLowerCase().includes(value.toLowerCase())
              : false)
        );
        this.setState({ filteredVendorsObject: filteredData });
      } else {
        let expenseAccountMainObject: IExpenseAccount[] = JSON.parse(
          JSON.stringify(this.state.expenseAccountObject)
        );
        const filteredData = expenseAccountMainObject.filter(
          (item) =>
            item?.exp_account?.toLowerCase()?.includes(value?.toLowerCase()) ||
            (item.updatedExpenseAccount
              ? item.updatedExpenseAccount
                  ?.toLowerCase()
                  .includes(value.toLowerCase())
              : false)
        );
        this.setState({ filteredExpenseAccountsObject: filteredData });
      }
    });
  };

  clearSearchRecords = () => {
    this.setState({
      filteredIdentifiersObject: this.state.identifiersObject,
      filteredVendorsObject: this.state.vendorsObject,
      filteredExpenseAccountsObject: this.state.expenseAccountObject,
      searchItem: "",
    });
  };

  getInconsistentErrorTitle = () => {
    let errMsg = configJSON.inconsistentExpenseAccountsError;
    if (this.state.isOpenErrorDialog === "identifier") {
      errMsg = configJSON.inconsistentDataError;
    } else if (this.state.isOpenErrorDialog === "vendor") {
      errMsg = configJSON.inconsistentVendorError;
    }
    return errMsg;
  };

  getInconsistentErrorDescription = () => {
    let errorMsg = configJSON.inconsistentExpenseAccountsDetails;
    if (this.state.isOpenErrorDialog === "identifier") {
      errorMsg = configJSON.inconsistentDataDetails;
    } else if (this.state.isOpenErrorDialog === "vendor") {
      errorMsg = configJSON.inconsistentVendorDetails;
    }
    return HtmlParser(errorMsg);
  };

  getDropdownList = () => {
    let optionList = this.state.expenseAccountList;
    if (this.state.missMatchView === "identifier") {
      optionList = this.state.prepaidAccountIdentifierList;
    } else if (this.state.missMatchView === "vendor") {
      optionList = this.state.vendorList;
    }
    return optionList;
  };

  getDropdownListRows = () => {
    let optionListRows: {
      vendor_name?: string;
      updatedVendor?: string;
      prepaid_account_identifier?: string;
      updatedAccountIdentifier?: string;
      exp_account?: string;
      updatedExpenseAccount?: string;
    }[] = this.state.filteredExpenseAccountsObject;
    if (this.state.missMatchView === "identifier") {
      optionListRows = this.state.filteredIdentifiersObject;
    } else if (this.state.missMatchView === "vendor") {
      optionListRows = this.state.filteredVendorsObject;
    }
    return optionListRows;
  };

  getDropdownListColumns = () => {
    let optionListColumns = configJSON.expenseAccountMissMatchColumns;
    if (this.state.missMatchView === "identifier") {
      optionListColumns = configJSON.identifierMissMatchColumns;
    } else if (this.state.missMatchView === "vendor") {
      optionListColumns = configJSON.vendorMissMatchColumns;
    }
    return optionListColumns;
  };

  deleteDropdownOption = (optionObject: string) => {
    if (this.state.missMatchView === "vendor") {
      this.deleteVendorItem(optionObject);
    } else if (this.state.missMatchView === "identifier") {
      this.deleteIdentifierItem(optionObject);
    } else {
      this.deleteExpenseAccountItem(optionObject);
    }
  };

  updateDropdownOption = (value: string, optionId: string) => {
    if (this.state.missMatchView === "vendor") {
      this.editVendorOptionItem(value, optionId);
    } else if (this.state.missMatchView === "identifier") {
      this.editIdentifierOptionItem(value, optionId);
    } else {
      this.editExpenseAccountItemOption(value, optionId);
    }
  };

  checkContinueEnabled = () => {
    if (this.state.missMatchView === "vendor") {
      return this.state.filteredVendorsObject.length > 0
        ? this.state.filteredVendorsObject.every(
            (object) => object.updatedVendor
          )
        : false;
    } else if (this.state.missMatchView === "identifier") {
      return this.state.filteredIdentifiersObject.length > 0
        ? this.state.filteredIdentifiersObject.every(
            (object) => object.updatedAccountIdentifier
          )
        : false;
    } else {
      return this.state.filteredExpenseAccountsObject.length > 0
        ? this.state.filteredExpenseAccountsObject.every(
            (object) => object.updatedExpenseAccount
          )
        : false;
    }
  };

  saveUpdatedData = () => {
    const rowFormData = new FormData();
    if (this.state.missMatchView === "vendor") {
      let vendorIds: string[] = [];
      let vendorNames: string[] = [];
      let vendorsUpdatedObjects = this.state.filteredVendorsObject.filter(
        (object) => object.updatedVendor
      );

      this.state.vendorList.forEach((item) => {
        const vendorObjList = vendorsUpdatedObjects.filter(
          (vendorItem) => vendorItem.updatedVendor === item.name
        );
        if (vendorObjList?.length > 0) {
          vendorObjList.forEach((itemOb) => {
            vendorIds.push(itemOb.vendor_name);
            vendorNames.push(itemOb?.updatedVendor as string);
          });
        }
      });
      rowFormData.append("wrong_vendors", JSON.stringify(vendorIds));
      rowFormData.append("vendors", JSON.stringify(vendorNames));
      this.setState({ updatedVendorIds: vendorIds });
    } else if (this.state.missMatchView === "identifier") {
      let identifierIds: string[] = [];
      let identifierNames: string[] = [];
      let identifiersUpdatedObjects =
        this.state.filteredIdentifiersObject.filter(
          (object) => object.updatedAccountIdentifier
        );

      this.state.prepaidAccountIdentifierList.forEach((item) => {
        const identifierObjList = identifiersUpdatedObjects.filter(
          (identifierItem) =>
            identifierItem.updatedAccountIdentifier === item.name
        );
        if (identifierObjList?.length > 0) {
          identifierObjList.forEach((itemOb) => {
            identifierIds.push(itemOb.prepaid_account_identifier);
            identifierNames.push(itemOb?.updatedAccountIdentifier as string);
          });
        }
      });
      rowFormData.append("wrong_prepaids", JSON.stringify(identifierIds));
      rowFormData.append("prepaid_accounts", JSON.stringify(identifierNames));
      this.setState({ updatedIdentifierIds: identifierIds });
    } else {
      let expenseAccountIds: string[] = [];
      let expenseAccountNames: string[] = [];
      let expenseAccountUpdatedObjects =
        this.state.filteredExpenseAccountsObject.filter(
          (object) => object.updatedExpenseAccount
        );

      this.state.expenseAccountList.forEach((item) => {
        const expenseAccountObjList = expenseAccountUpdatedObjects.filter(
          (expenseItem) => expenseItem.updatedExpenseAccount === item.name
        );
        if (expenseAccountObjList?.length > 0) {
          expenseAccountObjList.forEach((itemOb) => {
            expenseAccountIds.push(itemOb.exp_account);
            expenseAccountNames.push(itemOb?.updatedExpenseAccount as string);
          });
        }
      });
      rowFormData.append("wrong_expenses", JSON.stringify(expenseAccountIds));
      rowFormData.append(
        "expense_accounts",
        JSON.stringify(expenseAccountNames)
      );
      this.setState({ updatedExpenseAccountIds: expenseAccountIds });
    }
    this.updateVendorsAndIdentifiers(rowFormData);
  };

  backToDetails = () => {
    this.props.toggleLoader(true);
    this.deleteAccountsData();
  };

  getVendorListOfActiveAccount = async () => {
    const headersObj = {
      "Content-Type": configJSON.apiContentType,
      token: await getStorageData("token"),
    };

    const getVendorsOfActiveAccountRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getVendorsApiId = getVendorsOfActiveAccountRequestMsg.messageId;

    getVendorsOfActiveAccountRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getVendorListApiEndPoint
    );

    getVendorsOfActiveAccountRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headersObj)
    );
    getVendorsOfActiveAccountRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getBulkUploadMethod
    );

    runEngine.sendMessage(
      getVendorsOfActiveAccountRequestMsg.id,
      getVendorsOfActiveAccountRequestMsg
    );
  };

  getIdentifierOfActiveAccount = async () => {
    const headersObject = {
      "Content-Type": configJSON.apiContentType,
      token: await getStorageData("token"),
    };

    const getIdentifierOfActiveAccountRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getIdentifiersApiId = getIdentifierOfActiveAccountRequestMsg.messageId;

    getIdentifierOfActiveAccountRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getIdentifierListApiEndPoint
    );

    getIdentifierOfActiveAccountRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headersObject)
    );
    getIdentifierOfActiveAccountRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getBulkUploadMethod
    );

    runEngine.sendMessage(
      getIdentifierOfActiveAccountRequestMsg.id,
      getIdentifierOfActiveAccountRequestMsg
    );
  };

  getExpenseAccountsOfActiveAccount = async () => {
    const headers = {
      token: await getStorageData("token"),
      "Content-Type": configJSON.apiContentType,
    };

    const getExpenseAccountsOfActiveAccountRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getExpenseAccountsApiId =
      getExpenseAccountsOfActiveAccountRequestMsg.messageId;

    getExpenseAccountsOfActiveAccountRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getExpenseAccountsApiEndPoint
    );

    getExpenseAccountsOfActiveAccountRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getExpenseAccountsOfActiveAccountRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getBulkUploadMethod
    );

    runEngine.sendMessage(
      getExpenseAccountsOfActiveAccountRequestMsg.id,
      getExpenseAccountsOfActiveAccountRequestMsg
    );
  };

  updateVendorsAndIdentifiers = async (rowFormData: FormData) => {
    this.props.toggleLoader(true);
    const headers = {
      "Content-Type": undefined,
      token: await getStorageData("token"),
    };
    let apiEndPoint = configJSON.updateExpenseAccountDetailsApiEndPoint;
    if (this.state.missMatchView === "vendor") {
      apiEndPoint = configJSON.updateVendorDetailsApiEndPoint;
    } else if (this.state.missMatchView === "identifier") {
      apiEndPoint = configJSON.updateIdentifierDetailsApiEndPoint;
    }

    const updateVendorsAndIdentifiersRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateVendorsAndIdentifiersApiId =
      updateVendorsAndIdentifiersRequestMsg.messageId;

    updateVendorsAndIdentifiersRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint
    );

    updateVendorsAndIdentifiersRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    updateVendorsAndIdentifiersRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putMethod
    );

    updateVendorsAndIdentifiersRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      rowFormData
    );

    runEngine.sendMessage(
      updateVendorsAndIdentifiersRequestMsg.id,
      updateVendorsAndIdentifiersRequestMsg
    );
  };

  deleteAccountsData = async () => {
    const headers = {
      "Content-Type": configJSON.apiContentType,
      token: await getStorageData("token"),
    };

    const deleteAccountsDataRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.backToDetailsApiId = deleteAccountsDataRequestMsg.messageId;

    deleteAccountsDataRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteAccountsDataApiEndPoint
    );

    deleteAccountsDataRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    deleteAccountsDataRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteBulkUploadMethod
    );

    runEngine.sendMessage(
      deleteAccountsDataRequestMsg.id,
      deleteAccountsDataRequestMsg
    );
  };

  deleteVendorItem = async (vendorId: string) => {
    const headersOb = {
      "Content-Type": configJSON.apiContentType,
      token: await getStorageData("token"),
    };

    const deleteVendorItemRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteVendorApiId = deleteVendorItemRequestMsg.messageId;

    deleteVendorItemRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteVendorApiEndpoint + vendorId
    );

    deleteVendorItemRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headersOb)
    );
    deleteVendorItemRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteBulkUploadMethod
    );

    runEngine.sendMessage(
      deleteVendorItemRequestMsg.id,
      deleteVendorItemRequestMsg
    );
  };

  deleteIdentifierItem = async (identifierId: string) => {
    const headersObject = {
      "Content-Type": configJSON.apiContentType,
      token: await getStorageData("token"),
    };

    const deleteIdentifierItemRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteIdentifierApiId = deleteIdentifierItemRequestMsg.messageId;

    deleteIdentifierItemRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteIdentifierApiEndpoint + identifierId
    );

    deleteIdentifierItemRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headersObject)
    );
    deleteIdentifierItemRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteBulkUploadMethod
    );

    runEngine.sendMessage(
      deleteIdentifierItemRequestMsg.id,
      deleteIdentifierItemRequestMsg
    );
  };

  deleteExpenseAccountItem = async (expenseAccountId: string) => {
    const headersObject = {
      "Content-Type": configJSON.apiContentType,
      token: await getStorageData("token"),
    };

    const deleteExpenseAccountOptionItemRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteExpenseAccountApiId =
      deleteExpenseAccountOptionItemRequestMsg.messageId;

    deleteExpenseAccountOptionItemRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteExpenseAccountsApiEndPoint + expenseAccountId
    );

    deleteExpenseAccountOptionItemRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headersObject)
    );
    deleteExpenseAccountOptionItemRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteBulkUploadMethod
    );

    runEngine.sendMessage(
      deleteExpenseAccountOptionItemRequestMsg.id,
      deleteExpenseAccountOptionItemRequestMsg
    );
  };

  editVendorOptionItem = async (value: string, vendorId: string) => {
    const headersOb = {
      "Content-Type": configJSON.apiContentType,
      token: await getStorageData("token"),
    };

    let apiEndPointMsg = configJSON.createVendorApiEndpoint;
    let methodTypeMsg = configJSON.createBulkUploadMethod;

    if (vendorId) {
      this.setState({ currentVendorObject: { name: value?.trim(), value: vendorId } });
      apiEndPointMsg = configJSON.updateVendorApiEndpoint + vendorId;
      methodTypeMsg = configJSON.putMethod;
    }

    const editVendorOptionItemRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.editVendorApiId = editVendorOptionItemRequestMsg.messageId;

    editVendorOptionItemRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPointMsg
    );

    editVendorOptionItemRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headersOb)
    );
    editVendorOptionItemRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      methodTypeMsg
    );

    editVendorOptionItemRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({ vendor_name: value?.trim() })
    );

    runEngine.sendMessage(
      editVendorOptionItemRequestMsg.id,
      editVendorOptionItemRequestMsg
    );
  };

  editIdentifierOptionItem = async (value: string, identifierId: string) => {
    const apiHeaders = {
      "Content-Type": configJSON.apiContentType,
      token: await getStorageData("token"),
    };

    let apiEndPointMsg = configJSON.createIdentifierApiEndpoint;
    let methodTypeMsg = configJSON.createBulkUploadMethod;

    if (identifierId) {
      this.setState({
        currentIdentifierObject: { name: value?.trim(), value: identifierId },
      });
      apiEndPointMsg = configJSON.updateIdentifierApiEndpoint + identifierId;
      methodTypeMsg = configJSON.putMethod;
    }

    const editIdentifierItemRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.editIdentifierApiId = editIdentifierItemRequestMsg.messageId;

    editIdentifierItemRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPointMsg
    );

    editIdentifierItemRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(apiHeaders)
    );
    editIdentifierItemRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      methodTypeMsg
    );

    editIdentifierItemRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({ prepaid_account_identifier: value?.trim() })
    );

    runEngine.sendMessage(
      editIdentifierItemRequestMsg.id,
      editIdentifierItemRequestMsg
    );
  };

  editExpenseAccountItemOption = async (value: string, accountId: string) => {
    const headersOb = {
      "Content-Type": configJSON.apiContentType,
      token: await getStorageData("token"),
    };

    let apiEndPointMsg = configJSON.createExpenseAccountApiEndPoint;
    let methodTypeMsg = configJSON.createBulkUploadMethod;

    if (accountId) {
      this.setState({
        currentExpenseObject: { name: value?.trim(), value: accountId },
      });
      apiEndPointMsg = configJSON.updateExpenseAccountApiEndPoint + accountId;
      methodTypeMsg = configJSON.putMethod;
    }

    const editExpenseAccountItemRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.editExpenseAccountOptionApiId =
      editExpenseAccountItemRequestMsg.messageId;

    editExpenseAccountItemRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPointMsg
    );

    editExpenseAccountItemRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headersOb)
    );
    editExpenseAccountItemRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      methodTypeMsg
    );

    editExpenseAccountItemRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({ exp_account: value?.trim() })
    );

    runEngine.sendMessage(
      editExpenseAccountItemRequestMsg.id,
      editExpenseAccountItemRequestMsg
    );
  };

  backToReview = () => {
    let view = "vendor";
    if (this.state.isOpenErrorDialog === "identifier") {
      view = "identifier";
    } else if (this.state.isOpenErrorDialog === "expenseAccounts") {
      view = "expenseAccounts";
    }
    this.setState({ isOpenErrorDialog: "", missMatchView: view });
  };
}
