import React from "react";
import {
  IconButton,
  InputAdornment,
  TextField,
  ThemeProvider,
  createTheme,
  makeStyles,
} from "@material-ui/core";
import { VisibilityOutlined, VisibilityOffOutlined } from "@material-ui/icons";

const theme = createTheme({
  overrides: {
    MuiInputBase: {
      input: {
        "&:-webkit-autofill": {
          WebkitBoxShadow: "0 0 0 1000px white inset",
          WebkitTextFillColor: "black",
        },
        "&:-internal-autofill-selected": {
          backgroundColor: "white !important",
          WebkitBoxShadow: "0 0 0 1000px white inset",
          WebkitTextFillColor: "black !important",
        },
      },
    },
  },
});

const useStyles = makeStyles(() => ({
  root: {
    "& .MuiOutlinedInput-root": {
      backgroundColor: "#FFF",
      borderRadius: "8px",
      "& fieldset": {
        borderColor: "#CBD5E1 !important",
      },
      "& input::placeholder": {
        color: "#000",
        fontFamily: "Open Sans",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "24px",
      },
    },
    "& .MuiOutlinedInput-root.Mui-error": {
      "& fieldset": {
        borderColor: "#DC2626 !important",
      },
    },
    "& .MuiInputAdornment-root .MuiIconButton-root": {
      color: "#94A3B8",
    },
    "& .MuiFormHelperText-contained": {
      marginLeft: "0px",
      fontFamily: "Open Sans",
    },
  },
}));

interface IPasswordFieldProps {
  value: string | number | undefined;
  placeholder?: string;
  isShow: boolean;
  handleToggle: () => void;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isError?: boolean;
  errorMessage?: string;
}

const CustomPasswordInput: React.FC<IPasswordFieldProps> = ({
  value,
  handleChange,
  isShow,
  handleToggle,
  placeholder,
  isError,
  errorMessage,
}) => {
  const classes = useStyles();

  return (
    <ThemeProvider theme={theme}>
      <TextField
        className={classes.root}
        fullWidth
        variant="outlined"
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
        error={isError}
        helperText={errorMessage}
        InputLabelProps={{
          style: {
            color: "#000",
            fontFamily: "Open Sans",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "24px",
          },
        }}
        type={isShow ? "text" : "password"}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={handleToggle} edge="end">
                {isShow ? <VisibilityOutlined /> : <VisibilityOffOutlined />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        autoComplete="off"
      />
    </ThemeProvider>
  );
};

export default CustomPasswordInput;
