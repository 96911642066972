import React from "react";
import { Box, Dialog, DialogContent, makeStyles } from "@material-ui/core";
import CustomButton from "./CustomButton.web";
import CommonCheckBox from "./CommonCheckBox.web";
const dialogBackground = require("./dialog-background.svg");
const upArrow = require("./up-arrow.svg");
const downArrow = require("./down-arrow.svg");
import HtmlParser from "html-react-parser";

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    backgroundImage: `url(${dialogBackground})`,
    backgroundSize: "contain",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    padding: "2px 42px",
    [theme.breakpoints.down("xs")]: {
      padding: "2px 16px",
    },
  },
  content: {
    fontFamily: "Inter",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: 1.5,
    color: "#FFF",
    margin: "24px 32px 0px",
    padding: "0px 8px",
    maxHeight: "626px",
    "& p:first-child": {
      margin: "0px !important",
    },
    [theme.breakpoints.down("xs")]: {
      margin: "24px 16px 0px",
    },
  },
  agreeBtnContainer: {
    marginLeft: "24px",
    [theme.breakpoints.down("xs")]: {
      marginLeft: "2px",
    },
  },
  btnContainer: {
    [theme.breakpoints.down("xs")]: {
      "& button": {
        minWidth: "100px",
      },
    },
  },
}));

interface ITermsAndConditionsDialogProps {
  showTermsAndConditions: boolean;
  showPrivacyPolicy: boolean;
  isChecked: boolean;
  handleChecked: (event: React.ChangeEvent<HTMLInputElement>) => void;
  toggleAccordion: (item: number) => void;
  onAgree: () => void;
  onCancel: () => void;
  isOpen: boolean;
  termsAndConditionsDataObject: string;
  privacyPolicyDataObject: string;
}

const CustomTermsAndConditionsDialog: React.FC<
  ITermsAndConditionsDialogProps
> = ({
  showPrivacyPolicy,
  showTermsAndConditions,
  isChecked,
  handleChecked,
  toggleAccordion,
  onAgree,
  onCancel,
  isOpen,
  termsAndConditionsDataObject,
  privacyPolicyDataObject,
}) => {
  const classes = useStyles();
  return (
    <Dialog
      open={isOpen}
      fullWidth
      maxWidth="sm"
      PaperProps={{
        style: webStyle.dialogPaper,
      }}
      scroll="body"
    >
      <DialogContent className={classes.dialogContent}>
        <Box padding={"0px 7px"}>
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
            borderBottom={"1px solid #FFF"}
            marginTop={
              showPrivacyPolicy || showTermsAndConditions ? "32px" : "78px"
            }
            style={webStyle.pointer}
            onClick={() => toggleAccordion(0)}
            data-test-id="terms-conditions-tab"
          >
            <Box style={webStyle.titleLabel}>Terms and Conditions</Box>
            {showTermsAndConditions ? (
              <Box
                alignItems={"baseline"}
                display={"flex"}
                style={webStyle.showHideContainer}
              >
                Hide
                <img
                  alt="up-arrow-image"
                  style={webStyle.arrowIcon}
                  src={upArrow}
                  width={16}
                />
              </Box>
            ) : (
              <Box
                style={webStyle.showHideContainer}
                display={"flex"}
                alignItems={"baseline"}
              >
                Show
                <img
                  alt="down-arrow-image"
                  style={webStyle.arrowIcon}
                  src={downArrow}
                  width={16}
                />
              </Box>
            )}
          </Box>
          {showTermsAndConditions && (
            <Box overflow={"auto"} className={classes.content}>
              {HtmlParser(termsAndConditionsDataObject)}
            </Box>
          )}
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
            borderBottom={"1px solid #FFF"}
            marginTop={"24px"}
            style={webStyle.pointer}
            data-test-id="privacy-policy-tab"
            onClick={() => toggleAccordion(1)}
          >
            <Box style={webStyle.titleLabel}>Privacy Policy</Box>
            {showPrivacyPolicy ? (
              <Box
                display={"flex"}
                alignItems={"baseline"}
                style={webStyle.showHideContainer}
              >
                Hide
                <img
                  width={16}
                  alt="up-arrow"
                  src={upArrow}
                  style={webStyle.arrowIcon}
                />
              </Box>
            ) : (
              <Box
                display={"flex"}
                style={webStyle.showHideContainer}
                alignItems={"baseline"}
              >
                Show
                <img
                  style={webStyle.arrowIcon}
                  alt="down-arrow"
                  width={16}
                  src={downArrow}
                />
              </Box>
            )}
          </Box>
        </Box>
        {showPrivacyPolicy && (
          <Box overflow={"auto"} className={classes.content}>
            {HtmlParser(privacyPolicyDataObject)}
          </Box>
        )}
        <Box marginTop={"80px"} display={"flex"} alignItems={"start"}>
          <CommonCheckBox checked={isChecked} onChange={handleChecked} />
          <Box style={webStyle.checkboxLabel}>
            I have read and agree to these Terms Conditions and Privacy Policy
          </Box>
        </Box>
        <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          margin={"24px 0px 32px"}
          className={classes.btnContainer}
        >
          <Box>
            <CustomButton
              label="Cancel"
              variant="outlined"
              onClick={onCancel}
            />
          </Box>
          <Box className={classes.agreeBtnContainer}>
            <CustomButton
              disabled={!isChecked}
              label="Agree"
              variant="contained"
              onClick={onAgree}
            />
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

const webStyle = {
  dialogPaper: {
    minHeight: "472px",
    borderRadius: "16px",
    backgroundColor: "#001327",
  },
  titleLabel: {
    fontFamily: "Open Sans",
    fontSize: "30px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: 1.33,
    letterSpacing: -0.15,
    color: "#FFF",
  },
  showHideContainer: {
    fontFamily: "Open Sans",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: 1.44,
    color: "#FFF",
    cursor: "pointer",
  },
  arrowIcon: {
    paddingLeft: "16px",
  },
  checkboxLabel: {
    fontFamily: "Inter",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: 1.5,
    color: "#FFF",
  },
  pointer: {
    cursor: "pointer",
  },
};
export default CustomTermsAndConditionsDialog;