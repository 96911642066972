import React from "react";
import { Box, CircularProgress, makeStyles, Backdrop } from "@material-ui/core";
const background = require("../src/background.svg");

const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.down("sm")]: {
      minHeight: "calc(100vh - 72px)",
      width: "100%",
    },
    [theme.breakpoints.up("sm")]: {
      minHeight: "100vh",
      width: (props: ICustomMainPageWrapperProps) =>
        `calc(100% - ${props.width}px)`,
    },
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundColor: "#001327",
    backgroundImage: `url(${background})`,
    height: "100%",
    display: "flex",
    position: "relative",
  },
  progress: {
    position: "absolute",
    zIndex: 2,
    color: "#01AA7F",
    [theme.breakpoints.up("xs")]: {
      top: "47%",
      left: "47%",
    },
    [theme.breakpoints.down("xs")]: {
      top: "44%",
      left: "40%",
    },
    "& .MuiCircularProgress-colorPrimary": {},
  },
}));

interface ICustomMainPageWrapperProps {
  width: number;
  loader?: boolean;
}

const CustomMainPageWrapper: React.FC<ICustomMainPageWrapperProps> = ({
  width,
  children,
  loader,
}) => {
  const classes = useStyles({ width });

  return (
    <Box className={classes.container}>
      {loader && (
        <Box>
          <Backdrop style={{ zIndex: 9999 }} open={loader}>
            <CircularProgress className={classes.progress} size={60} />
          </Backdrop>
        </Box>
      )}
      {children}
    </Box>
  );
};

export default CustomMainPageWrapper;
