import React from "react";

import NavigationSidebarController, {
  Props,
  configJSON,
} from "./NavigationSidebarController";
import SidebarNavigation from "../../../components/src/SidebarNavigation.web";
import { Box, Dialog, DialogContent } from "@material-ui/core";
import CustomButton from "../../../components/src/CustomButton.web";
import { dialogBG } from "./assets";

export default class NavigationSidebar extends NavigationSidebarController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const {
      toggleSidebar,
      isMobileView,
      toggleMobileView,
      isOpenLogoutDialog,
      sidebarList
    } = this.state;
    return (
      <>
        <SidebarNavigation
          onTabClick={this.handleSidebarNavigation}
          activeTab={window.location.pathname.replace(/\//g, "")}
          toggleSidebar={toggleSidebar}
          setToggleSidebar={this.setToggleSidebar}
          isMobileView={isMobileView}
          setToggleMobileView={this.setToggleMobileView}
          toggleMobileView={toggleMobileView}
          menuList={sidebarList}
        />
        {isOpenLogoutDialog && (
          <Dialog
            open={isOpenLogoutDialog}
            PaperProps={{
              style: webStyle.logoutDialogPaper,
            }}
            fullWidth
          >
            <DialogContent style={webStyle.logoutDialogContent}>
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                style={webStyle.logoutDialogTitle}
              >
                {configJSON.logoutText}
              </Box>
              <Box
                style={webStyle.logoutDialogSubtitle}
                textAlign={"center"}
                padding={"28px 0px"}
              >
                {configJSON.logoutConfirmation}
              </Box>
              <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Box paddingRight={"8px"}>
                  <CustomButton
                    label={configJSON.cancelBtn}
                    variant="outlined"
                    onClick={this.onCloseLogoutDialog}
                    width={173}
                  />
                </Box>
                <Box>
                  <CustomButton
                    variant="contained"
                    label={configJSON.logoutText}
                    width={173}
                    onClick={this.onLogout}
                  />
                </Box>
              </Box>
            </DialogContent>
          </Dialog>
        )}
      </>
    );
  }
}

const webStyle = {
  logoutDialogPaper: {
    backgroundColor: "#001327",
    borderRadius: "16px",
    width: "418px",
  },
  logoutDialogContent: {
    backgroundPosition: "center",
    backgroundImage: `url(${dialogBG})`,
    backgroundRepeat: "no-repeat",
    padding: "40px 0px 32px",
  },
  logoutDialogTitle: {
    fontFamily: "Open Sans",
    fontSize: "30px",
    fontWeight: 700,
    fontStyle: "normal",
    lineHeight: 1.33,
    letterSpacing: -0.15,
    color: "#FFF",
  },
  logoutDialogSubtitle: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    color: "#FFF",
    lineHeight: 1.5,
  },
};
