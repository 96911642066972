import React, { CSSProperties } from "react";
import { Box, Button, Checkbox, Menu, MenuItem } from "@material-ui/core";
import NavigationSidebar from "../../../blocks/navigationmenu/src/NavigationSidebar.web";
import JournalEntriesController, {
  Props,
  configJSON,
} from "./JournalEntriesController.web";
import CustomMainPageWrapper from "../../../components/src/CustomMainPageWrapper.web";
import CustomMonthTabStepper from "../../../components/src/CustomMonthTabStepper.web";
import CustomJournalEntriesTable from "../../../components/src/CustomJournalEntriesTable.web";
import { addIcon, checkIcon, downloadIcon, uncheckIcon } from "./assets";

export default class JournalEntries extends JournalEntriesController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const {
      navigationBarWidth,
      selectedMonth,
      monthList,
      journalEntriesColumns,
      journalEntriesData,
      prepaidAccountIdentifierList,
      vendorList,
      expenseAccountList,
      menuAnchor,
      isLoading,
    } = this.state;
    return (
      <Box
        display={"flex"}
        sx={{ flexDirection: { xs: "column", sm: "row", md: "row" } }}
      >
        <Box>
          <NavigationSidebar
            navigation={this.props.navigation}
            onResize={this.onSidebarResize}
          />
        </Box>
        <CustomMainPageWrapper width={navigationBarWidth} loader={isLoading}>
          <Box height={"inherit"} width={"100%"}>
            <Box
              sx={{
                padding: {
                  xs: "32px 8px",
                  sm: "36px 16px",
                  md: "40px 24px",
                  lg: "48px 32px",
                },
              }}
            >
              <Box
                style={webStyle.journalEntriesTitle}
                data-test-id="title-text"
              >
                {configJSON.journalEntriesTitle}
              </Box>
              <Box marginTop={"32px"} style={webStyle.journalSubtitle}>
                {configJSON.journalEntriesSubTitle}
              </Box>
              <Box style={webStyle.journalMainWrapper}>
                <Box style={webStyle.activeMonth}>{selectedMonth.value}</Box>
                <Box marginBottom={"64px"}>
                  <CustomMonthTabStepper
                    stepList={monthList}
                    onStepClick={this.onChangeMonth}
                    activeStep={selectedMonth.name}
                  />
                </Box>
                <Box
                  marginBottom={"64px"}
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"flex-end"}
                >
                  <Button
                    variant="contained"
                    style={webStyle.addFieldButton}
                    onClick={this.onClickAddAnotherField}
                    data-test-id="add-another-btn"
                  >
                    <span style={webStyle.addImageWrapper}>
                      <img src={addIcon} height={18} width={18} alt="add" />
                    </span>
                    {configJSON.addAnotherFieldBtn}
                  </Button>
                  <Menu
                    anchorEl={menuAnchor}
                    keepMounted
                    open={Boolean(menuAnchor)}
                    onClose={this.handleCloseMenu}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    PaperProps={{
                      style: webStyle.menuPaper,
                    }}
                    MenuListProps={{
                      disablePadding: true,
                    }}
                    getContentAnchorEl={null}
                  >
                    {journalEntriesColumns
                      .filter(
                        (item) =>
                          !(
                            item.key === "invoice_description" ||
                            item.key === "expense_account" ||
                            item.key === "expense_amount"
                          )
                      )
                      .map((option) => (
                        <MenuItem
                          key={option.key}
                          style={this.setMenuItemStyle(option)}
                          onMouseEnter={() => this.toggleHoverState(option.key)}
                          onMouseLeave={() => this.toggleHoverState(null)}
                          onClick={() =>
                            this.handleToggleFilterItem(option.key)
                          }
                        >
                          <Checkbox
                            checked={option.isSelected}
                            checkedIcon={
                              <img src={checkIcon} alt="check" height={20} />
                            }
                            icon={
                              <img
                                src={uncheckIcon}
                                alt="uncheck"
                                height={20}
                              />
                            }
                            style={webStyle.checkBoxStyle}
                          />
                          <Box style={webStyle.optionStyle}>{option.name}</Box>
                        </MenuItem>
                      ))}
                  </Menu>
                </Box>
                <CustomJournalEntriesTable
                  columns={journalEntriesColumns}
                  rows={journalEntriesData}
                  prepaidAccountIdentifierList={prepaidAccountIdentifierList}
                  vendorList={vendorList}
                  expenseAccountList={expenseAccountList}
                  width={navigationBarWidth + 64}
                />
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  marginTop={"48px"}
                >
                  <Box marginRight={"16px"}>
                    <Button
                      style={webStyle.downloadBtn}
                      fullWidth
                      variant="outlined"
                      data-test-id="download-xls"
                      onClick={() => this.downloadJournalEntries(true)}
                    >
                      {configJSON.downloadXLS}
                      <img
                        src={downloadIcon}
                        width={"24px"}
                        alt="download"
                        style={webStyle.downloadImage}
                      />
                    </Button>
                  </Box>
                  <Box>
                    <Button
                      style={webStyle.downloadBtn}
                      variant="outlined"
                      data-test-id="download-csv"
                      fullWidth
                      onClick={() => this.downloadJournalEntries(false)}
                    >
                      {configJSON.downloadCSV}
                      <img
                        src={downloadIcon}
                        style={webStyle.downloadImage}
                        alt="download"
                        width={"24px"}
                      />
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </CustomMainPageWrapper>
      </Box>
    );
  }
}

const webStyle = {
  journalEntriesTitle: {
    color: "#FFF",
    fontFamily: "Brown",
    fontSize: "40px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "39.2px",
  },
  journalSubtitle: {
    fontStyle: "normal",
    fontFamily: "Open Sans",
    fontSize: "16px",
    fontWeight: 400,
    color: "#FFF",
    lineHeight: "24px",
  } as CSSProperties,
  journalMainWrapper: {
    backgroundColor: "#171717",
    padding: "16px 0px",
    margin: "16px 0px",
  },
  activeMonth: {
    fontFamily: "Open Sans",
    fontSize: "36px",
    fontWeight: 700,
    color: "#FFF",
    lineHeight: "44px",
    marginBottom: "16px",
  },
  downloadBtn: {
    borderRadius: "8px",
    fontFamily: "Open Sans",
    borderColor: "#FFF",
    color: "#FFF",
    textTransform: "none",
    fontSize: "12px",
    fontStyle: "normal",
    minWidth: "132px",
    fontWeight: 700,
    lineHeight: "18px",
    padding: "8px",
    minHeight: "40px",
  } as React.CSSProperties,
  downloadImage: {
    paddingLeft: "8px",
  },
  addImageWrapper: {
    paddingRight: "10px",
  },
  addFieldButton: {
    color: "#FFF",
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "22px",
    background: "linear-gradient(99deg, #01AA7F 2.64%, #00AED2 100%)",
    fontFamily: "Open Sans",
    textTransform: "none",
    borderRadius: "50px",
    width: "184px",
    padding: "8px",
    height: "38px",
  } as CSSProperties,
  optionStyle: {
    fontFamily: "Open Sans",
    color: "#FFF",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "22px",
  },
  checkBoxStyle: { padding: 0, paddingRight: "8px" },
  menuPaper: {
    width: 270,
    backgroundColor: "#171717",
    borderRadius: "0px 8px 8px 8px",
  },
};
