import React from "react";
import {
  IconButton,
  InputAdornment,
  TextField,
  makeStyles,
} from "@material-ui/core";
import { Clear, Search } from "@material-ui/icons";

const useStyles = makeStyles(() => ({
  root: {
    "& .MuiOutlinedInput-root": {
      backgroundColor: "#FFF",
      borderRadius: "50px",
      "& fieldset": {
        borderColor: "#CBD5E1 !important",
      },
      "& input": {
        padding: "8px 16px !important",
      },
      "& input::placeholder": {
        color: "#000",
        fontFamily: "Open Sans",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "24px",
      },
    },
  },
}));

interface ICustomSearchProps {
  value: string | number | undefined;
  placeholder?: string;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onClear: () => void;
}

const CustomSearchInput: React.FC<ICustomSearchProps> = ({
  value,
  handleChange,
  placeholder,
  onClear,
}) => {
  const classes = useStyles();

  return (
    <TextField
      className={classes.root}
      fullWidth
      value={value}
      onChange={handleChange}
      variant="outlined"
      placeholder={placeholder}
      InputLabelProps={{
        style: {
          color: "#000",
          fontFamily: "Open Sans",
          fontSize: "16px",
          fontStyle: "normal",
          fontWeight: 400,
          lineHeight: "24px",
        },
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <Search color="disabled" />
            <IconButton style={webStyle.iconBtn} onClick={onClear} edge="end">
              <Clear color="disabled" />
            </IconButton>
          </InputAdornment>
        ),
      }}
      autoComplete="off"
    />
  );
};

const webStyle = {
  iconBtn: {
    padding: "0px 12px",
  },
};

export default CustomSearchInput;
