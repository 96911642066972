import { Box, LinearProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { getFileSize, reducedFileName } from "./Utilities";
const uploadIcon = require("../src/upload.svg");
const browseIcon = require("../src/browse.svg");
const deleteIcon = require("../src/delete.svg");
const excel = require("../src/excel.svg");
const closeIcon = require("../src/close.svg");

const useStyles = makeStyles(() => ({
  container: {
    "& .MuiLinearProgress-bar": {
      background: "linear-gradient(99.09deg, #01AA7F 2.64%, #00AED2 100%)",
    },
  },
}));

interface ICustomFileUploadProps {
  handleFileDrop: (event: React.DragEvent<HTMLDivElement>) => void;
  handleFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleOpenFileInput: () => void;
  selectedFile: File | null;
  deleteFile: () => void;
  isFileUploadInProgress: boolean;
  uploadProgress: number;
}

const CustomFileUpload = (props: ICustomFileUploadProps) => {
  const classes = useStyles();

  return (
    <>
      {!props.selectedFile && (
        <Box
          onDragOver={(event) => event.preventDefault()}
          onDrop={props.handleFileDrop}
          style={webStyle.fileUploadContainer}
          textAlign={"center"}
        >
          <Box>
            <img src={uploadIcon} alt="" width={32} />
          </Box>
          <Box margin={"24px 0px 16px"} style={webStyle.uploadFileHeader}>
            Upload your file here
          </Box>
          <Box>
            <Box marginBottom={"16px"} style={webStyle.dragHeader}>
              Drag and drop the files here
            </Box>
            <input
              id="fileInput"
              type="file"
              onChange={props.handleFileChange}
              style={webStyle.inputStyle}
              accept=".xlsx, .xls, .csv"
            />
            <Box display={"flex"} justifyContent={"center"}>
              <Box
                data-test-id="browse-btn"
                style={webStyle.cursor}
                onClick={props.handleOpenFileInput}
                display={"flex"}
                alignItems={"center"}
              >
                <Box paddingRight={"8px"}>
                  <img src={browseIcon} alt="browse" width={20} />
                </Box>
                <Box style={webStyle.browseText}>Browse</Box>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
      {props.selectedFile && (
        <Box
          style={webStyle.uploadedContainer}
          sx={{
            padding: {
              xs: "20px",
              sm: "20px",
              md: "40px",
              lg: "40px",
            },
          }}
        >
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Box display={"flex"} alignItems={"center"}>
              <Box>
                <img src={excel} alt="" width={47} />
              </Box>
              <Box paddingLeft={"16px"}>
                <Box style={webStyle.fileName}>
                  {reducedFileName(props.selectedFile.name)}
                </Box>
                <Box style={webStyle.fileSize} paddingTop={"8px"}>
                  {getFileSize(props.selectedFile.size)}
                </Box>
              </Box>
            </Box>
            {!props.isFileUploadInProgress ? (
              <Box
                data-test-id="delete-file"
                onClick={props.deleteFile}
                style={webStyle.cursor}
              >
                <img src={deleteIcon} alt="delete" width={14} />
              </Box>
            ) : (
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Box
                  sx={{
                    width: {
                      xs: "40px",
                      sm: "64px",
                      md: "64px",
                      lg: "64px",
                    },
                  }}
                  padding={"0px 16px"}
                >
                  <LinearProgress
                    className={classes.container}
                    variant="determinate"
                    value={props.uploadProgress}
                  />
                </Box>
                <Box onClick={props.deleteFile} style={webStyle.cursor}>
                  <img src={closeIcon} alt="delete" width={14} />
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      )}
    </>
  );
};

const webStyle = {
  fileUploadContainer: {
    border: "2px dashed #CBD5E1",
    borderRadius: "16px",
    padding: "32px 24px",
  },
  uploadedContainer: {
    border: "1px solid #A8A29E",
    borderRadius: "8px",
  },
  inputStyle: {
    display: "none",
  },
  uploadFileHeader: {
    color: "#FFF",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "16px",
  },
  dragHeader: {
    color: "#A8A29E",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
  },
  browseText: {
    background: "linear-gradient(99.09deg, #01AA7F 2.64%, #00AED2 100%)",
    backgroundClip: "text",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    fontFamily: "Inter",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 700,
  },
  cursor: {
    cursor: "pointer",
  },
  fileName: {
    color: "#FFF",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
  },
  fileSize: {
    color: "#64748B",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
  },
};

export default CustomFileUpload;
