export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const background = require("../assets/background.svg");
export const stripeIcon = require("../assets/stripe.svg");
export const dialogBackground = require("../assets/dialog-background.svg");
export const upArrow = require("../assets/up-arrow.svg");
export const downArrow = require("../assets/down-arrow.svg");
export const checkIcon = require("../assets/check-icon.svg");
export const roundPass = require("../assets/round-pass.svg");
export const passIcon = require("../assets/pass-icon.svg");
export const failIcon = require("../assets/fail-icon.svg");