import React from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  makeStyles,
  Box,
} from "@material-ui/core";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CommonRadioButton from "./CommonRadioButton.web";
import CommonCheckBox from "./CommonCheckBox.web";

const useStyles = makeStyles((theme) => ({
  table: {
    overflowX: "auto",
    maxHeight: "430px",
    paddingBottom: "16px",
    [theme.breakpoints.down("sm")]: {
      maxWidth: `calc(100vw - 96px)`,
      [theme.breakpoints.down("xs")]: {
        maxWidth: `calc(100vw - 16px)`,
      },
    },
    [theme.breakpoints.up("sm")]: {
      maxWidth: (props: ICustomTableProps) => `calc(100vw - ${props.width}px)`,
    },
    "& .table-body-cell": {
      backgroundColor: "#FFF",
      color: "#0F172A",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "14px",
      padding: "12px 24px",
    },
    "& .checked-cell": {
      backgroundColor: "#01AA7F",
    },
    "& .selected-cell": {
      backgroundColor: "#00AED2",
    },
    "& .table-head-cell": {
      backgroundColor: "#001327",
      color: "#FFF",
      fontFamily: "Open Sans",
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "14px",
      padding: "8px 24px",
    },
    "& .MuiRadio-root": {
      paddingTop: "9px !important",
    },
    "& .MuiCheckbox-root": {
      padding: "0px !important",
    },
  },
}));

interface ICustomTableProps {
  columns: {
    name: string;
    checked?: boolean;
    showRadioButton?: boolean;
    selectedColumn?: boolean;
  }[];
  rows: {
    [key: string]: string | number | Date | boolean | null;
  }[];
  width: number;
  onSort?: (column: string, isAsc: boolean) => void;
  onSelectColumn?: (column: string) => void;
  showSortIcons?: boolean;
  showCheckbox?: boolean;
  selectAll?: boolean;
  onCheckboxChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
    isCheckAll: boolean,
    index?: number
  ) => void;
  sortedColumn: { name: string; isAsc: boolean };
}

const CustomTable: React.FC<ICustomTableProps> = ({
  columns,
  rows,
  width,
  onSort,
  onSelectColumn,
  showCheckbox,
  showSortIcons,
  selectAll,
  sortedColumn,
  onCheckboxChange,
}) => {
  const classes = useStyles({
    columns,
    rows,
    width,
    onSort,
    onSelectColumn,
    showCheckbox,
    showSortIcons,
    selectAll,
    onCheckboxChange,
    sortedColumn,
  });

  const handleSort = (column: {
    name: string;
    checked?: boolean | undefined;
    showRadioButton?: boolean | undefined;
    selectedColumn?: boolean | undefined;
  }) => {
    if (showSortIcons) {
      if (
        !sortedColumn.name ||
        sortedColumn.name !== column.name ||
        (sortedColumn.name === column.name && !sortedColumn.isAsc)
      ) {
        onSort && onSort(column.name, true);
      } else if (sortedColumn.name === column.name && sortedColumn.isAsc) {
        onSort && onSort(column.name, false);
      }
    }
  };

  return (
    <Box className={classes.table}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            {showCheckbox && (
              <TableCell className="table-head-cell">
                <CommonCheckBox
                  checked={!!selectAll}
                  onChange={(event) =>
                    onCheckboxChange && onCheckboxChange(event, true)
                  }
                  isTableCheckbox
                />
              </TableCell>
            )}
            {columns.map((column) => (
              <TableCell className="table-head-cell" key={column.name}>
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  style={
                    showSortIcons ? webStyle.pointer : webStyle.defaultPointer
                  }
                >
                  {column.showRadioButton && (
                    <Box>
                      <CommonRadioButton
                        isTableRadio
                        checked={column?.checked}
                        onChange={() =>
                          onSelectColumn && onSelectColumn(column.name)
                        }
                        value={column.name}
                      />
                    </Box>
                  )}
                  <Box
                    width={"max-content"}
                    padding={"0px 4px"}
                    onClick={() => handleSort(column)}
                  >
                    {column.name}
                  </Box>
                  {showSortIcons && (
                    <Box onClick={() => handleSort(column)}>
                      {!(
                        sortedColumn.name === column.name && !sortedColumn.isAsc
                      ) && (
                        <Box
                          style={webStyle.pointer}
                          onClick={() => onSort && onSort(column.name, true)}
                          data-test-id="asc-sort"
                        >
                          <ExpandLessIcon />
                        </Box>
                      )}
                      {!(
                        sortedColumn.name === column.name && sortedColumn.isAsc
                      ) && (
                        <Box
                          style={webStyle.pointer}
                          onClick={() => onSort && onSort(column.name, false)}
                          data-test-id="desc-sort"
                        >
                          <ExpandMoreIcon />
                        </Box>
                      )}
                    </Box>
                  )}
                </Box>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((rowData: any, index: number) => (
            <TableRow key={JSON.stringify(rowData) + index}>
              {showCheckbox && (
                <TableCell className="table-body-cell">
                  <CommonCheckBox
                    checked={rowData?.isSelected}
                    onChange={(event) =>
                      onCheckboxChange && onCheckboxChange(event, false, index)
                    }
                    isTableCheckbox
                  />
                </TableCell>
              )}
              {columns.map((columnRow, colIndex: number) => (
                <TableCell
                  align="center"
                  className={`table-body-cell
                    ${
                      columnRow.checked || rowData?.isSelected
                        ? "checked-cell"
                        : columnRow.selectedColumn
                        ? "selected-cell"
                        : ""
                    } 
                  `}
                  key={JSON.stringify(rowData[columnRow.name]) + colIndex}
                >
                  {rowData[columnRow.name]}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};

const webStyle = {
  pointer: {
    cursor: "pointer",
  },
  defaultPointer: { cursor: "default" },
};

export default CustomTable;
