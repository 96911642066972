import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Button } from "@material-ui/core";

const getBgColor = (props: ICustomButtonProps) => {
  let color = "transparent";
  if (props.variant === "contained") {
    color = props.disabled
      ? "#A8A29E !important"
      : "linear-gradient(99deg, #01AA7F 2.64%, #00AED2 100%)";
  }
  return color;
};

const useStyles = makeStyles((theme) => ({
  button: (props: ICustomButtonProps) => ({
    "&.MuiButton-root": {
      color: "#FFF",
      fontFamily: "Open Sans",
      fontSize: "1rem",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "1.5rem",
      border: props.variant === "contained" ? "none" : "1px solid #FFF",
      borderRadius: "0.5rem",
      textTransform: "none",
      minWidth: props.width ? props.width : "144px",
      minHeight: props.fullWidth ? "56px" : "44px",
      padding: "0px",
      background: getBgColor(props),
      [theme.breakpoints.down("xs")]: {
        minWidth: "144px",
        paddingLeft: "8px",
        paddingRight: "8px",
      },
      "&.Mui-disabled": {
        color: "#D6D3D1 !important",
      },
    },
  }),
}));

interface ICustomButtonProps {
  variant: "outlined" | "contained";
  onClick: () => void;
  label: string;
  disabled?: boolean;
  fullWidth?: boolean;
  width?: number;
  icon?: string;
}

const CustomButton = (props: ICustomButtonProps) => {
  const classes = useStyles(props);

  return (
    <Button
      className={classes.button}
      variant={props.variant}
      onClick={props.onClick}
      fullWidth={props.fullWidth}
      disabled={props.disabled}
    >
      {props.icon ? (
        <>
          <img src={props.icon} alt="btn-icon" />
          <Box paddingLeft={"8px"}> {props.label}</Box>
        </>
      ) : (
        props.label
      )}
    </Button>
  );
};

export default CustomButton;
